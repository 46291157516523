import styled from "@emotion/styled";
import { Flex as F } from "@rebass/emotion";
import { style } from "styled-system";

const display = style({
  prop: "display",
  cssProperty: "display",
  key: "display"
});

const Flex = styled(F)`
  ${display}
`;

export default Flex;
