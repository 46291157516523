import styled from "@emotion/styled";
const GradientText = styled.span`
  background: #7428ef;
  background: -webkit-linear-gradient(to right, #7428ef 0%, #d459ff 100%);
  background: -moz-linear-gradient(to right, #7428ef 0%, #d459ff 100%);
  background: linear-gradient(to right, #7428ef 0%, #d459ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-box-decoration-break: clone;
  box-sizing: border-box;
  font-weight: 800;
  line-height: 1.2;
  font-size: 40px;
  text-align: center;
  margin: 0;
  padding-bottom: 20px;
`;

export default GradientText;
