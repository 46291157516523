import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import SectionHeading from "@herb/components/SectionHeading";
import BreadCrumbs from "@herb/components/Navbar/BreadCrumbs";
import Button from "@herb/components/Button";
import ShareLinks from "@herb/components/ShareLinks";
import ProductList from "@herb/components/ProductList";
import ReactHtmlParser from "react-html-parser";
import paths from "@herb/utils/paths";
import Image from "@herb/components/Image";
import Slider from "react-slick";
import { graphql, Link } from "gatsby";
import { track } from "@herb/utils/analytics";
import { schema, deployUrl } from "@herb/utils";
// import { maxBy, minBy } from "lodash";
import SEO from "@herb/components/seo";
import JsonLd from "@herb/components/Schema/JsonLd";
import productLink from "@herb/utils/productLink";
import GraphiteProductCard from "../../components/Cards/GraphiteProductCard";
import TrendingArticleCard from "@herb/components/Cards/TrendingArticleCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { Helmet } from "react-helmet";
import { fallbackImages } from "../../utils/constants";
import { scriptCreate } from "@herb/utils/scripts";
import ProductReviews from "../../components/ProductReviews";
import Pagination from "@herb/components/Pagination";
const slugify = require("slugify");

const trackShopButtonEvent = to => () => {
  let analytics = { locationOnPage: "Product Page Affiliate CTA" };

  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Shop Button Clicked"
    });
  }

  track("Shop Button Click", {
    to,
    ...(analytics || {})
  });

  return true;
};

const ProductPage = ({ data, pageContext }) => {
  const {
    relatedProducts: { nodes: products },
    wordpressWpProduct: product,
    featuredIn
  } = data;
  const {
    noindex,
    catPath,
    paginationPath,
    pageNumber,
    skip,
    limit,
    numberOfPages
  } = pageContext;

  const allReviews = product.reviews;

  function getPaginatedReviews(reviews, skip, limit) {
    return reviews.slice(skip, skip + limit);
  }
  const paginatedReviews =
    allReviews != null ? getPaginatedReviews(allReviews, skip, limit) : [];
  const { related_links } = pageContext?.productLinks;

  // Scroll to reviews section on page change
  useEffect(() => {
    const reviewsSection = document.getElementById("reviews");
    if (reviewsSection && pageNumber > 0) {
      reviewsSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [pageNumber]);
  // Check if url is paginated for noindex
  const url =
    pageNumber == 0 ? paginationPath : `${paginationPath}/${pageNumber + 1}`;
  const regex = /\/\d+$/;
  const isPaginated = regex.test(url);

  const recommendedProducts =
    data.recommendedProducts.category_recommended_products?.recommendedProducts;

  const featuredInProductArticles = (featuredIn || {}).nodes;

  const averageRating =
    allReviews != null
      ? (
          allReviews.reduce(
            (a, b) => a + parseInt(b.rating != "" ? b.rating : 0),
            0
          ) / allReviews.length
        ).toFixed(1)
      : 5;

  const productSchema = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: schema.parseMeta(product.title),
    image: [
      (product.contentProducts.productThumbnail || {}).sourceUrl,
      ...(product.images || []).map(img => img)
    ],
    description: schema.parseMeta(product.description),
    brand: {
      "@type": "Thing",
      name: ((product.contentProducts || {}).brand || {}).title
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: averageRating > 0 ? parseFloat(averageRating) : 5,
      reviewCount: allReviews && allReviews.length > 0 ? allReviews.length : 1
    }
  };

  const breadCrumbs = [
    {
      url: paths.products,
      name: "Products"
    },
    {
      url: `${paths.products}/${slugify(product.category, { lower: true })}/`,
      name: product.category
    }
  ];

  var settings = {
    dots: true,
    dotsClass: "slick-dots custom-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    customPaging: function changeDots(i) {
      return (
        <div key={i}>
          <Image
            className={"lazyload"}
            imgixParams={{ fit: "clip", h: 70, w: 60 }}
            css={{
              height: "70px",
              width: "60px",
              borderRadius: "5px",
              objectFit: "cover"
            }}
            src={product.contentProducts.productImages[i].sourceUrl}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <div>
      <Layout boutiqueBackground={true} path={catPath}>
        <>
          {noindex ? (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          ) : isPaginated ? (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          ) : (
            ""
          )}
        </>
        <SEO
          title={`${product.brand_name} ${product.title}`}
          description={product.contentProducts.productDescription}
          image={(product.contentProducts.productThumbnail || {}).sourceUrl}
          url={productLink(product)}
          canonical={`${deployUrl}${productLink(product)}`}
          breadcrumbs={breadCrumbs}
        />
        <JsonLd data={productSchema} />
        <Container>
          <SectionHeading heading="products" />
          <BreadCrumbs
            breadcrumbs={[
              {
                url: "/",
                name: "Home"
              },
              ...breadCrumbs
            ]}
          />
          <div className="productContainer">
            <div className="productPageImage">
              <Slider {...settings}>
                {product?.contentProducts?.productImages?.map(
                  ({ sourceUrl, altText }) => (
                    <Image
                      className={`lazyload`}
                      key={sourceUrl}
                      fallback={fallbackImages.square}
                      width={480}
                      imgixParams={{ fit: "contain", q: "100" }}
                      src={sourceUrl}
                      altText={altText}
                    />
                  )
                )}
              </Slider>
            </div>
            <div className="productPageContent">
              <div className="productHeading">
                <h1 className="productName">{product?.title}</h1>
              </div>
              <div className="brandHeading">
                <Link
                  to={`${paths.brands}/${
                    ((product.contentProducts || {}).brand || {}).slug
                  }`}
                  className="brandName"
                >
                  {product?.brand_name}
                </Link>
              </div>
              <div className="buyProduct">
                <Button
                  href={product?.contentProducts?.affiliateUrl}
                  // to={product.contentProducts.affiliateUrl}
                  analytics={trackShopButtonEvent(
                    product?.contentProducts?.affiliateUrl
                  )}
                  target="_blank"
                  link
                  stlyes={{
                    fontStyle: "italic",
                    padding: "8px 24px",
                    fontSize: "20px",
                    lineHeight: "24px",
                    display: "inline-block"
                  }}
                  type="boutiqueButton"
                  rel="noopener noreferrer"
                >
                  shop now
                </Button>
              </div>
              <div className="shareProduct">
                <ShareLinks
                  article={{}}
                  url={`${paths.products}/${
                    ((product.productCategories || {}).nodes[0] || {}).slug
                  }/${((product.contentProducts || {}).brand || {}).slug}-${
                    (product || {}).slug
                  }/`}
                  media={
                    (product.productImages ? product.productImages[0] : {})
                      .source_url
                  }
                />
              </div>
              <div className="aboutProduct">
                <div className="productDescriptions">
                  {ReactHtmlParser(product.contentProducts.productDescription)}
                </div>
              </div>
              {(product?.strainType ||
                product?.thc?.units ||
                product?.cbd?.units) && (
                <>
                  <h4 className="productOverviewTitle">Overview</h4>
                  <div className="productOverviewButtons">
                    {product?.strainType && (
                      <div className="overViewButton">
                        <p className="overView">Type</p>
                        <Button type={"productButton"}>
                          {product?.strainType}
                        </Button>
                      </div>
                    )}
                    {product?.thc?.units && (
                      <div className="overViewButton">
                        <p className="overView">THC</p>
                        <Button type={"productButton"}>
                          {product?.thc?.min}
                          {"-"}
                          {product?.thc?.max} {product?.thc?.units}
                        </Button>
                      </div>
                    )}
                    {product?.cbd?.units && (
                      <div className="overViewButton">
                        <p className="overView">CBD</p>
                        <Button type={"productButton"}>
                          {product?.cbd?.min || product?.cbd?.max}{" "}
                          {product?.cbd?.units}
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
              {product.contentProducts.additionalDetails && (
                <div className="productDetails">
                  <h4 className="productDetailh4">Details</h4>
                  <div style={{ display: "flex" }}>
                    {(product.contentProducts.additionalDetails || []).map(
                      detail => (
                        <p className="productDetailP">{detail.value}</p>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* recommend products */}
          {recommendedProducts ? (
            recommendedProducts.length !== 0 ? (
              <>
                <SectionHeading heading="herb recommends" />
                <ProductList products={recommendedProducts} />
              </>
            ) : (
              <>
                {" "}
                <SectionHeading heading="herb recommends" />
                <ProductList products={products} />
              </>
            )
          ) : (
            <>
              {" "}
              <SectionHeading heading="herb recommends" />
              <ProductList products={products} />
            </>
          )}
          {/* Recommended Products Section Graphite API */}
          {related_links ? (
            <>
              <SectionHeading heading="More of Herb's favorite products" />
              <div className="graphiteProductList">
                {related_links?.map((product, index) => (
                  <GraphiteProductCard key={index} product={product} />
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Recommended Products Section Graphite API End */}
          {featuredInProductArticles.length !== 0 ? (
            <>
              <SectionHeading heading="Featured Content" />
              <div className="extra-article-div">
                {(featuredInProductArticles || []).map((article, index) => {
                  return <TrendingArticleCard article={article} key={index} />;
                })}
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Product Reviews Section */}
          <div id="reviews">
            {paginatedReviews != null && paginatedReviews.length !== 0 ? (
              <>
                <SectionHeading heading="Reviews" />
                <ProductReviews
                  reviews={paginatedReviews}
                  allReviews={allReviews != null ? allReviews : []}
                  productTitle={product.title}
                />
                {numberOfPages > 1 ? (
                  <div>
                    <Pagination path={paginationPath} context={pageContext} />
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default ProductPage;

export const pageQuery = graphql`
  query($id: String!, $category: String!, $featured_in: [Int]!) {
    wordpressWpProduct(id: { eq: $id }) {
      ...Product
    }
    featuredIn: allWordpressWpPost(
      filter: { databaseId: { in: $featured_in } }
      sort: { fields: [date], order: DESC }
      limit: 4
    ) {
      nodes {
        id
        title
        slug
        date
        modified
        categories {
          __typename
          nodes {
            name
            slug
            id
          }
        }
        content_post {
          postExcerpt
        }
        featuredImage {
          __typename
          node {
            sourceUrl
            altText
          }
        }
      }
    }

    recommendedProducts: wordpressWpProductCategory(name: { eq: $category }) {
      category_recommended_products {
        recommendedProducts {
          ... on WordpressWpProduct {
            ...Product
          }
        }
      }
    }

    relatedProducts: allWordpressWpProduct(
      filter: { category: { eq: $category }, id: { ne: $id } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...Product
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
