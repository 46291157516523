import React from "react";

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="23"
    viewBox="0 0 68 23"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path d="M22.263 21.113a4.194 4.194 0 0 1-.676-2.339V3.963c0-.866.225-1.646.676-2.339A4.193 4.193 0 0 1 24.091.065V0H13.77v.065c.79.346 1.41.866 1.86 1.56.452.692.677 1.472.677 2.338V8.92c-2.278.026-3.516.467-4.608.858-1.018.364-1.985.708-3.884.732V3.963c0-.866.226-1.646.676-2.339A4.193 4.193 0 0 1 10.32.065V0H0v.065c.79.346 1.41.866 1.86 1.56.452.692.678 1.472.678 2.338v14.811c0 .866-.226 1.646-.677 2.339A4.157 4.157 0 0 1 0 22.672v.065h10.32v-.065a4.193 4.193 0 0 1-1.828-1.56 4.195 4.195 0 0 1-.676-2.338v-6.271c2.278-.026 3.516-.466 4.608-.857 1.019-.365 1.985-.709 3.884-.732V18.774c0 .866-.225 1.646-.676 2.339a4.157 4.157 0 0 1-1.861 1.559v.065h10.32v-.065a4.193 4.193 0 0 1-1.828-1.56" />
      <path d="M28.767 11.643c.046-1.603.33-2.869.854-3.798.523-.929 1.092-1.393 1.707-1.393.66 0 1.234.476 1.723 1.428.49.953.814 2.207.973 3.763h-5.257zm7.869-4.494c-1.263-1.51-2.817-2.265-4.66-2.265-2.002 0-3.818.807-5.445 2.421-1.627 1.615-2.44 3.827-2.44 6.637 0 2.88.75 5.098 2.253 6.654 1.501 1.557 3.231 2.335 5.188 2.335 1.661 0 3.084-.61 4.267-1.83a10.467 10.467 0 0 0 2.526-4.267l-.068-.035c-1.502 1.487-3.015 2.23-4.54 2.23-1.206 0-2.264-.447-3.175-1.342-.91-.894-1.479-2.282-1.707-4.163h9.763c-.045-2.74-.7-4.866-1.962-6.375zM51.88 9.931c-.22-1.637-.766-2.768-1.635-3.393-.87-.626-1.998-.938-3.385-.938H39.2v.068c.77.41 1.255.921 1.453 1.535.198.614.297 1.466.297 2.558v8.73c0 1.092-.1 1.945-.297 2.559-.198.614-.683 1.125-1.453 1.534v.069h8.189v-.069c-.77-.409-1.255-.92-1.453-1.534-.198-.614-.298-1.467-.298-2.558V7.1h.067c.77 0 1.342.46 1.717 1.382.374.92.539 2.154.495 3.7l3.962-2.25z" />
      <path d="M61.3 20.366c-.627.628-1.544.942-2.752.942h-.923V7.926h.066c1.252 0 2.323.828 3.213 2.484.89 1.657 1.334 3.687 1.334 6.09 0 1.95-.313 3.238-.939 3.866m3.51-11.953c-1.494-1.278-3.482-1.917-5.965-1.917h-1.219V3.963c0-1.04-.1-1.852-.297-2.437-.198-.584-.681-1.072-1.45-1.461 0 0-.086-.042-.175-.065H51.2v.065c.769.39 1.252.877 1.45 1.462.197.585.296 1.397.296 2.436v14.813c0 1.039-.099 1.85-.296 2.434-.198.585-.681 1.072-1.45 1.462v.065h7.183a9.468 9.468 0 0 0 3.064-.504 9.519 9.519 0 0 0 2.768-1.494c.857-.66 1.543-1.564 2.06-2.712.516-1.148.774-2.447.774-3.898 0-2.533-.747-4.439-2.24-5.716" />
    </g>
  </svg>
);
