import React, { useEffect, useState } from "react";
import DispensaryLayout from "../../layouts/DispensaryLayout";
import Container from "@herb/components/Container";
import { graphql, Link } from "gatsby";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import { scriptCreate } from "@herb/utils/scripts";
import Collapsible from "react-collapsible";
import "react-nested-dropdown/dist/styles.css";
import "./index.css";
import { Helmet } from "react-helmet";

const Dispensaries = ({ data }) => {
  const { allWordpressWpDispensaryCategory: dispensaryCategory } = data;
  const cities = [];
  let desktopStates = [];
  let tabletStates = [];
  let mobileStates = [];

  const createBatches = (array, batchSize) => {
    const batches = [];
    for (let i = 0; i < array.length; i += batchSize) {
      const batch = array.slice(i, i + batchSize);
      batches.push(batch);
    }
    return batches;
  };
  function dataTransformer() {
    dispensaryCategory.nodes.map(dispensary => {
      if (dispensary.wpParent?.node != null) {
        const obj = {
          parentName: dispensary.wpParent.node.name,
          parentSlug: dispensary.wpParent.node.slug,
          name: dispensary.name,
          slug: dispensary.slug
        };
        cities.push(obj);
      }
    });
    const groupedCities = cities.reduce((acc, item) => {
      const { parentName, parentSlug, name, slug } = item;
      const existingItem = acc.find(
        entry =>
          entry.parentName === parentName && entry.parentSlug === parentSlug
      );

      if (existingItem) {
        existingItem.cities.push({ name, slug });
      } else {
        acc.push({
          parentName,
          parentSlug,
          cities: [{ name, slug }]
        });
      }

      return acc;
    }, []);
    const states = groupedCities.sort((a, b) =>
      a.parentName.localeCompare(b.parentName)
    );
    const arrayLength = states.length;

    //For Desktop
    const deskBatch = Math.ceil(arrayLength / 4);
    const desktopStatesData = createBatches(states, deskBatch);
    desktopStates = desktopStatesData;

    // For Tablet
    const tabBatch = Math.ceil(arrayLength / 3);
    const tabletStatesData = createBatches(states, tabBatch);
    tabletStates = tabletStatesData;

    // For Mobile
    const mobBatch = Math.ceil(arrayLength / 2);
    const mobileStatesData = createBatches(states, mobBatch);
    mobileStates = mobileStatesData;
  }
  dataTransformer();
  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <DispensaryLayout categoriesData={cities}>
      <>
        <Helmet>
          <meta name="robots" />
        </Helmet>
      </>
      <SEO
        title="Discover Weed Dispensaries in Every City and State"
        description="Find the best medical and recreational marijuana dispensaries near you, as well as CBD and other cannabis suppliers."
        url={"/dispensaries"}
        canonical={`${deployUrl}/dispensaries`}
        breadcrumbs={[]}
      />
      <Container>
        <div className="locationDesktopDiv">
          <div className="locationsDesktopColomnsMain">
            {(desktopStates || []).map((array, index) => {
              return (
                <div className="columns">
                  {array.map((category, index) => {
                    return (
                      <Collapsible trigger={`${category.parentName}`}>
                        {category.cities.map((item, index) => (
                          <Link
                            to={`/dispensaries/${category.parentSlug}/${item.slug}`}
                          >
                            <div className="city">{item.name}</div>
                          </Link>
                        ))}
                      </Collapsible>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="locationsTabletColomnsMain">
            {(tabletStates || []).map((array, index) => {
              return (
                <div className="columns">
                  {array.map((category, index) => {
                    return (
                      <Collapsible trigger={`${category.parentName}`}>
                        {category.cities.map((item, index) => (
                          <Link
                            to={`/dispensaries/${category.parentSlug}/${item.slug}`}
                          >
                            <div className="city">{item.name}</div>
                          </Link>
                        ))}
                      </Collapsible>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="locationsMobileColomnsMain">
            {(mobileStates || []).map((array, index) => {
              return (
                <div className="columns">
                  {array.map((category, index) => {
                    return (
                      <Collapsible trigger={`${category.parentName}`}>
                        {category.cities.map((item, index) => (
                          <Link
                            to={`/dispensaries/${category.parentSlug}/${item.slug}`}
                          >
                            <div className="city">{item.name}</div>
                          </Link>
                        ))}
                      </Collapsible>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </DispensaryLayout>
  );
};

export default Dispensaries;
export const pageQuery = graphql`
  query {
    allWordpressWpDispensaryCategory(sort: { fields: name }) {
      nodes {
        id
        slug
        databaseId
        name
        parentId
        wpParent {
          node {
            id
            slug
            name
          }
        }
      }
    }
  }
`;
