import React from "react";
import styled from "@emotion/styled";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon
} from "react-share";

import { Flex, Box } from "@herb/ui";

const SocialIcons = styled(Flex)`
  [role="button"] {
    cursor: pointer;
  }
`;

const ShareLinks = ({ url, media, article, ...props }) => (
  <SocialIcons alignItems="center" justifyContent={"flex-start"} {...props}>
    <Box pr={3}>
      <FacebookShareButton
        url={`https://herb.co${url}`}
        additionalprops={{ "aria-label": "Share on Facebook" }}
      >
        <FacebookIcon size={32} bgStyle={{ fill: "#535353" }} round={true} />
      </FacebookShareButton>
    </Box>
    <Box pr={3}>
      <TwitterShareButton
        url={`https://herb.co${url}`}
        additionalprops={{ "aria-label": "Share on Twitter" }}
      >
        <TwitterIcon size={32} bgStyle={{ fill: "#535353" }} round={true} />
      </TwitterShareButton>
    </Box>
    <Box pr={3}>
      <PinterestShareButton
        url={`https://herb.co${url}`}
        media={media}
        additionalprops={{ "aria-label": "Share on Pinterest" }}
      >
        <PinterestIcon size={32} bgStyle={{ fill: "#535353" }} round={true} />
      </PinterestShareButton>
    </Box>
  </SocialIcons>
);

export default ShareLinks;
