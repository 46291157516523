import React from "react";
import Button from "../Button";
import "./index.css";

const MarketingSolutionsForHeb = () => {
  return (
    <div className="solutionsForMarketing">
      <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardText">SEO</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>
        <div className="marketingContent">
          <p className="marketingText">
            Turn visitors into customers for life.
          </p>
          <Button type={"careerCardBtn"} to="/seo">
            Learn more
          </Button>
        </div>
      </div>
      <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardText">email</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>

        <div className="marketingContent">
          <p className="marketingText">
            Market your brand with trackable and measurable results.
          </p>
          <Button type={"careerCardBtn"} to="/email">
            Learn more
          </Button>
        </div>
      </div>
      {/* <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardText">photo studio</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>
        <div className="marketingContent">
          <p className="marketingText">
            Create your original product and lifestyle photography to convert
            consumers online.
          </p>
          <Button type={"careerCardBtn"}>Learn more</Button>
        </div>
      </div> */}
      <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardText">Programmatic</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>
        <div className="marketingContent">
          <p className="marketingText">
            Access the largest network of cannabis consumers in the world.
          </p>
          <Button type={"careerCardBtn"} to="/verified-partners">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MarketingSolutionsForHeb;
