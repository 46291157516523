import React from "react";
import Image from "@herb/components/Image";
import { getDate } from "@herb/utils/date";
import { orderBy } from "lodash";
import getCategoryPath from "@herb/utils/categoryPath";
import "./newcard.css";
import RedirectLink from "@herb/components/RedirectLink";
import CategoryAndDate from "../../CategoryAndDate";
import { fallbackImages } from "../../../utils/constants";

const Newcard = props => {
  const { news, strain, dispensaryCategory } = props;
  let categoryArray = [];
  if (dispensaryCategory) {
    categoryArray = news?.dispensaryCategories?.nodes;
  } else {
    categoryArray = news?.categories?.nodes;
  }

  let [category] = orderBy(categoryArray, ["databaseId"], ["desc"]);
  category = category || {
    slug: strain ? "strains" : "recipes",
    name: strain ? "strains" : "recipes"
  };
  const categoryPath = news.categories
    ? getCategoryPath(news.categories)
    : (category || {}).slug;
  const sourceImage =
    (news.featuredImage || {}).node || news.featuredImage || {};
  const date = getDate(news.date);

  return (
    <div className="sampleBlog" {...props}>
      <div className="sbPic">
        <RedirectLink to={`/${categoryPath}/${(news || {}).slug}`}>
          <Image
            className="lazyload sbImg"
            src={sourceImage.sourceUrl}
            alt={sourceImage.altText}
            fallback={fallbackImages.square}
            width={168}
            height={120}
            blur={50}
          />
        </RedirectLink>
      </div>
      <div className="sbHeadline">
        <CategoryAndDate
          path={`/${categoryPath}`}
          category={category}
          date={date}
        />
        <RedirectLink to={`/${categoryPath}/${(news || {}).slug}`}>
          <h4 className="sbHeading">{news.title}</h4>
        </RedirectLink>
      </div>
    </div>
  );
};

export default Newcard;
