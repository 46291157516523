import React from "react";
import BaseCard from "@herb/components/Cards/BaseCard.js";
import Image from "@herb/components/Image";
import { Flex, Heading, Text } from "@herb/ui";

const SeoSquareCard = ({ image, text, heading, ...props }) => {
  return (
    <BaseCard
      css={{
        background:
          "linear-gradient(122.18deg, rgba(247, 255, 227, 0.25) 31.31%, rgba(235, 250, 255, 0.25) 80.97%)",
        border: "1px solid #000",
        borderRadius: "16px",
        maxWidth: "394px",
        minWidth: "280px",
        width: "30%",
        padding: "16px",
        minHeight: "340px",

        margin: "10px"
      }}
      {...props}
    >
      <Flex
        css={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Image
          className="lazyload"
          src={`../images/seo/${image}`}
          css={{ width: "48px", marginTop: "32px" }}
        />
        <Heading
          css={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "40px",
            marginTop: "28px",
            textAlign: "center"
          }}
        >
          {heading}
        </Heading>
        <Text
          css={{
            marginTop: "8px",
            fontSize: "18px",
            lineHeight: "32px",
            fontWeight: "400",
            maxWidth: "360px",
            textAlign: "center"
          }}
        >
          {text}
        </Text>
      </Flex>
    </BaseCard>
  );
};

export default SeoSquareCard;
