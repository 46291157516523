import React from "react";
import { getDate } from "@herb/utils/date";
import getCategoryPath from "@herb/utils/categoryPath";
import "./index.css";
import { Link } from "gatsby";

const CategoryAndDate = ({
  date,
  category,
  categories,
  path,
  legacy,
  ...props
}) => {
  if (!legacy) {
    category =
      (category && (category[0] || {}).name) ||
      (category || {}).name ||
      category ||
      "Category";
  }
  const convertAlgoliaDate = d => {
    d = d.split("T");

    d = d[0].split("-");
    d = d[1] + "." + d[2] + "." + d[0];

    return d;
  };

  if ((date || "").includes("T")) {
    date = (date && convertAlgoliaDate(date)) || "01.01.2022";
  } else {
    date = (date && getDate(date)) || "01.01.2022";
  }

  return (
    <div className="categoryDate">
      <Link
        className="cusColor"
        to={path ? path : `/${getCategoryPath(categories)}`}
      >
        {category} |{" "}
      </Link>
      {date}
    </div>
    // <div className="categoryAndDate">
    //   <RedirectLink
    //     className="categoryLink"
    //     style={{ color: "#3041FF" }}
    //     to={path ? path : `/${getCategoryPath(categories)}`}
    //   >
    //     {category} |
    //   </RedirectLink>
    //   <p className="DateForCategory">{date}</p>
    // </div>
  );
};

export default CategoryAndDate;
