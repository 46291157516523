import React from "react";
import JsonLd from "@herb/components/Schema/JsonLd";
import { deployUrl } from "@herb/utils";

const SITE_URL = deployUrl;
const LOGO_URL = `${SITE_URL}/icons/icon-512x512.png`;

const website = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  "@id": "#website",
  url: SITE_URL,
  name: "Herb",
  alternateName: "Herb"
};

const webpage = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  "@id": "#webpage",
  author: {
    "@type": "Organization",
    name: "Herb",
    logo: LOGO_URL
  }
};

const org = {
  "@context": "http://schema.org",
  "@type": "Organization",
  url: SITE_URL,
  sameAs: [
    "https://www.facebook.com/herb/",
    "https://www.instagram.com/herb/",
    "https://www.linkedin.com/company/herb",
    "https://www.youtube.com/herbvideo",
    "https://www.pinterest.com/herbworthy/",
    "https://twitter.com/herbworthy"
  ],
  "@id": "#organization",
  name: "Herb",
  logo: LOGO_URL
};

export default ({ hideWebPageSchema }) => (
  <React.Fragment>
    <JsonLd data={website} />
    {!hideWebPageSchema && <JsonLd data={webpage} />}
    <JsonLd data={org} />
  </React.Fragment>
);
