import React from "react";
import Image from "@herb/components/Image";
import "./index.css";
import { Link } from "gatsby";
import { track } from "@herb/utils/analytics";
import Button from "../Button";
import { fallbackImages } from "../../utils/constants";

const ArticleProduct = ({ item }) => {
  const image = item.image;

  const title = item.title;
  const url = item.url;
  const trackShopButtonEvent = to => () => {
    let analytics = { locationOnPage: "Product Page Affiliate CTA" };

    if (typeof window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Shop Button Clicked"
      });
    }

    track("Shop Button Click", {
      to,
      ...(analytics || {})
    });

    return true;
  };
  return (
    <div className="viewProductForArticlePage">
      <div className="mSingleProduct">
        <>
          <Link
            to={url}
            target="_blank"
            onClick={() => {
              if (typeof window !== undefined) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "View Product"
                });
              }
              track("Read More Link", {
                url
              });
            }}
            style={{ color: "inherit" }}
          >
            <div className="mProductImg">
              <Image
                className="mImg lazyload"
                sizes="(min-width: 40em) 30vw, 40vw"
                src={(image || {}).sourceUrl || url}
                alt={(image || {}).altText}
                fallback={fallbackImages.square}
                imgixParams={{ ar: "3:4", fit: "clip" }}
                height={334}
                width={295}
              />
            </div>
          </Link>
        </>
        <div className="productDetailsForArticlePage">
          <div className="mProductDetails">
            <>
              <Link to={url} target="_blank" style={{ color: "inherit" }}>
                <h4 className={"mTitle"}>{title}</h4>
              </Link>
            </>
          </div>
        </div>

        <>
          <div className="buyProductOnArticle">
            <div className="buyCardProduct">
              <Button
                href={url}
                analytics={trackShopButtonEvent(url)}
                target="_blank"
                link
                stlyes={{
                  fontStyle: "italic",
                  padding: "6px 18px",
                  fontSize: "20px",
                  lineHeight: "24px",
                  display: "inline-block",
                  color: " #141313"
                }}
                type={"boutiqueButton"}
                rel="noopener noreferrer"
              >
                shop now
              </Button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ArticleProduct;
