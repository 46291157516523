import React from "react";
import styled from "@emotion/styled";
import S from "react-slick";
import { Icon, Flex, Box } from "@herb/ui";
import { track } from "@herb/utils/analytics";

const StyledArrow = styled(Icon)`
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 7px;
  transform: translate(0, -100%);
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 99px;
  background: black;
  color: white;
  z-index: 1;
  @media screen and (max-width: 376px) and (min-width: 300px) {
    width: 25px;
    height: 25px;
  }
`;

const MobileScroll = styled(Box)`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const visibility = c => (c.includes("slick-disabled") ? "hidden" : "visible");

const NextArrow = ({
  className,
  style,
  onClick,
  name,
  currentSlide,
  slideCount,
  arrowStyle
}) => (
  <StyledArrow
    icon="pointer-right"
    className="salesPageArrows"
    style={{
      ...style,
      ...arrowStyle,
      right: "-3vw",
      visibility: visibility(className)
    }}
    onClick={e => {
      track("Carousel Next Arrow", {
        carousel: name,
        startPosition: currentSlide,
        totalSlidesOfCarousel: slideCount
      });
      onClick(e);
    }}
  />
);

const PrevArrow = ({
  className,
  style,
  onClick,
  name,
  currentSlide,
  slideCount,
  arrowStyle
}) => (
  <StyledArrow
    icon="pointer-left"
    className="salesPageArrows"
    style={{
      ...style,
      ...arrowStyle,
      left: "-3vw",
      visibility: visibility(className)
    }}
    onClick={e => {
      track("Carousel Prev Arrow", {
        carousel: name,
        startPosition: currentSlide,
        totalSlidesOfCarousel: slideCount
      });
      onClick(e);
    }}
  />
);

const Slider = React.forwardRef(
  (
    {
      children,
      name,
      sliderStyles = {},
      showMobile = true,
      mobileParentProps,
      arrowStyle = {},
      ...rest
    },
    ref
  ) => {
    const makeChildren = children =>
      React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          index,
          onClick: () =>
            track("Carousel Click Item", {
              carousel: name,
              item: child.key,
              position: index
            })
        })
      );

    return (
      <React.Fragment>
        {showMobile && (
          <MobileScroll display={["block", "none"]}>
            <Flex {...mobileParentProps}>{makeChildren(children)}</Flex>
          </MobileScroll>
        )}
        <Box display={showMobile ? ["none", "block"] : "block"}>
          <S
            {...{
              dots: false,
              infinite: false,
              lazyLoad: true,
              arrows: true,
              speed: 500,
              swipeToSlide: true,
              nextArrow: <NextArrow name={name} arrowStyle={arrowStyle} />,
              prevArrow: <PrevArrow name={name} arrowStyle={arrowStyle} />,
              ...sliderStyles
            }}
            css={{
              ".slick-track": {
                margin: 0
              }
            }}
            ref={ref}
            {...rest}
          >
            {makeChildren(children)}
          </S>
        </Box>
      </React.Fragment>
    );
  }
);

export default Slider;
