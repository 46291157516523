import React, { useEffect, useState } from "react";
// import Modal from "react-modal";
// import ReferralPopUp from "../../components/PopUps/Referral";
import { DFPSlotsProvider } from "react-dfp";
import { deployUrl } from "@herb/utils";
import InstantSearch from "@herb/components/Search/InstantSearch";
import GlobalSchema from "@herb/components/Schema/GlobalSchema";
import IFrame from "@herb/components/IFrame";
import theme from "@herbkit/theme";
import { CookiesProvider } from "react-cookie";
import SEO from "@herb/components/seo";
import { ThemeProvider } from "emotion-theming";
import ReferralNavBar from "../../components/ReferralPage/ReferralNavBar";
import { scriptCreate } from "@herb/utils/scripts";
// import ReferralForm from "../../components/ReferralPage/ReferralForm";
// import { Icon, Box } from "@herb/ui";
// import Button from "@herb/components/Button";
import "./index.css";

// const isBrowser = typeof window !== "undefined";

const Referral = ({ hideWebPageSchema = false }) => {
  // const [isOpen, setIsOpen] = useState(true);
  // const [isPopUPOpen, setIsPopUpOpen] = useState(false);

  // const toggleModal = () => {
  //   if (isBrowser) {
  //     localStorage.setItem("referralPopUp", false);
  //   }

  //   setIsOpen(!isOpen);
  // };

  // const popUpToggle = () => {
  //   if (isBrowser) {
  //     localStorage.setItem("referralPopUp", false);
  //   }
  //   setIsOpen(false);
  //   setIsPopUpOpen(!isPopUPOpen);
  // };
  // const customStyles = {
  //   content: {
  //     width: "100%",
  //     height: "100%",
  //     border: "none"
  //   },
  //   overlay: {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center"
  //   }
  // };

  // const customStylesPopUp = {
  //   overlay: {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center"
  //   },
  //   content: {
  //     position: "relative",
  //     background: "rgb(255, 255, 255)",
  //     maxWidth: "550px",
  //     overflow: "hidden",
  //     padding: "0",
  //     border: "none",
  //     borderRadius: "0",
  //     height: "100%",
  //     width: "100%",
  //     inset: "0",
  //     maxHeight: "530px",
  //     margin: "10px"
  //   }
  // };
  useEffect(() => {
    scriptCreate();
  });
  return (
    <DFPSlotsProvider
      lazyload={true}
      dfpNetworkId={process.env.GATSBY_DFP_NETWORK_ID}
    >
      <GlobalSchema hideWebPageSchema={hideWebPageSchema} />
      <IFrame />
      <CookiesProvider>
        <InstantSearch>
          <ReferralNavBar />
          <ThemeProvider theme={theme}>
            <SEO
              title={`Referral`}
              url={"/refferal"}
              canonical={`${deployUrl}/refferal`}
              image={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
              }
            />
            <div className="referralPage">
              <div className="referalMainContentDiv">
                <div className="referralMainBannerImageText">
                  <div className="referralMainBannerImageQoute">
                    <span>We want to hook you up</span>
                    <span> with your next ounce.</span>
                  </div>
                  {/* <div className="referralGetItHereButton">
                    <Button onClick={popUpToggle}>Get it here</Button>
                  </div> */}
                </div>
                <div className="referralCommunityDiv">
                  <div className="referralCommunityText">
                    Here at Herb we do two things
                  </div>
                  <div className="referralCommunityCards">
                    <div className="referralCard">
                      <p className="referralCardText">
                        We’re the #1 community online for all things cannabis.
                        We seek to break down the stigma around weed with our
                        highly-curated content about cannabis products,
                        experiences and culture.
                      </p>
                    </div>
                    <div className="referralCard">
                      <p className="referralCardText">
                        We partner with cannabis brands and dispensaries to help
                        them reach more people with their incredible products.
                      </p>
                    </div>
                  </div>
                  <div className="referralValueMemberDiv">
                    <div className="referralValueMemberSub1">
                      Because you’re a valued member of our cannabis community,
                      we’d like to ask you for a favor.
                    </div>
                    <div className="referralValueMemberSub2">
                      (Don’t worry, we’ll make it worth your while.)
                    </div>
                  </div>
                </div>
                <div className="referralPartenerDiv">
                  <div className="referralLowerDiv">
                    <div className="referralPartenerLeftImage">
                      <p className="referralPartenerLeftImageText">
                        We want to partner with your favorite local dispensary
                        and help them grow.
                      </p>
                    </div>
                    <div className="referralPartenerDivArrow">
                      <div className="referralPartenerArrow">
                        <svg
                          className="referralMiddleSectionArrow"
                          width="176"
                          height="79"
                          viewBox="0 0 176 79"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M135 1L174 39.5042L135 78"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M0 40H173"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="referralPartenerRightImage">
                      <p className="referralPartenerRightImageText">
                        And we want to hook you up with your next ounce if you
                        make the intro.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="referralReachOutDiv">
                  <div className="referralReachOutSubDiv1">
                    All you have to do is reach out to your favorite dispensary
                    (or dispensaries) and tell them we’d like to have a chat
                    about helping them grow their business.
                  </div>
                  <div className="referralReachOutSubDiv2">
                    If they decide to partner with us, we will give you $300 to
                    use on your next weed purchase.
                  </div>
                </div>
                <div className="referralBottomSection">
                  <div className="bottomheading">
                    To be eligible you need to do 2 things
                  </div>
                  <div className="bottomContent">
                    <div className="bottomContentUpperDiv">
                      <div className="contentListNumber">
                        <div className="contentListNumberSubDiv">1</div>
                      </div>
                      <div className="contentListData1">
                        First, have your favorite dispensary email us at
                        andrew@herb.co or give us a call at 647-828-9861, to get
                        the conversation started.
                      </div>
                    </div>
                    <div className="bottomContentUpperDiv">
                      <div className="contentListNumber">
                        <div className="contentListNumberSubDiv">2</div>
                      </div>

                      <div className="contentListData2">
                        <p className="contentListDataP">
                          Second, (and this is extremely important) when they
                          reach out to us, make sure they include your name and
                          the email address you used to subscribe to our
                          newsletter.
                        </p>
                        <p className="contentListDataP">
                          That way if we end up partnering up with them, we can
                          get your “thank you weed.”
                        </p>
                        <p className="contentListDataP">
                          <em>And one last thing.</em>
                        </p>
                        <p className="contentListDataP">
                          Many dispensary owners are busy ensuring their awesome
                          customers (like you) are getting the good stuff week
                          in and week out without any delays.
                        </p>
                        <p className="contentListDataP">
                          You might need to follow up with them a couple of
                          times to make sure they get in touch with us.
                        </p>
                        <p className="contentListDataP">
                          Thanks in advance, and feel free to reach out to us at
                          <strong> referral@herb.co</strong> if you have any
                          questions.
                        </p>
                        <p className="contentListDataP">
                          <strong>
                            We look forward to hooking you up with your next
                            ounce.
                          </strong>
                        </p>
                      </div>
                    </div>
                    {/* <div className="bottomContentLowerDiv">
                      <div className="referralGetItHereButton">
                        <Button onClick={popUpToggle}>Get it here</Button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <footer className="referralFooter">
                  <svg
                    className="referralFooterLogo"
                    width="251"
                    height="86"
                    viewBox="0 0 251 86"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0H38.3823L38.4261 0.218972C38.1722 0.367874 37.914 0.512396 37.6558 0.656917C32.1983 3.66122 29.2879 8.32095 29.1784 14.4916C29.034 22.4841 29.1391 30.481 29.1434 38.4778C29.1434 38.6486 29.1741 38.8151 29.1959 39.0647C31.8868 39.2031 34.5835 38.9479 37.2007 38.307C39.8266 37.6633 42.365 36.8268 44.8902 35.9378C49.7679 34.2268 54.8872 33.3058 60.055 33.2094C60.2651 33.2094 60.4708 33.1743 60.7859 33.1437V31.97C60.7859 26.3074 60.8209 20.6491 60.7859 14.9865C60.7202 8.32095 57.7179 3.39845 51.6433 0.437945C51.4919 0.342302 51.3458 0.238502 51.2056 0.127004H89.868C89.5748 0.3197 89.3822 0.468601 89.1721 0.564949C83.5089 3.63056 80.5241 8.35599 80.511 14.8069C80.476 33.0955 80.476 51.3855 80.511 69.677C80.511 76.2462 83.4433 81.0899 89.2641 84.1861C89.4391 84.2825 89.6054 84.4007 89.9993 84.6241H51.1356C51.919 84.1861 52.4485 83.9321 52.9869 83.6168C58.0024 80.5512 60.694 76.0885 60.7509 70.2288C60.8428 60.7517 60.7771 51.2702 60.7771 41.7843V40.5887C58.405 40.8252 56.0986 40.8646 53.8841 41.3245C51.189 41.8789 48.5383 42.6313 45.9537 43.5755C40.8778 45.4591 35.5268 46.4948 30.115 46.6411C29.8349 46.6411 29.5592 46.6849 29.1259 46.7156V47.9769C29.1259 55.1197 29.1259 62.267 29.1259 69.4143C29.1259 76.1236 32.0101 81.1468 38.0191 84.3044C38.1591 84.3745 38.2817 84.4708 38.6537 84.7423C38.146 84.773 37.9228 84.7993 37.6996 84.7993H0.319488C0.210074 84.7993 0.105037 84.7993 0 84.7993V84.5234C0.153179 84.4358 0.306358 84.3482 0.463914 84.2694C6.26722 81.3307 9.36143 76.6272 9.38332 70.1194C9.43584 51.6118 9.38332 33.1086 9.38332 14.6011C9.41596 12.5963 9.051 10.6049 8.30954 8.74208C7.56808 6.87931 6.46484 5.18216 5.06366 3.74881C3.65442 2.26418 2.03947 0.994135 0 0.437945V0Z"
                      fill="white"
                    />
                    <path
                      d="M190.682 84.6592C191.233 84.3263 191.509 84.1512 191.794 83.9935C194.945 82.2417 196.695 79.5703 196.993 76.0054C197.207 73.4697 197.404 70.9296 197.409 68.3895C197.444 50.5272 197.444 32.6634 197.409 14.7982C197.331 12.1553 197.068 9.52109 196.621 6.91517C196.231 4.2437 194.503 2.44813 192.301 1.05108C191.89 0.788316 191.47 0.529929 191.054 0.262782C191.054 0.262782 191.032 0.205849 190.988 0.0613274C191.343 0.0613274 191.667 0.00439453 191.995 0.00439453C196.988 0.00439453 201.982 0.00439453 206.971 0.00439453C207.634 0.0226138 208.285 0.183958 208.88 0.477375C212.7 2.36054 214.451 5.60571 214.608 9.69612C214.797 14.1106 214.797 18.5295 214.875 22.9483C214.875 23.3293 214.875 23.7104 214.875 24.2052C215.956 24.2052 216.937 24.2052 217.917 24.2052C222.588 24.037 227.256 24.6105 231.747 25.9045C241.988 28.9701 248.562 36.8531 249.721 47.482C250.426 53.9767 249.953 60.305 247.489 66.4144C243.988 75.0463 237.314 80.0739 228.767 82.9862C225.242 84.1743 221.544 84.7663 217.825 84.738C209.151 84.738 200.477 84.738 191.802 84.738C191.544 84.7336 191.277 84.6986 190.682 84.6592ZM214.959 29.5569V79.3819C216.735 79.3819 218.434 79.452 220.123 79.3819C221.322 79.3462 222.512 79.1578 223.664 78.8214C227.808 77.5075 230.395 74.6959 231.222 70.3734C232.666 62.7926 232.259 55.2555 230.482 47.793C229.274 42.7303 227.226 38.0312 223.817 34.024C221.532 31.3131 218.722 29.5832 214.959 29.5569Z"
                      fill="white"
                    />
                    <path
                      d="M144.034 50.4428H107.542C108.369 56.2368 109.669 61.7111 113.936 65.8935C119.114 70.9649 125.232 72.1079 132.016 69.7386C135.819 68.4248 138.992 66.0468 141.889 63.2965C142.134 63.0644 142.393 62.8585 142.646 62.6176L142.891 62.749C142.664 63.4541 142.454 64.1724 142.204 64.8643C139.845 71.1751 136.239 76.645 130.979 80.9062C126.633 84.4098 121.565 85.693 116.011 85.4521C105.297 84.9879 94.268 76.4173 91.2395 63.9271C89.0818 55.0368 88.8936 46.0765 92.3161 37.4665C96.3644 27.2755 103.91 20.6669 114.746 18.6348C124.681 16.7517 132.948 20.3122 138.896 29.8418C141.5 34.0111 142.808 38.6533 143.425 43.4926C143.714 45.7392 143.828 48.0253 144.034 50.4428ZM126.921 43.2911C126.536 41.0708 126.252 38.973 125.801 36.9103C125.013 33.2841 123.858 29.7805 121.556 26.7587C120.593 25.5062 119.433 24.4858 117.805 24.188C115.376 23.75 113.652 24.95 112.221 26.6886C109.503 29.9864 108.282 33.9016 107.748 38.0752C107.516 39.8007 107.402 41.5437 107.231 43.3306L126.921 43.2911Z"
                      fill="white"
                    />
                    <path
                      d="M178.695 45.3624C178.748 41.4778 178.577 37.8166 177.658 34.2342C177.111 32.1014 176.345 30.0474 174.822 28.392C174.248 27.7483 173.533 27.2456 172.733 26.9229C171.933 26.6001 171.069 26.4659 170.209 26.5307V27.5512C170.209 41.9114 170.209 56.2716 170.249 70.6362C170.313 72.6822 170.523 74.7209 170.879 76.7368C171.317 79.7235 173.01 81.9001 175.553 83.4592C175.886 83.6607 176.214 83.8709 176.538 84.0855C176.586 84.1205 176.608 84.1993 176.708 84.3658H146.02C146.255 84.1668 146.502 83.9825 146.76 83.8139C150.519 81.8257 152.09 78.5104 152.366 74.4594C152.528 72.0989 152.624 69.7296 152.624 67.3647C152.624 56.0439 152.624 44.723 152.563 33.4065C152.492 31.572 152.259 29.7474 151.867 27.9541C151.333 25.1337 149.539 23.1892 147.171 21.7133L145.985 20.9864C146.273 20.9133 146.565 20.8606 146.86 20.8287C156.489 20.8287 166.117 20.8068 175.746 20.855C178.507 20.8746 181.242 21.397 183.816 22.3965C188.788 24.2972 191.457 28.0898 192.705 33.0868C193.015 34.313 193.659 35.7801 193.247 36.7655C192.836 37.7509 191.322 38.2239 190.245 38.8458C186.744 40.8515 183.243 42.8223 179.741 44.8062C179.439 44.977 179.137 45.1303 178.695 45.3624Z"
                      fill="white"
                    />
                  </svg>
                </footer>
              </div>
              {/* <div>
                <Modal isOpen={isPopUPOpen} style={customStylesPopUp}>
                  <div
                    className="marketingGuideModel1"
                    style={{
                      height: "100%",
                      background:
                        "linear-gradient( 154.02deg, rgba(255, 243, 225, 0.81) 22.37%, rgba(247, 255, 227, 0.81) 68.48%, rgba(249, 204, 204, 0.81) 111.92% )"
                    }}
                  >
                    <div className="referralCloseIcon">
                      <Box
                        width={["24px", "", "24px"]}
                        css={{
                          background: "white",
                          borderRadius: "50%"
                        }}
                        onClick={popUpToggle}
                      >
                        <Icon icon="close" width={24} height={24} viewBox="0 0 24 24" />
                      </Box>
                    </div>
                    <ReferralForm />
                  </div>
                </Modal>

                {isBrowser &&
                  localStorage.getItem("referralPopUp") !== "false" && (
                    <Modal
                      isOpen={isOpen}
                      style={customStyles}
                      className="referralModalMainDiv"
                    >
                      <div className="referralModal">
                        <div className="referralCloseIcon">
                          <Box
                            width={["24px", "", "24px"]}
                            css={{
                              background: "white",
                              borderRadius: "50%"
                            }}
                            onClick={toggleModal}
                          >
                            <Icon icon="close" width={24} height={24} viewBox="0 0 24 24" />
                          </Box>
                        </div>
                        <ReferralPopUp popUpToggle={popUpToggle} />
                      </div>
                    </Modal>
                  )}
              </div> */}
            </div>
          </ThemeProvider>
        </InstantSearch>
      </CookiesProvider>
    </DFPSlotsProvider>
  );
};

export default Referral;
