import React from "react";
import Button from "@herb/components/Button";
import Image from "@herb/components/Image";
import { Formik, Form, ErrorMessage } from "formik";
import { rules } from "@herb/components/Forms";
import "./index.css";
import { fallbackImages } from "../../utils/constants";

const StickySideNewsletter = () => {
  return (
    <div className="stickyNewsletterContent">
      <div className="mlaImage">
        <Image
          className="lazyload mlaimg"
          src="https://herb-platform-images.imgix.net/wp-content/uploads/2024/09/19072839/stickyNewsLetterImage.webp"
          alt="/"
          height={248}
          width={322}
        />
      </div>
      <div className="mlarichTextContainer">
        <div className="mlarichText">
          <p className="mlarichTextP">
            Enter your email below to receive exclusive insider updates,
            delivered directly to your inbox.
          </p>
        </div>
        <Formik
          initialValues={{
            email: ""
          }}
          validate={values => {
            let errors = {};

            // Validate email
            if (!values.email) {
              errors.email = "Required";
            } else if (!rules.email.test(String(values.email).toLowerCase())) {
              errors.email = "Please enter a valid e-mail address";
            }

            return errors;
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            const options = {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                email: values.email
              })
            };

            let url = `https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=SVQR9F`;

            try {
              const res = await fetch(url, options);
              actions.setStatus({
                message: "Thanks for signing up!"
              });

              actions.setSubmitting(false);
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {({ status, isSubmitting, handleBlur, handleChange }) => (
            <Form
              name="join-newsletter"
              method="POST"
              style={{ width: "100%" }}
            >
              <div className="mlarichTextInput">
                {(status || {}).message ? (
                  <p style={{ textAlign: "center" }}>{status.message}</p>
                ) : (
                  <div className="mlarichTextInputForm">
                    <input
                      name="email"
                      id="email"
                      className="mlarichTextInputField"
                      type="text"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      style={{
                        color: "red",
                        textAlign: "start",
                        marginTop: "8px"
                      }}
                    >
                      <ErrorMessage name="email" />
                    </p>
                    <div className="mlartButton" type="submit">
                      <Button type="submitButton">submit</Button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default StickySideNewsletter;
