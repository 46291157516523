import React from "react";
import "./index.css";

const PostEventForm = ({ buttonText = "" }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          href="https://calendly.com/herb-sales"
          alt="Broken Link"
          target="_Blank"
        >
          <button
            className="webinarModalOpenButton"
            value="grab Your Free Spot Here"
          >
            {buttonText}
          </button>
        </a>
      </div>
    </div>
  );
};

export default PostEventForm;
