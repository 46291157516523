import React from "react";
import { DFPSlotsProvider } from "react-dfp";
import DispensaryNav from "@herb/components/Navbar/DispensaryNav";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "emotion-theming";
import InstantSearch from "@herb/components/Search/InstantSearch";
import theme from "@herbkit/theme";
import AgeGate from "@herb/components/AgeGate/AgeGate";
import IFrame from "@herb/components/IFrame";
import GlobalSchema from "@herb/components/Schema/GlobalSchema";
import Footer from "@herb/components/Footer";
import "./index.css";

const isBrowser = typeof window !== "undefined";

function Layout({ children, hideWebPageSchema = false, page, categoriesData }) {
  return (
    <DFPSlotsProvider
      lazyload={true}
      disableInitialLoad
      dfpNetworkId={process.env.GATSBY_DFP_NETWORK_ID}
    >
      <div className="layout mainSec">
        <GlobalSchema hideWebPageSchema={hideWebPageSchema} />
        <IFrame />
        <CookiesProvider>
          <InstantSearch>
            <DispensaryNav categoriesData={categoriesData} page={page} />
            <ThemeProvider theme={theme}>
              {children}
              <Footer />
              <AgeGate />
            </ThemeProvider>
          </InstantSearch>
        </CookiesProvider>
      </div>
    </DFPSlotsProvider>
  );
}

export default Layout;
