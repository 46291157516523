const hideAdSlugs = [
  "cannabiotix-homecoming-the-return-of-good-weed",
  "giving-back-this-season-five-organizations-contributing-to-the-cannabis-movement",
  "letter-to-santa-mary-jane-we-asked-8-famous-stoners-to-share-their-gift-list-for-2021",
  "zoomed-a-qa-with-the-photographer-behind-immigrantstoner",
  "high-for-the-holidays-mocktail-recipes-with-cannabis-infused-drinks",
  "herb-holiday-gift-guide",
  "how-to-pass-a-drug-test",
  "how-long-does-weed-stay-in-your-urine",
  "hair-follicle-test",
  "how-to-flush-marijuana-out-of-your-system",
  "quickest-detox-thc-system",
  "best-detox-drinks-for-weed",
  "best-home-drug-test-kit",
  "best-synthetic-urine-for-a-drug-test",
  "how-to-pass-a-mouth-swab-drug-test",
  "pass-a-thc-urine-test",
  "pass-your-test-review",
  "verdant-herbals-detox",
  "clear-choice-rescue-cleanse-detox-drink-review",
  "thc-detox-kit-verdant-detox",
  "nutra-cleanse-clean-shot-review"
];

export default hideAdSlugs;
