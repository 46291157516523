import merge from "lodash.merge";

import {
  breakpoints,
  breakpointsPx,
  colors,
  fontSizes,
  lineHeights,
  measure,
  radii,
  sizes,
  space
} from "./style";

const theme = {
  breakpoints,
  breakpointsPx,
  colors,
  fontSizes,
  lineHeights,
  measure,
  radii,
  sizes,
  space
};

export const light = merge({}, theme, {
  colors: {
    background: "#FAFAFA",
    foreground: "#F4F4F4",
    border: "#ECECEC",
    text: colors.neutral[900],
    highlight: "#F4EEFF"
  }
});

export default theme;
