import React from "react";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import { slugs as createSlug } from "@herb/utils";

import "./index.css";
import RedirectLink from "../RedirectLink";

const VideoBanner = ({ serie }) => {
  return (
    <div className="videoBanner">
      <div className="bannerForVideos">
        <div
          className="videoBannerImage"
          style={{
            backgroundImage: `linear-gradient(269.88deg, rgba(20, 19, 19, 0) 32.47%, #141313 99.85%), linear-gradient(360deg, rgba(20, 19, 19, 0) 72.53%, #141313 100%), linear-gradient(180deg, rgba(20, 19, 19, 0) 64.27%, #141313 100%),url(${serie.coverImage})`
          }}
        >
          <div className="videoBannerContent">
            <div className="episodeBy">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="71"
                height="24"
                viewBox="0 0 71 24"
                fill="none"
              >
                <path
                  d="M0 0H10.7764L10.7887 0.0614796C10.7174 0.103286 10.6449 0.143862 10.5724 0.184439C9.04012 1.02794 8.22298 2.33623 8.19226 4.06872C8.15171 6.31273 8.1812 8.55796 8.18243 10.8032C8.18243 10.8512 8.19103 10.8979 8.19718 10.968C8.95267 11.0068 9.70979 10.9352 10.4446 10.7552C11.1819 10.5745 11.8946 10.3396 12.6036 10.09C13.973 9.60966 15.4104 9.35107 16.8613 9.324C16.9203 9.324 16.978 9.31416 17.0665 9.30556V8.97603C17.0665 7.38616 17.0763 5.79753 17.0665 4.20767C17.0481 2.33623 16.2051 0.954164 14.4996 0.122959C14.4571 0.0961062 14.416 0.0669629 14.3767 0.0356582H25.2317C25.1494 0.0897603 25.0953 0.131566 25.0363 0.158617C23.4463 1.01933 22.6083 2.34606 22.6046 4.15725C22.5948 9.29203 22.5948 14.4272 22.6046 19.5628C22.6046 21.4072 23.4279 22.7671 25.0621 23.6365C25.1113 23.6635 25.158 23.6967 25.2686 23.7594H14.357C14.577 23.6365 14.7257 23.5651 14.8768 23.4766C16.285 22.6159 17.0407 21.3629 17.0567 19.7177C17.0825 17.0569 17.064 14.3948 17.064 11.7315V11.3959C16.398 11.4623 15.7505 11.4733 15.1287 11.6024C14.372 11.7581 13.6278 11.9693 12.9022 12.2344C11.477 12.7633 9.97466 13.0541 8.45522 13.0952C8.37658 13.0952 8.29916 13.1075 8.17752 13.1161V13.4702C8.17752 15.4757 8.17752 17.4823 8.17752 19.489C8.17752 21.3728 8.98728 22.7831 10.6744 23.6697C10.7137 23.6893 10.7481 23.7164 10.8526 23.7926C10.71 23.8012 10.6474 23.8086 10.5847 23.8086H0.0897008C0.0589813 23.8086 0.0294907 23.8086 0 23.8086V23.7311C0.0430072 23.7065 0.0860144 23.682 0.13025 23.6598C1.75961 22.8348 2.62836 21.5142 2.6345 19.687C2.64924 14.4907 2.6345 9.29572 2.6345 4.09946C2.64366 3.53658 2.5412 2.97746 2.33302 2.45446C2.12485 1.93146 1.8151 1.45496 1.4217 1.05253C1.02603 0.635699 0.57261 0.279117 0 0.122959V0Z"
                  fill="white"
                />
                <path
                  d="M53.5366 23.769C53.6914 23.6756 53.7689 23.6264 53.8487 23.5821C54.7335 23.0903 55.225 22.3402 55.3085 21.3393C55.3687 20.6274 55.424 19.9142 55.4253 19.2011C55.4351 14.186 55.4351 9.17046 55.4253 4.15454C55.4035 3.41251 55.3296 2.67292 55.2041 1.94127C55.0947 1.19122 54.6093 0.687089 53.9913 0.294849C53.8758 0.221074 53.7578 0.148528 53.6411 0.0735225C53.6411 0.0735225 53.6349 0.0575378 53.6226 0.0169613C53.7222 0.0169613 53.8131 0.000976562 53.9053 0.000976562C55.3073 0.000976562 56.7093 0.000976562 58.1101 0.000976562C58.2962 0.00609188 58.4789 0.0513916 58.6459 0.133773C59.7186 0.662497 60.2101 1.57363 60.2543 2.72206C60.3072 3.96149 60.3072 5.20215 60.3293 6.44281C60.3293 6.54979 60.3293 6.65676 60.3293 6.79571C60.6328 6.79571 60.9081 6.79571 61.1833 6.79571C62.4947 6.74846 63.8052 6.90949 65.0662 7.27279C67.9416 8.1335 69.7872 10.3468 70.1128 13.331C70.3107 15.1545 70.1779 16.9312 69.4861 18.6465C68.5031 21.07 66.6292 22.4816 64.2294 23.2993C63.2399 23.6329 62.2017 23.7991 61.1575 23.7911C58.7221 23.7911 56.2866 23.7911 53.8512 23.7911C53.7787 23.7899 53.7037 23.7801 53.5366 23.769ZM60.3526 8.29827V22.2873C60.8515 22.2873 61.3283 22.307 61.8026 22.2873C62.1393 22.2773 62.4733 22.2244 62.7967 22.13C63.9603 21.7611 64.6865 20.9717 64.9188 19.7581C65.3243 17.6296 65.21 15.5135 64.7111 13.4183C64.372 11.9969 63.7969 10.6775 62.8397 9.55245C62.1983 8.79133 61.4094 8.30564 60.3526 8.29827Z"
                  fill="white"
                />
                <path
                  d="M40.4395 14.1629H30.194C30.4262 15.7897 30.7911 17.3266 31.9892 18.5009C33.4428 19.9248 35.1607 20.2457 37.0653 19.5805C38.1331 19.2116 39.024 18.5439 39.8374 17.7718C39.9062 17.7066 39.9787 17.6488 40.05 17.5812L40.1188 17.6181C40.0549 17.816 39.9959 18.0177 39.9259 18.212C39.2636 19.9838 38.251 21.5196 36.7741 22.716C35.5539 23.6996 34.131 24.0599 32.5716 23.9923C29.5636 23.8619 26.4671 21.4556 25.6168 17.9488C25.011 15.4527 24.9581 12.937 25.919 10.5196C27.0557 7.65836 29.1741 5.8029 32.2165 5.23237C35.0058 4.70365 37.327 5.70331 38.9969 8.3789C39.728 9.54947 40.0954 10.8528 40.2687 12.2115C40.3498 12.8423 40.3818 13.4842 40.4395 14.1629ZM35.635 12.155C35.5268 11.5316 35.447 10.9426 35.3204 10.3635C35.0992 9.34536 34.7748 8.36169 34.1285 7.51327C33.8582 7.1616 33.5325 6.87511 33.0754 6.7915C32.3935 6.66854 31.9093 7.00544 31.5075 7.49359C30.7444 8.41948 30.4016 9.51873 30.2517 10.6905C30.1866 11.175 30.1546 11.6644 30.1067 12.166L35.635 12.155Z"
                  fill="white"
                />
                <path
                  d="M50.1712 12.736C50.1859 11.6454 50.138 10.6174 49.88 9.61161C49.7264 9.0128 49.5113 8.43612 49.0837 7.97134C48.9224 7.79062 48.7217 7.64948 48.4971 7.55886C48.2725 7.46824 48.0301 7.43057 47.7886 7.44876V7.73525C47.7886 11.7671 47.7886 15.7989 47.7996 19.832C47.8176 20.4064 47.8768 20.9788 47.9766 21.5448C48.0995 22.3834 48.575 22.9945 49.2889 23.4322C49.3823 23.4888 49.4745 23.5478 49.5654 23.6081C49.5789 23.6179 49.5851 23.64 49.6133 23.6868H40.9971C41.0631 23.6309 41.1325 23.5792 41.2048 23.5318C42.2603 22.9736 42.7015 22.0428 42.7789 20.9054C42.8243 20.2427 42.8514 19.5775 42.8514 18.9135C42.8514 15.735 42.8514 12.5565 42.8342 9.37922C42.8143 8.86417 42.7489 8.35189 42.6388 7.84838C42.4889 7.05652 41.9851 6.51058 41.3203 6.09621L40.9873 5.89209C41.0681 5.87158 41.1502 5.85679 41.2331 5.84783C43.9364 5.84783 46.6397 5.84168 49.343 5.85521C50.1183 5.86073 50.8861 6.00739 51.6089 6.28802C53.0047 6.82167 53.7543 7.88649 54.1045 9.28946C54.1917 9.63375 54.3724 10.0457 54.2569 10.3223C54.1414 10.599 53.7162 10.7318 53.4139 10.9064C52.4309 11.4695 51.4479 12.0228 50.4649 12.5798C50.3801 12.6278 50.2953 12.6708 50.1712 12.736Z"
                  fill="white"
                />
              </svg>
              <p className="seriesBy">original series</p>
            </div>
            <h1 className="videoBannerTitle">{serie.title}</h1>
            <p className="videoBannerText">
              {ReactHtmlParser(serie.shortDescription)}
            </p>
            <RedirectLink
              to={`/videos/${(serie || {}).slug}/${createSlug(
                serie.videos[0].title
              )}`}
            >
              <Button type={"videoBannerBtn"}>watch now</Button>
            </RedirectLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
