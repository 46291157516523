export default {
  isValidDate: (d, m, y) => {
    let daysInMonth = 31;

    d = parseInt(d, 10);
    m = parseInt(m, 10);
    y = parseInt(y, 10);

    switch (m) {
      case 1:
        daysInMonth = (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
        break;
      case 8:
      case 3:
      case 5:
      case 10:
        daysInMonth = 30;
        break;
    }

    return m >= 0 && m < 12 && d > 0 && d <= daysInMonth;
  },
  duration: input => {
    let sec_num = parseInt(input, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
  },
  durationISO8601: input => {
    let sec_num = parseInt(input, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let duration = "";

    if (hours > 0) {
      duration += hours + "H";
    }

    if (minutes > 0) {
      duration += minutes + "M";
    }

    if (seconds > 0) {
      duration += seconds + "S";
    }

    return "PT" + duration;
  }
};
