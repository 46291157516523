import React from "react";
import Image from "@herb/components/Image";
import "./index.css";
import { fallbackImages } from "../../../utils/constants";
const DealCard = ({ deal }) => {
  const { title } = deal;
  const content = deal?.contentDeals?.content[0];
  const { product, badgeText } = content;
  return (
    <div className="dealCardMain">
      <Image
        className="dealsCardImage"
        src={product?.images ? product?.images[0] : ""}
        width={500}
      />
      <div className="dealTitleAndProductTitle">
        <h3 className="dealsCardHeading">{title}</h3>
        {product?.brand_name && product?.title ? (
          <>
            <p className="dealsProductandBrandTitle">{product?.brand_name}</p>
            <p className="dealsProductandBrandTitle">{product?.title}</p>
          </>
        ) : (
          <>
            <p className="dealsProductandBrandTitle"></p>
          </>
        )}
        <p className="dealsCodeCard">{badgeText}</p>
        <a
          className="dealsCardLink"
          href={content?.dealsPageUrl}
          target="_blank"
          rel="noreferrer"
        >
          View Deal
        </a>
      </div>
    </div>
  );
};

export default DealCard;
