import React from "react";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import { PageHeading } from "@herb/ui";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <Layout>
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <SEO title="404: Not found" />
      <PageHeading subheading="Sorry the page you're looking for no longer exists or may have been moved.">
        Not Found
      </PageHeading>
    </Layout>
  );
};

export default NotFoundPage;
