export default {
  AB: 0.05,
  BC: 0.12,
  MB: 0.13,
  NB: 0.15,
  NL: 0.15,
  NT: 0.05,
  NS: 0.15,
  NU: 0.05,
  ON: 0.13,
  PE: 0.15,
  QC: 0.14975,
  SK: 0.11,
  YT: 0.05
};
