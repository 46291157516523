import React, { useEffect } from "react";
import { graphql } from "gatsby";
import DispensaryLayout from "../../layouts/DispensaryLayout";
import { deployUrl } from "@herb/utils";
import DispensaryBanner from "../../components/DispensaryBanner";
import DispensaryTabs from "../../components/DispensaryTabs";
import DispensarySlider from "../../components/Sliders/DispensarySlider";
import Container from "@herb/components/Container";
import SEO from "@herb/components/seo.js";
import SectionHeading from "@herb/components/SectionHeading";
import { Link } from "gatsby";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const Dispensary = ({ pageContext, data }) => {
  const {
    wordpressWpDispensary: dispensary,
    allWordpressWpDispensary,
    nearbyDispensaries
  } = data;
  const {
    related_links: { related_links: all_related_links }
  } = pageContext;

  const featuredDispensaries = allWordpressWpDispensary?.nodes;

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <DispensaryLayout>
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <SEO
        title={`${((dispensary.acf || {}).seo || {}).title ||
          dispensary.title} | Weed Dispensary in ${
          dispensary.content_dispensary?.dispensaryAddress?.city
        }`}
        shareTitle={((dispensary.acf || {}).seo || {}).share_title}
        description={
          ((dispensary.acf || {}).seo || {}).meta_description ||
          dispensary.content_dispensary.excerpt
        }
        image={((dispensary.featuredImage || {}).node || {}).sourceUrl}
        url={`/dispensaries/${(dispensary || {}).slug}`}
        canonical={`${deployUrl}/dispensaries/${(dispensary || {}).slug}`}
      />
      <Container>
        <div className="dispensaryPage">
          <DispensaryBanner dispensary={dispensary} />
          <div className="dispensaryTabs">
            <DispensaryTabs
              context={pageContext}
              dispensary={dispensary}
              nearbyDispensaries={nearbyDispensaries}
            />
          </div>
          {featuredDispensaries.length > 2 ? (
            <div className="dispensaryDetailsLower">
              <div className="featuredDispensariesHeading">
                see more dispensaries
              </div>
              <DispensarySlider featuredDispensaries={featuredDispensaries} />
            </div>
          ) : (
            <></>
          )}

          <SectionHeading heading="MORE DISPENSARIES NEARBY" />
          <div className="relatedDispensaryLinks">
            {all_related_links?.map((link, index) => {
              return (
                <Link
                  key={index}
                  to={link.url_path}
                  className="relatedDispensaryLinksCard"
                >
                  <p className="relatedDispensaryLinkTitle">{link.title}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </Container>
    </DispensaryLayout>
  );
};

export default Dispensary;

export const pageQuery = graphql`
  query($id: String!, $catSlug: String, $dispArray: [Int]!) {
    wordpressWpDispensary(id: { eq: $id }) {
      ...Dispensary
    }
    allWordpressWpDispensary(
      filter: {
        dispensaryCategories: {
          nodes: { elemMatch: { slug: { eq: $catSlug } } }
        }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 5
    ) {
      nodes {
        ...Dispensary
      }
    }
    nearbyDispensaries: allWordpressWpDispensary(
      filter: { databaseId: { in: $dispArray } }
    ) {
      nodes {
        ...Dispensary
      }
    }
  }
`;
