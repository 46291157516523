import React from "react";
import { Link } from "gatsby";

const RedirectLink = ({ children, to, ...props }) => {
  return (
    <Link style={{ color: "inherit" }} to={to} {...props}>
      {children}
    </Link>
  );
};

export default RedirectLink;
