import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import SectionHeading from "@herb/components/SectionHeading";
import ProductList from "@herb/components/ProductList";
import ProductNav from "@herb/components/Navbar/ProductNav";
import SEO from "@herb/components/seo";
import { paths, deployUrl } from "@herb/utils";
import Featuredcard from "@herb/components/Cards/Featuredcard";
import ProductSlider from "@herb/components/ProductsPage/ProductSlider";
import MiddleSectionCard from "@herb/components/ProductsPage/MiddleSectionCard";
import BuyersGuide from "@herb/components/ProductsPage/BuyersGuide";
import BrandsWeLove from "@herb/components/ProductsPage/BrandsWeLove";
import HerbProductNewsletter from "@herb/components/HerbProductNewsLetter";
import ProductAndBrandTabs from "@herb/components/ProductAndBrandTabs";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";
import "react-tabs/style/react-tabs.css";

const Boutique = ({ data }) => {
  const { allWordpressWpProductCategory: categories } = data;

  const trendingProducts = data?.wordpressWpPage?.template?.trendingProducts;

  const topFeatureSection = trendingProducts?.topFeatureSection;
  const {
    herbHighlights,
    summerSeason,
    brandsWeLove,
    buyerGuide,
    middleSection
  } = trendingProducts;

  const {
    featureProducts,
    featureSection: middleFeatureSection
  } = middleSection;

  const {
    herbProductHype,
    topBrands
  } = trendingProducts?.secondaryFeatureSection;

  const breadcrumbs = [
    {
      name: "Products",
      url: paths.products
    }
  ];

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <div>
      <Layout boutiqueBackground={true} page={"products"} path={paths.products}>
        <SEO
          title="Shop The Best Weed & CBD Brands Online | Herb Products"
          description="Shop the best marijuana THC, CBD and Delta 8 products online. Browse premium cannabis accessories, gummies, flower, growing kits & more."
          url={paths.products}
          breadcrumbs={breadcrumbs}
          canonical={`${deployUrl}${paths.products}`}
        />

        <div className="section1">
          <Featuredcard article={topFeatureSection} page={"products"} />
          <div className="cardContainer">
            <div className="sampleBlogsDiv">
              <ProductAndBrandTabs
                brands={topBrands}
                products={herbProductHype}
              />
            </div>
          </div>
        </div>

        <div className="section4">
          <SectionHeading heading={"herb highlights"} topHeading />
          <div>
            <div className="productSlidersWidth">
              <ProductSlider products={herbHighlights} />
            </div>
            <div className="HerbProductNewsletter">
              <HerbProductNewsletter />
            </div>
          </div>
        </div>
        <div className="section4">
          <SectionHeading heading={"Devices We Love"} topHeading />
          <div className="productSlidersWidth">
            <ProductSlider products={summerSeason} />
          </div>
        </div>
        <div style={{ background: "black" }}>
          <div className="productSection5">
            <MiddleSectionCard article={middleFeatureSection} />
            <div className="productSlidersWidth">
              <ProductSlider
                section={"middleSection"}
                products={featureProducts}
              />
            </div>
          </div>
        </div>

        <div className="section4">
          <SectionHeading heading={"buyer’s guide"} topHeading />
          <div className="guideForBuyers">
            {(buyerGuide || []).map((buyer, index) => {
              return <BuyersGuide post={buyer} key={index} />;
            })}
          </div>
        </div>

        <div className="section4">
          <SectionHeading heading={"brands we love"} topHeading />
          <div className="brandsWeLoveDiv">
            {brandsWeLove.map((brand, index) => (
              <BrandsWeLove brand={brand} key={index} />
            ))}
          </div>
        </div>

        <Container>
          <SectionHeading heading="All Products" topHeading />
          <ProductNav categories={categories} boutique={true} />
          <ProductList trendingProducts={trendingProducts.trendingProducts} />
        </Container>
      </Layout>
    </div>
  );
};

export default Boutique;

export const pageQuery = graphql`
  query {
    ...allWordpressWpProductCategory
    wordpressWpPage(slug: { eq: "products" }) {
      template {
        ... on WordpressWpTemplate_TrendingProducts {
          templateName
          trendingProducts {
            animateLoop
            trendingProducts {
              ... on WordpressWpProduct {
                ...Product
              }
            }
            topFeatureSection {
              featureLink
              featureTitle
              featureImage {
                sourceUrl
                altText
                date(formatString: "DD,MM,YYYY")
              }
            }
            secondaryFeatureSection {
              herbProductHype {
                ... on WordpressWpProduct {
                  ...Product
                }
              }
              topBrands {
                ... on WordpressWpBrand {
                  slug
                  title
                  url
                  logo
                  link
                }
              }
            }
            herbHighlights {
              ... on WordpressWpProduct {
                ...Product
              }
            }
            summerSeason {
              ... on WordpressWpProduct {
                ...Product
              }
            }
            middleSection {
              featureProducts {
                ... on WordpressWpProduct {
                  ...Product
                }
              }
              featureSection {
                featureBlurb
                featureLink
                featureTitle

                featureImage {
                  sourceUrl
                  altText
                  date(formatString: "DD,MM,YYYY")
                }
              }
            }
            buyerGuide {
              ... on WordpressWpPost {
                ...Post
              }
            }
            brandsWeLove {
              ... on WordpressWpBrand {
                slug
                title
                url
                logo
                link
              }
            }
          }
        }
      }
    }
  }
`;
