import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import "./index.css";
import { fallbackImages } from "../../../utils/constants";

const BrandsWeLove = ({ brand = {} }) => {
  const { slug, title = "Sample Brand", logo } = brand;
  return (
    <div className="brandsWeLove">
      <div className="brandsWeLoveImage ">
        <Link to={`/brands/${slug}`} className="brandsWeLoveViewBrand">
          <Image className="image lazyload" src={logo} />
        </Link>
      </div>

      <div className="brandsWeLoveContent">
        <h3 className="heading ">{title}</h3>
        <div>
          <Link to={`/brands/${slug}`} className="brandsWeLoveViewBrand">
            view brand
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BrandsWeLove;
