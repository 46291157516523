import React from "react";

import { Flex } from "@herb/ui";

export default ({ css = {}, ...rest }) => (
  <Flex
    flexDirection="column"
    css={{
      width: "100%",
      position: "relative",
      borderRadius: "32px",
      overflow: "hidden",
      transition: "box-shadow 0.4s ease-in-out",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08)",
      ":hover": {
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.24)"
      },
      ...css
    }}
    {...rest}
  />
);
