module.exports = {
  brands: "/brands",
  learn: "/learn",
  guides: "/guides",
  news: "/news",
  products: "/products",
  boutique: "/boutique",
  categories: "/categories",
  watch: "/watch",
  authors: "/authors",
  series: "/watch/series",
  claim: "/claim-brand",
  app: "/app",
  read: "/read",
  readAll: "/read/all",
  collections: "/collections",
  lists: "/lists",
  doctors: "/authors",
  stores: "/stores",
  careers: "/careers",
  cities: "/cities",
  partnerships: "/partnerships",
  partnershipsForm: "/partnerships-form",
  pricing: "/pricing",
  pricingForm: "/pricing-form",
  tags: "/tags",
  growing: "/growing",
  newsletters: "/newsletters",
  legacy: {
    news: "/news",
    recipes: "/recipes",
    strains: "/strains",
    products: "/learn",
    read: "/read",
    travel: "/news/travel",
    health: "/news/health",
    culture: "/news/culture",
    legalization: "/news/legalization",
    industry: "/news/industry",
    sports: "/news/sports",
    events: "/news/events",
    learn: "/learn",
    guides: "/guides",
    uncategorized: "/uncategorized"
  }
};
