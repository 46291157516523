import React from "react";
import { Link } from "gatsby";
import "./index.css";

const BreadCrumbs = ({ breadcrumbs, ...props }) => {
  return (
    <div className="productPageNav">
      <div className="productPageNavLinks">
        {breadcrumbs.map(obj => (
          <Link className="productPageLink" to={obj.url} key={obj.url}>
            {obj.name} /
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BreadCrumbs;
