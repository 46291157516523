import React from "react";
import "./index.css";

const DispensaryButtons = ({ item }) => {
  const { webiste } = item.content_dispensary.websiteAndDirections;
  const { dispensaryAddress } = item.content_dispensary;
  let doFollow = false;
  if (
    `${webiste}` ==
    "https://ma.temescalwellness.com/?utm_source=herb&utm_medium=listing&utm_campaign=herb_brand#shop"
  ) {
    doFollow = true;
  }
  return (
    <div className="container-DispensaryButtons">
      {webiste ? (
        <a
          className="dispButtonAnchorLinkBlue"
          href={`${webiste}`}
          target="_blank"
          rel={doFollow ? "noreferrer" : "nofollow noreferrer"}
        >
          Visit Website
        </a>
      ) : (
        <></>
      )}

      {dispensaryAddress ? (
        <a
          className="buttonAnchorLinkWhite"
          href={`https://www.google.com/maps/search/?api=1&query=${dispensaryAddress.latitude},${dispensaryAddress.longitude}&zoom=14`}
          target="_blank"
          rel="nofollow noreferrer"
        >
          Get Directions
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DispensaryButtons;
