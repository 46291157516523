import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import { Box, Flex, Heading } from "@herb/ui";
import Container from "@herb/components/Container";
import Fade from "react-reveal/Fade";
import MarketingPlan from "@herb/components/MarketingPlan";
import HerbPlusBanner from "@herb/components/Cards/HerbPlusBanner";
import SeoQuote from "@herb/components/SeoQuote";
import SeoSquareCard from "@herb/components/Cards/SeoSquareCard";
import animationObserver from "@herb/utils/animationObserver";
import SEO from "@herb/components/seo";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const EmailMarketing = ({ data, pageContext }) => {
  useEffect(() => {
    animationObserver.observe(document.querySelector(".animate-div"));
    scriptCreate();
  }, []);
  return (
    <Layout>
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <SEO
        title={`Email`}
        url={"/email"}
        image={
          "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
        }
      />

      <HerbPlusBanner
        heading="Email is your best friend."
        subheading="Interact with the most engaged cannabis community 1:1 like never before. Access targeted email lists and drive sales today."
        backgroundImage="Frame249.png"
      />

      <Container>
        <Box>
          <Box>
            <Fade bottom>
              <Heading
                css={{
                  color: "#3041FF",
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "48px",
                  lineHeight: "56px",
                  paddingTop: "120px",
                  paddingBottom: "30px",
                  maxWidth: "600px",
                  margin: "0 auto",
                  "@media screen and (max-width:768px)": {
                    fontSize: "32px",
                    lineHeight: "40px",
                    paddingTop: "15px"
                  }
                }}
              >
                How Herb can help you with email marketing
              </Heading>
            </Fade>
            <Flex
              className="animate-div"
              css={{
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-1"
                heading="Access targeted email lists"
                text="Email banner ads in targeted emails to convert Herb users to website visitors."
                image="square4.png"
              />
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-2"
                heading="Get real conversions from real people."
                text="Herb has industry leading open rates (27%) and click-through rates (16%)."
                image="square5.png"
              />
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-3"
                heading="Turn traffic into loyal customers."
                text="Our emails give people the content they know and love. We help endear them to your brand and help you grow predictable traffic and revenue."
                image="square6.png"
              />
            </Flex>
          </Box>
        </Box>
      </Container>
      <Box
        css={{
          paddingTop: "25px",
          background:
            "linear-gradient(190.02deg,rgb(255 255 255 / 81%) 15.01%,rgba(235,250,255,0.81) 63.27%,rgba(235,250,255,0) 93.05%)"
        }}
      >
        <div
          css={{
            padding: "0px 64px",
            "@media screen and (max-width:768px)": {
              padding: "0px 16px"
            }
          }}
        >
          <SeoQuote
            starting="“Herb emails are an "
            colored="immersive experience that emotionally engage consumers and drive real-life action."
            ending="They have helped us accomplish our mission by driving more eyeballs and helping us increase sales.”"
            author="Ryan Meyers - Marketing, Cresco"
          />
        </div>

        <MarketingPlan />
      </Box>
    </Layout>
  );
};

export default EmailMarketing;
