import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import VideoBanner from "@herb/components/VideoBanner";
import SectionHeading from "@herb/components/SectionHeading";
import MiniShows from "@herb/components/MiniShows";
import SEO from "@herb/components/seo";
import SmallVideoArticles from "@herb/components/SmallVideoArticles";
// import DisplayAd from "@herb/components/DisplayAd";
import { paths } from "@herb/utils";
import { graphql } from "gatsby";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const Video = ({ data }) => {
  const videoSettings = data.legacyWP.videoSettings;
  const series = videoSettings.series.reduce((acc, curr) => {
    const _ser = data.allSeries.edges.find(s => s.node.id === curr);

    if (_ser) {
      acc.push(_ser);
    }

    return acc;
  }, []);

  const { node: latest } = data.allSeries.edges.find(
    s => (s.node || {}).slug === "latest"
  );

  const { node: featured } = data.allSeries.edges.find(
    s => s.node.id === videoSettings.series[0]
  );

  const breadcrumbs = [
    {
      name: "Watch",
      url: paths.watch
    }
  ];
  useEffect(() => {
    scriptCreate();
  }, []);
  return (
    <div className="videoTemplate">
      <Layout>
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <SEO
          title="Watch Cannabis Videos"
          description="Watch High Moms roll joints, laugh with Cannabis Longley, & get inspired by real Strain Stories with Herb cannabis videos. Don’t forget the popcorn!"
          url={paths.watch}
          breadcrumbs={breadcrumbs}
        />
        <VideoBanner serie={featured} />
        <Container>
          <SectionHeading heading="trending shows" />
          <div className="suggestionsForShows">
            {(series || []).map(({ node }) => (
              <MiniShows serie={node} key={node.id} />
            ))}
          </div>
          {/* <DisplayAd
            visibleProps={{
              margin: "80px 0px"
            }}
            adUnit="21810472093/Homepage/"
            banners={[
              {
                breakpoint: "mobile",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "tablet",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "desktop",
                sizes: [[728, 90]]
              }
            ]}
          /> */}
          <SectionHeading heading="latest episode" />
          <div className="ForLatestShows">
            {(latest.videos.slice(0, 6) || []).map(video => (
              <div className="forWrapDiv">
                <SmallVideoArticles
                  path={(latest || {}).slug}
                  key={video.id}
                  video={video}
                />
              </div>
            ))}
          </div>

          {/* <DisplayAd
            visibleProps={{
              margin: "80px 0px"
            }}
            adUnit="21810472093/Homepage/"
            banners={[
              {
                breakpoint: "mobile",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "tablet",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "desktop",
                sizes: [[728, 90]]
              }
            ]}
          /> */}
          <SectionHeading heading="incase you missed it" />
          <div className="suggestionsForShows">
            {(series || []).map(({ node }) => (
              <MiniShows serie={node} key={node.id} />
            ))}
          </div>

          {/* <DisplayAd
            visibleProps={{
              marginTop: "80px"
            }}
            adUnit="21810472093/Homepage/"
            banners={[
              {
                breakpoint: "mobile",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "tablet",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "desktop",
                sizes: [[970, 250]]
              }
            ]}
          /> */}
        </Container>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    legacyWP {
      videoSettings {
        playerId
        homepagePlaylist
        featuredVideo {
          type
          image
          video
          title
          subtitle
          url
          cta
        }
        series
        playerId
      }
    }
    allSeries {
      edges {
        node {
          id
          slug
          title
          bannerImage
          logoImage
          coverImage
          shortDescription
          videos {
            mediaid
            title
            description
            image
          }
        }
      }
    }
  }
`;

export default Video;
