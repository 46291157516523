import React from "react";
import { Link } from "gatsby";
import "./index.css";
import allSitePages from "../../utils/site-pages.json";

const BrandsSitemap = () => {
  const brandPages = allSitePages.filter(url => url.path.startsWith("/brands"));
  const productPages = allSitePages.filter(url =>
    url.path.startsWith("/products")
  );

  const brands = brandPages.slice(0, 125);
  const products = productPages.slice(0, 125);
  brands.unshift({ path: "/products" });

  const pages = [...brands, ...products];

  let desktopPages = [];
  let tabletPages = [];

  const createBatches = (array, batchSize) => {
    const batches = [];
    for (let i = 0; i < array.length; i += batchSize) {
      const batch = array.slice(i, i + batchSize);
      batches.push(batch);
    }
    return batches;
  };
  function dataTransformer() {
    const arrayLength = pages.length;

    //For Desktop
    const deskBatch = Math.ceil(arrayLength / 4);
    const desktopPagesData = createBatches(pages, deskBatch);
    desktopPages = desktopPagesData;

    // For Tablet
    const tabBatch = Math.ceil(arrayLength / 3);
    const tabletPagesData = createBatches(pages, tabBatch);
    tabletPages = tabletPagesData;
  }
  dataTransformer();
  function titleProcessor(link) {
    if (/^\/brands\/\d+$/.test(link) || /^\/products\/\d+$/.test(link)) {
      // If the link is like /news/1, /news/2, etc.
      return link
        .replace(/\//g, " ")
        .trim()
        .replace(/\b\w/g, char => char.toUpperCase());
    } else if (
      /^\/brands\/[^\/]+$/.test(link) ||
      /^\/products\/[^\/]+$/.test(link)
    ) {
      // If the link is like /news/any-string
      let processedString = link
        .replace(/\/(brands|products)\//, "")
        .replace(/-/g, " ");
      return processedString.replace(/\b\w/g, char => char.toUpperCase());
    } else if (
      /^\/brands\/[^\/]+\/[^\/]+$/.test(link) ||
      /^\/products\/[^\/]+\/[^\/]+$/.test(link)
    ) {
      // If the link is like /news/culture/any-string
      let processedString = link
        .split("/")
        .pop()
        .replace(/-/g, " ");
      return processedString.replace(/\b\w/g, char => char.toUpperCase());
    } else {
      // If the link doesn't match any of the above patterns
      return (
        link
          .replace(/\//, "")
          .slice(0, 1)
          .toUpperCase() + link.slice(2)
      );
    }
  }

  return (
    <div className="learnSitemap">
      <div className="ColomnsMain">
        {(desktopPages || []).map((array, index) => {
          return (
            <div className="sitemapColumn">
              {array.map((page, index) => {
                const title = titleProcessor(page.path);
                return (
                  <Link key={page.path} to={page.path} className="link">
                    {title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className="tabletColomnsMain">
        {(tabletPages || []).map((array, index) => {
          return (
            <div className="sitemapColumn">
              {array.map((page, index) => {
                const title = titleProcessor(page.path);
                return (
                  <Link key={page.path} to={page.path} className="link">
                    {title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className="mobileColomnsMain">
        <div className="sitemapColumn">
          {pages.map((page, index) => {
            const title = titleProcessor(page.path);
            return (
              <Link key={page.path} to={page.path} className="link">
                {title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BrandsSitemap;
