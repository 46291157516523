import React from "react";
import { convertNodeToElement } from "react-html-parser";
import InstagramEmbed from "react-instagram-embed";
import { Tweet } from "react-twitter-widgets";

import { paths } from "@herb/utils";

import { Text } from "@herb/ui";

import Image from "@herb/components/Image";
// import TOC from "@herb/components/TOC";

/**
 * Returns the last path segment of a URL
 * @param  {string} url A URL string
 * @return {string}     The last segment of a URL
 */
const getLastSegment = (url) => {
  let path = url.split("?"); // Renove query string if it exists
  path = path[0];

  // Get the last path:
  path = path.split("/");
  path = path[path.length - 1];

  return path;
};

/**
 * Prepare content from Wordpress
 * @param  {string} data The HTML content from Wordpress
 * @return {string}      Prepared HTML content
 */
export const prepareContent = (data) => {
  // Get URL from env
  let url =
    process.env.CONTEXT === "production"
      ? process.env.URL
      : process.env.DEPLOY_PRIME_URL;

  // Setup regex using URL variable
  let findRecipeLinks = new RegExp(`${url}/recipes`, "g");
  let findStrainLinks = new RegExp(`${url}/strains`, "g");
  let findDateLinks = new RegExp(
    `${url}(/article)?/[0-9]{4}/[0-9]{2}/[0-9]{2}`,
    "g"
  );
  let findInternalHref = new RegExp(`(${url}[^<>]+?)/?"`, "g");

  /**
   * OPERATIONS:
   *
   * Swap H1 for H2
   * Remove inline styles
   * Remove allow transparency attr
   * Ensure wp-content comes from CDN
   * Replace HTTP with HTTPS for internal links
   */
  let content = data
    .replace(/(<\/?h)1/g, "$12")
    .replace(/(style)=".+?"/g, "")
    .replace(/allowtransparency="true"/gi, "")
    .replace(
      /https?:\/\/(admin\.)?herb\.co\/wp-content/g,
      `${process.env.GATSBY_LEGACY_CDN}/wp-content`
    )
    .replace(
      /https?:\/\/cdn\.herb\.co\/wp-content/g,
      `${process.env.GATSBY_LEGACY_CDN}/wp-content`
    )
    .replace(/https?:\/\/(www\.)?herb\.co/g, url)
    .replace(findRecipeLinks, `${url}${paths.legacy.recipes}`)
    .replace(findStrainLinks, `${url}${paths.legacy.strains}`)
    .replace(findDateLinks, `${url}${paths.legacy.news}`)
    .replace(findInternalHref, '$1/"');

  return content;
};

/**
 * Transform parsed HTML from Wordpress to React components
 * @param  {object} node  The ReactHTMLParser node
 * @param  {number} index The current index of the HTML node
 * @return {object}       React component
 */
export const transformContent = (node, index) => {
  // Imgix
  if (
    node.type === "tag" &&
    node.name === "img" &&
    node.attribs &&
    node.attribs.src.indexOf("herb.co") >= 0
  ) {
    const { src, alt, width, height, style, ...props } = node.attribs || {};

    return (
      <Image
        key={src}
        className="lazyload"
        // width={768}
        src={src}
        alt={alt}
        imgixParams={{
          fit: "clip",
          q: 60
        }}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes"
        }}
      />
    );
  }

  // if (
  //   node.type === "tag" &&
  //   node.name === "div" &&
  //   node.attribs &&
  //   node.attribs.id === "toc_container"
  // ) {
  //   return <TOC key={`TOC-${index}`}>{convertNodeToElement(node, index)}</TOC>;
  // }

  if (node.type === "tag" && node.name === "figcaption") {
    let children = node.children || [];

    return (
      children.length > 0 && (
        <Text
          as="figcaption"
          color="textSubdued"
          fontSize={2}
          key={index}
          pt={3}
          pb={2}
        >
          <React.Fragment>
            {children.map((n, i) => convertNodeToElement(n, i))}
          </React.Fragment>
        </Text>
      )
    );
  }

  // IG embed
  if (
    node.name === "blockquote" &&
    node.attribs.class &&
    node.attribs.class.indexOf("instagram-media") >= 0
  ) {
    try {
      return (
        <InstagramEmbed
          key={
            node.attribs["data-instgrm-permalink"] ||
            node.children[1].children[3].children["0"].attribs.href
          }
          url={
            node.attribs["data-instgrm-permalink"] ||
            node.children[1].children[3].children["0"].attribs.href
          }
          maxWidth={520}
        />
      );
    } catch (e) {
      return (
        <div key={Date.now() + Math.random()} data-message="instagram failed">
          {convertNodeToElement(node, index)}
        </div>
      );
    }
  }

  // Twitter embed
  if (
    node.name === "blockquote" &&
    node.attribs.class &&
    node.attribs.class.indexOf("twitter-tweet") >= 0
  ) {
    try {
      // Get the last <a> tag from the last <p> in tweet html
      let p_idx = node.children.length - 1;
      let a_idx = node.children[p_idx].children.length - 1;

      return (
        <Tweet
          key={node.children[p_idx].children[a_idx].attribs.href}
          tweetId={getLastSegment(
            node.children[p_idx].children[a_idx].attribs.href
          )}
          options={{
            width: "520"
          }}
        />
      );
    } catch (e) {
      return (
        <div key={Date.now() + Math.random()} data-message="twitter failed">
          {convertNodeToElement(node, index)}
        </div>
      );
    }
  }
};
