import React from "react";
import styled from "@emotion/styled";
import { Button as B } from "@rebass/emotion";
import { themeGet } from "styled-system";

import Icon from "./Icon";

const Button = styled(B)`
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 8px;

  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  color: ${themeGet("colors.neutral.0")};
  background-color: ${themeGet("colors.brand.purple")};

  &:hover {
    text-decoration: inherit;
  }
`;

export default ({ icon, ...props }) => (
  <Button borderRadius={6} px={0} py={0} {...props}>
    <Icon icon={icon} />
  </Button>
);
