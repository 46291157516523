import React from "react";

import Flex from "../../Flex";

export default props => (
  <Flex
    alignItems="center"
    px={2}
    css={{ height: 44, position: "relative" }}
    {...props}
  />
);
