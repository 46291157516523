import React from "react";
import ReactStars from "react-rating-stars-component";
import "./index.css";
const ReviewCard = ({ review, totalsReviews }) => {
  return (
    <div className="reviewCard">
      <h5 className="reviewAuthor">{review.author_name}</h5>
      <p className="reviewAuthorText">{review.text}</p>
      <div className="reveiwsAndRating">
        <ReactStars
          className="reviewStars"
          count={5}
          value={review.rating}
          size={28}
          color={"#ffffff"}
          activeColor={"#3041FF"}
          edit={false}
        />
        <p className="reviewRating">{review.rating}</p>
        <p className="totalReviews">({totalsReviews})</p>
      </div>
    </div>
  );
};

export default ReviewCard;
