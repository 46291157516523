const getCategoryPath = categoriesParam => {
  const categories = Array.isArray(categoriesParam)
    ? categoriesParam
    : (categoriesParam || {}).nodes || [];

  if ((categories || []).length === 1) {
    return (categories[0] || {}).slug;
  }

  const parent = (categories || []).find(
    cat =>
      cat.wpParent === null ||
      cat.parentDatabaseId === null ||
      cat.wordpress_parent === 0
  );
  const child = (categories || []).find(findChild);

  return `${(parent || {}).slug}/${(child || {}).slug}`;
};

function findChild(cat) {
  if (
    typeof cat.parentDatabaseId !== "undefined" &&
    cat.parentDatabaseId !== null
  ) {
    return cat;
  } else if (
    typeof cat.wordpress_parent !== "undefined" &&
    cat.wordpress_parent !== null
  ) {
    return cat;
  } else if (typeof cat.wpParent !== "undefined" && cat.wpParent !== null) {
    return cat;
  }
}

module.exports = getCategoryPath;
