import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import ReactPlayer from "react-player";
import SEO from "@herb/components/seo";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";
const Confirmation = () => {
  useEffect(() => {
    scriptCreate();
  });
  return (
    <div className="webinarBg">
      <Layout>
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <SEO
          title={`Confirmation`}
          url={"/workshop/confirmation"}
          image={
            "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
          }
        />
        <Container>
          <div className="webinarConfirmationHeadingDiv webinarConfirmationMainDiv ">
            Congratulations, you’re registered!
          </div>
          <div className="webinarConfirmationExcerptDiv webinarConfirmationMainDiv ">
            Check out this quick message from Herb Founder, Matt Gray.
          </div>
          <center className="videoElement">
            <ReactPlayer url="https://player.vimeo.com/video/730964405" />
          </center>
          <div className="webinarConfirmationText">
            <div className="webinarConfirmationLowerDiv">
              If you haven’t received your confirmation email after 15 minutes,
              and you’ve checked your Promotions tab and Spam folder, please
              email
              <span className="webinarEmailSupport"> workshop@herb.co</span>
            </div>
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default Confirmation;
