import React, { useEffect, useState } from "react";
import InstantSearch from "@herb/components/Search/InstantSearch";
import { Index, connectInfiniteHits, Configure } from "react-instantsearch-dom";
import RandomProductCard from "../../Cards/RandomProductCard";
import SidebarProductCard from "../../Cards/SidebarRandomProductCard";
import "./index.css";
const CustomHits = connectInfiniteHits(({ hits, productsToShow = 3 }) => {
  let randomHits = [];
  const shuffledHits = [...hits].sort(() => Math.random() - 0.5);
  randomHits = shuffledHits.slice(0, productsToShow);

  return (
    <div className="random">
      {productsToShow === 2
        ? randomHits.map(hit => {
            return (
              <React.Fragment>
                <InstantSearch>
                  <Index indexName="Products">
                    <Configure
                      aroundLatLngViaIP={false}
                      hitsPerPage={1}
                      query="*"
                      filters={`contentProducts.brand.slug:${hit.slug}`}
                    />
                    <CustomBrandHits />
                  </Index>
                </InstantSearch>
              </React.Fragment>
            );
          })
        : randomHits.map((hit, index) => {
            if (index == 2) {
              return (
                <React.Fragment>
                  <InstantSearch>
                    <Index indexName="Products">
                      <Configure
                        aroundLatLngViaIP={false}
                        hitsPerPage={100}
                        query="*"
                        filters={`contentProducts.brand.slug:binoid-cbd`}
                      />
                      <CustomProductHits />
                    </Index>
                  </InstantSearch>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <InstantSearch>
                    <Index indexName="Products">
                      <Configure
                        aroundLatLngViaIP={false}
                        hitsPerPage={1}
                        query="*"
                        filters={`contentProducts.brand.slug:${hit.slug}`}
                      />
                      <CustomProductHits />
                    </Index>
                  </InstantSearch>
                </React.Fragment>
              );
            }
          })}
    </div>
  );
});

const CustomBrandHits = connectInfiniteHits(({ hits }) => {
  return (
    <React.Fragment>
      <div className="randomProductCardContainer">
        {hits.map(product => {
          return <SidebarProductCard productdetails={product} />;
        })}
      </div>
    </React.Fragment>
  );
});
const CustomProductHits = connectInfiniteHits(({ hits }) => {
  const randomNumber = Math.floor(Math.random() * hits.length);
  const binoidHits =
    hits.length > 1 ? [...hits].sort(() => Math.random() - 0.5) : [];
  const randomHits = binoidHits.length > 0 ? [binoidHits[randomNumber]] : [];
  if (randomHits.length > 0) {
    return (
      <React.Fragment>
        <div className="randomProductCardContainer">
          <div>
            {randomHits.map(product => {
              return <RandomProductCard productdetails={product} />;
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="randomProductCardContainer">
        <div>
          {hits.map(product => {
            return <RandomProductCard productdetails={product} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
});
const LoadRandomProducts = ({ productsToShow }) => {
  return (
    <>
      <InstantSearch>
        <Index indexName="Brands">
          <Configure
            aroundLatLngViaIP={false}
            hitsPerPage={200}
            query="*"
            filters={
              'isBrandActive.brandActive:true AND NOT title:"Binoid CBD"'
            }
          />
          <CustomHits productsToShow={productsToShow} />
        </Index>
      </InstantSearch>
    </>
  );
};

export default LoadRandomProducts;
