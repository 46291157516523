import React, { useState } from "react";
import Image from "../../components/Image";
import DispensaryButtons from "@herb/components/DispensaryButtons";
import OpeningHours from "@herb/components/OpeningHours";
import PickUpAndDelivery from "@herb/components/PickupAndDelivery";
import Button from "@herb/components/Button";
import Modal from "react-modal";
import { Icon, Box, Container, Heading } from "@herb/ui";
import Recaptcha from "react-recaptcha";
import ReactTooltip from "react-tooltip";
// import ReactStars from "react-rating-stars-component";
// import useWindowDimensions from "../../utils/useWindowDimensions";
// import "react-tooltip/dist/react-tooltip.css";
import styled from "@emotion/styled";
import "aos/dist/aos.css";
import "./index.css";
import { height } from "styled-system";
import { fallbackImages } from "../../utils/constants";

const SubmitInput = styled.input`
  font-style: italic;
  line-height: 30px;
  font-size: 28px;
  text-transform: uppercase;
  padding: 8px 24px;
  border: 1px solid #3041ff;
  color: #fff;
  font-family: "Source Code Pro";
  cursor: pointer;
  background: #3041ff;
  font-weight: 300;
  -webkit-appearance: none;
  &:hover {
    border: 1px solid #000;
    background: #fff;
    color: #000;
  }
  @media only screen and (max-width: 865px) {
    padding: 8px 24px;
    font-size: 24px;
    line-height: 24px;
  }
  @media only screen and (max-width: 769px) {
    padding: 8px 16px;
    font-size: 20px;
    line-height: 24px;
  }
  @media only screen and (max-width: 430px) {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 16px;
  }
`;

// const isBrowser = typeof window !== "undefined";
const DispensaryBanner = ({ dispensary }) => {
  // const { categories } = dispensary;
  // const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const customStylesPopUp = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgb(70 68 68 / 75%)"
    },
    content: {
      position: "relative",
      background: "rgb(255, 255, 255)",
      maxWidth: "500px",
      overflow: "hidden",
      padding: "20px",
      border: "none",
      borderRadius: "0",
      height: "100%",
      width: "100%",
      inset: "0",
      maxHeight: "570px",
      margin: "50px"
    }
  };
  const { featuredImage, content_dispensary } = dispensary;
  const image = featuredImage?.node;
  const {
    dispensaryAddress,
    callDispensary,
    pickupAndDelivery,
    license
  } = content_dispensary;
  const { pickup, delivery } = pickupAndDelivery;
  var phoneNumber =
    callDispensary != null ? callDispensary?.match(/\d+/g)?.join("") : "";
  phoneNumber = callDispensary != null ? Number(phoneNumber) : "";
  const { country, city } = dispensaryAddress;

  const callback = () => {};
  return (
    <div>
      <div className="topSectionMobile">
        <div className="dispensaryImageAndContentMobile">
          <Image
            className="lazyload dispensaryImageMobile"
            src={
              image
                ? image?.sourceUrl
                : "https://herb-platform-images.imgix.net/wp-content/uploads/2023/01/12084032/dispensary-image.png"
            }
            width={296}
            height={296}
            imgixParams={{ fit: "contain", dpr: 1 }}
          />
          <div>
            <div className="dispensarytitleMobile">{dispensary.title}</div>
            {/* <div className="dispensaryRatingMobile">
               <ReactStars
                  edit={false}
                  count={5}
                  size={24}
                  value={4}
                  activeColor="#3041FF"
                /> 
              <Button onClick={toggleModal}>Claim</Button>
            </div> */}
            {pickup || delivery ? (
              <div className="dispensaryPickupAndDeliveryMobile">
                <PickUpAndDelivery item={dispensary} />
              </div>
            ) : (
              <></>
            )}
            <div className="dispensaryCallButtonMobile">
              {callDispensary ? (
                <a className="callDispensaryMobile" href={`tel:${phoneNumber}`}>
                  Call Dispensary
                </a>
              ) : (
                <></>
              )}
              <a
                className="claimDispensary"
                style={{
                  color: "black"
                }}
                onClick={toggleModal}
              >
                Claim
              </a>
            </div>
          </div>
        </div>
        <div className="dispensaryDetailsMobile">
          {/* <div className="dispensaryHoursAndLicense">
            <div>
              <OpeningHours item={dispensary.content_dispensary} />
            </div>
            <div className="dispensaryLicenseInfoMain">
              {license !== "" ? (
                <>
                  <div
                    data-tip
                    data-for="happyFace"
                    className="dispensaryLicenseInfo"
                  >
                    License information
                  </div>
                  <ReactTooltip id="happyFace">
                    <div style={{ margin: "5px" }}>{license}</div>
                  </ReactTooltip>
                </>
              ) : (
                <></>
              )}
            </div>
          </div> */}
          <div className="dispensaryButtonsMobile">
            <DispensaryButtons item={dispensary} />
          </div>
        </div>
      </div>

      <div className="topSection">
        <div className="dispensaryImage">
          <Image
            className="lazyload dispensaryImage"
            src={
              image
                ? image?.sourceUrl
                : "https://herb-platform-images.imgix.net/wp-content/uploads/2023/01/12084032/dispensary-image.png"
            }
            fallback={fallbackImages.square}
            width={296}
            height={296}
            imgixParams={{ fit: "contain", dpr: 1 }}
          />
          <p className="dispenaryArea">{`${
            city ? `${city}, ${country}` : `${country}`
          }`}</p>
        </div>
        <div className="dispensaryDetails">
          <p className="dispensarytitle">{dispensary.title}</p>

          {/* <div className="dispensaryRating">
            <Button onClick={toggleModal}>Claim</Button>
             <ReactStars
              edit={false}
              count={5}
              size={24}
              value={4}
              activeColor="#3041FF"
            />
            <p className="ratingScores">4.0 (1,198)</p> 
          </div> */}
          {pickup || delivery ? (
            <div className="dispensaryPickupAndDelivery">
              <PickUpAndDelivery item={dispensary} />
            </div>
          ) : (
            <></>
          )}

          <DispensaryButtons item={dispensary} />

          <div className="dispensaryCallAndClaim">
            <div className="dispensaryCallButton">
              {callDispensary ? (
                <a className="callDispensary" href={`tel:${phoneNumber}`}>
                  Call Dispensary
                </a>
              ) : (
                <></>
              )}
            </div>
            <a className="claimDispensary" onClick={toggleModal}>
              Claim
            </a>

            {/* <Button onClick={toggleModal}>Claim</Button> */}
          </div>
          {/* <div className="dispensaryHoursAndLicense">
            <div>
              <OpeningHours item={dispensary.content_dispensary} />
            </div>
            <div className="dispensaryLicenseInfoMain">
              {license !== "" ? (
                <>
                  {" "}
                  <div
                    data-tip
                    data-for="happyFace"
                    className="dispensaryLicenseInfo"
                  >
                    License information
                  </div>
                  <ReactTooltip id="happyFace">
                    <div style={{ margin: "5px" }}>{license}</div>
                  </ReactTooltip>
                </>
              ) : (
                <></>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <Modal isOpen={isOpen} style={customStylesPopUp}>
        <div
          style={{
            height: "100%"
          }}
        >
          <div className="referralCloseIcon">
            <Box
              width={["24px", "", "24px"]}
              css={{
                background: "white",
                borderRadius: "50%",
                height: "24px"
              }}
              onClick={toggleModal}
            >
              <Icon icon="close" />
            </Box>
          </div>
          <div style={{ margin: "10px" }}>
            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
              method="POST"
            >
              <input type="hidden" name="oid" value="00D5f000007G9M3" />
              <input
                id="00N5f00000i1O7J"
                name="00N5f00000i1O7J"
                type="hidden"
                value="1"
              />
              <input
                type="hidden"
                name="retURL"
                value="http://www.testsite.com"
              />

              <input
                style={{
                  outline: "none",
                  "&:placeholder": {
                    color: "#fff"
                  },
                  fontFamily: "Source Code Pro",
                  marginRight: "3%",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "48%",
                  backgroundColor: "transparent",
                  padding: "12px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                id="first_name"
                maxlength="40"
                name="first_name"
                size="20"
                type="text"
                placeholder="First name*"
                required
              />

              <input
                style={{
                  outline: "none",
                  "&:placeholder": {
                    color: "#fff"
                  },
                  fontFamily: "Source Code Pro",

                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "48%",
                  backgroundColor: "transparent",
                  padding: "12px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                placeholder="Last name*"
                required
                id="last_name"
                maxlength="80"
                name="last_name"
                size="20"
                type="text"
              />
              <br />

              <input
                style={{
                  outline: "none",
                  "&:placeholder": {
                    color: "#fff"
                  },
                  fontFamily: "Source Code Pro",
                  borderTop: "none",
                  borderRight: "none",

                  borderLeft: "none",
                  borderBottom: "1px solid #000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "12px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                placeholder="Email*"
                required
                id="email"
                maxlength="80"
                name="email"
                size="20"
                type="text"
              />

              <br />

              <input
                style={{
                  outline: "none",
                  "&:placeholder": {
                    color: "#fff"
                  },
                  fontFamily: "Source Code Pro",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  marginRight: "3%",
                  width: "48%",
                  backgroundColor: "transparent",
                  padding: "12px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                required
                placeholder="City*"
                id="city"
                maxlength="40"
                name="city"
                size="20"
                type="text"
              />

              <input
                style={{
                  outline: "none",
                  "&:placeholder": {
                    color: "#fff"
                  },
                  fontFamily: "Source Code Pro",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "48%",
                  backgroundColor: "transparent",
                  padding: "12px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                required
                placeholder="State*"
                id="state"
                maxlength="20"
                name="state"
                size="20"
                type="text"
              />
              <br />

              <input
                style={{
                  outline: "none",
                  "&:placeholder": {
                    color: "#fff"
                  },
                  fontFamily: "Source Code Pro",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1px solid #000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "12px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                required
                placeholder="Company*"
                id="company"
                maxlength="40"
                name="company"
                size="20"
                type="text"
              />

              <textarea
                style={{
                  fontFamily: "Source Code Pro",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "100%",
                  outline: "none",
                  backgroundColor: "transparent",
                  color: "grey",
                  padding: "12px",
                  border: "1px solid #000",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px",
                  height: "200px",
                  "@media screen and (max-width:425px)": {
                    height: "150px"
                  }
                }}
                placeholder="Additional Comments"
                id="00N5f00000c3v7a"
                name="00N5f00000c3v7a"
                type="text"
                wrap="soft"
              ></textarea>
              <br />
              <SubmitInput type="submit" name="submit" value={"Submit"} />
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DispensaryBanner;
