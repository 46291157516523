import React from "react";
import { Box, Icon } from "@herb/ui";
import Image from "../Image";
import { fallbackImages } from "../../utils/constants";
import "./index.css";

const IndutryImages = ({ list, topSection = false }) => {
  return topSection ? (
    <>
      <div className="industryImagesMain">
        <div className="premium">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153220/image-7-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="alCapone">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153224/image-10-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="storz">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153237/Storz_Bickel_Logo-1-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="nativeRoots">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153236/OGImage_HP-1-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="glaxy">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153208/galaxy-treats-logo-1-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="streamLine">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153222/image-8-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="botany">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153230/image-13-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="dwap">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153226/image-11-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="effex">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153228/image-12-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="binoid">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153206/binoid-logo-1-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="hamilton">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153210/image-2-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="pax">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153232/image-14-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="moonwalker">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153212/image-3-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="gPen">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153233/Layer-2-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="hemper">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153214/image-4-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
        <div className="freezePipe">
          <Image
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153217/image-5-1.png"
            }
            fallback={fallbackImages.square}
          />
        </div>
      </div>
      <div className="industryImagesMainMobile">
        <div className="rows">
          <div className="premium">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153220/image-7-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="alCapone">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153224/image-10-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="storz">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153237/Storz_Bickel_Logo-1-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
        </div>
        <div className="rows">
          <div className="nativeRoots">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153236/OGImage_HP-1-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="dwap">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153226/image-11-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="glaxy">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153208/galaxy-treats-logo-1-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
        </div>
        <div className="rows">
          <div className="gPen">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153233/Layer-2-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="freezePipe">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153217/image-5-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="effex">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153228/image-12-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
        </div>
        <div className="rows">
          <div className="moonwalker">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153212/image-3-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="hemper">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153214/image-4-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
        </div>
        <div className="rows">
          {" "}
          <div className="binoid">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153206/binoid-logo-1-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="hamilton">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153210/image-2-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="pax">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153232/image-14-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
        </div>
        <div className="rows">
          <div className="botany">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153230/image-13-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
          <div className="streamLine">
            <Image
              src={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/12/27153222/image-8-1.png"
              }
              fallback={fallbackImages.square}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <Box
      css={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "32px",
        "@media screen and (max-width:768px)": {
          justifyContent: "space-evenly",
          marginBottom: "40px"
        }
      }}
    >
      <div className="americanLeaf">
        <Image
          src={
            "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124134/44f299329eb877eaca6a2a692d2555dd.png"
          }
          fallback={fallbackImages.square}
        />
      </div>
      <div className="nativeRootsDisp">
        <Image
          src={
            "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124135/NR_WTree_Black-1.png"
          }
          fallback={fallbackImages.square}
        />
      </div>
      <div className="insa">
        <Image
          src={
            "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124133/20-insa-Logo-BW-SmallUse.png"
          }
          fallback={fallbackImages.square}
        />
      </div>
    </Box>
  );
};

export default IndutryImages;
