import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import { paths, slugs as createSlug, deployUrl } from "@herb/utils";
import { Container, Flex, PageHeading } from "@herb/ui";
import AuthorArticleTile from "@herb/components/AuthorArticleTile";
import Pagination from "@herb/components/Pagination";
import Image from "@herb/components/Image";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const AuthorTemplate = ({ data, pageContext }) => {
  const {
    allLegacyPosts: { edges: posts },
    legacyAuthors: author
  } = data;
  const { noindex } = pageContext;
  const breadcrumbs = [
    {
      name: "Authors",
      url: `${paths.authors}/`
    },
    {
      name: author.name,
      url: `${paths.authors}/${createSlug(author.name)}/`
    }
  ];

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <Layout>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          <></>
        )}
      </>
      <SEO
        title={author.name}
        description={author.description}
        url={`${paths.authors}/${createSlug(author.name)}`}
        canonical={`${deployUrl}${paths.authors}/${createSlug(author.name)}`}
        breadcrumbs={breadcrumbs}
      />
      <Container flexDirection="column">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Image
            className="lazyload"
            css={{
              borderRadius: "144px",
              width: "160px",
              height: "160px",
              objectFit: "cover",
              marginTop: "56px",
              marginBottom: "24px"
            }}
            width="100%"
            src={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/02/22183039/Group-65.png"
            }
          />
          <div>
            <PageHeading subheading={author.description}>
              {author.name}
            </PageHeading>
          </div>
        </div>
        <Flex
          flexWrap="wrap"
          className="TrendingCardsForAuthors"
          mx={-3}
          mb={5}
        >
          {posts.map(article => (
            <AuthorArticleTile
              key={`author-article-${article.node.id}`}
              article={article.node}
              width={[1 / 2, 1 / 3]}
              p={3}
            />
          ))}
        </Flex>

        <Pagination
          path={`${paths.authors}/${createSlug(author.name)}`}
          context={pageContext}
        />
      </Container>
    </Layout>
  );
};

export default AuthorTemplate;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $name: String!) {
    allLegacyPosts(
      filter: { author: { name: { eq: $name } } }
      skip: $skip
      limit: $limit
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          ...LegacyPost
        }
      }
    }
    legacyAuthors(name: { eq: $name }) {
      name
      description
    }
  }
`;
