import { paths } from "@herb/utils";

export default {
  search: {
    placeholder: "Find products, articles, videos, brands..."
  },
  subscribe: {
    heading: "Cannabis For You, Near You",
    subheading:
      "Join the Herb community and get exclusive offers, early access to products, and good vibes.",
    placeholder: "Enter your email"
  },
  growing: {
    labels: {
      name: "Name*",
      email: "Email*"
    },
    placeholders: {
      name: "Enter your name",
      email: "Enter your email"
    }
  },
  claim: {
    labels: {
      name: "Name*",
      position: "Position*",
      country: "Country*",
      company: "Company Name*",
      phone: "Phone Number*",
      email: "Email*",
      website: "Website URL",
      comments: "What can we help you with?"
    },
    placeholders: {
      name: "Enter your name",
      position: "Enter your position",
      country: "",
      company: "Enter your company's name",
      phone: "Enter your phone number",
      email: "Enter your email",
      website: "Enter your website",
      comments: ""
    }
  },
  advertise: {
    labels: {
      name: "Name*",
      email: "Email*",
      company: "Company*",
      position: "Position",
      message: "Message"
    },
    placeholders: {
      name: "Enter your name",
      email: "Enter your email",
      company: "Enter your company's name",
      position: "Enter your position",
      message: "Let us know more about your advertising goals."
    }
  },
  pricing: {
    labels: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      company: "Company",
      position: "Position",
      budget: "Budget",
      reason:
        "What are you most excited about in potentially working with Herb?",
      message: "Notes (optional)"
    },
    placeholders: {
      firstName: "Enter your first name",
      lastName: "Enter your last name",
      email: "Enter your email",
      company: "Enter your company's name",
      position: "Enter your position",
      budget: "Select your budget",
      reason:
        "What are you most excited about in potentially working with Herb?",
      message: "Let us know more about your advertising goals."
    }
  },
  brand: {
    labels: {
      name: "Name*",
      email: "Email*",
      company: "Company*",
      position: "Position",
      budget: "Monthly budget",
      product: "What products are you interested in?*",
      message: "Message"
    },
    placeholders: {
      name: "Enter your name",
      email: "Enter your email",
      company: "Enter your company's name",
      position: "Enter your position",
      budget: "Enter your monthly budget",
      product: "Select a product",
      message:
        "Let us know more about your brand goals and when you'd like to speak."
    }
  },
  retailer: {
    labels: {
      name: "Name*",
      email: "Email*",
      company: "Company*",
      city: "City*",
      position: "Position",
      message: "Message"
    },
    placeholders: {
      name: "Enter your name",
      email: "Enter your email",
      company: "Enter your company's name",
      city: "Enter your company's city",
      position: "Enter your position",
      message:
        "Let us know more about your retail goals and when you'd like to speak."
    }
  },
  nav: {
    desktop: {
      items: [
        { label: "Read", route: paths.read, color: "purple" },
        { label: "Watch", route: paths.watch, color: "purple" },
        { label: "Products", route: paths.products, color: "purple" },
        { label: "Newsletters", route: paths.newsletters, color: "purple" }
      ]
    },
    herb: {
      label: "Herb",
      items: [
        { label: "About Us", route: "/about" },
        { label: "Careers", route: "/careers" },
        { label: "FAQ", route: "/faq" },
        { label: "Terms", route: "/tos" },
        { label: "Privacy", route: "/privacy" },
        { label: "Newsletters", route: "/newsletters" },
        // { label: "Partnerships", route: "/partnerships" },
        { label: "Herb Plus", route: "/herb-plus" }
        // { label: "SEO", route: "/seo" },
        // { label: "Email", route: "/email" },
        // { label: "Verified Partners", route: "/verified-partners" }
      ]
    },
    cities: {
      label: "Cities",
      items: [{ label: "Los Angeles", route: "/cities/los-angeles" }]
    },
    primary: {
      label: "Discover",
      items: [
        { label: "Products", route: paths.products, color: "purple" },
        { label: "Read", route: paths.read, color: "purple" },
        { label: "Watch", route: paths.watch, color: "purple" },
        { label: "Partner with us", route: "/partnerships", color: "purple" }
      ]
    },
    footer: {
      label: "Discover",
      items: [
        { label: "Products", route: paths.products },
        { label: "Read", route: paths.read },
        { label: "Learn", route: paths.learn },
        { label: "Guides", route: paths.guides },
        { label: "News", route: paths.news },
        { label: "Watch", route: paths.watch }
        // { label: "Herb Plus", route: "/herb-plus" }
      ]
    },
    secondary: {
      label: "Herb",
      items: [
        { label: "About Us", route: "/about" },
        { label: "Careers", route: "/careers" },
        { label: "FAQ", route: "/faq" },
        { label: "Terms", route: "/tos" },
        { label: "Privacy", route: "/privacy" },
        { label: "Newsletters", route: "/newsletters" },
        { label: "Herb Plus", route: "/herb-plus" }

        // { label: "Partnerships", route: "/partnerships" }
      ]
    },
    social: {
      label: "Follow",
      items: [
        { label: "Instagram", url: "https://www.instagram.com/herb/" },
        { label: "Facebook", url: "https://www.facebook.com/herb" },
        { label: "Twitter", url: "https://twitter.com/herbworthy" },
        { label: "YouTube", url: "https://www.youtube.com/c/herbvideo" },
        { label: "Pinterest", url: "https://www.pinterest.com/herbworthy/" }
      ]
    }
  },
  madeIn: "Made in Los Angeles and Toronto",
  appLink: {
    ios: "https://apps.apple.com/us/app/herb/id1471943430?ls=1",
    android:
      "https://play.google.com/store/apps/details?id=com.herb.herbcannabis"
  },
  newsletters: [
    {
      id: "weekly",
      image:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2021/03/19082434/Screen-Shot-2020-07-23-at-19.01-1.png",
      title: "Herb Weekly",
      description:
        "Get the latest in cannabis news, strains, products, and deals straight to your inbox.",
      url: process.env.GATSBY_MAILCHIMP_POST_URL
    }
  ]
};
