import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Newscard from "@herb/components/Cards/Newscard";
import MegaCardArticle from "@herb/components/Cards/MegaCardArticle";
import SectionHeading from "@herb/components/SectionHeading";
import Container from "@herb/components/Container";
import SEO from "@herb/components/seo";
import TrendingArticleCard from "@herb/components/Cards/TrendingArticleCard";
import ClipLoader from "react-spinners/ClipLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { paths, deployUrl } from "@herb/utils";
import InstantSearch from "@herb/components/Search/InstantSearch";
import { scriptCreate } from "@herb/utils/scripts";

import { Index, connectInfiniteHits, Configure } from "react-instantsearch-dom";

const Read = ({ data }) => {
  const {
    articles: { nodes: [megaArticle, ...allArticles] = [] }
  } = data;

  const breadcrumbs = [
    {
      name: "Read",
      url: paths.read
    }
  ];

  const CustomHits = connectInfiniteHits(({ hits, hasMore, refineNext }) => {
    return (
      <InfiniteScroll
        dataLength={hits.length}
        hasMore={hasMore}
        next={refineNext}
        loader={
          <div style={{ textAlign: "center", height: "100px" }}>
            <ClipLoader size={24} color="#3041ff" loading={true} />
          </div>
        }
        children={
          <div className="extra-article-div">
            {(hits || []).map(obj => (
              <TrendingArticleCard article={obj} key={obj.id} />
            ))}
          </div>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      ></InfiniteScroll>
    );
  });

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <Layout>
      <SEO
        title="Read About Cannabis News and Culture"
        description="Stay up-to-date on the latest happenings in cannabis culture. Read recipes, skim news, learn cannabis basics, & discover the best new cannabis products."
        url={paths.read}
        breadcrumbs={breadcrumbs}
        canonical={`${deployUrl}${paths.read}`}
      />
      <Container>
        <SectionHeading heading="Read" />
        <div className="cardsForAuthorPage">
          {allArticles.slice(0, 1)[0] && (
            <MegaCardArticle article={megaArticle} />
          )}
          <div className="cardContainer">
            <div className="sampleBlogsDiv">
              {(allArticles.slice(1, 5) || []).map(obj => (
                <Newscard news={obj} key={obj.id} />
              ))}
            </div>
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <InstantSearch>
            <Index indexName="Dev_Articles">
              <Configure
                aroundLatLngViaIP={false}
                hitsPerPage={4}
                query="*"
                filters={"NOT categories:strains AND NOT categories:recipes"}
              />
              <CustomHits />
            </Index>
          </InstantSearch>
        </div>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    articles: allWordpressWpPost(
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...Post
      }
    }
  }
`;

export default Read;
