import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import { getDate } from "@herb/utils/date";
import getCategoryPath from "@herb/utils/categoryPath";
import { orderBy } from "lodash";
import RedirectLink from "@herb/components/RedirectLink";
import { fallbackImages } from "../../../utils/constants";

const MegaCardArticle = ({
  article,
  strain,
  recipe,
  secondHeading = false,
  dispensaryCategory = false
}) => {
  let { categories: { nodes: categoryArray = [] } = {} } = article;

  let [category] = orderBy(categoryArray, ["databaseId"], ["desc"]);
  category = category || {
    slug: strain ? "strains" : "recipes",
    name: strain ? "strains" : "recipes"
  };
  const sourceImage =
    (article.featuredImage || {}).node || article.featuredImage || {};
  const date = getDate(article.date);

  const categoryPath = article.categories
    ? getCategoryPath(article.categories)
    : (category || {}).slug;

  const excerpt = article.title || article.content_post.postExcerpt;

  return (
    <div className="featuredCard">
      <RedirectLink to={`/${categoryPath}/${(article || {}).slug}`}>
        <div className="featuredBanner">
          <Image
            className="featuredImg"
            src={sourceImage.sourceUrl}
            // fallback={fallbackImages.square}
            alt={sourceImage.altText}
            width={728}
            height={385}
          />
        </div>
      </RedirectLink>

      <div className="cDate">
        <div className="categoryDate">
          <Link to={`/${categoryPath}`} className="cusColor">
            {category.name} |{" "}
          </Link>
          {date}
        </div>
      </div>
      <div className="featuredText">
        <RedirectLink to={`/${categoryPath}/${(article || {}).slug}`}>
          {secondHeading ? (
            <p className="featuredTitle">{article.title}</p>
          ) : (
            <h1 className="featuredTitle">{article.title}</h1>
          )}
          <p className="featuredSmallText">{excerpt}</p>
        </RedirectLink>
      </div>
    </div>
  );
};

export default MegaCardArticle;
