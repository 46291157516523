import React from "react";
import { Link } from "gatsby";

import { track } from "@herb/utils/analytics";

export default ({ borderRadius, onClick, analytics, to, ...props }) => {
  const url = to.toLocaleLowerCase().replace(/(.*[^/])$/, "$1/");

  return (
    <Link
      onClick={e => {
        track("Internal Link", {
          url,
          ...(analytics || {})
        });
        if (typeof onClick === `function`) {
          onClick(e);
        }
      }}
      to={url}
      {...props}
    />
  );
};
