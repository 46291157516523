import React, { useEffect, useState } from "react";
import { CaliNewsletterForm } from "@herb/components/Forms";
import { Box, Flex, Heading, Text, Icon } from "@herb/ui";
import { supportsLocalStorage } from "@herb/utils";

export default () => {
  const [isOpen, setOpen] = useState(
    supportsLocalStorage() &&
      !localStorage.getItem("closedNewsletterOverlayJun2021")
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const delay = () => {
    setTimeout(() => {
      setIsReady(true);
    }, 30000);
  };

  useEffect(() => {
    delay();
  }, []);

  const checkIfNotNewLetter = () => {
    if (window !== undefined) {
      if (window.location.pathname.includes("newsletter")) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    isReady &&
    isOpen &&
    checkIfNotNewLetter() && (
      <Box
        css={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 9999999999999999999,
          background: "rgba(0,0,0,0.8)",
          overflowY: "scroll"
        }}
      >
        <Box
          p={3}
          css={{
            width: "1000px",
            maxWidth: "calc(100% - 30px)",
            margin: "0 auto",
            paddingTop: "50px",
            "@media screen and (max-device-width: 430px)": {
              paddingTop: "100px"
            }
          }}
        >
          <Box
            bg="white"
            css={{
              position: "relative"
            }}
          >
            <Box
              width={["22px", "", "30px"]}
              p={1}
              css={{
                cursor: "pointer",
                position: "absolute",
                top: "0px",
                right: "0px",
                borderRadius: 99,
                background: "#3041FF",
                color: "white",
                transform: "translate(calc(50% - 50px),calc(-50% + 50px))"
              }}
              onClick={() => {
                localStorage.setItem("closedNewsletterOverlayJun2021", true);
                setOpen(false);
              }}
            >
              <Icon icon="close" height={24} width={24} viewBox={"0 0 24 24"} />
            </Box>
            <Box
              width={1}
              ml="auto"
              css={{
                backgroundImage: `url(https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16091641/Facebook-cover.png)`,
                backgroundPosition: "70% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "200px",
                "@media screen and (min-width: 40em)": {
                  minHeight: "400px"
                }
              }}
            ></Box>
            <Flex
              width={1}
              p={["24px", "", 4]}
              flexDirection="column"
              alignItems="center"
              css={{ textAlign: "center" }}
            >
              <Heading
                as="h4"
                mb={3}
                css={{
                  fontSize: "38px",
                  lineHeight: "48px",
                  fontWeight: "300",
                  fontFamily: "Source Code Pro",
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  "@media only screen and (max-device-width: 420px) and (min-device-width: 300px)": {
                    fontSize: "24px",
                    lineHeight: "32px"
                  }
                }}
              >
                Live in California and love weed?
              </Heading>
              <Text
                mb={4}
                px={[0, 5]}
                css={{
                  fontSize: "24px",
                  lineHeight: "32px",
                  fontWeight: "500",
                  "@media only screen and (max-device-width: 420px) and (min-device-width: 300px)": {
                    fontSize: "16px",
                    lineHeight: "24px"
                  }
                }}
              >
                Subscribe to our Cali newsletter for a highly curated selection
                of must-try products, huge deals on weed, and can't-miss events
                near you. Do you feel fancy and exclusive? Good.
              </Text>
              <Box css={{ width: "625px", maxWidth: "100%" }}>
                {!showConfirmation && (
                  <CaliNewsletterForm
                    callback={() => {
                      setShowConfirmation(true);
                      if (window) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: "caliOverlaySubmitted"
                        });
                      }
                    }}
                  />
                )}
                {showConfirmation && (
                  <Text textAlign="center">Thanks for signing up!</Text>
                )}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    )
  );
};
