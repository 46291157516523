import React, { useState } from "react";
import Modal from "react-modal";
import styled from "@emotion/styled";
import "./index.css";
import { Box, Icon } from "@herb/ui";
import { Helmet } from "react-helmet";

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const WebinarForm = ({ page, state }) => {
  const SubmitInput = styled.input`
    font-style: italic;
    line-height: 30px;
    font-size: 32px;
    text-transform: uppercase;
    padding: 8px 32px;
    border: 1px solid #3041ff;
    color: #fff;
    font-family: "Source Code Pro";
    cursor: pointer;
    background: #3041ff;
    font-weight: 300;
    margin-top: 49px;

    -webkit-appearance: none;
    &:hover {
      border: 1px solid #000;
      background: #fff;
      color: #000;
    }
    @media only screen and (max-width: 769px) {
      padding: 8px 24px;
      font-size: 20px;
      line-height: 24px;
    }
  `;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModalOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const customStyles = {
    content: {
      background: "rgb(255, 255, 255)",
      borderRadius: "4px",
      margin: "83px auto",
      maxWidth: "712px"
    },
    overlay: {
      overflowY: "scroll",
      background: "#00000033"
    }
  };
  return (
    <div>
      {page !== "workshop" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="webinarModalOpenButton"
            onClick={toggleModalOpen}
            value="grab Your Free Spot Here"
          >
            grab Your Free Spot Here
          </button>
        </div>
      ) : (
        <></>
      )}
      <Modal
        isOpen={page !== "workshop" ? modalIsOpen : state}
        style={customStyles}
        className="testing"
      >
        <div className="webinarFormContainer">
          <div className="webinarCross">
            <Box
              width={["22px", "", "25px"]}
              p={1}
              css={{
                cursor: "pointer",
                position: "absolute",
                top: "0px",
                right: "0px",
                borderRadius: 99,
                background: "black",
                color: "white",
                transform: "translate(calc(50% - 50px),calc(-50% + 50px))"
              }}
              onClick={toggleModalOpen}
            >
              <Icon icon="close" width={24} height={24} viewBox="0 0 24 24" />
            </Box>
          </div>

          <div className="webinarFormHeading">
            Join Matt, founder of Herb, for this no-BS training, where he’ll
            share exactly how you land your dream customer at scale.
          </div>

          <Modal isOpen={modalIsOpen} style={customStyles} className="testing">
            <div className="webinarFormContainer">
              <div className="webinarCross">
                <Box
                  width={["22px", "", "25px"]}
                  p={1}
                  css={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    borderRadius: 99,
                    background: "black",
                    color: "white",
                    transform: "translate(calc(50% - 50px),calc(-50% + 50px))"
                  }}
                  onClick={e => {
                    e.preventDefault();
                    toggleModalOpen();
                    if (typeof window !== undefined)
                      window.location.reload(false);
                  }}
                >
                  <Icon
                    icon="close"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  />
                </Box>
              </div>

              <div className="webinarFormHeading">
                Join Matt, founder of Herb, for this no-BS training, where he’ll
                share exactly how you land your dream customer at scale.
              </div>
              <div class="wj-embed-wrapper" data-webinar-hash="7064rtm">
                <Helmet>
                  <script src="https://event.webinarjam.com/register/7064rtm/embed-form?formButtonText=Register&formAccentColor=%23080808&formAccentOpacity=1&formBgColor=%23ffffff&formBgOpacity=1"></script>
                </Helmet>
              </div>
            </div>
          </Modal>
        </div>
      </Modal>
    </div>
  );
};

export default WebinarForm;
