import React from "react";
import { Global as EmotionGlobal, css } from "@emotion/core";
import { withTheme } from "emotion-theming";
import { fontFace, normalize } from "polished";

const Global = withTheme(({ theme }) => (
  <EmotionGlobal
    styles={css`
      ${normalize()};

      ${fontFace({
        fontFamily: "Graphik",
        fontDisplay: "auto",
        fontFilePath: `https://cdn.herb.co/fonts/Graphik/Graphik-Regular-Web`,
        fontWeight: 400,
        fileFormats: ["woff", "woff2"]
      })}

      ${fontFace({
        fontFamily: "Graphik",
        fontDisplay: "auto",
        fontFilePath: `https://cdn.herb.co/fonts/Graphik/Graphik-Semibold-Web`,
        fontWeight: 600,
        fileFormats: ["woff", "woff2"]
      })}

      ${fontFace({
        fontFamily: "Graphik",
        fontDisplay: "auto",
        fontFilePath: `https://cdn.herb.co/fonts/Graphik/Graphik-Semibold-Web`,
        fontWeight: 500,
        fileFormats: ["woff", "woff2"]
      })}

      ${fontFace({
        fontFamily: "Graphik",
        fontDisplay: "auto",
        fontFilePath: `https://cdn.herb.co/fonts/Graphik/Graphik-Bold-Web`,
        fontWeight: 900,
        fileFormats: ["woff", "woff2"]
      })}

      html {
        -webkit-font-smoothing: antialiased;
        scroll-behavior: smooth;
      }

      body {
        background-color: ${theme.colors.background};
        color: ${theme.colors.text};
        font-family: "Graphik", Helvetica, sans-serif;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1em;
        font-weight: 400;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }


    
    `}
  />
));

export default Global;
