import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import slugify from "slugify";
import Image from "../../components/Image";
import DispensaryLayout from "../../layouts/DispensaryLayout";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import PickUpAndDelivery from "@herb/components/PickupAndDelivery";
import DispensaryButtons from "@herb/components/DispensaryButtons";
import { useScrollPercentage } from "react-scroll-percentage";
import OpeningHours from "@herb/components/OpeningHours";
import { InView } from "react-intersection-observer";
import { Link } from "gatsby";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import { stateLabelValues } from "@herb/utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import SectionHeading from "@herb/components/SectionHeading";
import DispensaryRelatedLinkCard from "../../components/Cards/DispensaryRelatedLinkCard";
import "./index.css";
import { Helmet } from "react-helmet";
import { fallbackImages } from "../../utils/constants";
import { scriptCreate } from "@herb/utils/scripts";
const isBrowser = typeof window !== "undefined";

//Branch for adding best dispensaries

const DispensaryArticle = ({ data, pageContext }) => {
  const {
    allWordpressWpDispensary: alldispensaries,
    allWordpressWpDispensaryCategory
  } = data;

  const {
    related_links: { related_links: all_related_links }
  } = pageContext;

  const parentCategoryName = pageContext.parentCategory?.wpParent?.node?.name;

  const stateCategoryAbbrivation = stateLabelValues.find(
    state => state.label === parentCategoryName
  );

  const categoryDispensaries =
    allWordpressWpDispensaryCategory.nodes[0]?.bestDispensaries
      ?.bestDispensaries;

  const dispensaries = alldispensaries.nodes;

  const mergeDispensaries = categoryDispensaries
    ? [...categoryDispensaries, ...dispensaries]
    : dispensaries;
  const uniqueDispensaries = Array.from(
    new Set(mergeDispensaries.map(a => a.id))
  ).map(id => {
    return mergeDispensaries.find(a => a.id === id);
  });

  const [isInView, setIsInView] = useState();
  const [isInViewHeading, setIsInViewHeading] = useState("");
  const [center, setCenter] = useState({
    lat: dispensaries
      ? dispensaries[0]?.content_dispensary?.dispensaryAddress?.latitude
      : 40.7128,
    lng: dispensaries
      ? dispensaries[0]?.content_dispensary?.dispensaryAddress?.longitude
      : 74.006
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [ref, percentageLocal, entry] = useScrollPercentage({
    /* Optional options */
    threshold: 1
  });

  const { width } = useWindowDimensions();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCakS5hJl6a2jmqIbR3E2zhXGIvtotxsd0"
  });
  const [map, setMap] = React.useState(null);

  const addressinView = (viewValue, heading, content_dispensary) => {
    const position = {
      lat: content_dispensary?.dispensaryAddress?.latitude,
      lng: content_dispensary?.dispensaryAddress?.longitude
    };

    setCenter(position);
    setIsInView(viewValue);
    setIsInViewHeading(slugify(heading));
  };

  const handleActiveMarker = (heading, index) => {
    if (isBrowser) {
      document.getElementById(slugify(heading)).scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <div>
      <DispensaryLayout page={"dispensaryListing"}>
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <SEO
          title={`Weed Dispensaries in ${pageContext.name}, ${stateCategoryAbbrivation?.value}`}
          description={`Find the best medical and recreational marijuana dispensaries in ${pageContext.name}, as well as CBD and other cannabis suppliers. `}
          url={`${pageContext.dispensaryPath}`}
          canonical={`${deployUrl}${pageContext.dispensaryPath}`}
          breadcrumbs={[]}
        />

        <div className=" featuredDispensaryPageCity">
          <div ref={ref} className="dispensaryCityPage">
            <div className="dispensaryFeaturedContentDiv P-L-0">
              <div className="dispensaryPageBanner">
                <div className="articlePageContent">
                  <h1 className="articlePageH1">
                    {`Dispensaries in ${pageContext.name}`}
                  </h1>
                </div>
              </div>
              {uniqueDispensaries &&
                uniqueDispensaries?.map((dispensary, index) => {
                  const { featuredImage, slug, title } = dispensary;
                  const { content_dispensary } = dispensary;
                  const {
                    callDispensary,
                    pickupAndDelivery
                  } = content_dispensary;
                  const { pickup, delivery } = pickupAndDelivery;

                  var phoneNumber =
                    callDispensary != null
                      ? callDispensary?.match(/\d+/g)?.join("")
                      : "";
                  phoneNumber =
                    callDispensary != null ? Number(phoneNumber) : "";

                  return (
                    <>
                      <div key={index} className="dispensariesMainDiv">
                        <div>
                          <div className="dispensarylistingCardImage">
                            <Link to={`/dispensaries/${slug}/`}>
                              <InView
                                as="div"
                                threshold={1}
                                id={slugify(title)}
                                onChange={(inView, entry) =>
                                  addressinView(
                                    inView,
                                    title,
                                    content_dispensary
                                  )
                                }
                              >
                                <Image
                                  src={
                                    featuredImage
                                      ? featuredImage?.node?.sourceUrl
                                      : "https://herb-platform-images.imgix.net/wp-content/uploads/2023/01/12084032/dispensary-image.png"
                                  }
                                  imgixParams={{ fit: "contain" }}
                                  fallback={fallbackImages.square}
                                  alt={
                                    featuredImage
                                      ? featuredImage?.node?.altText
                                      : ""
                                  }
                                  width={1}
                                />
                              </InView>
                            </Link>
                          </div>

                          <Link to={`/dispensaries/${slug}/`}>
                            <div className="dispensaryriesHeading">{title}</div>
                          </Link>

                          {pickup || delivery ? (
                            <div className="listingPickupAndDelivery">
                              <PickUpAndDelivery item={dispensary} />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="listingDispensaryButtons">
                            <DispensaryButtons item={dispensary} />
                          </div>
                          <div className="dispensaryCallButton">
                            <a
                              className="callDispensary"
                              href={`tel:${phoneNumber}`}
                            >
                              Call Dispensary
                            </a>
                          </div>
                          {title == "Puffin Dispensary" ||
                          title == "PUFFIN DISPENSARY" ||
                          title == "Indigo Dispensary" ||
                          title == "INDIGO DISPENSARY" ? (
                            <div className="addressAndPhone">
                              <p>Phone : {phoneNumber}</p>
                              <p>
                                Address :{" "}
                                {
                                  dispensary.content_dispensary
                                    .dispensaryAddress.streetAddress
                                }
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          <div>
                            <OpeningHours
                              item={dispensary.content_dispensary}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>

            {uniqueDispensaries.length !== 0 && (
              <div className="dispensaryMap">
                <div className="googleMapSingleMarker">
                  {isLoaded ? (
                    firstLoad ? (
                      <>
                        <GoogleMap
                          mapContainerClassName="mapContainerClass"
                          center={center}
                          zoom={window.scrollY > 110 ? 14 : 8}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          options={{
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            scaleControl: false,
                            zoomControl: true
                          }}
                        >
                          <Marker
                            icon={`https://herb-platform-images.imgix.net/wp-content/uploads/2023/12/19130613/map-marker-svgrepo-com2.png?w=40`}
                            zoom={10}
                            position={center}
                            label={{
                              text: `${uniqueDispensaries.length}`,
                              color: "white"
                            }}
                            onClick={() => setFirstLoad(false)}
                          />
                        </GoogleMap>
                      </>
                    ) : (
                      <GoogleMap
                        mapContainerClassName="mapContainerClass"
                        center={center}
                        zoom={window.scrollY > 110 ? 14 : 8}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                          mapTypeControl: false,
                          fullscreenControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          scaleControl: false,
                          zoomControl: true
                        }}
                      >
                        {uniqueDispensaries &&
                          uniqueDispensaries?.map((dispensary, index) => {
                            const position = {
                              lat:
                                dispensary?.content_dispensary
                                  ?.dispensaryAddress?.latitude,
                              lng:
                                dispensary?.content_dispensary
                                  ?.dispensaryAddress?.longitude
                            };
                            const {
                              zoom
                            } = dispensary?.content_dispensary?.dispensaryAddress;

                            return (
                              <Marker
                                key={index}
                                position={position}
                                icon={
                                  width > 768
                                    ? window.scrollY > 110
                                      ? isInView &&
                                        slugify(dispensary.title) ===
                                          isInViewHeading
                                        ? "https://herb-platform-images.imgix.net/wp-content/uploads/2022/08/17111905/MapMarker.png"
                                        : "https://herb-platform-images.imgix.net/wp-content/uploads/2022/09/19093937/marker-gray-style.png"
                                      : "https://herb-platform-images.imgix.net/wp-content/uploads/2022/10/04083042/Group-65.png"
                                    : "https://herb-platform-images.imgix.net/wp-content/uploads/2022/10/04083042/Group-65.png"
                                }
                                onClick={() =>
                                  handleActiveMarker(dispensary.title, zoom)
                                }
                              />
                            );
                          })}
                      </GoogleMap>
                    )
                  ) : (
                    <></>
                  )}
                  <div className="dispensaryCountMain">
                    <p className="dispensaryCountHeading">Dispensaries</p>
                    <p className="dispensaryCount"> {dispensaries.length}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="moreRelatedDispensaries">
            <SectionHeading heading="More Dispensaries In" />
            <div className="relatedLinksCards">
              {all_related_links?.map((link, index) => {
                return (
                  <DispensaryRelatedLinkCard
                    key={index}
                    item={link}
                    length={all_related_links.length}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </DispensaryLayout>
    </div>
  );
};

export default DispensaryArticle;

export const pageQuery = graphql`
  query($slug: String!) {
    allWordpressWpDispensary(
      filter: {
        dispensaryCategories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
      }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        ...Dispensary
      }
    }
    allWordpressWpDispensaryCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        bestDispensaries {
          bestDispensaries {
            ... on WordpressWpDispensary {
              ...Dispensary
            }
          }
        }
      }
    }
  }
`;
