import React, { useEffect, useState, useRef } from "react";
import { graphql, scriptCache } from "gatsby";
import Slider from "react-slick";
import DealCard from "../../components/Cards/DealCard";
import DispensaryDealCard from "../../components/Cards/DispensaryDealCard";
import InstantSearch from "@herb/components/Search/InstantSearch";
import SearchBox from "../../components/Search/SearchBox";
import DealFooter from "../../components/DealFooter";
import Layout from "../../layouts";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import DealsSearchResults from "../../components/DealsSearchResults";
import CategorySearchResults from "../../components/CategorySearchResults";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";

const Deals = ({ data }) => {
  const ref = useRef(null);
  const { wordpressWpPage, productCategories, dealsCategories } = data;
  const [dealProductCategories, setDealProductCategories] = useState([]);
  const [dealSearchCategory, setDealSearchCategory] = useState("");
  const [productSearchCategory, setProductSearchCategory] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [activeNavButton, setActiveNavButton] = useState({});
  useEffect(() => {
    filterProductCategories();
    scriptCreate();
  }, []);

  const filterProductCategories = () => {
    let arr = [];
    productCategories.nodes.map((category, index) =>
      arr.push({
        catName: category.contentDeals.content[0]?.productCategory.name,
        catUrl:
          category.contentDeals.content[0]?.productCategory
            .contentProductCategroyImage?.image?.sourceUrl,
        catCount: category.contentDeals.content[0]?.productCategory.count,
        isSelected: false
      })
    );
    arr = Array.from(new Set(arr.map(a => a.catName))).map(catName => {
      return arr.find(a => a.catName === catName);
    });

    setDealProductCategories([...arr]);
  };

  const {
    dealsOfTheMonth,
    savingDeals,
    dispensaryDeals,
    blackFridayDealText,
    hideBlackFridayDeals
  } = wordpressWpPage.pageDeals;
  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const dispensaryDealSlidersettings = {
    dots: true,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const productDealsSlidersettings = {
    dots: false,
    infinite: true,
    centerMode: false,
    arrows: true,
    speed: 500,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 680,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="dealsSliderArrowRight"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path d="M1 1L11 11L1 21" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="dealsSliderArrowLeft"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path d="M1 1L11 11L1 21" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
    );
  }

  const handleSearchChange = e => {
    setSearchValue(e.target.value);
  };

  const handleProductCategoryClick = category => {
    if (productSearchCategory === category.catName) {
      setProductSearchCategory("");
    } else {
      setProductSearchCategory(category.catName);
    }
  };

  const handleDealCategorySearchClick = category => {
    if (dealSearchCategory === category.name && activeNavButton === category) {
      setActiveNavButton({});
      setDealSearchCategory("");
    } else {
      setActiveNavButton(category);
      setDealSearchCategory(category.name);
    }
  };

  return (
    <>
      <div className="dealsPageMain">
        <Layout page="deals">
          <SEO
            title="Best Weed Deals: Explore The Top Cannabis Discounts"
            description="Herb Deals"
            url="/deals"
            canonical={`${deployUrl}/deals`}
            breadcrumbs={[]}
          />
          <div className="deals-page-content">
            <div className="deals-page-banner-text">
              <h1 className="deals-page-banner-heading">
                Herb's hottest{" "}
                <span className="dealsPageBannerHeadingDealsText">deals</span>{" "}
                of the month
              </h1>
            </div>
            <div className="dealsSlider">
              <Slider {...settings}>
                {dealsOfTheMonth?.map((deal, index) => (
                  <DealCard key={index} deal={deal} />
                ))}
              </Slider>
            </div>

            {/* Black Firday Deals Section */}
            {!hideBlackFridayDeals ? (
              <div className="dispensaryDeals">
                <h1 className="dispensaryDealsText">{blackFridayDealText}</h1>
                <div className="dispensaryDealsSlider">
                  <Slider {...dispensaryDealSlidersettings}>
                    {dispensaryDeals?.map((dispensaryDeal, index) => (
                      <DispensaryDealCard
                        key={index}
                        deal={dispensaryDeal}
                        cardNum={index}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            ) : (
              <div className="removeDispensaryDeals"></div>
            )}

            <div className="dispensaryDeals">
              <h1 className="savingDealsText">
                Low Prices, <span className="highSavings">High Savings</span>
              </h1>

              <div className="dispensaryDealsSlider">
                <Slider {...dispensaryDealSlidersettings}>
                  {savingDeals?.map((deal, index) => (
                    <DispensaryDealCard
                      key={index}
                      deal={deal}
                      cardNum={index}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </Layout>
      </div>

      <InstantSearch>
        <div className="allDeals" ref={ref}>
          <div className="allDealsInner">
            <div className="allDealsHeading">
              <h1 className="allDealsHeadingText">Cannabis Deals Galore</h1>
              <div className="dealsSearchContainer">
                <form className="dealsSearch" action="/action_page.php">
                  <SearchBox
                    onChange={handleSearchChange}
                    name="search"
                    showLoadingIndicator
                    placeholder="Search Deal"
                    aria-label="Search"
                  />
                  <div className="dealssearchLogo" type="submit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.48655 4.62034C8.98034 1.12655 14.6449 1.12655 18.1387 4.62034C21.6325 8.11413 21.6325 13.7787 18.1387 17.2725C14.6449 20.7663 8.98034 20.7663 5.48655 17.2725C1.99276 13.7787 1.99276 8.11413 5.48655 4.62034ZM19.5529 3.20613C15.2781 -1.06871 8.34717 -1.06871 4.07234 3.20613C-0.202498 7.48096 -0.202498 14.4118 4.07234 18.6867C8.04991 22.6642 14.327 22.9408 18.6243 19.5165L23.2415 24.1336L24.9998 22.3753L20.3826 17.7582C23.8071 13.4608 23.5305 7.18371 19.5529 3.20613Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </form>
              </div>
            </div>
            <div className="dealsByProduct">
              <h1 className="dealsByProductText">SHOP BY CATEGORY</h1>
              <div className="dealsByProductSlider">
                <Slider {...productDealsSlidersettings}>
                  {(dealProductCategories || []).map((category, index) => {
                    return (
                      <div
                        className="dealsByProductCategoryMain"
                        onClick={() => handleProductCategoryClick(category)}
                      >
                        <div className="categoryNameAndProductCount">
                          <p className="dealsByProductCategory">
                            {category.catName}
                          </p>
                          <p className="categoryProductCount">{`${category.catCount} products`}</p>
                        </div>
                        <div className="dealsByProductCategoryImageContainer">
                          <img
                            className="dealsByProductCategoryImage"
                            src={category.catUrl}
                            alt={category.catName}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
            <div className="categorySearchResults">
              <CategorySearchResults
                searchValue={searchValue}
                productSearchCategory={productSearchCategory}
              />
            </div>
            <div className="shopByDeals">
              <h1 className="shopByDealsText">SHOP BY DEALS</h1>
              <div className="dealsSearchCategories">
                {(dealsCategories.nodes || []).map((category, index) => {
                  return (
                    <div
                      className="dealsByProductCategorySearch "
                      onClick={() => handleDealCategorySearchClick(category)}
                    >
                      <p
                        className={`dealsSearchProductCategory ${
                          activeNavButton.name === category.name
                            ? "activeNavButton"
                            : ""
                        } `}
                      >
                        {category.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="dealsSearchResults">
              <DealsSearchResults dealSearchCategory={dealSearchCategory} />
            </div>
          </div>
        </div>
      </InstantSearch>
      <div className="dealFooter">
        <DealFooter />
      </div>
    </>
  );
};

export default Deals;
export const pageQuery = graphql`
  query {
    wordpressWpPage(slug: { eq: "deals" }) {
      id
      content
      title
      pageDeals {
        blackFridayDealText
        hideBlackFridayDeals
        dealsOfTheMonth {
          ... on WordpressWpDeal {
            title
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            contentMobileImage {
              mobileImage {
                altText
                sourceUrl
              }
            }
            contentDeals {
              content {
                ... on WordpressWpDeal_Contentdeals_Content_ProductDeal {
                  dealPrice
                  dealsPageUrl
                  productPrice
                  badgeText
                  badgeTextColor
                  discountCode
                  product {
                    ... on WordpressWpProduct {
                      id
                      brand_name
                      images
                      title
                    }
                  }
                }
              }
            }
          }
        }
        dispensaryDeals {
          ... on WordpressWpDeal {
            id
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            contentMobileImage {
              mobileImage {
                altText
                sourceUrl
              }
            }
            contentDeals {
              content {
                ... on WordpressWpDeal_Contentdeals_Content_ProductDeal {
                  dealPrice
                  dealsPageUrl
                  productPrice
                  badgeText
                  badgeTextColor
                  discountCode
                  product {
                    ... on WordpressWpProduct {
                      id
                      brand_name
                      images
                      category
                      title
                    }
                  }
                }
              }
            }
          }
        }

        savingDeals {
          ... on WordpressWpDeal {
            id
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            contentMobileImage {
              mobileImage {
                altText
                sourceUrl
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            contentDeals {
              content {
                ... on WordpressWpDeal_Contentdeals_Content_ProductDeal {
                  dealPrice
                  dealsPageUrl
                  productPrice
                  badgeText
                  badgeTextColor
                  discountCode
                  product {
                    ... on WordpressWpProduct {
                      id
                      brand_name
                      images
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    productCategories: allWordpressWpDeal(
      filter: {
        dealsCategories: { nodes: { elemMatch: { slug: { eq: "product" } } } }
      }
    ) {
      nodes {
        title
        contentDeals {
          content {
            ... on WordpressWpDeal_Contentdeals_Content_ProductDeal {
              productCategory {
                slug
                name
                count
                contentProductCategroyImage {
                  image {
                    sourceUrl
                    title
                  }
                }
              }
              product {
                ... on WordpressWpProduct {
                  category
                }
              }
            }
          }
        }
      }
    }
    dealsCategories: allWordpressWpDealCategory(
      filter: { slug: { ne: "product" } }
    ) {
      nodes {
        id
        slug
        name
        databaseId
      }
    }
  }
`;
