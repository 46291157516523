import { Link } from "gatsby";
import React from "react";
import { slugs as createSlug } from "@herb/utils";
import Image from "@herb/components/Image";

import "./index.css";

const WrittenBy = ({ author, legacy }) => {
  let image;
  if (!legacy) {
    author = author && author.node;
    image = author?.contentUser && author?.contentUser?.userImage;
  }
  return (
    <Link
      to={`/authors/${createSlug(author?.name ? author.name : "sample")}`}
      style={{ color: "inherit" }}
    >
      <div className="WrittenBy">
        <div className="writtenByImage">
          <Image
            className="lazyload personImage"
            src={
              (image || {}).sourceUrl ||
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/02/22183039/Group-65.png"
            }
            alt="/"
            width={45}
            height={45}
            imgixParams={{ fit: "clip" }}
          />
        </div>
        <div className="WrittenByText">
          <p className="WrittenByP">
            {author?.name ? author.name : "Written by:"}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default WrittenBy;
