import React from "react";
import { Box, Heading, Text } from "@herb/ui";
import Image from "@herb/components/Image";
import Button from "@herb/components/Button";

const HerbPlusBanner = ({ heading, subheading, backgroundImage }) => {
  const scrolltoMarketingForm = () => {
    document.getElementById("marketing-plan-form").scrollIntoView();
  };
  return (
    <Box>
      <Box
        css={{
          maxWidth: "1300px",
          margin: "0 auto",
          paddingLeft: "64px",
          paddingRight: "64px",
          "@media screen and (max-width:768px)": {
            maxWidth: "380px",
            paddingLeft: "0px",
            paddingRight: "0px"
          }
        }}
      >
        <Box
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "50px 0px",
            "@media screen and (max-width:768px)": {
              flexDirection: "column"
            }
          }}
        >
          <Box
            css={{
              width: "47%",
              "@media screen and (max-width:768px)": {
                width: "100%",
                paddingBottom: "30px !important",
                padding: "0 16px"
              }
            }}
          >
            <Heading
              as="h1"
              css={{
                color: "#3041FF",
                boxSizing: "border-box",
                lineHeight: "80px",
                fontSize: "70px",
                fontWeight: "700",
                margin: 0,
                paddingBottom: "35px",
                "@media screen and (max-width:768px)": {
                  fontSize: "48px",
                  lineHeight: "56px",
                  textAlign: "center"
                }
              }}
            >
              {heading}
            </Heading>
            <Text
              css={{
                fontSize: "32px",
                lineHeight: "40px",
                color: "#000",
                fontFamily: "Source Code Pro",
                fontWeight: "300",
                textTransform: "uppercase",
                fontStyle: "italic",
                paddingBottom: "35px",
                "@media screen and (max-width:768px)": {
                  fontSize: "22px",
                  lineHeight: "32px",
                  textAlign: "center"
                }
              }}
            >
              {subheading}
            </Text>
            <Box
              css={{
                textAlign: "left",
                "@media screen and (max-width:768px)": {
                  textAlign: "center"
                }
              }}
            >
              <Button type={"herbPlusBtn"} onClick={scrolltoMarketingForm}>
                let’s get started
              </Button>
            </Box>
          </Box>
          <Box
            css={{
              width: "45%",
              position: "relative",
              "@media screen and (max-width:768px)": {
                width: "100%",
                padding: "0 16px",
                paddingTop: "20px"
              }
            }}
          >
            <Image
              className="lazyload animate__animated animate__fadeInRight animate__delay-1s"
              src={`../images/seo/layered/${backgroundImage}`}
              css={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HerbPlusBanner;
