import React from "react";
import { graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import Layout from "@herb/layouts";
import Link from "@herb/components/Link";
import SEO from "@herb/components/seo";
import { Box, Container, Text, Flex } from "@herb/ui";
import { paths, slugs as createSlug, deployUrl } from "@herb/utils";
import Button from "@herb/components/Button";
import "./index.css";
import { Helmet } from "react-helmet";
import { fallbackImages } from "../../utils/constants";
import { scriptCreate } from "@herb/utils/scripts";

const JobListing = ({ data, pageContext }) => {
  const { wordpressWpCareer: career } = data;
  const { noindex } = pageContext;

  const breadcrumbs = [
    {
      name: "Careers",
      url: `${paths.careers}/`
    },
    {
      name: career.title,
      url: `${paths.careers}/${createSlug(career.title)}/`
    }
  ];

  React.useEffect(() => {
    const element = document.getElementsByClassName("contentCareersOutro")[0];
    const allATags = element.getElementsByTagName("a");
    for (let x of allATags) {
      if (x.href.includes("careers@herb.co")) x.href = "mailto:careers@herb.co";
    }

    scriptCreate();
  }, []);

  return (
    <Layout hideSubscribe>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          <></>
        )}
      </>
      <SEO
        title={career.title}
        description={career.title}
        url={`${paths.careers}/${createSlug(career.title)}`}
        canonical={`${deployUrl}${paths.careers}/${createSlug(career.title)}`}
        breadcrumbs={breadcrumbs}
      />

      <Box justifyContent="space-around" css={{ padding: "32px 0px" }}>
        <Container flexDirection="column">
          <Box>
            <p className="herbLife">
              <Link className="workAtHerbLink" to="/careers">
                Work at Herb
              </Link>
            </p>
            <h2 className="titleForPositions">{career.title}</h2>
            <p className="contentCareersInfo">{career.contentCareers.intro}</p>
          </Box>
        </Container>
      </Box>

      <Container flexDirection="column">
        <Text>
          {/* {career.contentCareers.location && (
            <Flex mb={4}>
              {career.contentCareers.location.split(",").map((tag, index) => (
                <Tag key={index} size="small" css={{ margin: "10px" }}>
                  {tag}
                </Tag>
              ))}
            </Flex>
          )} */}
          {career.employmentType && (
            <Text color="textSubdued" mb={5}>
              <p>Employment Type: {career.employmentType}</p>
            </Text>
          )}

          {career.contentCareers.description && (
            <Box mb={4}>
              <h2 className="briefOverview">A brief overview</h2>
              <p className="contentCareerDescription">
                {career.contentCareers.description}
              </p>
            </Box>
          )}

          {(career.contentCareers.responsibilitiesAndSkills || []).map(
            (obj, idx) => (
              <Box key={`career-list-${career.id}-${idx}`} mb={4}>
                <h2 className="briefOverview">{obj.heading}</h2>
                {obj.subHeading && (
                  <h2 className="briefOverview">{obj.subHeading}</h2>
                )}

                <ul className="ulForCareers">
                  {obj.list.map(({ item }, j) => (
                    <li
                      className="listForCareers"
                      key={`career-list-${career.id}-${idx}-${j}`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </Box>
            )
          )}
          {career.reportsTo && (
            <React.Fragment>
              <h2 className="briefOverview">Reports To:</h2>
              <p className="contentCareerDescription">{career.reportsTo}</p>
            </React.Fragment>
          )}

          <h2 className="briefOverview">How to apply</h2>
          <p className="contentCareersOutro">
            {ReactHtmlParser(career.contentCareers.outro)}
          </p>
        </Text>
      </Container>
      <Flex
        css={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#464646",
          height: "700px",
          marginTop: "150px",
          "@media screen and (max-width:425px)": {
            backgroundColor: "unset",
            backgroundSize: "900px",
            height: "420px",
            marginTop: "100px"
          },
          backgroundImage: `url(https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16091421/career-banner.png), url(${fallbackImages.banner})`
        }}
      >
        <div className="careerBannerTitle">
          <h1 className="careerBannerText">
            Are you ready for your new career?
          </h1>
        </div>
        <div className="careerBannerButton">
          <Button type={"careerButton"} to="/positions">
            view open positions
          </Button>
        </div>
      </Flex>
    </Layout>
  );
};

export default JobListing;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpCareer(id: { eq: $id }) {
      id
      slug
      title
      contentCareers {
        location
        employmentType
        reportsTo
        intro
        description
        responsibilitiesAndSkills {
          heading
          subHeading
          list {
            item
          }
        }
        outro
      }
    }
  }
`;
