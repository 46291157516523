import React from "react";
import Link from "@herb/components/Link";
import replaceImageCdn from "@herb/utils/replaceImageCdn";
import "lazysizes/plugins/attrchange/ls.attrchange";
import Imgix from "react-imgix";
import { Heading, Flex, Box } from "@herb/ui";
import Highlight from "@herb/components/Search/Highlight";

const DispensaryHit = hit => {
  const sourceUrl =
    hit.featuredImage && hit.featuredImage?.node?.sourceUrl
      ? replaceImageCdn(hit.featuredImage && hit.featuredImage?.node?.sourceUrl)
      : "https://herb-platform-images.imgix.net/wp-content/uploads/2023/01/12084032/dispensary-image.png";
  return (
    <Flex key={(hit || {}).slug} alignItems="center" mb={3}>
      <Box
        width="68px"
        css={{
          height: "68px",
          position: "relative",
          borderRadius: "6px",
          overflow: "hidden"
        }}
      >
        <Link
          analytics={{ locationOnPage: "Search Results" }}
          to={`/dispensaries/${(hit || {}).slug}`}
        >
          <Imgix
            className="lazyload"
            src={sourceUrl}
            width={68}
            height={68}
            imgixParams={{
              ar: "1:1",
              crop: "focalpoint",
              fit: "crop"
            }}
            css={{ position: "absolute", top: "0", borderRadius: "35px" }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes"
            }}
            htmlAttributes={{
              src: sourceUrl,
              alt: ""
            }}
          />
        </Link>
      </Box>
      <Box flex="1 0" pl={3}>
        <Link
          analytics={{ locationOnPage: "Search Results" }}
          to={`/dispensaries/${(hit || {}).slug}`}
        >
          <Heading fontSize={3}>
            <Highlight hit={hit} attribute="title" />
          </Heading>
        </Link>
      </Box>
    </Flex>
  );
};

export default DispensaryHit;
