import React from "react";
import { Box, Text } from "@herb/ui";
import Fade from "react-reveal/Fade";
import styled from "@emotion/styled";

const SeoQuoteDispensary = ({ author, SeoQuoteStyles, section }) => {
  const ColoredText = styled.span`
    color: #3041ff;
    font-weight: 400;
    font-style: italic;
  `;
  return (
    <Fade bottom>
      <Box
        css={{
          maxWidth: "980px",
          margin: "0 auto",
          textAlign: "center",
          paddingTop: "146px",

          "@media screen and (max-width:768px)": {
            paddingTop: "0px"
          }
        }}
      >
        {section === "topSection" && (
          <Text css={SeoQuoteStyles}>
            <div
              style={{
                fontFamily: "Source Code Pro",
                maxWidth: "920px",
                margin: "0 auto"
              }}
            >
              “WE SAW{" "}
              <span
                css={{
                  background:
                    "linear-gradient(177.11deg, rgba(247, 255, 227, 0.81) 0%, rgba(249, 204, 204, 0.81) 48.11%, rgba(235, 250, 255, 0.81) 100.21%);"
                }}
              >
                2,300% YEAR-OVER-YEAR REVENUE GROWTH
              </span>{" "}
              FROM HERB’S SEO, EMAIL, AND COMMUNITY TACTICS.
              <br />
              <ColoredText>
                I CAN’T SAY ENOUGH GOOD THINGS ABOUT THEIR CUSTOMER SERVICE AND
                HUNGER TO ACHIEVE RESULTS.
              </ColoredText>
              ”
            </div>
          </Text>
        )}
        {section === "bottomSection" && (
          <Text css={SeoQuoteStyles}>
            <div
              css={{
                fontFamily: "Source Code Pro",
                maxWidth: "920px",
                fontStyle: "italic",
                "@media screen and (max-width:768px)": {
                  marginTop: "80px"
                }
              }}
            >
              “Working with Herb with these hands-on campaigns has been a
              game-changer for Delta Extrax.
              <br />
              Herb gave us some{" "}
              <span
                css={{
                  background:
                    "linear-gradient(177.11deg, rgba(247, 255, 227, 0.81) 0%, rgba(249, 204, 204, 0.81) 48.11%, rgba(235, 250, 255, 0.81) 100.21%);"
                }}
              >
                {" "}
                incredible results
              </span>{" "}
              through
              <br />
              <ColoredText>
                a detailed customer acquisition strategy and proper planning.
              </ColoredText>{" "}
              and it has been very rewarding for us.”
            </div>
          </Text>
        )}
        <Text
          css={{
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            fontFamily: "Source Code Pro",
            paddingTop: "32px"
          }}
        >
          {author}
        </Text>
      </Box>
    </Fade>
  );
};

export default SeoQuoteDispensary;
