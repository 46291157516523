import React from "react";
import styled from "@emotion/styled";
import { themeGet } from "styled-system";
import { withFormik } from "formik";
import { track } from "@herb/utils/analytics";
import { Box, Button, Flex, Text } from "@herb/ui";
import ExternalLink from "@herb/components/ExternalLink";

const AgeGateLink = styled(ExternalLink)`
  font-weight: 300;
  font-size: ${themeGet("fontSizes.2")};
  text-decoration: none;
  color: #000;
  font-family: "Source Code Pro";
  text-transform: uppercase;
  font-style: italic;
`;

const ErrorText = props => (
  <Text color="error" fontSize="1" mt={1} {...props} />
);

/**
 * Verify legal age based on input value
 * @param  {object} values The finished values from the form
 * @return {boolean}       Whether the age is legal or not
 */

/**
 * Handle form submission
 * @param  {Object}   e              The event object from the submission
 * @param  {Object}   values         The values object
 * @param  {Boolean}  isValid        Whether the form is valid or not
 * @param  {Function} submitCallback The formik submit callback function
 * @param  {Function} setStatus      The formik set status function
 * @param  {Function} callback       The prop callback method
 */
const formSubmission = (
  e,
  values,
  isValid,
  submitCallback,
  setStatus,
  callback
) => {
  // Prevent default
  if (e) {
    e.preventDefault();
  }

  setStatus({
    submitted: true
  });

  if (isValid) {
    callback();
  }

  // Call formik submit handler to trigger final validation
  if (submitCallback) {
    submitCallback(e);
  }
};

class AgeGateForm extends React.Component {
  render() {
    const {
      values,
      errors,
      status,
      setStatus,
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      ipData,
      innerRef,
      callback
    } = this.props;

    const formValues = {
      COUNTRY: ipData.country_code,
      ...values
    };

    const sharedProps = {
      onChange: handleChange,
      onBlur: handleBlur,
      dark: true,
      containerPx: "14px"
    };

    return (
      <form
        onSubmit={evt =>
          formSubmission(
            evt,
            formValues,
            isValid,
            handleSubmit,
            setStatus,
            callback
          )
        }
        noValidate
        action=""
      >
        <Text
          fontSize={4}
          mb={-4}
          fontWeight="300"
          fontFamily="Source Code Pro"
          textAlign="center"
        >
          Are you over 18 yet?
        </Text>
        {/* )} */}

        <Flex
          css={{
            paddingTop: "60px",
            paddingBottom: "25px",
            justifyContent: "space-evenly",
            "@media screen and (max-width:769px)": {
              flexDirection: "column",
              alignItems: "center"
            }
          }}
        >
          <Box
            css={{
              "@media screen and (max-width:769px)": {
                paddingBottom: "15px"
              }
            }}
          >
            <Text>
              <Button
                type="submit"
                px={5}
                ref={innerRef}
                size="small"
                css={t => ({
                  fontSize: "24px",
                  display: "flex",
                  paddingLeft: "43px",
                  paddingRight: "44px",
                  backgroundColor: "#FFF",
                  textTransform: "uppercase",
                  letterSpacing: "0.05em",
                  fontFamily: "Source Code Pro",
                  fontStyle: "italic",
                  borderRadius: "0",
                  border: "1px solid #000"
                })}
              >
                <AgeGateLink
                  href="https://google.ca"
                  rel="noopener noreferrer"
                  analytics={{ locationOnPage: "Age Gate" }}
                >
                  not yet :/
                </AgeGateLink>
              </Button>
            </Text>
          </Box>
          <Box>
            <Button
              type="submit"
              px={5}
              ref={innerRef}
              size="small"
              css={t => ({
                fontSize: "24px",
                fontWeight: "300",
                display: "flex",
                paddingLeft: "50px",
                paddingRight: "50px",
                backgroundColor: "#3041FF",
                textTransform: "uppercase",
                letterSpacing: "0.05em",
                fontFamily: "Source Code Pro",
                fontStyle: "italic",
                borderRadius: "0"
              })}
              onClick={() => {
                track("Submit Form", {
                  form: "Age Gate",
                  ofAge: isValid,
                  values: formValues
                });

                callback();
              }}
            >
              yea baby!
            </Button>
          </Box>
        </Flex>
      </form>
    );
  }
}

export default withFormik({
  // Transform outer props into form values
  mapPropsToValues: props => ({
    DAY: "",
    MONTH: "",
    YEAR: "",
    COUNTRY:
      props.ipData &&
      (props.ipData.country_code === "CA" || props.ipData.country_code === "US")
        ? props.ipData.country_code
        : "OT",
    PROVINCE:
      props.ipData && props.ipData.country_code === "CA"
        ? props.ipData.region_code
        : ""
  }),

  handleSubmit: (values, { setSubmitting, setStatus, props }) => {
    // Set submitting to true
    setSubmitting(true);
  }
})(AgeGateForm);
