import React from "react";
import RedirectLink from "@herb/components/RedirectLink";
import "./index.css";

const CategoryTags = ({ tag }) => {
  return (
    <RedirectLink to={`/tags/${(tag || {}).slug}`}>
      <div className="tagsForCategory" style={{ marginBottom: "10px" }}>
        <button className="tagCategory">
          {String(tag.name).toLocaleLowerCase()}
        </button>
      </div>
    </RedirectLink>
  );
};

export default CategoryTags;
