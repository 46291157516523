import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import "./featuredcard.css";
import { getDate } from "@herb/utils/date";
import DynamicLink from "@herb/components/DynamicLink";
import { fallbackImages } from "../../../utils/constants";

const Featuredcard = ({ article, latestArticles, page }) => {
  const {
    featureTitle,
    featureBlurb,
    featureLink = "",
    featureImage = {}
  } = article;

  const internal = !!featureLink.match(/^https?:\/\/(www\.)?herb\.co/g);
  const internalLink = featureLink.replace(/^https?:\/\/(www\.)?herb\.co/g, "");
  const [, category] = internalLink.split("/");
  const date = article?.featureImage?.date || "";
  return (
    <div className="featuredCard">
      <DynamicLink
        style={{ color: "inherit" }}
        to={internal ? internalLink : featureLink}
      >
        <div className="featuredBanner">
          <Image
            className="featuredImg"
            src={featureImage.sourceUrl}
            // fallback={fallbackImages.square}
            alt={featureImage.altText}
            srcSet={featureImage.srcSet}
            blur={50}
            width={736}
            height={385}
            // imgixParams={{
            //   fit: "clip",
            //   ar: "16:9"
            // }}
          />
        </div>
      </DynamicLink>

      <div className="cDate">
        <div className="categoryDate">
          <Link
            className="cusColor"
            to={`/${category}`}
            style={{ textTransform: "capitalize" }}
          >
            {category} |{" "}
          </Link>
          {getDate(date)}
        </div>
      </div>
      <DynamicLink
        style={{ color: "inherit" }}
        to={internal ? internalLink : featureLink}
      >
        <div className="featuredText">
          <h1 className="featuredTitle">{featureTitle}</h1>
          {page === "products" ? (
            <></>
          ) : (
            <p className="featuredSmallText">{featureBlurb}</p>
          )}
        </div>
      </DynamicLink>
    </div>
  );
};

export default Featuredcard;
