import React, { useEffect, useState } from "react";
import Layout from "@herb/layouts";
import { Box, Container, Flex, Heading } from "@herb/ui";
import Image from "@herb/components/Image";
import { Icon } from "@herb/ui";
import { paths, slugs as createSlug } from "@herb/utils";
import ButtonPurple from "@herb/components/Buttons/ButtonPurple";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";

import { graphql } from "gatsby";

const Authors = ({ data, pageContext }) => {
  const { allWordpressWpUser: authors } = data;
  const [currentAuthors, setCurrentAuthors] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const pageSize = 8;
  useEffect(() => {
    setCurrentAuthors(authors.edges.slice(currentIndex, pageSize));
    scriptCreate();
  }, []);

  const onClickShowMore = () => {
    const startIndex = currentIndex + pageSize;
    const endingIndex = startIndex + pageSize;
    const newAuthors = authors.edges.slice(startIndex, endingIndex);
    setCurrentAuthors([...currentAuthors, ...newAuthors]);
    setCurrentIndex(currentIndex + pageSize);
  };

  const checkNextEnteries = () => {
    const data = authors.edges.slice(
      currentIndex + pageSize,
      currentIndex + pageSize + 1
    );
    if (!data.length) {
      return false;
    }
    return true;
  };
  return (
    <Layout>
      <Container css={{ flexDirection: "column", marginBottom: "100px" }}>
        <Box width={1} my={5}>
          <Heading
            css={{
              fontSize: "48px",
              fontWeight: "700",
              lineHeight: "56px",
              textAlign: "center"
            }}
          >
            Our Authors
          </Heading>
        </Box>
        <Flex
          css={{
            flexWrap: "wrap",
            justifyContent: "center"
          }}
        >
          {(currentAuthors || []).map(({ node }, authIndex) => (
            <Box
              key={authIndex}
              css={{
                display: "flex",
                width: "290px",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "60px"
              }}
            >
              <a
                href={`${paths.authors}/${createSlug(node.name)}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <Image
                  className="lazyload"
                  css={{
                    borderRadius: "144px",
                    width: "120px",
                    height: "120px",
                    objectFit: "cover"
                  }}
                  src={
                    node.contentUser && node.contentUser.userImage
                      ? node.contentUser.userImage.sourceUrl
                      : "https://herb-platform-images.imgix.net/wp-content/uploads/2021/10/08131650/Group-65.png"
                  }
                />
                <p className="authorName">{node.name}</p>
              </a>

              <Flex style={{ alignItems: "center" }}>
                {node.contentUser && (
                  <>
                    {node.contentUser.twitter && (
                      <a
                        href={node.contentUser.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon icon="twitter-author" width={36} height={25} />
                      </a>
                    )}

                    {node.contentUser.globe && (
                      <a
                        href={node.contentUser.globe}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon icon="globe" width={36} height={25} />
                      </a>
                    )}

                    {node.contentUser.instagram && (
                      <a
                        href={node.contentUser.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon
                          icon="insta-author"
                          width={36}
                          height={32}
                          svgProps={{
                            viewBox: "0 0 36 32"
                          }}
                        />
                      </a>
                    )}

                    {node.contentUser.linkedin && (
                      <a
                        href={node.contentUser.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon
                          icon="linkedin-author"
                          svgProps={{
                            viewBox: "0 0 36 32"
                          }}
                          width={36}
                          height={32}
                        />
                      </a>
                    )}
                  </>
                )}
              </Flex>
            </Box>
          ))}
        </Flex>
        <Box css={{ display: "flex", justifyContent: "center" }}>
          {checkNextEnteries() && (
            <ButtonPurple onClick={onClickShowMore}>Show More</ButtonPurple>
          )}
        </Box>
      </Container>
    </Layout>
  );
};
export default Authors;
export const pageQuery = graphql`
  query {
    allWordpressWpUser(
      filter: {
        name: {
          in: [
            "Juan José Restrepo"
            "Simón Cartagena"
            "Kiara Concepcion"
            "Pedro Bernal"
            "Seb Jaramillo"
            "Itali Heide"
            "Ricky Alejandro"
          ]
        }
      }
    ) {
      edges {
        node {
          description
          name
          slug
          contentUser {
            description
            globe
            twitter
            userImage {
              sourceUrl
              altText
            }
            instagram
            linkedin
          }
        }
      }
    }
  }
`;
