import React from "react";
import { Link } from "gatsby";
import "./index.css";
import learnPages from "../../utils/site-pages.json";

const LearnSitemap = () => {
  const filteredUrls = learnPages.filter(url => url.path.startsWith("/learn"));

  const pages = [
    ...filteredUrls.slice(0, 50),
    ...filteredUrls.slice(300, 350),
    ...filteredUrls.slice(600, 650),
    ...filteredUrls.slice(1100, 1150),
    ...filteredUrls.slice(1500, 1550)
  ];
  let desktopPages = [];
  let tabletPages = [];

  const createBatches = (array, batchSize) => {
    const batches = [];
    for (let i = 0; i < array.length; i += batchSize) {
      const batch = array.slice(i, i + batchSize);
      batches.push(batch);
    }
    return batches;
  };
  function dataTransformer() {
    const arrayLength = pages.length;

    //For Desktop
    const deskBatch = Math.ceil(arrayLength / 4);
    const desktopPagesData = createBatches(pages, deskBatch);
    desktopPages = desktopPagesData;

    // For Tablet
    const tabBatch = Math.ceil(arrayLength / 3);
    const tabletPagesData = createBatches(pages, tabBatch);
    tabletPages = tabletPagesData;
  }
  dataTransformer();

  function titleProcessor(link) {
    // Extract the entity type dynamically
    const entityMatch = link.match(/^\/([^\/]+)(\/|$)/);
    const entityType = entityMatch ? entityMatch[1] : null;

    if (!entityType) {
      // If no entity type is found, return the link as it is
      return link;
    }

    // Handle the exact entity type like /learn or /behind-the-brand
    if (new RegExp(`^\/${entityType}$`).test(link)) {
      return entityType
        .replace(/-/g, " ")
        .replace(/\b\w/g, char => char.toUpperCase());
    }

    // Handle /entityType/1, /entityType/2, etc.
    if (new RegExp(`^\/${entityType}\/\\d+$`).test(link)) {
      return link
        .replace(/\//g, " ")
        .trim()
        .replace(/\b\w/g, char => char.toUpperCase());
    }

    // Handle /entityType/any-string
    if (new RegExp(`^\/${entityType}\/[^\/]+$`).test(link)) {
      let processedString = link
        .replace(new RegExp(`\/${entityType}\/`), "")
        .replace(/-/g, " ");
      return processedString.replace(/\b\w/g, char => char.toUpperCase());
    }

    // Handle /entityType/any-category/any-string
    if (new RegExp(`^\/${entityType}\/[^\/]+\/[^\/]+$`).test(link)) {
      let segments = link.split("/");
      let lastSegment = segments.pop();
      let processedString =
        segments.pop().replace(/-/g, " ") + " " + lastSegment;
      return processedString.replace(/\b\w/g, char => char.toUpperCase());
    }

    // Default case
    return (
      link
        .replace(/\//, "")
        .slice(0, 1)
        .toUpperCase() + link.slice(2)
    );
  }

  return (
    <div className="learnSitemap">
      <div className="ColomnsMain">
        {(desktopPages || []).map((array, index) => {
          return (
            <div className="sitemapColumn">
              {array.map((page, index) => {
                const title = titleProcessor(page.path);
                return (
                  <Link key={page.path} to={page.path} className="link">
                    {title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className="tabletColomnsMain">
        {(tabletPages || []).map((array, index) => {
          return (
            <div className="sitemapColumn">
              {array.map((page, index) => {
                const title = titleProcessor(page.path);
                return (
                  <Link key={page.path} to={page.path} className="link">
                    {title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className="mobileColomnsMain">
        <div className="sitemapColumn">
          {pages.map((page, index) => {
            const title = titleProcessor(page.path);
            return (
              <Link key={page.path} to={page.path} className="link">
                {title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LearnSitemap;
