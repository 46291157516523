import React from "react";
import styled from "@emotion/styled";
import Link from "@herb/components/Link";
import { Box, Text, Flex, Icon } from "@herb/ui";
import ReadMoreLink from "@herb/components/ReadMoreLink";

const NumberLink = styled(Link)`
  display: block;
  padding: 6px;
  margin: 0 5px;
`;

const PaginationWrap = styled(Flex)`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 600px;
`;

export default ({ context, path, ...props }) => {
  const { humanPageNumber, numberOfPages } = context;

  const start = Math.max(humanPageNumber - 3, 1);
  const end = Math.min(humanPageNumber + 3, numberOfPages);

  return (
    <PaginationWrap
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      <Box width={75} order={[2, 1]}>
        {context.previousPagePath && (
          <Link
            to={`${context.previousPagePath}/`}
            css={(t) => ({
              display: "inline-flex",
              color: t.colors.brand.purple,
              letterSpacing: "0.05em",
              textTransform: "capitalize",
              fontWeight: "600",
              alignItems: "center",
              justifyContent: "center"
            })}
          >
            <Box
              width="24px"
              mr={3}
              css={{
                color: "white",
                borderRadius: "99px",
                background: "black",
                height: "24px"
              }}
            >
              <Icon icon="pointer-left" />
            </Box>
            Prev
          </Link>
        )}
      </Box>

      <Flex
        width={[1, "auto"]}
        order={[1, 2]}
        justifyContent="center"
        mb={[4, 0]}
      >
        {Array.from({ length: humanPageNumber - start }, (_, i) => (
          <NumberLink
            key={`${path}-pagination-number-${i + start}`}
            to={`${path}/${i + start === 1 ? "" : i + start}`}
          >
            {i + start}
          </NumberLink>
        ))}

        <NumberLink
          style={{ fontWeight: "900" }}
          to={`${path}/${humanPageNumber !== 1 ? humanPageNumber : ""}`}
        >
          {humanPageNumber}
        </NumberLink>

        {Array.from({ length: end - humanPageNumber }, (_, i) => (
          <NumberLink
            key={`${path}-pagination-number-${humanPageNumber + 1 + i}`}
            to={`${path}/${humanPageNumber + 1 + i}`}
          >
            {humanPageNumber + 1 + i}
          </NumberLink>
        ))}
      </Flex>

      <Box width={75} style={{ textAlign: "right" }} order={3}>
        {context.nextPagePath && (
          <Link
            to={`${context.nextPagePath}/`}
            css={(t) => ({
              display: "inline-flex",
              color: t.colors.brand.purple,
              letterSpacing: "0.05em",
              textTransform: "capitalize",
              fontWeight: "600",
              alignItems: "center",
              justifyContent: "center"
            })}
          >
            Next
            <Box
              width="24px"
              ml={3}
              css={{
                color: "white",
                borderRadius: "99px",
                background: "black",
                height: "24px"
              }}
            >
              <Icon icon="pointer-right" />
            </Box>
          </Link>
        )}
      </Box>
    </PaginationWrap>
  );
};
