import React from "react";
import Link from "@herb/components/Link";
import { paths } from "@herb/utils";
import { categoryPath } from "@herb/utils";
import Highlight from "@herb/components/Search/Highlight";

const ArticleHit = hit => {
  const path =
    hit.categories && (hit.categories[0] || {}).slug
      ? `/${categoryPath(hit.categories)}`
      : paths.legacy[hit.categories[0].toLowerCase()];

  return (
    <div className="sampleSearchText">
      <Link
        className="sampleSearchP"
        analytics={{ locationOnPage: "Search Results" }}
        to={`${path}/${(hit || {}).slug}`}
      >
        <Highlight hit={hit} attribute="title" />
      </Link>
    </div>
  );
};

export default ArticleHit;
