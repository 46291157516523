import React from "react";
import styled from "@emotion/styled";
import ReactHtmlParser from "react-html-parser";

import { Box } from "@herb/ui";

const ResponsiveEmbed = styled(Box)`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe,
  video {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export default ({ type, video_id, src, settings, player = "", embed }) => {
  const playerId = player || process.env.GATSBY_JW_PLAYER_ID;

  return (
    <Box pb={[3, 4]}>
      <ResponsiveEmbed>
        {type === "herb" && (
          <iframe
            src={`https://cdn.jwplayer.com/players/${video_id}-${playerId}.html`}
            frameBorder="0"
            scrolling="auto"
            width="100%"
            height="100%"
            scrolling="auto"
            allowFullScreen
          />
        )}

        {type === "youtube" && (
          <iframe
            src={`https://www.youtube.com/embed/${video_id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}

        {type === "inline" && (
          <video
            controls
            loop={settings.indexOf("loop") >= 0}
            autoPlay={settings.indexOf("autoplay") >= 0}
            muted={settings.indexOf("sound") === -1}
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        {type === "embed" && ReactHtmlParser(embed)}
      </ResponsiveEmbed>
    </Box>
  );
};
