import React from "react";
import DispensaryCard from "../../Cards/DispensaryCard";
import Slider from "react-slick";
import "./index.css";

const DispensarySlider = ({ featuredDispensaries }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="citySlidertArrow"
          viewBox="0 0 32 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.999999 1L31 21L0.999999 41" stroke="black" />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="citySlidertArrow"
          viewBox="0 0 32 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M31 1L1 21L31 41" stroke="black" />
        </svg>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.03,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="dispensarySliderMain">
      <Slider {...settings}>
        {(featuredDispensaries || [])?.map((dispensary, index) => {
          return <DispensaryCard dispensary={dispensary} key={index} />;
        })}
      </Slider>
    </div>
  );
};

export default DispensarySlider;
