import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import { paths, slugs as createSlug, deployUrl } from "@herb/utils";
import Image from "@herb/components/Image";
import { Icon } from "@herb/ui";
import { Container, Flex } from "@herb/ui";
import JsonLd from "@herb/components/Schema/JsonLd";
import TrendingArticleCard from "@herb/components/Cards/TrendingArticleCard";
// import Pagination from "@herb/components/Pagination";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";

const AuthorTemplate = ({ data, pageContext }) => {
  const {
    allWordpressWpPost: { edges: posts },
    allWordpressWpUser: {
      nodes: [author]
    }
  } = data;
  const { noindex } = pageContext;

  const acf = author.contentUser || {};

  const breadcrumbs = [
    {
      name: "Authors",
      url: `${paths.authors}/`
    },
    {
      name: author.name,
      url: `${paths.authors}/${createSlug(author.name)}/`
    }
  ];

  const authorSchema = {
    "@context": "http://schema.org",
    "@type": "Person",
    name: author.name,
    url: `${deployUrl}/${paths.authors}/${createSlug(author.name)}/`,
    description: author.description,
    image: (acf.user_image || {}).source_url,
    sameAs: acf.twitter || acf.linkedin || acf.instagram,
    jobTitle: "Author",
    worksFor: {
      "@type": "Organization",
      name: "Herb"
    }
  };

  const { allWordpressWpPost } = data;
  var { edges: latestArticles } = allWordpressWpPost;

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <Layout>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          <></>
        )}
      </>
      <SEO
        title={author.name}
        description={
          author.description || author.contentUser.description || author.name
        }
        url={`${paths.authors}/${createSlug(author.name)}`}
        canonical={`${deployUrl}${paths.authors}/${createSlug(author.name)}`}
        breadcrumbs={breadcrumbs}
      />
      <JsonLd data={authorSchema} />
      <Container flexDirection="column" css={{ alignItems: "center" }}>
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "712px"
          }}
        >
          <Image
            className="lazyload"
            css={{
              borderRadius: "144px",
              width: "160px",
              height: "160px",
              objectFit: "cover",
              marginTop: "56px",
              marginBottom: "24px"
            }}
            width="100%"
            src={
              acf && acf.userImage
                ? acf.userImage.sourceUrl
                : "https://herb-platform-images.imgix.net/wp-content/uploads/2022/02/22183039/Group-65.png"
            }
          />
          <h1 className="nameOfAuthor">{author.name}</h1>
          <p className="descriptionOfAuthor">{author.description}</p>
          <Flex
            css={{
              marginBottom: "50px"
            }}
          >
            {acf && (
              <>
                {acf.twitter && (
                  <a
                    href={acf.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon icon="twitter-author" width={36} height={25} />
                  </a>
                )}

                {acf.globe && (
                  <a href={acf.globe} target="_blank" rel="noopener noreferrer">
                    <Icon icon="globe" width={36} height={25} />
                  </a>
                )}
                {acf.instagram && (
                  <a
                    href={acf.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      icon="insta-author"
                      width={36}
                      height={32}
                      svgProps={{
                        viewBox: "0 0 36 32"
                      }}
                    />
                  </a>
                )}

                {acf.linkedin && (
                  <a
                    href={acf.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      icon="linkedin-author"
                      svgProps={{
                        viewBox: "0 0 36 32"
                      }}
                      width={36}
                      height={32}
                    />
                  </a>
                )}
              </>
            )}
          </Flex>
        </Flex>

        <div className="TrendingCardsForAuthors">
          {(latestArticles || []).map(({ node }) => (
            <TrendingArticleCard article={node} />
          ))}
        </div>

        {/* <Flex flexWrap="wrap" mx="-12px" mb={4} css={{ width: "100%" }}>
          {posts.map((article, idx) => (
            <React.Fragment key={`article-tile-${article.node.id}`}>
              <ArticleTile
                article={article.node}
                width={[1, "calc(50% - 24px)", "", "calc(33.33333% - 24px)"]}
                mb={4}
                mx="12px"
              />
            </React.Fragment>
          ))}
        </Flex> */}

        {/* <Pagination
          path={`${paths.authors}/${createSlug(author.name)}`}
          context={pageContext}
        /> */}
      </Container>
    </Layout>
  );
};

export default AuthorTemplate;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $name: String!) {
    allWordpressWpPost(
      filter: { author: { node: { name: { eq: $name } } } }
      skip: $skip
      limit: $limit
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          modified
          date(formatString: "MM,DD,YYYY")
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
              slug
              databaseId
              wpParent {
                node {
                  id
                }
              }
            }
          }
          content_post {
            postExcerpt
          }
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
        }
      }
    }
    allWordpressWpUser(filter: { name: { eq: $name } }) {
      nodes {
        id
        name
        description
        contentUser {
          userImage {
            sourceUrl
          }
          twitter
          instagram
          linkedin
          globe
        }
      }
    }
  }
`;
