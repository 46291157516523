import React from "react";

import { Box, Text } from "../";

const LabelText = ({ children, ...props }) => (
  <Text
    color="brand.charcoal"
    fontSize={3}
    fontWeight={600}
    mb={2}
    css={{ display: "block" }}
    {...props}
  >
    {children}
  </Text>
);

const ErrorText = props => <Text color="error" fontSize={1} {...props} />;

export default ({ label, labelProps = {}, error, children }) => (
  <Box width={1}>
    {label && (
      <LabelText as="label" {...labelProps}>
        {label}
      </LabelText>
    )}
    {children}
    {error && <ErrorText pt={2}>{error}</ErrorText>}
  </Box>
);
