import React, { useEffect } from "react";
import { DFPSlotsProvider } from "react-dfp";
import InstantSearch from "@herb/components/Search/InstantSearch";
import GlobalSchema from "@herb/components/Schema/GlobalSchema";
import IFrame from "@herb/components/IFrame";
import Footer from "@herb/components/Footer";
import theme from "@herbkit/theme";
import Navbar from "@herb/components/WebinarForm/Navbar";
import Container from "@herb/components/Container";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import "../../layouts/layout.css";
import SEO from "@herb/components/seo";
import { ThemeProvider } from "emotion-theming";
import Slider from "@herb/components/Slider";
import SalesCounter from "../../components/salesCounter";
import PostEventForm from "../../components/PostEventForm";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";
const isBrowser = typeof window !== "undefined";

const getScreenWidth = () => {
  if (typeof window !== "undefined") {
    return window.screen.width;
  }
};
const PostSalesEvent = ({ location, hideWebPageSchema = false }) => {
  const width = getScreenWidth();
  useEffect(() => {
    scriptCreate();
  });
  return (
    <>
      <DFPSlotsProvider
        lazyload={true}
        dfpNetworkId={process.env.GATSBY_DFP_NETWORK_ID}
      >
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <div className="salesBg">
          <div className="layout mainSec">
            <GlobalSchema hideWebPageSchema={hideWebPageSchema} />
            <IFrame />
            <CookiesProvider>
              <InstantSearch>
                <Navbar
                  page={"PostEventSales"}
                  counterDate={"2022-08-19T21:00:00Z"}
                />
                <ThemeProvider theme={theme}>
                  <div className="contentForLayout">
                    <SEO
                      title={`Workshop Grow`}
                      url={"/workshop-grow/"}
                      image={
                        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
                      }
                    />

                    <Container>
                      <div class="cus-position-relative">
                        <h1 className="salesMainHeading">
                          Grow Your Business with Herb Cannabis Marketing
                        </h1>
                        <div className="salesHeadingExcerpt">
                          A proven, trackable method of rapidly growing your
                          revenue.
                        </div>
                        <div className="salesSubText">
                          57% Off Special - Only{" "}
                          <span className="span">20 Spots</span> Available
                        </div>
                        <div className="salesDateDiv">
                          Ends On Friday 8.19.22
                        </div>

                        <PostEventForm
                          buttonText={"CLICK HERE TO SCHEDULE YOUR CALL"}
                        />
                        <div className="cusPt130px">
                          <p className="salesSideHeading">
                            “WE SAW 2,300% YEAR-OVER-YEAR REVENUE GROWTH FROM
                            HERB’S SEO, EMAIL, AND COMMUNITY TACTICS.{" "}
                            <span class="salesSideHeadingSpan">
                              I CAN’T SAY ENOUGH GOOD THINGS ABOUT THEIR
                              CUSTOMER SERVICE
                            </span>{" "}
                            AND HUNGER TO ACHIEVE RESULTS.”
                          </p>
                        </div>
                        <div className="salesBotnyForm">
                          Dylan, Botany Farms
                        </div>

                        <div>
                          <h3 className="salesBusiness">
                            Explode the growth of your business with:
                          </h3>
                          {isBrowser && (
                            <div className="salesBussinessDetailDiv">
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  Brand Listing On herb.co
                                </div>
                                <div className="salesBusinessValue">
                                  $3,500 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  5 High-Ranking SEO Articles
                                </div>
                                <div className="salesBusinessValue">
                                  $15,579 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  62,933 Prospecting Impressions
                                </div>
                                <div className="salesBusinessValue">
                                  $598 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  62,933 Retargeting Impressions
                                </div>
                                <div className="salesBusinessValue">
                                  $532 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  62,933 Audience Extension Impressions
                                </div>
                                <div className="salesBusinessValue">
                                  $1,330 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  100 New Email Contacts Per Month
                                </div>
                                <div className="salesBusinessValue">
                                  $1,500 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  Email Deliverability Audit
                                </div>
                                <div className="salesBusinessValue">
                                  $2,400 Value
                                </div>
                              </div>
                              <div className="salesBusinessSubDiv">
                                <div className="salesBusinessCheckBox">
                                  <svg
                                    width={width >= 430 ? "24" : "16"}
                                    height={width >= 430 ? "24" : "16"}
                                    viewBox={
                                      width >= 430 ? "0 0 16 16" : "0 0 16 16"
                                    }
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="1.52393"
                                      width="14.4762"
                                      height="14.4762"
                                      fill="black"
                                    />
                                    <rect
                                      x="0.190476"
                                      y="1.7144"
                                      width="14.0952"
                                      height="14.0952"
                                      fill="white"
                                      stroke="black"
                                      stroke-width="0.380952"
                                    />
                                    <path
                                      d="M2.28564 7.18655L6.35639 12.9523L15.238 2.28564"
                                      stroke="black"
                                      stroke-width="0.761905"
                                    />
                                  </svg>
                                </div>
                                <div className="salesBusninessText">
                                  1.5 Hours With Expert Each Month
                                </div>
                                <div className="salesBusinessValue">
                                  $600 Value
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="investmentDiv">
                            <div className="investmentDivDetail">
                              <p className="detail">TOTAL VALUE</p>
                              <p className="value">$26,039</p>
                            </div>
                            <div className="investmentDivDetail">
                              <p className="detail">NORMAL INVESTMENT</p>
                              <p className="value is-text-decoration-line-through">
                                $9,947
                              </p>
                            </div>
                            <div className="investmentDivDetail">
                              <p className="detail">
                                DISCOUNT FOR ACER WORKSHOP ATTENDEES
                              </p>
                              <p className="value">-57%</p>
                            </div>
                            <div className="investmentDivDetail">
                              <p className="detail">
                                <span className="font-weight-color">
                                  TOTAL INVESTMENT
                                </span>
                              </p>
                              <p className="value">
                                <span className="font-weight-color">
                                  $4,277
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="cus-width-85-mobile">
                          <PostEventForm
                            buttonText={"CLICK HERE TO CLAIM SPECIAL OFFER"}
                          />
                        </div>

                        <div className="">
                          <div className="salesSubText1">
                            This special offer is only available until 5pm EST
                            on Friday, August 19<sup>th</sup>.
                          </div>
                          <div className="salesHeadingExcerpt is-text-align-left-mobile">
                            This offer is limited to 20 brands.
                          </div>

                          <div className="salesCarousel">
                            <Slider
                              slidesToShow={2}
                              slidesToScroll={2}
                              showMobile={false}
                              css={{
                                maxWidth: "1300px"
                              }}
                              responsive={[
                                {
                                  breakpoint: 768,
                                  settings: {
                                    slidesToShow: 1.2,
                                    slidesToScroll: 1
                                  }
                                },
                                {
                                  breakpoint: 350,
                                  settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                  }
                                }
                              ]}
                            >
                              <div className="custom-slider-box">
                                <div>
                                  <p class="text1">
                                    “Working with Herb with these hands-on
                                    campaigns has been a game-changer for Delta
                                    Extract. In the past, we’ve utilized
                                    sponsored articles from other
                                    brands/publications and struggled to find a
                                    solid marketing strategy that allowed us to
                                    showcase our brand and also have a positive
                                    return on investment. After hopping on a few
                                    one-on-one calls, Herb gave us some
                                    incredible results through enhanced strategy
                                    and proper planning and it has been very
                                    rewarding for us.”
                                  </p>
                                </div>
                                <div>
                                  <p className="name">
                                    - Matt Muratet Digital Marketing
                                    Coordinator, Delta Extract
                                  </p>
                                </div>
                              </div>
                              <div className="custom-slider-box">
                                <div>
                                  <p class="text2">
                                    “Herb provided us with excellent reach for
                                    our brand and their personalized approach to
                                    integration of our brand into their website
                                    was better than we could have hoped for.”
                                  </p>
                                </div>
                                <div>
                                  <p className="name">
                                    - Jeff Houkal CEO, Invincibow
                                  </p>
                                </div>
                              </div>
                              <div className="custom-slider-box">
                                <div>
                                  <p class="text3">
                                    "Keef’s biggest focus has always been to
                                    maintain a valued customer experience
                                    through education, communication, and
                                    quality products. Platforms such as social
                                    media have been a great tool we have
                                    utilized to do so thus far. However, we knew
                                    there were more avenues that would allow us
                                    to share unique messages to segmented
                                    markets or to reach an audience that might
                                    otherwise be missed through our social media
                                    efforts. Herb has supported us in those
                                    objectives by supplying us with a hands-on
                                    team who offered expertise and guidance to
                                    help steer our vision through campaigns and
                                    other various marketing tactics.
                                    Incorporating Herb into our marketing
                                    practices has allowed us to bridge those
                                    open gaps between our customers and
                                    communication channels."
                                  </p>
                                </div>
                                <div>
                                  <p className="name">
                                    - Kim Stolz and Bayli Lewis Marketing
                                    Manager & Marketing Associate, Keef
                                  </p>
                                </div>
                              </div>
                              <div className="custom-slider-box">
                                <div>
                                  <p class="text4">
                                    “The Herb.co team has been instrumental in
                                    helping us expand our brand presence, and
                                    they are great to work with.”
                                  </p>
                                </div>
                                <div>
                                  <p className="name">
                                    - JElan Lipin CEO, Binoid
                                  </p>
                                </div>
                              </div>
                            </Slider>
                          </div>

                          <div className="">
                            <p className="salesBelowText">
                              We can’t guarantee that we’ll ever offer a special
                              like this again. If you’re serious about taking
                              your cannabis-related business to the next
                              level…don’t wait around. This might be the only
                              chance you have to claim our premium growth
                              package for 57% off the regular price.
                            </p>
                          </div>
                          <PostEventForm
                            buttonText={"CLICK HERE TO SECURE YOUR SPOT"}
                          />
                          <div className="salesCounterDiv">
                            <SalesCounter
                              counterDate={"2022-08-19T21:00:00Z"}
                            />
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <Footer />
                </ThemeProvider>
              </InstantSearch>
            </CookiesProvider>
          </div>
        </div>
      </DFPSlotsProvider>
    </>
  );
};

export default PostSalesEvent;
