import React from "react";
import Image from "@herb/components/Image";

import { Link } from "gatsby";
import "./index.css";

const CareerBelieved = () => {
  return (
    <div className="careerBelieved">
      <div className="photoForCareer">
        <Image
          className="careerImage lazyload"
          src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114819/career-3.png"
          alt=""
        />
      </div>
      <p className="textForCareer">
        Herb believed in my potential since day one and I've been able to grow
        so much! I'm grateful to work in a place that allows me to be my truest
        self, I also work with some incredible people that now I get to call
        friends and I'm able to make part of such a strong and powerful
        industry.
      </p>
      <Link className="careersLink" to="/">
        Val, Operations Manager
      </Link>
    </div>
  );
};

export default CareerBelieved;
