import React from "react";
import Image from "@herb/components/Image";
import { Link } from "gatsby";
import slugify from "slugify";
import Button from "@herb/components/Button";
import "./index.css";

const GraphiteProductCard = ({ product }) => {
  const { category, image_url, title, url, url_path } = product;
  return (
    <div className="graphiteProductCard">
      <Image className="graphiteProductImage" src={image_url} />
      <div className="graphiteProductContent">
        <Link
          className="graphiteProductCategory"
          to={`/products/${slugify(
            category[0].replace("/", "").toLowerCase()
          )}`}
        >
          {category[0].replace("/", "")}
        </Link>
        <h3 className="graphiteProductTitle">{title}</h3>
        <div className="buyCardProduct">
          <Button
            href={url_path}
            link
            stlyes={{
              fontStyle: "italic",
              padding: "6px 18px",
              fontSize: "20px",
              lineHeight: "24px",
              display: "inline-block",
              color: " #141313"
            }}
            type={"boutiqueButton"}
            rel="noopener noreferrer"
          >
            shop now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GraphiteProductCard;
