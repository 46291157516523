import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./index.css";
import slugify from "slugify";
import { fallbackImages } from "../../utils/constants";

const CategoryBanner = ({ category, strain }) => {
  const title = category ? category.name : strain ? "strains" : "recipes";
  const { content_category: { bannerImage = {} } = {} } = category || {};
  let legacyImage =
    title === "strains"
      ? "https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/09163653/Rectangle-102-15.png"
      : title === "recipes"
      ? "https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/09163707/Rectangle-102-16.png"
      : "https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/04105910/Rectangle-5.jpg";
  let fallback = fallbackImages.banner;

  return (
    <div className="categoryBannerDiv">
      <div
        className="bannerForCategory"
        style={{
          backgroundImage: `url(${bannerImage?.sourceUrl ||
            legacyImage}), url(${fallback})`
        }}
      >
        {/* <Image
          className="lazyload categoryBannerImage"
          src={bannerImage?.sourceUrl || legacyImage}
          alt={bannerImage?.altText}
          lowQIPH={true}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes"
          }}
        /> */}
        <div className="bannerContentMaxWidth">
          <div className="bannerContent">
            <div className="bannerCategory">
              <p
                className="bannerCategoryLink"
                to={`/${slugify(title, { lower: true })}`}
              >
                {title}
              </p>
            </div>
            {title === "strains" && (
              <h1 className="bannerCategoryTitle">Herb Guide to Strains</h1>
            )}
            {title === "recipes" && (
              <h1 className="bannerCategoryTitle">
                Weed & Pot Recipes from Herb
              </h1>
            )}
            {category && (
              <h1 className="bannerCategoryTitle">
                {category.contentCategorySeo.seoTitle}{" "}
              </h1>
            )}
            {title === "strains" && (
              <p className="bannerCategoryText">
                Every marijuana strain is different. Understand the difference
                and pick the best strain for you–from Runtz to Gelato to Wedding
                Cake–with Herb’s Strain Guides.
              </p>
            )}
            {title === "recipes" && (
              <p className="bannerCategoryText">
                Cooking with cannabis is fun and tasty. Learn to make butter,
                brownies, cake, chocolate, oil, cookies and more with Herb’s
                weed and pot recipes.
              </p>
            )}

            {category && (
              <p className="bannerCategoryText">
                {ReactHtmlParser(category.contentCategorySeo.seoDescription)}
              </p>
            )}
          </div>
          {bannerImage.caption && (
            <p className="bannerCategoryCaption">
              Photo credits: {ReactHtmlParser(bannerImage.caption)}
            </p>
          )}
          {title === "recipes" && (
            <p className="bannerCategoryCaption">
              Photo credits: Shyla, @shy_snaps
            </p>
          )}

          {title === "strains" && (
            <p className="bannerCategoryCaption">
              Photo credits: Keti Chikhladze, @immigrantstoner
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryBanner;
