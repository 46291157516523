import React from "react";
import Button from "../Button";
import "./index.css";

const GiveAwayTextBanner = ({ scrollToElement }) => {
  return (
    <div className="giveAwayTextBanner">
      <h1 className="Title">Hey Herbivore!</h1>
      <p className="postTitle">
        This 4/20, we're partnering with leading industry innovators and brands
        for a jam-packed giveaway, promoting mindfulness, responsible
        consumption, and breakthrough cannabis-centric technology.
      </p>
      <p className="smallTitle">
        Enter for a chance to win one of the five eclectic bundles, ready to
        take each sesh to new heights. Five winners will be randomly selected on
        April 30.
      </p>
      <Button type={"herbPlusBtn"} onClick={scrollToElement}>
        enter to win
      </Button>
    </div>
  );
};

export default GiveAwayTextBanner;
