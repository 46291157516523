import React, { useEffect } from "react";
import { Box, Heading, Text } from "@herb/ui";
import SeoBussinessSection from "./SeoBussinessSection";
import Image from "@herb/components/Image";
import Fade from "react-reveal/Fade";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import { fallbackImages } from "../utils/constants";

const SeoImageSeriveSection = ({
  image1,
  image2,
  bussiness,
  image1Styles,
  image2Styles,
  reverse = false,
  text,
  textBold,
  subHeading,
  heading,
  page = "herbPlus"
}) => {
  useEffect(() => {
    $(document).ready(function() {
      if (window !== undefined) {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          delay: 300,
          once: true
        });
      }
    });
  }, []);
  return (
    <Box
      css={{
        maxWidth: "1250px",
        margin: "0 auto",
        display: "flex",

        alignItems: reverse ? "center" : "center",
        flexDirection: reverse ? "row-reverse" : "row",
        "@media screen and (max-width:768px)": {
          paddingTop: "50px",
          flexDirection: reverse ? "column-reverse" : "column"
        }
      }}
    >
      <Box
        width={[1, 1 / 2]}
        css={{
          width: "50%",
          paddingTop: reverse ? "146px" : "0",
          marginLeft: "24px",
          "@media screen and (max-width:768px)": {
            width: "100%",
            padding: "0 15px",
            paddingBottom: "30px",
            marginLeft: "0"
          },
          "@media only screen and (max-device-width: 768px) and (min-device-width: 700px)": {
            paddingTop: reverse ? "350px" : "0"
          },
          "@media only screen and (max-device-width: 700px) and (min-device-width: 550px)": {
            paddingTop: reverse ? "300px" : "0"
          },
          "@media only screen and (max-device-width: 550px) and (min-device-width: 430px)": {
            paddingTop: reverse ? "250px" : "0"
          },
          "@media only screen and (max-device-width: 430px) and (min-device-width: 0px)": {
            paddingTop: reverse ? "200px" : "0"
          }
        }}
      >
        <Fade top>
          <Heading
            css={{
              color: "#3041FF",
              fontSize: "48px",
              lineHeight: "56px",
              paddingBottom: "20px",

              "@media screen and (max-width:768px)": {
                fontSize: "32px",
                lineHeight: "40px",
                textAlign: "center"
              }
            }}
          >
            {heading}
          </Heading>
          {subHeading !== "" && (
            <Text
              css={{
                maxWidth: "600px",
                fontStyle: "normal",
                fontFamily: "Graphik",
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: "500",
                "@media screen and (max-width:768px)": {
                  textAlign: "center",
                  fontWeight: "400"
                }
              }}
            >
              {subHeading}
            </Text>
          )}
        </Fade>

        {text && page === "dispensaryPlus" ? (
          <>
            <Text
              css={{
                fontSize: "18px",
                lineHeight: "32px",
                fontWeight: "400",
                maxWidth: "600px",

                "@media screen and (max-width:768px)": {
                  textAlign: "center"
                }
              }}
            >
              {text}
            </Text>
            <Text
              css={{
                fontSize: "18px",
                lineHeight: "32px",
                fontWeight: "500",
                maxWidth: "600px",
                paddingBottom: "25px",
                "@media screen and (max-width:768px)": {
                  textAlign: "center"
                }
              }}
            >
              {textBold}
            </Text>
          </>
        ) : (
          <Text
            css={{
              fontSize: "18px",
              lineHeight: "32px",
              fontWeight: "400",
              paddingBottom: "25px",
              "@media screen and (max-width:768px)": {
                textAlign: "center"
              }
            }}
          >
            {text}
          </Text>
        )}

        {bussiness.map(
          (
            {
              number,
              text,
              heading,
              textNum,
              text1,
              text2,
              boldText1,
              boldText2,
              coloredText
            },
            index
          ) => (
            <Fade bottom key={index}>
              <SeoBussinessSection
                page={page}
                key={number}
                number={number}
                text={text}
                heading={heading}
                textNum={textNum}
                text1={text1}
                text2={text2}
                boldText1={boldText1}
                boldText2={boldText2}
                coloredText={coloredText}
              />
            </Fade>
          )
        )}
      </Box>
      <Box
        width={[1, 1 / 2]}
        css={{
          position: "relative",
          width: "50%",
          textAlign: reverse ? "left" : "right",
          // padding: "0 30px",
          "@media screen and (max-width:768px)": {
            width: "100%",
            padding: "0 16px"
          }
        }}
      >
        <Image
          className="lazyload"
          src={`../images/seo/layered/${image1}`}
          fallback={fallbackImages.square}
          css={{
            ...image1Styles
          }}
        />

        <Box
          data-aos={reverse ? "fade-down" : "fade-down"}
          css={{
            ...image2Styles
          }}
        >
          {image2 ? (
            <Image
              className={`lazyload`}
              src={`../images/seo/layered/${image2}`}
              fallback={fallbackImages.square}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SeoImageSeriveSection;
