import React from "react";
import "./index.css";
import { categoryPath } from "@herb/utils";
import WrittenBy from "../../WrittenBy";
import RedirectLink from "../../RedirectLink";
import { getDate } from "@herb/utils/date";

const StickySideArticle = ({ article }) => {
  const category = article.categories ? article.categories.nodes[0] : {};

  const articleUrl = `/${categoryPath(
    article?.categories
  )}/${article?.post_name || (article || {}).slug}/`;
  return (
    <div className="miniArticle">
      <div className="maCategory">
        <RedirectLink to={`/${categoryPath(article.categories)}`}>
          <p className="maCategoryP">{(category || {}).name || "Category"}</p>
        </RedirectLink>
        <p className="maDateP">{getDate(article.date)}</p>
      </div>
      <div className="maContent">
        <RedirectLink to={articleUrl}>
          <h4 className="maTitle">{article.title}</h4>
        </RedirectLink>

        <WrittenBy
          css={{
            justifyContent: "center"
          }}
          author={article.author}
        />
        {/* <div className="maWrittenBy">
          <div className="maImage">
            <img className="maImg" src="https://herb-platform-images.imgix.net/wp-content/uploads/2021/10/08131650/Group-65.png" alt="/"></img>
          </div>
          <p className="maWrittenByP">Written by {author.name}</p>
        </div> */}
      </div>
    </div>
  );
};

export default StickySideArticle;
