import React from "react";
import styled from "@emotion/styled";
import { Button as B } from "@rebass/emotion";
import { themeGet } from "styled-system";

const Button = styled(B)`
  cursor: pointer;
  height: ${p => (p.size === "small" ? "40px" : "44px")};
  line-height: ${p => (p.size === "small" ? "40px" : "44px")};
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;

  color: ${themeGet("colors.neutral.0")};
  background-color: ${themeGet("colors.brand.flow")};

  &:hover {
    text-decoration: inherit;
  }
`;

export default React.forwardRef(({ size, ...props }, ref) => (
  <Button
    fontWeight={600}
    fontSize={size === "small" ? 2 : 3}
    borderRadius={99}
    display="inline-flex"
    px={4}
    py={0}
    size={size}
    ref={ref}
    {...props}
  />
));
