import React from "react";
import ReactStars from "react-rating-stars-component";
import "./index.css";

const ProductReviews = ({ reviews, allReviews, productTitle }) => {
  return (
    <div className="productReviews">
      <div className="overallRating">
        <p className="overallRatingText">{`${allReviews.length} ${
          allReviews.length > 1 ? "Reviews" : "Review"
        } of ${productTitle}`}</p>
        <ReactStars
          classNames="reviewStars"
          count={5}
          value={
            allReviews.reduce(
              (a, b) => a + parseInt(b.rating != "" ? b.rating : 0),
              0
            ) / allReviews.length
          }
          size={60}
          activeColor={"#3041FF"}
          isHalf={true}
          halfIcon={`<svg
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            clip-rule="evenodd"
            viewBox="0 0 40 40"
            id="star"
          >
            <path
              fill="#f4c01e"
              d="M250,25L305.112,148.906C307.229,153.665 310.562,157.782 314.776,160.844C318.99,163.906 323.936,165.803 329.116,166.346L463.988,180.471L363.177,271.175C359.305,274.659 356.419,279.101 354.809,284.055C353.2,289.008 352.923,294.299 354.008,299.393L382.252,432.029L264.835,364.181C260.325,361.575 255.209,360.203 250,360.203C244.791,360.203 239.675,361.575 235.165,364.181L117.748,432.029L145.992,299.393C147.077,294.299 146.8,289.008 145.191,284.055C143.581,279.101 140.695,274.659 136.823,271.175L36.012,180.471L170.884,166.346C176.064,165.803 181.01,163.906 185.224,160.844C189.438,157.782 192.771,153.665 194.888,148.906L250,25Z"
              transform="translate(-25.612 -2.561) scale(1.10245)"
            ></path>
          </svg>`}
          edit={false}
        />
      </div>
      <div className="reviewList">
        {reviews.map((review, index) => {
          return (
            <div className="productReview">
              <div className="reviewHeader">
                <div className="buyerAndRating">
                  <div className="buyerAndStatus">
                    <p className="buyer">{review.username}</p>
                    {review.buyerStatus && review.buyerStatus != "" ? (
                      <p className="buyerStatus">Verified Buyer</p>
                    ) : (
                      ""
                    )}
                  </div>
                  {review.rating && review.rating != "" ? (
                    <div className="rating">
                      <ReactStars
                        className="reviewStars"
                        count={5}
                        value={
                          review.rating != "" ? parseInt(review.rating) : 0
                        }
                        size={25}
                        activeColor={"#3041FF"}
                        isHalf={true}
                        halfIcon={`<svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      clip-rule="evenodd"
                      viewBox="0 0 40 40"
                      id="star"
                      >
                      <path
                      fill="#f4c01e"
                      d="M250,25L305.112,148.906C307.229,153.665 310.562,157.782 314.776,160.844C318.99,163.906 323.936,165.803 329.116,166.346L463.988,180.471L363.177,271.175C359.305,274.659 356.419,279.101 354.809,284.055C353.2,289.008 352.923,294.299 354.008,299.393L382.252,432.029L264.835,364.181C260.325,361.575 255.209,360.203 250,360.203C244.791,360.203 239.675,361.575 235.165,364.181L117.748,432.029L145.992,299.393C147.077,294.299 146.8,289.008 145.191,284.055C143.581,279.101 140.695,274.659 136.823,271.175L36.012,180.471L170.884,166.346C176.064,165.803 181.01,163.906 185.224,160.844C189.438,157.782 192.771,153.665 194.888,148.906L250,25Z"
                      transform="translate(-25.612 -2.561) scale(1.10245)"
                      ></path>
                      </svg>`}
                        edit={false}
                      />
                      {review.rating && review.rating != "" ? (
                        <>
                          <p className="givenRating">
                            {parseInt(review.rating)}
                          </p>
                          <p className="totalRating">(5)</p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="date">{review.created}</div>
              </div>
              {review.title != "" ? (
                <div className="reviewTitle">{review.title}</div>
              ) : (
                ""
              )}
              {review.comment && review.comment != "" ? (
                <div className="reviewText">{review.comment}</div>
              ) : (
                ""
              )}
              <div className="reviewVotes">
                {review.voteUp && review.voteUp != "" ? (
                  <div className="likesDiv">
                    <svg
                      height="20"
                      viewBox="0 0 1792 1792"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M320 1344q0-26-19-45t-45-19q-27 0-45.5 19t-18.5 45q0 27 18.5 45.5t45.5 18.5q26 0 45-18.5t19-45.5zm160-512v640q0 26-19 45t-45 19h-288q-26 0-45-19t-19-45v-640q0-26 19-45t45-19h288q26 0 45 19t19 45zm1184 0q0 86-55 149 15 44 15 76 3 76-43 137 17 56 0 117-15 57-54 94 9 112-49 181-64 76-197 78h-129q-66 0-144-15.5t-121.5-29-120.5-39.5q-123-43-158-44-26-1-45-19.5t-19-44.5v-641q0-25 18-43.5t43-20.5q24-2 76-59t101-121q68-87 101-120 18-18 31-48t17.5-48.5 13.5-60.5q7-39 12.5-61t19.5-52 34-50q19-19 45-19 46 0 82.5 10.5t60 26 40 40.5 24 45 12 50 5 45 .5 39q0 38-9.5 76t-19 60-27.5 56q-3 6-10 18t-11 22-8 24h277q78 0 135 57t57 135z" />
                    </svg>
                    <p className="likes">{review.voteUp}</p>
                  </div>
                ) : (
                  ""
                )}
                {review.voteDown && review.voteDown != "" ? (
                  <div className="likesDiv">
                    <svg
                      viewBox="0 0 512 512"
                      height={17}
                      width={17}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 56v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56zm40 200c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm272 256c-20.183 0-29.485-39.293-33.931-57.795-5.206-21.666-10.589-44.07-25.393-58.902-32.469-32.524-49.503-73.967-89.117-113.111a11.98 11.98 0 0 1-3.558-8.521V59.901c0-6.541 5.243-11.878 11.783-11.998 15.831-.29 36.694-9.079 52.651-16.178C256.189 17.598 295.709.017 343.995 0h2.844c42.777 0 93.363.413 113.774 29.737 8.392 12.057 10.446 27.034 6.148 44.632 16.312 17.053 25.063 48.863 16.382 74.757 17.544 23.432 19.143 56.132 9.308 79.469l.11.11c11.893 11.949 19.523 31.259 19.439 49.197-.156 30.352-26.157 58.098-59.553 58.098H350.723C358.03 364.34 384 388.132 384 430.548 384 504 336 512 312 512z" />
                    </svg>
                    <p>{review.voteDown}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductReviews;
