import React from "react";

import FormMessaging from "./FormMessaging";
import { StyledBackdrop, StyledContainer, StyledInput } from "./styled";

import { Box } from "../";
import { track } from "@herb/utils/analytics";

export default ({
  onClick,
  analytics = {},
  prefix,
  suffix,
  semiTransparent,
  label,
  backdropProps,
  containerStyles = {},
  labelProps = {},
  ...props
}) => (
  <FormMessaging label={label} labelProps={labelProps}>
    <StyledContainer
      css={{
        ...containerStyles
      }}
    >
      {prefix && <Box css={{ zIndex: 1 }}>{prefix}</Box>}
      <StyledInput
        onClick={e => {
          track("Click Input", {
            field: props.name,
            ...analytics
          });
          onClick && onClick(e);
        }}
        aria-label={label}
        type="text"
        {...props}
      />
      {suffix && <Box css={{ zIndex: 1 }}>{suffix}</Box>}
      <StyledBackdrop semiTransparent={semiTransparent} {...backdropProps} />
    </StyledContainer>
  </FormMessaging>
);
