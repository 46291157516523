import React, { useEffect, useRef } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import GiveAwayTextBanner from "@herb/components/GiveAwayTextBanner";
import GiveAwayProductCard from "@herb/components/Cards/GiveAwayProductCard";
import GiveAwayForm from "@herb/components/GiveAwayForm";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const GiveAway = () => {
  const formRef = useRef(null);
  const scrollToElement = () => formRef.current.scrollIntoView();

  const cardsData = [
    {
      title: "The Meditator Bundle",
      img:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114048/bundle-1.png",
      bundleLink: [
        {
          title: "Hitoki - Trident",
          url:
            "https://hitoki.com/pages/promotions?utm_source=Herb.co&utm_medium=Banner+&utm_campaign=Herb+&utm_id=Herb.co"
        },
        {
          title: "Eureka Vapor - MOD Battery and Glow Tray",
          url:
            "https://www.loyaltotheoil.com/product/eureka-mod-battery/?utm_source=herb.co&utm_medium=google&utm_campaign=promotion&utm_content=get%20yours&utm_term=batteries"
        },
        {
          title: "Keef - $100 worth of merch",
          url:
            "https://keefbrands.com/product/high-octane/?utm_source=Herb+Product+Listing&utm_medium=Herb+Brand+Page&utm_campaign=High+Octane+Herb+Product+Listing&utm_id=High+Octane+Herb+Product+Listing"
        }
      ]
    },
    {
      title: "The High Yogi Bundle",
      img:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114050/bundle-2.png",
      bundleLink: [
        {
          title: "GPen - Elite II",
          url: "https://gpen.com/"
        },
        {
          title: "Ryot - Traveler Water Pipe",
          url:
            "https://ryot.com/product/ceramic-traveller-water-pipe/?sscid=41k6_612fy&utm_source=ShareASale&utm_medium=Affiliate&utm_campaign=springsale&utm_id=389818_1758512"
        },
        {
          title: "Delta Munchies - $200+ worth of Dart XL Delta 8 Vape Pens",
          url:
            "https://deltamunchies.com/delta-8-thc-products/?utm_source=Herb+420+Guide+Promo+Dart+XL&utm_medium=Product+Listing&utm_campaign=DMxHerb&utm_id=Herb420"
        }
      ]
    },
    {
      title: "The Mindfulness Beginner",
      img:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114051/bundle-3.png",
      bundleLink: [
        {
          title: "Stündenglass - Kompact Gravity Bong",
          url:
            "https://stundenglass.com/?utm_source=herbco&utm_medium=promotion&utm_campaign=homepage&utm_content=guide"
        },
        {
          title: "Flower Mill - 3 Standard Mills (Grinders)",
          url: "https://flowermillusa.com/collections/mills/?ref=oIEsAkioK6a03"
        },
        {
          title: "Invincibowl - 1 Bowl and Downstem",
          url:
            "https://invincibowl.com/shop/invinci-bundles/?utm_source=Herb+mag&utm_medium=email&utm_campaign=Herb"
        }
      ]
    },
    {
      title: "The Mindful Materialist",
      img:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114053/bundle-4.png",
      bundleLink: [
        {
          title: "High Five - E-Rig DUO",
          url: "https://highfivevape.com/the-duo.html"
        },
        {
          title: "Smoke Honest - Bong",
          url:
            "https://www.smokehonest.com/products/capsule-water-pipe?utm_campaign=Capsule&utm_source=Herb&utm_medium=product-referral&sscid=41k6_6150z&"
        },
        {
          title: "Purple Rose Supply - Cannagar Kit",
          url:
            "https://purplerosesupply.com/product/cannagar-mold-g2-large/?utm_source=herb&utm_medium=brand-directory&utm_campaign=Q2-Q3-22"
        }
      ]
    },
    {
      title: "The #Selfcare Addict",
      img:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114054/bundle-5.png",
      bundleLink: [
        {
          title: "PAX - Pax 3",
          url:
            "https://www.pax.com/?utm_source=herb.co&utm_medium=paid&utm_campaign=herb_pax&utm_content=4%2F20"
        },
        {
          title: "HOJ - KOL Pipe",
          url: "https://social.hoj.life/7pLZ"
        },
        {
          title: "Weed.com - $500 Gift Card",
          url: "https://weed.com/weed-com-home?affiliate_code=MA92VSN"
        }
      ]
    }
  ];
  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <div className="giveAwayBgColor">
      <Layout>
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <Container>
          <GiveAwayTextBanner scrollToElement={scrollToElement} />

          <div className="mobileGiveAwayForm">
            <GiveAwayForm />
          </div>
          <div className="giveAwayCardsLayout">
            {cardsData.map((card, index) => {
              return (
                <GiveAwayProductCard
                  title={card.title}
                  image={card.img}
                  links={card.bundleLink}
                  key={index}
                />
              );
            })}
          </div>
          <div ref={formRef} className="desktopGiveAwayForm">
            <GiveAwayForm />
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default GiveAway;
