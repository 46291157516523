import React from "react";

import { track } from "@herb/utils/analytics";

export default ({ borderRadius, onClick, analytics, children, ...props }) => (
  <a
    onClick={e => {
      track("External Link", {
        to: props.href,
        ...(analytics || {})
      });
      if (typeof onClick === `function`) {
        onClick(e);
      }
    }}
    {...props}
  >
    {children}
  </a>
);
