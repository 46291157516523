import React from "react";
import styled from "@emotion/styled";
import { Text as T } from "@rebass/emotion";
import { style } from "styled-system";

const measure = style({
  prop: "measure",
  cssProperty: "maxWidth",
  key: "measure"
});

const Text = styled(T)(measure);

export default props => <Text lineHeight="body" {...props} />;
