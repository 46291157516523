import React from "react";
import "./index.css";

const BoldTextForArticlePage = ({ item }) => {
  return (
    <div className="boldTextForArticlePage">
      <h2 className="articlePageLargeText">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="32"
          viewBox="0 0 50 32"
          fill="none"
        >
          <path
            d="M15.5847 32L25.1731 3.9278L15.5847 0L0.682129 26.5704L15.5847 32ZM39.7291 32L49.3175 3.9278L39.7291 0L24.8265 26.5704L39.7291 32Z"
            fill="#141313"
          />
        </svg>
        <br />
        {item.text}
      </h2>
    </div>
  );
};

export default BoldTextForArticlePage;
