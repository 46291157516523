const childCategory = categories => {
  if (categories.length === 1) {
    // If only 1 category, defaults to child
    return categories[0];
  }

  // If more than one category, return category without parent
  const child = categories.find(cat => cat.wordpress_parent !== 0);

  return child;
};

module.exports = childCategory;
