const animationObserver = () => {
  if (typeof window !== "undefined") {
    return new IntersectionObserver(entries => {
      // Loop over the entries
      const animateClass = "showCareerAnimation animate__animated";
      entries.forEach(entry => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          for (let child of entry.target.children) {
            let durationClass = child.className.search("customS-");
            let duration = child.className[durationClass + 8];
            if (child.className.includes("animate_down")) {
              child.className = `${child.className} ${animateClass} animate__fadeInDown animate__delay-${duration}s `;
            } else if (child.className.includes("animate_right")) {
              child.className = `${child.className} ${animateClass} animate__fadeInRight animate__delay-${duration}s`;
            } else if (child.className.includes("animate_left")) {
              child.className = `${child.className} ${animateClass} animate__fadeInLeft animate__delay-${duration}s`;
            } else if (child.className.includes("animate_up")) {
              child.className = `${child.className} ${animateClass} animate__fadeInUp animate__delay-${duration}s`;
            }
          }
        }
      });
    });
  }
  return {};
};

export default animationObserver();
