import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./index.css";

const DescriptionForArticlePage = ({ item }) => {
  const removeDataSaferedirecturl = / data-saferedirecturl="[^"]*"/g;
  const updatedContent = item.text?.replace(removeDataSaferedirecturl, "");
  return (
    <div className="descriptionForArticle">
      <div className="descriptionTextP">{ReactHtmlParser(updatedContent)}</div>
    </div>
  );
};

export default DescriptionForArticlePage;
