import React from "react";
import Link from "@herb/components/Link";
import Image from "@herb/components/Image";
import { Heading, Flex, Box } from "@herb/ui";
import { paths } from "@herb/utils";
import Highlight from "@herb/components/Search/Highlight";
import { fallbackImages } from "../../../utils/constants";

const BrandHit = hit => (
  <Flex key={(hit || {}).slug} alignItems="center" mb={3}>
    <Box
      width="68px"
      css={{
        height: "68px",
        position: "relative",
        borderRadius: "6px",
        overflow: "hidden"
      }}
    >
      <Link
        analytics={{ locationOnPage: "Search Results" }}
        to={`${paths.brands}/${(hit || {}).slug}`}
      >
        <Image
          className={"lazyload"}
          src={hit.contentBrands.logo.sourceUrl}
          alt={hit.contentBrands.logo.altText}
          width={68}
          height={68}
          imgixParams={{
            ar: "1:1",
            crop: "focalpoint",
            fit: "crop"
          }}
          css={{ position: "absolute", top: "0", borderRadius: "35px" }}
        />
      </Link>
    </Box>
    <Box flex="1 0" pl={3}>
      <Link
        analytics={{ locationOnPage: "Search Results" }}
        to={`${paths.brands}/${(hit || {}).slug}`}
      >
        <Heading fontSize={3}>
          <Highlight hit={hit} attribute="title" />
        </Heading>
      </Link>
    </Box>
  </Flex>
);

export default BrandHit;
