import React from "react";
import { Link } from "gatsby";

import { track } from "@herb/utils/analytics";

import { Box, Icon } from "@herb/ui";
import { Flex } from "@rebass/emotion";

export default ({
  borderRadius,
  onClick,
  analytics,
  to = "",
  children,
  ...props
}) => {
  const url = to.toLocaleLowerCase().replace(/(.*[^/])$/, "$1/");

  return (
    <Link
      onClick={e => {
        if (typeof window !== undefined) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "View Product"
          });
        }

        track("Read More Link", {
          url,
          ...(analytics || {})
        });
        if (typeof onClick === `function`) {
          onClick(e);
        }
      }}
      to={url}
      css={t => ({
        display: "inline-flex",
        // color: t.colors.brand.purple,
        textTransform: "capitalize",
        fontWeight: "600",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          color: "#591FB6"
        }
      })}
      {...props}
    >
      <Flex>
        {children}
        {/* <Box
          width="24px"
          ml={2}
          css={{
            color: "#7428EF",
            height: "24px"
          }}
        >
          <Icon icon="pointer-right" />
        </Box> */}
      </Flex>
    </Link>
  );
};
