import React from "react";
import Button from "@herb/components/Button";
import { paths } from "@herb/utils";
import "./index.css";

const ProductNav = ({ boutique, categories, current }) => {
  return (
    <div className={boutique ? "productNavbarBoutique" : "productNavbar"}>
      {(categories.edges || []).map(({ node }) => (
        <div className="productNavButtons" key={node.slug}>
          <Button
            type={"boutiqueButton"}
            stlyes={{
              marginBottom: "20px",
              fontSize: "16px",
              padding: "8px 16px",
              display: "inline-block"
            }}
            role="link"
            to={`${paths.products}/${node.slug}`}
            active={node.name === current ? "active" : ""}
          >
            {node.name}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default ProductNav;
