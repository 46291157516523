import React from "react";
import { Link, navigate } from "gatsby";
import { track } from "@herb/utils/analytics";
import "./index.css";

const Button = ({
  onClick,
  to,
  active,
  stlyes = {},
  children,
  link = false,
  containerStyles = {},
  type = "globalButton",
  analytics,
  ...props
}) => {
  return (
    <div
      className="global-btn-container"
      onClick={() => {
        let url = to || props.href;
        if (url && url.includes("https://")) {
          track("External Link", {
            to: url,
            ...(analytics || {})
          });
        }
        analytics && analytics();
      }}
      style={{
        ...containerStyles
      }}
    >
      {!link ? (
        <button
          className={`${type} ${active} ${
            props.disabled ? "btn-disabled" : ""
          }`}
          onClick={() => {
            onClick && onClick();
            to && navigate(to);
          }}
          style={{
            ...stlyes
          }}
          {...props}
        >
          {children}
        </button>
      ) : (
        <Link
          onClick={e => {
            onClick && onClick(e);
          }}
          to={to}
          className={`${type} ${active}`}
          style={{
            ...stlyes
          }}
          {...props}
        >
          {children}
        </Link>
      )}
    </div>
  );
};

export default Button;
