import values from "@herb/utils/values";

export default (min, max, unit) => {
  let value = "";
  let u = values.units[unit] || "";

  if (min === max || (min && !max)) {
    value = `${min} ${u}`;
  }

  if (!min && max) {
    value = `${max} ${u}`;
  }

  if (min && max && min !== max) {
    value = `${min}-${max} ${u}`;
  }

  return value;
};
