import React from "react";
import styled from "@emotion/styled";
import { themeGet } from "styled-system";

import FormMessaging from "./FormMessaging";
import { StyledBackdrop, StyledContainer, StyledInput } from "./styled";

import { Box } from "../";
import { track } from "@herb/utils/analytics";

const StyledTextArea = styled(StyledInput)`
  height: 100px;
  resize: none;
  padding: ${themeGet("space.2")}px;
`;

export default ({
  onClick,
  analytics = {},
  prefix,
  suffix,
  semiTransparent,
  label,
  ...props
}) => (
  <FormMessaging label={label}>
    <StyledContainer style={{ height: "auto" }}>
      {prefix && <Box css={{ zIndex: 1 }}>{prefix}</Box>}
      <StyledTextArea
        onClick={e => {
          track("Click Input", {
            field: props.name,
            ...analytics
          });
          onClick && onClick(e);
        }}
        as="textarea"
        aria-label={label}
        {...props}
      />
      {suffix && <Box css={{ zIndex: 1 }}>{suffix}</Box>}
      <StyledBackdrop semiTransparent={semiTransparent} />
    </StyledContainer>
  </FormMessaging>
);
