const getCategoryBreadcrumbs = categoriesParam => {
  const categories =
    typeof categoriesParam === Array ? categoriesParam : categoriesParam.nodes;
  if (categories.length === 1) {
    categories[0].url = `/${(categories[0] || {}).slug}`;
    return categories;
  }

  const parent = categories.find(cat => cat.wpParent === null);
  const child = categories.find(cat => cat.wpParent !== null);

  parent.url = `/${(parent || {}).slug}`;
  child.url = `/${(parent || {}).slug}/${(child || {}).slug}`;

  return [parent, child];
};

module.exports = getCategoryBreadcrumbs;
