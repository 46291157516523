import React from "react";
import NearbyDispCard from "../NearbyDispCard";
import "./index.css";
const NearbyDispenariesTab = ({ nearDispensaries, dispensariesDistances }) => {
  return (
    <div className="nearbyDispensariesTab">
      {nearDispensaries.nodes.length > 10
        ? nearDispensaries.nodes.slice(0, 10).map(dispensary => {
            return (
              <NearbyDispCard
                dispensary={dispensary}
                dispensariesDistances={dispensariesDistances}
              />
            );
          })
        : nearDispensaries.nodes.map(dispensary => {
            return (
              <NearbyDispCard
                dispensary={dispensary}
                dispensariesDistances={dispensariesDistances}
              />
            );
          })}
    </div>
  );
};

export default NearbyDispenariesTab;
