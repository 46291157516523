import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import { track } from "@herb/utils/analytics";
import productLink from "@herb/utils/productLink";
import Button from "@herb/components/Button";
import "./index.css";
import { fallbackImages } from "../../../utils/constants";
const RandomProductCard = ({ productdetails }) => {
  const { acf, contentProducts } = productdetails;

  const image = acf
    ? (acf || {}).product_images[0]
    : (contentProducts || {}).productImages[0];
  const sourceUrl = image?.sourceUrl || image?.source_url;
  const altText = (image || {}).altText || (image || {}).alt_text;
  const { productCategory: category, affiliateUrl } = contentProducts;

  const trackShopButtonEvent = to => () => {
    let analytics = { locationOnPage: "Product Page Affiliate CTA" };

    if (typeof window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Shop Button Clicked"
      });
    }

    track("Shop Button Click", {
      to,
      ...(analytics || {})
    });

    return true;
  };
  return (
    <div className="randomProductCard">
      <div className="randomProductCardInner">
        <Image
          className="lazyload randomProductImage"
          fallback={fallbackImages.square}
          src={sourceUrl}
          alt={altText}
          width={250}
        />

        <div className="randomProductCategoryAndBrand">
          <p className="randomProductCategory">
            {category.name}&nbsp;
            <span className="randomProductBrand">
              by {productdetails.acf.brand.post_title}
            </span>
          </p>
        </div>
        <p className="randomProductTitle">{productdetails.title}</p>
      </div>
      <div className="randomProductCardFooter">
        <Button
          href={affiliateUrl}
          analytics={trackShopButtonEvent(affiliateUrl)}
          target="_blank"
          link
          css={{
            fontStyle: "italic",
            fontWeight: "300",
            padding: "6px 18px",
            fontSize: "20px",
            lineHeight: "24px",

            display: "inline-block",
            background: "#3041FF",
            color: "white"
          }}
          type={"boutiqueButton"}
          rel="noopener noreferrer"
        >
          SHOP NOW
        </Button>
        <Link
          href={`${productLink(productdetails)}`}
          css={{
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "24px",
            margin: "5px 0 0 0",
            display: "inline-block",
            textDecorationLine: "underline",
            textTransform: "uppercase",
            color: "#141313",
            fontFamily: "Source Code Pro"
          }}
        >
          View Product
        </Link>
      </div>
    </div>
  );
};

export default RandomProductCard;
