import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

const CustomSearchBox = ({
  currentRefinement,
  isSearchStalled,
  refine,
  semiTransparent,
  customReset,
  onChange,
  ...props
}) => (
  <input
    type="search"
    value={currentRefinement}
    className="searchInputModal"
    placeholder="Search for something"
    onChange={event => {
      refine(event.target.value);
      onChange && onChange(event);
    }}
    {...props}
  />
);

const SearchBox = connectSearchBox(CustomSearchBox);

export default SearchBox;
