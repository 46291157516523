/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import he from "he";
import BreadcrumbSchema from "@herb/components/Schema/BreadcrumbSchema";
import { schema, deployUrl } from "@herb/utils";

function SEO({
  description,
  lang = "en",
  meta = [],
  keywords = [],
  title,
  type,
  image,
  shareTitle,
  canonical = "",
  url = "",
  breadcrumbs
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const socialTitle = shareTitle || title;

  const metaDescription = schema.stripHtml(
    description || site.siteMetadata.description
  );

  const metaType = type || "website";

  const metaImage = `${image ||
    "https://images.herb.co/herb_share.png"}?w=1000`;

  const metaUrl = `${deployUrl}${url}`.toLocaleLowerCase();

  return (
    <React.Fragment>
      <Helmet>
        <link rel="alternate" hrefLang="en" href={canonical || metaUrl} />
      </Helmet>
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={he.decode(title)}
        titleTemplate={`%s`}
        meta={[
          {
            name: "apple-itunes-app-name",
            content: "app-id=1471943430"
          },
          {
            name: `description`,
            content: he.decode(metaDescription)
          },
          {
            property: `og:title`,
            content: he.decode(socialTitle)
          },
          {
            property: `og:image`,
            content: metaImage
          },
          {
            property: `og:description`,
            content: he.decode(metaDescription)
          },
          {
            property: `og:type`,
            content: metaType
          },
          {
            property: `og:url`,
            content: metaUrl
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author
          },
          {
            name: `twitter:title`,
            content: he.decode(socialTitle)
          },
          {
            name: `twitter:description`,
            content: he.decode(metaDescription)
          },
          {
            name: `twitter:image`,
            content: metaImage
          },
          {
            property: `fb:app_id`,
            content: process.env.GATSBY_FB_APP_ID
          }
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `)
                }
              : []
          )
          .concat(meta)}
        link={[{ rel: "canonical", href: canonical || metaUrl }]}
      />
      {breadcrumbs && <BreadcrumbSchema breadcrumbs={breadcrumbs} />}
    </React.Fragment>
  );
}

export default SEO;
