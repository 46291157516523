import React from "react";
import { style } from "styled-system";

import Flex from "./Flex";
import Heading from "./Heading";
import Text from "./Text";

export default ({ children, subheading, styles = {} }) => (
  <Flex
    flexDirection="column"
    alignItems={["flex-start", "center"]}
    my={[4, 5]}
    css={{
      marginTop: "0px"
      // ...styles
    }}
  >
    <Heading as="h1" fontSize={[6, 8]} textAlign={["left", "center"]}>
      {children}
    </Heading>
    {subheading && (
      <Text
        textAlign={["left", "center"]}
        mt={3}
        color="textSubdued"
        css={{ maxWidth: "29rem" }}
      >
        {subheading}
      </Text>
    )}
  </Flex>
);
