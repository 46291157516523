import React from "react";
import styled from "@emotion/styled";
import { Heading as H } from "@rebass/emotion";
import { style, space } from "styled-system";

const measure = style({
  prop: "measure",
  cssProperty: "maxWidth",
  key: "measure"
});

const Heading = styled(H)(measure, space);

export default props => (
  <Heading fontWeight={700} lineHeight="heading" {...props} />
);
