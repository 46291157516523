import React from "react";
import Button from "../Button";
import "./index.css";

const MarketingSolutionForDispensaryPlus = () => {
  return (
    <div className="solutionsForMarketing">
      <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardTextDispensary">WEBSITE GROWTH</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>
        <div className="marketingContent">
          <p className="marketingTextDispensary">
            Boost the amount of organic traffic coming to your website each
            month, and be found more easily.
          </p>
          <Button type={"careerCardBtn"} to="/seo">
            Learn more
          </Button>
        </div>
      </div>
      <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardTextDispensary">EMAIL & SOCIAL</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>

        <div className="marketingContent">
          <p className="marketingTextDispensary">
            Market your dispensary directly to Herb’s audience of millions of
            cannabis users with trackable and measurable results.
          </p>
          <Button type={"careerCardBtn"} to="/email">
            Learn more
          </Button>
        </div>
      </div>

      <div className="solutionsForHerb">
        <div className="marketingCard">
          <div className="marketingCardBg">
            <p className="marketingCardTextDispensary">PROGRAMMATIC ADS</p>
          </div>
        </div>
        <div className="herbPlusArrow">
          <div class="rightSideArrowPlus"></div>
        </div>
        <div className="marketingContent">
          <p className="marketingTextDispensary">
            Target local cannabis users by accessing the largest network of
            known cannabis consumers in the world.
          </p>
          <Button type={"careerCardBtn"} to="/verified-partners">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MarketingSolutionForDispensaryPlus;
