import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import DispensaryTimings from "../DispensaryTimings";
import ReactHtmlParser from "react-html-parser";
import "./index.css";

const DispensaryDetailsTab = ({ dispensary }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCakS5hJl6a2jmqIbR3E2zhXGIvtotxsd0"
  });

  const [map, setMap] = React.useState(null);
  const { content_dispensary } = dispensary;
  const {
    dispensaryAddress,
    dispensaryTimings,
    detailContent,
    callDispensary
  } = content_dispensary;

  var phoneNumber = callDispensary
    ? callDispensary?.match(/\d+/g)?.join("")
    : "";
  const displayNumber = callDispensary
    ? phoneNumber?.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    : "";

  phoneNumber = callDispensary ? Number(phoneNumber) : "";

  const place = {
    lat: dispensaryAddress.latitude,
    lng: dispensaryAddress.longitude
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(place);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div className="dispensaryDetailsMain">
      <div className="dispensaryDetailsUpper">
        <div className="dispensaryDetailsContent">
          {ReactHtmlParser(detailContent)}
        </div>

        <div className="dispensaryDetailsMapAndContent">
          {isLoaded ? (
            <GoogleMap
              mapContainerClassName="mapContainerStyle"
              id="dispDetailTab"
              zoom={14}
              center={place}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                rotateControl: false,
                scaleControl: false,
                zoomControl: true
              }}
            >
              <Marker position={place} animation={2} />
            </GoogleMap>
          ) : (
            <></>
          )}

          <div className="dispensaryAddressMap">
            {dispensaryAddress.streetAddress}
          </div>
          <div className="dispensaryCallButton">
            {callDispensary ? (
              <a className="callDispensary" href={`tel:${phoneNumber}`}>
                {displayNumber}
              </a>
            ) : (
              <></>
            )}
          </div>
          <div>
            <DispensaryTimings timings={dispensaryTimings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DispensaryDetailsTab;
