import React from "react";
import Button from "../Button";
import { Box } from "@herb/ui";
import "./index.css";

const DispensaryHelp = ({
  heading,
  subHeading,
  buttonText,
  buttonLink,
  herbPlus = false,
  css
}) => {
  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: herbPlus ? "292px" : "250px",
        background:
          "radial-gradient(50% 50% at 50% 50%, #F9CCCC 0%, rgba(255, 255, 255, 0) 100%)",
        "@media screen and (max-width:500px)": {
          height: "368px"
        }
      }}
    >
      <div className="helpHeading">{heading}</div>{" "}
      <div className="helpSubHeading">{subHeading}</div>
      <div className="helpButton">
        <Button type={"herbPlusBtn"} to={buttonLink}>
          {buttonText}
        </Button>
      </div>
    </Box>
  );
};

export default DispensaryHelp;
