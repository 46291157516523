import React from "react";

import { Box, Flex } from "./";
import Heading from "./Heading";
import Text from "./Text";

export default ({ children, subheading, all, ...props }) => (
  <Flex {...props} justifyContent="space-between" alignItems="flex-end">
    <Flex flexDirection="column">
      <Heading as="h2" fontSize={[4, 7]}>
        {children}
      </Heading>
      {subheading && (
        <Text color="textSubdued" mt={3} css={{ maxWidth: "25rem" }}>
          {subheading}
        </Text>
      )}
    </Flex>
    {all && (
      <Box pl={4} style={{ whiteSpace: "nowrap" }}>
        {all}
      </Box>
    )}
  </Flex>
);
