import React from "react";
import Button from "@herb/components/Button";
import { Formik, Form, ErrorMessage } from "formik";
import { rules } from "@herb/components/Forms";
import "./index.css";

const Newsletter = props => {
  const { richtext } = props;

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validate={values => {
        let errors = {};

        // Validate email
        if (!values.email) {
          errors.email = "Required";
        } else if (!rules.email.test(String(values.email).toLowerCase())) {
          errors.email = "Please enter a valid e-mail address";
        }

        return errors;
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: values.email
          })
        };

        let url = `https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=SVQR9F`;

        try {
          const res = await fetch(url, options);
          actions.setStatus({
            message: "Thanks for signing up!"
          });

          actions.setSubmitting(false);
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ status, isSubmitting, handleBlur, handleChange }) => (
        <Form name="join-newsletter" method="POST" style={{ width: "100%" }}>
          <div className="newsletterContainer">
            <div className="richText">
              <p className="richTextP">{richtext.title}</p>
            </div>
            <div className="newsletterInput">
              {(status || {}).message ? (
                <p>{status.message}</p>
              ) : (
                <>
                  <div className="newsletterInputForm">
                    <input
                      id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="newsletterInputField"
                      type="text"
                      placeholder="Enter your email"
                      name="email"
                    />
                    <div className="newsletterSubmitButton" type="submit">
                      <Button>submit</Button>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "red",
                      textAlign: "start",
                      marginTop: "8px"
                    }}
                  >
                    <ErrorMessage name="email" />
                  </p>
                </>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Newsletter;
