import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import { Box, Flex, Heading } from "@herb/ui";
import Container from "@herb/components/Container";
import Fade from "react-reveal/Fade";
import MarketingPlan from "@herb/components/MarketingPlan";
import HerbPlusBanner from "@herb/components/Cards/HerbPlusBanner";
import SeoQuote from "@herb/components/SeoQuote";
import SeoSquareCard from "@herb/components/Cards/SeoSquareCard";
import animationObserver from "@herb/utils/animationObserver";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const SeoMarketing = ({ data, pageContext }) => {
  useEffect(() => {
    animationObserver.observe(document.querySelector(".animate-div"));
    scriptCreate();
  }, []);
  return (
    <Layout>
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <SEO
        title={`SEO`}
        url={"/seo"}
        image={
          "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
        }
        canonical={`${deployUrl}/seo`}
      />

      <HerbPlusBanner
        heading="Your SEO is powerful. Optimize it."
        subheading="Unlock the power of high purchase intent keywords using Herb’s
        domain authority and ranking juice. It’s easy and affordable."
        backgroundImage="Rectangle4.png"
      />

      <Container>
        <Box>
          <Box>
            <Fade bottom>
              <Heading
                css={{
                  color: "#3041FF",
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "48px",
                  lineHeight: "56px",
                  paddingTop: "120px",
                  paddingBottom: "30px",
                  "@media screen and (max-width:768px)": {
                    fontSize: "32px",
                    lineHeight: "40px",
                    paddingTop: "15px"
                  }
                }}
              >
                How SEO can help
              </Heading>
            </Fade>
            <Flex
              className="animate-div"
              css={{
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-1"
                heading="Build your digital real estate"
                text="Herb will do the heavy lifting and optimize your website to rank higher than your competitors."
                image="square1.png"
              />
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-2"
                heading="Tell impactful stories that convert"
                text="Ensure you are ranking for keywords that your consumers are searching for."
                image="square2.png"
              />
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-3"
                heading="Utilize proprietary data"
                text="Herb has one of the highest domain authorities in cannabis and 10 years of experience. Let us help you make an impact today."
                image="square3.png"
              />
            </Flex>
          </Box>
        </Box>
      </Container>
      <div
        css={{
          paddingTop: "25px",
          background:
            "linear-gradient(190.02deg,rgb(255 255 255 / 81%) 15.01%,rgba(235,250,255,0.81) 63.27%,rgba(235,250,255,0) 93.05%)"
        }}
      >
        <div
          css={{
            padding: "0px 64px",
            "@media screen and (max-width:768px)": {
              padding: "0px 16px"
            }
          }}
        >
          <SeoQuote
            starting="“Thanks to Herb’s SEO capabilities"
            colored=" we can now convert consumers across 30+ keywords that we previously did not rank for"
            ending="
        No more hoping our traffic and revenue increase. With Herb’s proven growth strategies we have been able to grow consistently and drive real, trackable results.”
        "
            author="Tanya Kinney, Organigram"
          />
        </div>

        <MarketingPlan />
      </div>
    </Layout>
  );
};

export default SeoMarketing;
