export const scriptCreate = () => {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    setTimeout(() => {
      const scriptFunction = document.createElement("script");
      scriptFunction.type = "text/javascript";
      scriptFunction.innerHTML = `
         !function(s) {
        var o = s.createElement('script'), u = s.getElementsByTagName('script')[0];
        o.src = 'https://cdn.aggle.net/oir/oir.min.js';
        o.id = 'aggleScript';
        o.async = !0, o.setAttribute('oirtyp', '6311ae17'), o.setAttribute('oirid', 'A91A52FE');
        u.parentNode.insertBefore(o, u);
        }(document);
        !function(e) {
        var t = {  };
        (e._oirtrk = e._oirtrk || []).push(['track', 'on-site', t]);
        }(window);`;
      document.body.appendChild(scriptFunction);
    }, 500);
  }
};
