import React, { useEffect } from "react";
// import { FastCommentsCommentWidget } from "fastcomments-react";
import ReviewCard from "../Cards/ReviewCard";
import ReactStars from "react-rating-stars-component";
import "./index.css";
const ReviewsTab = ({ reviews, rating, dispensaryTitle }) => {
  // useEffect(() => {
  //   (function(d, s, id) {
  //     var js;
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://embedsocial.com/cdn/ht.js";
  //     d.getElementsByTagName("head")[0].appendChild(js);
  //   })(document, "script", "EmbedSocialHashtagScript");
  // }, []);
  return (
    <div className="dispensaryReviewTab">
      <div className="dispensaryCommentSection">
        <h2 className="dispensaryReviewHeading">
          {reviews.length} Reviews of {dispensaryTitle}
        </h2>
        <ReactStars
          className="reviewStars"
          count={5}
          value={rating}
          size={60}
          activeColor={"#3041FF"}
          isHalf={true}
          halfIcon={`<svg
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            clip-rule="evenodd"
            viewBox="0 0 40 40"
            id="star"
          >
            <path
              fill="#f4c01e"
              d="M250,25L305.112,148.906C307.229,153.665 310.562,157.782 314.776,160.844C318.99,163.906 323.936,165.803 329.116,166.346L463.988,180.471L363.177,271.175C359.305,274.659 356.419,279.101 354.809,284.055C353.2,289.008 352.923,294.299 354.008,299.393L382.252,432.029L264.835,364.181C260.325,361.575 255.209,360.203 250,360.203C244.791,360.203 239.675,361.575 235.165,364.181L117.748,432.029L145.992,299.393C147.077,294.299 146.8,289.008 145.191,284.055C143.581,279.101 140.695,274.659 136.823,271.175L36.012,180.471L170.884,166.346C176.064,165.803 181.01,163.906 185.224,160.844C189.438,157.782 192.771,153.665 194.888,148.906L250,25Z"
              transform="translate(-25.612 -2.561) scale(1.10245)"
            ></path>
          </svg>`}
          edit={false}
        />
        {/* <FastCommentsCommentWidget tenantId="3EWhZWknfRI" />
        <div
          class="embedsocial-hashtag"
          data-ref="cca804e9091af5666269f39471545a4aa3323231"
        ></div> */}
        {reviews?.map((review, index) => (
          <ReviewCard
            review={review}
            totalsReviews={reviews.length}
            key={index}
          />
        ))}
      </div>
      {/* <div className="featuredDispensariesList">Dispensaries</div> */}
    </div>
  );
};

export default ReviewsTab;
