import React from "react";
import styled from "@emotion/styled";
import { themeGet } from "styled-system";
import { connectHighlight } from "react-instantsearch-dom";

const StyledHighlight = styled.mark`
  background-color: ${themeGet("colors.highlight")};
  color: ${themeGet("colors.text")};
`;

const CustomHighlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit
  });

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <StyledHighlight key={index}>{part.value}</StyledHighlight>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
};

const Highlight = connectHighlight(CustomHighlight);

export default Highlight;
