import { useEffect, useState } from "react";

import { supportsLocalStorage } from "@herb/utils";

export default () => {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);

  function fetchData() {
    fetch(process.env.GATSBY_GEOLOCATION)
      .then(response => response.json())
      .then(data => {
        if (data) {
          const location = {
            country: data.country_code,
            region: data.region,
            coordinates: {
              lat: data.latitude,
              lng: data.longitude
            }
          };
          setLocation(location);
          supportsLocalStorage() &&
            localStorage.setItem("userLocation", JSON.stringify(location));
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    if (supportsLocalStorage() && localStorage.getItem("userLocation")) {
      setLocation(JSON.parse(localStorage.getItem("userLocation")));
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  return [location, loading];
};
