import React, { useEffect } from "react";
import { Box, Icon } from "@herb/ui";
import $ from "jquery";

const IndustryImagesScroll = ({ list, direction }) => {
  useEffect(() => {
    if (typeof window !== undefined) {
      $(window).ready(() => {
        if ($(".location-row-left").infiniteslide) {
          $(".location-row-left").infiniteslide({
            speed: 50
          });
          $(".location-row-right").infiniteslide({
            direction: "right",
            speed: 40
          });
        }
      });
    }
  }, []);

  return (
    <Box
      className={`location-row-${direction}`}
      css={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "@media screen and (max-width:768px)": {
          flexWrap: "wrap",
          justifyContent: "space-around"
        }
      }}
    >
      {list.map(logo => (
        <Box
          key={logo.id}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: logo.divWidth,
            marginRight: "56px",
            "@media screen and (max-width:768px)": {
              width: "26%"
            },
            "@media screen and (max-width:425px)": {
              marginRight: "35px"
            }
          }}
        >
          <Icon
            icon={`industry-logo-${logo.id}`}
            svgProps={{
              viewBox: logo.viewBox
            }}
            width={logo.width}
            height={logo.height}
          />
        </Box>
      ))}
    </Box>
  );
};

export default IndustryImagesScroll;
