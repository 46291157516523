import React, { useEffect, useState } from "react";
import Layout from "@herb/layouts";
import { Text, Heading, Box } from "@herb/ui";
import Image from "@herb/components/Image";
import { Helmet } from "react-helmet";
import Button from "@herb/components/Button";
import { withPrefix } from "gatsby";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";

const Download = props => {
  const [downloadGuide, setDownloadGuide] = useState(false);
  const [utm_source, setUtmSource] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.search === "?utm_source=herb-plus-submitted") {
        var source = window.location.search;
        source = source.split("?");
        source = source[1].split("=");

        if (source[0] === "utm_source") {
          setUtmSource(source[1]);
        }
      }
    }
    scriptCreate();
  }, []);

  const handleClick = () => {
    if (typeof window !== "undefined") {
      window.open(
        "https://herb-platform-images.s3.amazonaws.com/Herb+Marketing+Guide2022.pdf"
      );
    }
    setDownloadGuide(true);
  };

  return (
    <>
      <Box
        css={{
          background:
            "linear-gradient(190.02deg, rgba(247, 255, 227, 0.81) 15.01%, rgba(235, 250, 255, 0.81) 63.27%, rgba(235, 250, 255, 0) 93.05%)"
        }}
      >
        <Layout>
          <div className="thankYouPage">
            <Box
              width={[1 / 2]}
              css={{
                "@media screen and (max-width:768px)": {
                  width: "100% !important",
                  paddingBottom: "50px"
                }
              }}
            >
              <div className="Text-content">
                <Heading
                  css={{
                    fontSize: "70px",
                    maxWidth: "1000px",
                    lineHeight: "80px",
                    fontWeight: "700",
                    color: "#3041FF",
                    "@media screen and (max-width:768px)": {
                      fontSize: "32px",
                      lineHeight: "40px"
                    }
                  }}
                >
                  Thank you for Signing Up!
                </Heading>
                <Text
                  css={{
                    paddingTop: "50px",
                    color: "#000",
                    fontSize: "32px",
                    lineHeight: "40px",
                    fontFamily: "Source Code Pro",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontWeight: "300",
                    paddingBottom: "40px",
                    maxWidth: "550px",
                    "@media screen and (max-width:768px)": {
                      fontSize: "24px",
                      lineHeight: "32px",
                      paddingTop: "20px"
                    }
                  }}
                >
                  This guide is a summary of our key learnings we have collected
                  over the years helping cannabis brands achieve their marketing
                  and revenue goals. We hope you find it useful!
                </Text>
                <Button type={"herbPlusBtn"} onClick={handleClick}>
                  Download The Guide
                </Button>
              </div>
            </Box>

            <Box
              width={[1 / 2]}
              css={{
                "@media screen and (max-width:768px)": {
                  width: "100% !important"
                }
              }}
            >
              <div className="cus-download-padding">
                <Image
                  className="downloadPageImage lazyload"
                  src={
                    "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04092459/DTS_Remote_Studio_Tino_Renato_3707-1.webp"
                  }
                />
              </div>
            </Box>
          </div>
        </Layout>
      </Box>

      {downloadGuide && (
        <Helmet>
          <script
            src={withPrefix("marketingScripts.js")}
            type="text/javascript"
          />
        </Helmet>
      )}

      {utm_source === "herb-plus-submitted" && (
        <Helmet>
          <script
            src={withPrefix("herbPlusScripts.js")}
            type="text/javascript"
          />
        </Helmet>
      )}
    </>
  );
};

export default Download;
