import React from "react";
import { Button } from "@herb/ui";

export default ({ css = {}, size = "normal", ...rest }) => (
  <Button
    css={t => ({
      backgroundColor: t.colors.neutral[900],
      textTransform: "capitalize",
      height: "32px",
      textDecoration: "underline",
      fontWeight: "500",
      lineHeight: "32px",
      ...css
    })}
    {...rest}
  />
);
