import React from "react";
import { Box, Container, Heading } from "@herb/ui";
import styled from "@emotion/styled";
import Image from "@herb/components/Image";
import "aos/dist/aos.css";
import Recaptcha from "react-recaptcha";

const SubmitInput = styled.input`
  font-style: italic;
  line-height: 30px;
  font-size: 28px;
  text-transform: uppercase;
  padding: 8px 24px;
  border: 1px solid #3041ff;
  color: #fff;
  font-family: "Source Code Pro";
  cursor: pointer;
  background: #3041ff;
  font-weight: 300;
  -webkit-appearance: none;
  &:hover {
    border: 1px solid #000;
    background: #fff;
    color: #000;
  }
  @media only screen and (max-width: 865px) {
    padding: 8px 24px;
    font-size: 24px;
    line-height: 24px;
  }
  @media only screen and (max-width: 769px) {
    padding: 8px 16px;
    font-size: 20px;
    line-height: 24px;
  }
  @media only screen and (max-width: 430px) {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 16px;
  }
`;
const SalesFoceForm = () => {
  const callback = () => {};

  return (
    <>
      <Heading
        css={{
          color: "#3041FF",
          fontSize: "48px",
          lineHeight: "56px",
          paddingBottom: "25px",
          "@media screen and (max-width:768px)": {
            fontSize: "32px",
            lineHeight: "40px"
          }
        }}
      >
        Ready To Grow Your Dispensary?
      </Heading>

      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
      >
        <input
          type="hidden"
          name="retURL"
          value="https://herb.co/marketing/download/?utm_source=dispensary-plus-submitted"
        />
        <input type="hidden" name="oid" value="00D5f000007G9M3" />

        <input
          style={{
            outline: "none",
            "&:placeholder": {
              color: "#fff"
            },
            fontFamily: "Source Code Pro",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "1px solid #000",
            fontSize: "14px",
            lineHeight: "16px",
            width: "100%",
            backgroundColor: "transparent",
            padding: "12px",
            boxSizing: "border-box",
            marginTop: "6px",
            marginBottom: "15px"
          }}
          placeholder="Full Name*"
          id="first_name"
          maxlength="40"
          name="first_name"
          size="20"
          type="text"
          required
        />
        <br />

        <input
          style={{
            outline: "none",
            "&:placeholder": {
              color: "#fff"
            },
            fontFamily: "Source Code Pro",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "1px solid #000",
            fontSize: "14px",
            lineHeight: "16px",
            width: "100%",
            backgroundColor: "transparent",
            padding: "12px",
            boxSizing: "border-box",
            marginTop: "6px",
            marginBottom: "15px"
          }}
          placeholder="Email*"
          id="email"
          maxlength="80"
          name="email"
          size="20"
          type="text"
          required
        />
        <br />
        <input
          style={{
            outline: "none",
            "&:placeholder": {
              color: "#fff"
            },
            fontFamily: "Source Code Pro",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "1px solid #000",
            fontSize: "14px",
            lineHeight: "16px",
            width: "100%",
            backgroundColor: "transparent",
            padding: "12px",
            boxSizing: "border-box",
            marginTop: "6px",
            marginBottom: "15px"
          }}
          placeholder="Phone*"
          id="phone"
          maxlength="40"
          name="phone"
          size="20"
          type="text"
          required
        />
        <br />

        <select
          css={{
            fontFamily: "Source Code Pro",
            color: "grey",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "1px solid rgb(0, 0, 0)",
            fontSize: "14px",
            lineHeight: "16px",
            backgroundColor: "transparent",
            padding: "12px",
            boxSizing: "border-box",
            marginTop: "6px",
            marginRight: "4%",
            marginBottom: "15px",
            width: "48%",
            outline: "none"
          }}
          id="00N5f00000c17Yi"
          name="00N5f00000c17Yi"
          title="Category"
          required
        >
          <option value="">Category</option>
          <option value="Accessory">Accessory</option>
          <option value="Canadian LP">Canadian LP</option>
          <option value="Cannabis Brand">Cannabis Brand</option>
          <option value="CBD">CBD</option>
          <option value="Device">Device</option>
          <option value="Dispensary">Dispensary</option>
          <option value="Drinkable">Drinkable</option>
          <option value="Edible">Edible</option>
          <option value="MSO">MSO</option>
          <option value="Other">Other</option>
        </select>

        <select
          css={{
            fontFamily: "Source Code Pro",
            color: "grey",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "1px solid rgb(0, 0, 0)",
            fontSize: "14px",
            lineHeight: "16px",
            backgroundColor: "transparent",
            padding: "12px",
            boxSizing: "border-box",
            marginTop: "6px",
            marginBottom: "15px",
            width: "48%",
            outline: "none"
          }}
          id="00N5f00000c17Xu"
          name="00N5f00000c17Xu"
          title="Budget"
          required
        >
          <option value="">Monthly Budget</option>
          <option value="$0 - 10K per month">$0 - 10K per month</option>
          <option value="$10K - 25K per month">$10K - 25K per month</option>
          <option value="$25K+ per month">$25K+ per month</option>
        </select>
        <br />

        <select
          css={{
            fontFamily: "Source Code Pro",
            color: "grey",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "1px solid rgb(0, 0, 0)",
            fontSize: "14px",
            lineHeight: "16px",
            backgroundColor: "transparent",
            padding: "12px",
            boxSizing: "border-box",
            marginTop: "6px",
            marginBottom: "15px",
            width: "100%",
            outline: "none"
          }}
          id="00N5f00000gR34P"
          name="00N5f00000gR34P"
          title="How did you hear about us"
          required
        >
          <option value="">How did you hear about us</option>
          <option value="Google">Google</option>
          <option value="Instagram">Instagram</option>
          <option value="Facebook">Facebook</option>
          <option value="Linkedin">Linkedin</option>
          <option value="Twitter">Twitter</option>
          <option value="Webinar">Webinar</option>
          <option value="Referral">Referral</option>
          <option value="Other">Other</option>
        </select>
        <br />
        <textarea
          style={{
            fontFamily: "Source Code Pro",
            fontSize: "14px",
            lineHeight: "16px",
            width: "100%",
            outline: "none",
            backgroundColor: "transparent",
            color: "grey",
            padding: "12px",
            border: "1px solid #000",
            boxSizing: "border-box",
            marginTop: "6px",
            marginBottom: "15px",
            height: "200px"
          }}
          placeholder="Additional comments"
          id="00N5f00000c3v7a"
          name="00N5f00000c3v7a"
          type="text"
          wrap="soft"
        ></textarea>
        <br />

        <Recaptcha
          sitekey="6Lf9x1geAAAAAOM2OKsNCQDU4S-RHl-u2xavTggp"
          render="explicit"
          onloadCallback={callback}
        />
        <br />

        <SubmitInput type="submit" name="submit" value={"Let's Chat"} />
      </form>
    </>
  );
};

const MarketingPlanDispensary = () => {
  return (
    <>
      <Box
        id="marketing-plan-form"
        css={{
          paddingTop: "100px",
          "@media screen and (max-width:768px)": {
            paddingTop: "30px"
          }
        }}
      >
        <Container>
          <Box
            css={{
              width: "50%",
              height: "auto",
              padding: "120px 30px",
              paddingBottom: "20px",
              color: "#fff",
              paddingLeft: "0",
              "@media screen and (max-width:768px)": {
                width: "100%",
                padding: "0"
              }
            }}
          >
            <SalesFoceForm />
          </Box>

          <Box
            css={{
              width: "50%",
              paddingTop: "120px",
              paddingLeft: "30px",
              "@media screen and (max-width:768px)": {
                display: "none"
              }
            }}
          >
            <Image
              className="lazyload"
              src="https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08083057/Frame-93.png"
              css={{
                width: "100%",
                height: "79%",
                objectFit: "cover",
                borderRadius: "24px"
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes"
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MarketingPlanDispensary;
