import React from "react";
import Image from "@herb/components/Image";
import { Box, Heading, Text } from "@herb/ui";

const SeoBussinessSection = ({
  number,
  text,
  heading,
  textNum,
  text1,
  text2,
  boldText1,
  boldText2,
  coloredText,
  page
}) => {
  return (
    <Box
      css={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        paddingTop: "15px",
        paddingBottom: "15px",
        "@media screen and (max-width:760px)": {
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "40px"
        }
      }}
    >
      <Box
        css={{
          width: "8%",
          "@media screen and (max-width:760px)": {
            width: "15%"
          }
        }}
      >
        {textNum === 2 ? (
          <>
            <Image
              className="lazyload"
              src={`../images/seo/business-${10}.png`}
              css={{
                display: "none",
                width: "100%",
                "@media screen and (max-width:480px)": {
                  display: "flex"
                }
              }}
            />
            <Image
              className="lazyload"
              src={`../images/seo/business-${number}.png`}
              css={{
                display: "flex",
                width: "100%",
                "@media screen and (max-width:480px)": {
                  display: "none"
                }
              }}
            />
          </>
        ) : (
          <Image
            className="lazyload"
            src={`../images/seo/business-${number}.png`}
            css={{
              width: "100%"
            }}
          />
        )}
      </Box>
      <Box
        css={{
          width: "88%",
          "@media screen and (max-width:760px)": {
            textAlign: "center",
            paddingTop: "15px"
          }
        }}
      >
        <Heading
          css={{
            paddingBottom: "10px",
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "40px",
            textTransform: "uppercase",
            fontFamily: "Source Code Pro",
            "@media screen and (max-width:760px)": {
              lineHeight: "32px",
              fontSize: "24px",
              fontStyle: "italic",
              fontWeight: "300"
            }
          }}
        >
          {heading}
        </Heading>

        {textNum === 2 ? (
          <div
            css={{
              fontSize: "18px",
              lineHeight: "32px",
              fontWeight: "400",
              maxWidth: "506px"
            }}
          >
            <span>{text1}</span>{" "}
            <span
              style={{
                fontWeight: "500"
              }}
            >
              {boldText1}
            </span>{" "}
            <span style={{ color: "blue", fontWeight: "500" }}>
              {coloredText}
            </span>{" "}
            <span
              style={{
                fontWeight: "500"
              }}
            >
              {boldText2}
            </span>{" "}
            <span>{text2}</span>
          </div>
        ) : (
          <Text
            css={{
              fontSize: "18px",
              lineHeight: "32px",
              fontWeight: "400"
            }}
          >
            {text}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default SeoBussinessSection;
