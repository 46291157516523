import React, { useEffect } from "react";
import "./index.css";

const SectionHeading = ({ heading, topHeading }) => {
  const [defaultColor, setColor] = React.useState("/");

  useEffect(() => {
    const page = window.location.pathname.split("/");
    const classassigne = page[1] ? page[1] : "";
    setColor(classassigne);
  }, []);

  heading = heading || "What’s new";
  return (
    <div
      className={`${
        defaultColor === "videos"
          ? "mainHeadingVideos hrClassVideos"
          : "mainHeading hrClass"
      }`}
    >
      {topHeading ? (
        <h1
          className={`${defaultColor === "videos" ? "titleVideos" : "title"}`}
        >
          {heading}
        </h1>
      ) : (
        <p className={`${defaultColor === "videos" ? "titleVideos" : "title"}`}>
          {heading}
        </p>
      )}
    </div>
  );
};

export default SectionHeading;
