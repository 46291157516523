import React, { useState } from "react";

import { useLocation } from "@herb/utils";

const LocationContext = React.createContext({
  location: { country: null },
  loading: true
});

const LocationProvider = ({ children, ...props }) => {
  const [location, loading] = useLocation();
  const [pages, setPages] = useState({
    home: 8,
    category: 9,
    article: 9,
    categorySlug: ""
  });

  return (
    <LocationContext.Provider value={{ location, loading, pages, setPages }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
export { LocationProvider };
