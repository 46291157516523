import React from "react";
import LoadRandomProducts from "../Article/LoadRandomProducts";
import "./index.css";

const SidebarBrandsAndProducts = () => {
  return (
    <div>
      <div className="sidebarBrandsAndProducts">
        <div className="sidebarBrandsAndProductsInner">
          <div css={{ margin: "0 auto" }}>
            <p
              css={{ fontSize: "14px" }}
              className="recommendedProductsHeading"
            >
              Herb <span>Recommended</span> Products:
            </p>

            <LoadRandomProducts productsToShow={2} marginLeft={"0px"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarBrandsAndProducts;
