import React from "react";
// import CategoryAndDate from "../CategoryAndDate";
import Button from "../Button";
import Image from "@herb/components/Image";
import { slugs as createSlug } from "@herb/utils";
import "./index.css";
import RedirectLink from "../RedirectLink";

const MiniShows = ({ serie }) => {
  return (
    <div className="smallShowDiv">
      <div className="smallShowPreview">
        <Image
          className="lazyload smallShowImage"
          src={serie.bannerImage}
          imgixParams={{
            ar: "4:3",
            crop: "focalpoint",
            fit: "crop"
          }}
        />
        <RedirectLink
          to={`/videos/${(serie || {}).slug}/${createSlug(
            serie.videos[0].title
          )}`}
        >
          <div className="watchNowBtn">
            <Button type={"miniShowBtn"}>watch now</Button>
          </div>
        </RedirectLink>
      </div>
      <div className="miniShowDescription">
        <div>{/* <CategoryAndDate /> */}</div>
        <p className="smallShowDescription">{serie.shortDescription}</p>
      </div>
    </div>
  );
};

export default MiniShows;
