import React from "react";
import { Link } from "gatsby";
import Image from "../Image";
import "./index.css";
const NearbyDispCard = ({ dispensary, dispensariesDistances }) => {
  const { title, slug, featuredImage, content_dispensary } = dispensary;

  const dispensaryDistance = dispensariesDistances[0].nearDispensaries.find(
    disp => disp.destination === slug
  ).distance;

  return (
    <Link to={`/dispensaries/${slug}/`}>
      <div className="nearbyDispCard">
        <Image
          className="nearbyDispCardImage"
          src={featuredImage?.node?.sourceUrl}
        />
        <div className="nearbyDispCardContent">
          <h4 className="nearbyDispCardTitle">{title}</h4>
          <p className="nearbyDispCardDistance">{dispensaryDistance}</p>
          <p className="nearbyDispCardAddress">
            {content_dispensary.dispensaryAddress.streetAddress}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default NearbyDispCard;
