const paths = require("./paths");
const slugify = require("slugify");

const productLink = product => {
  const productCategory = product.productCategory
    ? product.productCategory.name
    : product.category;
  const category = slugify(productCategory, { lower: true });

  const brand = product.acf
    ? product.acf.brand.post_name
    : ((product.contentProducts || {}).brand || {}).slug;

  return `${paths.products}/${category}/${brand}-${(product || {}).slug}`;
};

module.exports = productLink;
