import React, { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { Box, Icon } from "@herb/ui";
import "./index.css";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <>
      <div className="counter">
        <div className="time">
          <p className="countDays">{zeroPad(days)}</p>

          <p className="timeDetail">Days</p>
        </div>
        <div className="time">
          <p className="countHours">{zeroPad(hours)}</p>

          <p className="timeDetail">Hours</p>
        </div>
        <div className="time">
          <p className="countMinutes">{zeroPad(minutes)}</p>

          <p className="timeDetail">Minutes</p>
        </div>
        <div className="time">
          <p className="countSecond">{zeroPad(seconds)}</p>
          <p className="timeDetail">Seconds</p>
        </div>
      </div>
    </>
  );
};

const Counter = ({
  page = "WorkShop",
  counterDate = "2022-12-01T00:00:01Z"
}) => {
  let finalDate;

  const utcDate = counterDate;

  let date = new Date(utcDate);

  date = date.toLocaleString("en-US", { hour12: false });

  date = date.split(",");
  let firstHalf = date[0];
  let secondHalf = date[1];
  firstHalf = firstHalf.split("/");
  firstHalf = firstHalf[2] + "-" + firstHalf[0] + "-" + "0" + firstHalf[1];
  secondHalf = secondHalf.split(" ");
  secondHalf = secondHalf[1];

  finalDate = firstHalf + "T" + secondHalf;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModalOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const customStyles = {
    content: {
      background: "rgb(255, 255, 255)",
      borderRadius: "4px",
      margin: "83px auto",
      maxWidth: "712px"
    },
    overlay: {
      overflowY: "scroll",
      background: "#00000033"
    }
  };

  return (
    <>
      <div className="mainCounter">
        <div
          className={
            page === "WorkShop"
              ? "mainCounterContent"
              : "mainCounterContent is-justify-space-between"
          }
        >
          <div
            className={
              page === "WorkShop"
                ? "counterText"
                : "counterText counterTextSalesPage"
            }
          >
            {page === "WorkShop" ? (
              <h2 className="text">
                Free Live Training:
                <br />
                The 4-Step Blueprint for Landing Your Dream Customer at Scale
              </h2>
            ) : (
              <h2 className="text">Offer ends in....</h2>
            )}
          </div>
          <div className="mainCountdown">
            <Countdown date={finalDate} renderer={renderer} />
          </div>

          {page === "WorkShop" ? (
            <div className="mainCountdownBtn">
              <button className="clockButton" onClick={toggleModalOpen}>
                Sign Up
              </button>
            </div>
          ) : (
            <a
              href="https://calendly.com/herb-sales"
              alt="Broken Link"
              target="_Blank"
            >
              <div className="mainCountdownClaimBtn">
                <button className="clockButton">claim offer</button>
              </div>
            </a>
          )}
        </div>
      </div>
      {page === "WorkShop" ? (
        <Modal isOpen={modalIsOpen} style={customStyles} className="testing">
          <div className="webinarFormContainer">
            <div className="webinarCross">
              <Box
                width={["22px", "", "25px"]}
                p={1}
                css={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  borderRadius: 99,
                  background: "black",
                  color: "white",
                  transform: "translate(calc(50% - 50px),calc(-50% + 50px))"
                }}
                onClick={e => {
                  e.preventDefault();
                  toggleModalOpen();
                  if (typeof window !== undefined)
                    window.location.reload(false);
                }}
              >
                <Icon
                  icon="close"
                  height={24}
                  width={24}
                  viewBox={"0 0 24 24"}
                />
              </Box>
            </div>

            <div className="webinarFormHeading">
              Join Matt, founder of Herb, for this no-BS training, where he’ll
              share exactly how you land your dream customer at scale.
            </div>
            <div class="wj-embed-wrapper" data-webinar-hash="7064rtm">
              <Helmet>
                <script src="https://event.webinarjam.com/register/7064rtm/embed-form?formButtonText=Register&formAccentColor=%23080808&formAccentOpacity=1&formBgColor=%23ffffff&formBgOpacity=1"></script>
              </Helmet>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal isOpen={modalIsOpen} style={customStyles} className="testing">
          <div className="webinarFormContainer">
            <div className="webinarCross">
              <Box
                width={["22px", "", "25px"]}
                p={1}
                css={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  borderRadius: 99,
                  background: "black",
                  color: "white",
                  transform: "translate(calc(50% - 50px),calc(-50% + 50px))"
                }}
                onClick={e => {
                  e.preventDefault();
                  toggleModalOpen();
                  if (typeof window !== undefined)
                    window.location.reload(false);
                }}
              >
                <Icon
                  icon="close"
                  height={24}
                  width={24}
                  viewBox={"0 0 24 24"}
                />
              </Box>
            </div>

            <div class="wj-embed-wrapper" data-webinar-hash="7064rtm">
              <Helmet>
                <script src="https://event.webinarjam.com/register/7064rtm/embed-form?formButtonText=Register&formAccentColor=%23080808&formAccentOpacity=1&formBgColor=%23ffffff&formBgOpacity=1"></script>
              </Helmet>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Counter;
