import React, { useState } from "react";
import TopBrandCard from "../ProductsPage/TopBrandCard";
import ProductHypeCard from "../ProductsPage/ProductHypeCard";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useWindowDimensions from "../../utils/useWindowDimensions";
import "./index.css";
const hasWindow = typeof window !== "undefined";

const DropDownTabs = ({ products, brands }) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const { height, width } = useWindowDimensions();

  return (
    hasWindow && (
      <div>
        {width >= 480 ? (
          <Tabs>
            <TabList className={"prodcutTabList"}>
              <Tab
                className={"productTabListElement"}
                selectedClassName={"productTabListElementSelected"}
              >
                Herb Product Hype
              </Tab>

              <Tab
                className={"productTabListElement"}
                selectedClassName={"productTabListElementSelected"}
              >
                Top Brands
              </Tab>
            </TabList>
            <TabPanel
              className={"productsTabPanel"}
              selectedClassName={"productsTabPanelSelected"}
            >
              {products ? (
                (products || []).map((productHype, index) => {
                  return (
                    <ProductHypeCard product={productHype} index={index} />
                  );
                })
              ) : (
                <p className="noDataAvailable">No Product Available</p>
              )}
            </TabPanel>
            <TabPanel
              className={"productsTabPanel"}
              selectedClassName={"productsTabPanelSelected"}
            >
              {brands ? (
                (brands || []).map((topBrand, index) => {
                  return <TopBrandCard brand={topBrand} index={index} />;
                })
              ) : (
                <p className="noDataAvailable">No Brands Available</p>
              )}
            </TabPanel>
          </Tabs>
        ) : (
          <div className="mobileTabs">
            <div class="mobileTabOptions">
              <select
                className="selectMobile"
                onChange={e => {
                  setSelectedTab(e.target.value);
                }}
              >
                <option className={"tabOption"} value="1">
                  Herb Product Hype
                </option>
                <option className={"tabOption"} value="2">
                  Top Brands
                </option>
              </select>
              <div className="borderBottom"></div>
              <div className="mobileTabCotent">
                {selectedTab === "1" ? (
                  <div className="tab1">
                    {products ? (
                      (products || []).map((productHype, index) => {
                        return (
                          <ProductHypeCard
                            product={productHype}
                            index={index}
                          />
                        );
                      })
                    ) : (
                      <p className="noDataAvailable">No Product Available</p>
                    )}
                  </div>
                ) : (
                  <div className="tab2">
                    {brands ? (
                      (brands || []).map((topBrand, index) => {
                        return <TopBrandCard brand={topBrand} index={index} />;
                      })
                    ) : (
                      <p className="noDataAvailable">No Brands Available</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default DropDownTabs;
