import React, { useEffect } from "react";
import { Box } from "@herb/ui";
import $ from "jquery";
import { useStaticQuery, graphql } from "gatsby";
import "./index.css";

const InfiniteTextScroll = ({ direction = "right" }) => {
  const { wordpressWpPage } = useStaticQuery(graphql`
    query {
      wordpressWpPage(slug: { eq: "products" }) {
        template {
          ... on WordpressWpTemplate_TrendingProducts {
            templateName
            trendingProducts {
              animateLoop
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (typeof window !== undefined) {
      $(window).ready(() => {
        if ($(".location-row-left").infiniteslide) {
          // $(".location-row-left").infiniteslide({
          //   speed: 50
          // });
          $(".location-row-right").infiniteslide({
            direction: "right",
            speed: 40
          });
        }
      });
    }
  }, []);

  let animateLoop = "YOUR NEXT FAVORITE WEED PRODUCT. WE GOT YOU.";
  animateLoop = wordpressWpPage?.template?.trendingProducts?.animateLoop;
  const text = [animateLoop, animateLoop, animateLoop];

  return (
    <div className="infinitScrollListTextDiv">
      <Box
        className={`location-row-${direction}`}
        css={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          "@media screen and (max-width:768px)": {
            flexWrap: "wrap",
            justifyContent: "space-around"
            // padding: "0 20px"
          }
        }}
      >
        {text.map((t, index) => {
          return (
            <>
              <div>
                <p className="scrollText">{t}</p>
              </div>
            </>
          );
        })}
      </Box>
    </div>
  );
};

export default InfiniteTextScroll;
