import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DispensaryDetailsTab from "../DispensaryDetailsTab";
import ReviewsTab from "../DispensaryReviewsTab";
import NearbyDispenariesTab from "../NearbyDispensaryTab";
import "react-tabs/style/react-tabs.css";
import "./index.css";
const DispensaryTabs = ({ context, dispensary, nearbyDispensaries }) => {
  const reviews = context?.reviewRes[0]?.reviewsData?.result?.reviews;
  const rating = context?.reviewRes[0]?.reviewsData?.result?.rating;
  const { nearDispensaries } = context;

  return (
    <div className="dispensaryTabs">
      <Tabs>
        <TabList className={"dispensaryTabList"}>
          <Tab
            className={"dispensaryTabListElement"}
            selectedClassName={"dispensarySelectedTabClassName"}
          >
            Details
          </Tab>
          {reviews ? (
            <Tab
              className={"dispensaryTabListElement"}
              selectedClassName={"dispensarySelectedTabClassName"}
            >
              Reviews
            </Tab>
          ) : (
            <></>
          )}
          {nearDispensaries.length > 0 ? (
            <Tab
              className={"dispensaryTabListElement"}
              selectedClassName={"dispensarySelectedTabClassName"}
            >
              Near Dispensaries
            </Tab>
          ) : (
            ""
          )}
        </TabList>

        <TabPanel>
          <DispensaryDetailsTab dispensary={dispensary} />
        </TabPanel>
        {reviews ? (
          <TabPanel>
            <ReviewsTab
              reviews={reviews}
              rating={rating}
              dispensaryTitle={dispensary.title}
            />
          </TabPanel>
        ) : (
          ""
        )}
        {nearDispensaries ? (
          <TabPanel>
            <NearbyDispenariesTab
              dispensariesDistances={nearDispensaries}
              nearDispensaries={nearbyDispensaries}
            />
          </TabPanel>
        ) : (
          ""
        )}
      </Tabs>
    </div>
  );
};

export default DispensaryTabs;
