import styled from "@emotion/styled";
import { themeGet } from "styled-system";

import { Text } from "@herb/ui";

const increaseScale = num => props => props.theme.fontSizes[3 + num];

const WPContent = styled(Text)`
  font-size: 1em;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 30px;

  @media screen and (min-width: ${themeGet("breakpoints.0")}) {
    font-size: 18px;
    line-height: 32px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: ${themeGet("lineHeights.heading")};
    margin: 1em 0 0.5em 0;
  }

  h1 {
    font-weight: 700;
    font-size: 72px;
    line-height: 60px;
    @media (max-width: 429px) {
      font-size: 48px;
      line-height: 56px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    @media (max-width: 429px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  h3,
  h4 {
    font-size: ${increaseScale(1)};
  }

  h5,
  h6 {
    font-size: ${increaseScale(0)};
  }

  p {
    margin: 0 0 1em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid;
    &:hover {
      text-decoration: none;
    }
  }

  ul,
  ol {
    margin: 2em 0;
    padding-left: 2.5em;
  }

  li {
    margin-bottom: 0.5em;
  }

  figure {
    margin: 0 0 1em;

    img {
      margin-bottom: 0em;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    margin: 1em 0;
  }

  strong,
  b {
    font-weight: 700;
  }

  video,
  iframe:not([src*="facebook.com"]) {
    display: block;
    width: 100%;
    border-style: none;
    margin: 0;

    @media (max-width: ${themeGet("breakpoints.0")}) {
      height: 330px;
    }
  }

  .responsive {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;

    video,
    iframe {
      position: absolute;
    }
  }

  video,
  .wp-video {
    width: 100% !important;
    height: auto !important;
  }

  iframe[src*="facebook.com"] {
    display: block;
    max-width: 100% !important;
    margin: 15px auto !important;
  }

  blockquote.twitter-tweet {
    border: 1px solid rgb(225, 232, 237);
    border-radius: 4px;
    max-width: 100%;
    width: 520px;
  }

  twitterwidget,
  .twitter-video,
  .twitter-tweet,
  .instagram-media {
    margin: 2em auto !important;
  }
`;

export default WPContent;
