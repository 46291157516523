import React from "react";
import "./index.css";
import Slider from "react-slick";
import SliderProductCard from "../SliderProductCard";

const ProductSlider = ({ products, section = "" }) => {
  // For Slider
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="citySlidertArrow"
          viewBox="0 0 32 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.999999 1L31 21L0.999999 41"
            stroke={section === "middleSection" ? "white" : "black"}
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="citySlidertArrow"
          viewBox="0 0 32 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31 1L1 21L31 41"
            stroke={section === "middleSection" ? "white" : "black"}
          />
        </svg>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 5,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false
        }
      }
    ]
  };
  return (
    <div className="productSlider">
      <Slider {...settings}>
        {(products || []).map(product => (
          <SliderProductCard
            section={section}
            productdetails={product}
            key={product.id}
          />
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
