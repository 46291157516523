import React from "react";
import Image from "../../Image";
import { Link } from "gatsby";
import ReactStars from "react-rating-stars-component";
import getCategoryPath from "@herb/utils/categoryPath";
import "./index.css";
import { fallbackImages } from "../../../utils/constants";

const DispensaryCard = ({ dispensary }) => {
  const {
    dispensaryCategories,
    content_dispensary,
    featuredImage,
    title,
    slug
  } = dispensary;
  const { dispensaryAddress } = content_dispensary;

  const { city, country } = dispensaryAddress;

  return (
    <div className="dispensaryCardMainDiv">
      <div className="dispensaryCardImage">
        <Link to={`/dispensaries/${slug}/`}>
          <Image
            className="lazyload dispensaryCardImageDiv"
            src={featuredImage?.node?.sourceUrl}
            alt={featuredImage?.node?.altText}
            fallback={fallbackImages.square}
            width={168}
            height={168}
          />
        </Link>
      </div>
      <div className="dispensaryCardContent">
        <div className="dispensaryCardAddress">{`${city}, ${country}`}</div>
        <Link to={`/dispensaries/${slug}/`}>
          <div className="dispensaryCardTitle">{title}</div>
        </Link>
        {/* <div className="dispensaryCardReviews">
          <ReactStars
            classNames="dispensaryRatingStars"
            edit={false}
            count={5}
            size={24}
            value={4}
            activeColor="#3041FF"
          />
          <div className="dispensaryCardRatingsNum">4.0 (1,198)</div>
        </div> */}
        <div className="dispensaryCardAddressMobile">{`${city}, ${country}`}</div>
      </div>
    </div>
  );
};

export default DispensaryCard;
