import React from "react";
import { Link } from "gatsby";
import "./index.css";
const DispensaryRelatedLinkCard = ({
  item,
  dispensaryDetailPage = false,
  length
}) => {
  const { title, url, description, url_path } = item;
  const splitpath = url_path.split("/");
  const state = splitpath[2];
  const city = splitpath[3];

  return (
    <div className="dispensaryRelatedLinkCard">
      <h2 className="dispensaryRelatedLinkCardTitle">{city}</h2>
      <div className="dispensaryRelatedLinkCardContent">
        <Link
          to={`/dispensaries/${state === "washington" ? "wa" : state}`}
          className="dispensaryRelatedLinkCardLink"
        >
          {state}
        </Link>
        {"|"}
        <p className="dispensaryRelatedLinkCardDescription">
          {length} Dispensaries
        </p>
      </div>
    </div>
  );
};

export default DispensaryRelatedLinkCard;
