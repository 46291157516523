import ReactHtmlParser from "react-html-parser";

function parseMeta(content) {
  return (content && ReactHtmlParser(stripHtml(content).trim())) || "";
}

function parseTitle(item) {
  return ReactHtmlParser((item.seo || {}).seoTitle || item.title);
}

function stripHtml(str) {
  return str && str.replace(/<(.|\n)*?>/g, "");
}

export default {
  parseMeta,
  parseTitle,
  stripHtml
};
