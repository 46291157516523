import React from "react";
import styled from "@emotion/styled";
import { themeGet } from "styled-system";

import { Box, Icon } from "../";
import FormMessaging from "./FormMessaging";
import { StyledBackdrop, StyledContainer, StyledInput } from "./styled";

const SelectInput = styled(StyledInput)`
  option {
    color: ${themeGet("colors.neutral.900")};
  }
`;

export default ({ id, label, error, onClick, meta = {}, ...props }) => (
  <FormMessaging label={label} error={error}>
    <StyledContainer>
      <SelectInput
        aria-label={label}
        as="select"
        css={{ cursor: "pointer" }}
        {...props}
      />
      <Box
        width="24px"
        color="textSubdued"
        css={t => ({
          position: "absolute",
          right: `${t.space[1]}px`,
          zIndex: 1,
          pointerEvents: "none"
        })}
      >
        <Icon icon="arrow-drop-down" />
      </Box>
      <StyledBackdrop />
    </StyledContainer>
  </FormMessaging>
);
