import React from "react";
import { Flex, Heading } from "@herb/ui";
import Image from "@herb/components/Image";
import { fallbackImages } from "../../utils/constants";

const CareerLocation = ({ image, name }) => {
  return (
    <Flex
      css={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex !important",
        "&:hover": {
          WebkitTextStroke: "1px black",
          color: "transparent",
          img: {
            transform: "scale(1.2)"
          }
        }
      }}
    >
      <Image
        className="lazyload"
        src={`../images/${image}`}
        fallback={fallbackImages.square}
        css={{
          transition: "transform .2s",
          display: "inline-block",
          width: "88px",
          height: "88px",
          borderRadius: "16px"
        }}
      />
      <Heading
        css={{
          display: "inline-block",
          margin: "0px 32px",
          fontSize: "48px"
        }}
      >
        {name}
      </Heading>
    </Flex>
  );
};

export default CareerLocation;
