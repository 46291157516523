import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import ShareLinks from "@herb/components/ShareLinks";
import slugify from "slugify";
import CategoryAndDate from "@herb/components/CategoryAndDate";
import WrittenBy from "@herb/components/WrittenBy";
import StickySideArticle from "@herb/components/Article/StickySideArticle";
import StickySideNewsletter from "@herb/components/StickySideNewsletter";
import SectionHeading from "@herb/components/SectionHeading";
import Image from "@herb/components/Image";
import JsonLd from "@herb/components/Schema/JsonLd";
import SEO from "@herb/components/seo";
import { prepareContent, transformContent } from "@herb/utils/transformContent";
import WPContent from "@herb/components/WPContent";
import { paths, deployUrl } from "@herb/utils";
import ReactHtmlParser from "react-html-parser";
import { Link } from "@reach/router";
import { generateRandom } from "@herb/utils/randomNumber";
import LatestArticleLoadAlgolia from "@herb/components/Article/LatestArticleLoadAlgolia";
import RelatedLinksCard from "@herb/components/Cards/RelatedLinksCard";
import LoadRandomBrands from "../../components/Article/LoadRandomBrands";
import LoadRandomProducts from "../../components/Article/LoadRandomProducts";
import SidebarBrandsAndProducts from "../../components/SidebarBrandsAndProducts";
import { Helmet } from "react-helmet";
import { scriptCreate as aggleScript } from "@herb/utils/scripts";
import "./index.css";
import { fallbackImages } from "../../utils/constants";

const isBrowser = typeof window !== "undefined";

const getCategory = post => {
  let cat = "Category";
  if (post.categories) cat = slugify(post.categories[0], { lower: true });
  else if (post.thc || post.thc === "") cat = "strains";
  else if (post.ingredients) cat = "recipes";

  return cat;
};
const PostTemplate = ({ data, pageContext }) => {
  useEffect(() => {
    scriptCreate();
    aggleScript();
  }, []);
  function scriptCreate() {
    if (isBrowser) {
      setTimeout(() => {
        const script = document.createElement("script");
        script.src = "https://e.infogram.com/js/dist/embed-loader-min.js";
        script.async = true;
        const scriptFunction = document.createElement("script");
        scriptFunction.innerHTML = `
      !function(e,i,n,s){var t="InfogramEmbeds",d=e.getElementsByTagName("script")[0];if(window[t]&&window[t].initialized)window[t].process&&window[t].process();else if(!e.getElementById(n)){var o=e.createElement("script");o.async=1,o.id=n,o.src="https://e.infogram.com/js/dist/embed-loader-min.js",d.parentNode.insertBefore(o,d)}}(document,0,"infogram-async");`;
        document.body.appendChild(script);
        document.body.appendChild(scriptFunction);
      }, 500);
    }
  }
  const {
    latestArticles: { nodes: latestArticles }
  } = data;
  const { related_links: pagelinks, noindex } = pageContext;

  const currentPost = data.legacyPosts || data.strain || data.recipe;

  const {
    title,
    slug,
    featuredImage = {},
    excerpt,
    author = {},
    date,
    ISOModified,
    content,
    seo: { fbImage, fbTitle, metaDescription, seoTitle } = {},
    ingredients,
    directions
  } = currentPost || {};

  let nextPost = data.nextLegacyPost || data.nextRecipe || data.nextStrain;
  let { nodes: nextAvailablePosts = [] } = nextPost || {};
  nextPost =
    nextAvailablePosts[generateRandom(0, nextAvailablePosts.length - 1)];

  const stickyArticles = latestArticles.splice(0, 2);

  var cat = getCategory(currentPost);
  var catNext = nextPost ? getCategory(nextPost) : null;

  let postSchema;

  if (cat === "recipes") {
    let recipeIngredients = [];
    ReactHtmlParser(prepareContent(currentPost.ingredients || ""), {
      transform: (n, i) => {
        if (n.type === "text" && n.data.trim() !== "") {
          recipeIngredients.push(n.data);
        }
      }
    });

    let recipeDirections = [];
    ReactHtmlParser(prepareContent(currentPost.directions || ""), {
      transform: (n, i) => {
        if (n.type === "text" && n.data.trim() !== "") {
          recipeDirections.push({ "@type": "HowToStep", text: n.data });
        }
      }
    });

    postSchema = {
      "@context": "http://schema.org",
      "@type": "Recipe",
      name: currentPost.title,
      datePublished: currentPost.ISODate,
      dateModified: currentPost.ISOModified,
      image: {
        "@type": "ImageObject",
        url: `${currentPost?.featuredImage?.sourceUrl?.replace(
          "cdn.herb.co",
          "images.herb.co"
        )}`
      },
      author: {
        "@type": "Person",
        name: currentPost.author.name
      },
      description: currentPost.seo.metaDescription || currentPost.excerpt,
      recipeIngredient: recipeIngredients,
      recipeInstructions: recipeDirections,
      recipeCategory: currentPost.type,
      prepTime: `PT${currentPost.preptime}M`,
      cookTime: `PT${currentPost.cooktime}M`,
      totalTime: `PT${currentPost.readytime}M`,
      publisher: {
        "@type": "Organization",
        name: "Herb",
        logo: {
          "@type": "ImageObject",
          url: `${deployUrl}/icons/icon-512x512.png`
        }
      }
    };
  } else {
    postSchema = {
      "@context": "http://schema.org",
      "@type": "Article",
      mainEntityOfPage: `${deployUrl}${paths.legacy[cat]}/${
        (currentPost || {}).slug
      }/`,
      headline: currentPost.title,
      datePublished: currentPost.ISODate,
      dateModified: currentPost.ISOModified,
      author: {
        "@type": "Person",
        name: currentPost.author.name
      },
      description: currentPost.seo.metaDescription || currentPost.excerpt,
      image: {
        "@type": "ImageObject",
        url: `${currentPost?.featuredImage?.sourceUrl?.replace(
          "cdn.herb.co",
          "images.herb.co"
        )}`
      },
      publisher: {
        "@type": "Organization",
        name: "Herb",
        logo: {
          "@type": "ImageObject",
          url: `${deployUrl}/icons/icon-512x512.png`
        }
      }
    };
  }

  const headerBreadcrumbs = [
    {
      url: paths.legacy[cat],
      slug: cat,
      name: cat.charAt(0).toUpperCase() + cat.slice(1)
    }
  ];

  return (
    <Layout>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          <></>
        )}
      </>
      <SEO
        title={seoTitle || title}
        shareTitle={fbTitle}
        description={metaDescription || excerpt}
        image={
          `${fbImage?.replace("cdn.herb.co", "images.herb.co")}` ||
          `${featuredImage?.sourceUrl?.replace(
            "cdn.herb.co",
            "images.herb.co"
          )}`
        }
        url={`${paths.legacy[cat]}/${(currentPost || {}).slug}`}
        canonical={`${deployUrl}${paths.legacy[cat]}/${
          (currentPost || {}).slug
        }`}
        breadcrumbs={headerBreadcrumbs}
      />
      <JsonLd data={postSchema} />
      <div className="aticleBreadcrumb">
        <div className="readNow">
          <Link className="readLink" to={`${paths.legacy[cat]}/${slug}`}>
            <p className="nameOfArticle">
              <span className="readingLink">Now Reading:</span>
              {cat} | {ReactHtmlParser(title)}
            </p>
          </Link>
        </div>
        <div className="socialLinks">
          <p className="shareLinks">Share:</p>
          <ShareLinks
            url={`${paths.legacy[cat]}/${slug}/`}
            article={currentPost}
            media={featuredImage?.sourceUrl || ""}
          />
        </div>
        {nextPost && (
          <div className="readNext">
            <Link
              className="readLink"
              to={`${paths.legacy[catNext]}/${nextPost.slug}`}
            >
              <p className="nameOfArticle">
                <span className="readingLink">Next:</span>
                {catNext} | {ReactHtmlParser(nextPost.title)}
              </p>
            </Link>
          </div>
        )}
      </div>
      <div className="articlePageBanner">
        <div className="MainArticle">
          <div className="articlePageBannerImage">
            <Image
              className="bannerImage"
              src={featuredImage?.sourceUrl}
              alt={featuredImage?.altText}
              // fallback={fallbackImages.square}
              imgixParams={{
                fit: "crop"
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes"
              }}
            />
          </div>
          <div className="articleImageCredits">
            <div className="imageCourtesy">
              {ReactHtmlParser(
                featuredImage?.node || featuredImage?.caption || ""
              )}
            </div>
          </div>
          <div className="CategoryAndDateDiv">
            <CategoryAndDate
              legacy={true}
              category={cat}
              path={`${paths.legacy[cat]}`}
              date={ISOModified ? ISOModified : date}
            />
          </div>
          <div className="articlePageContent">
            <h1 className="articlePageH1">{ReactHtmlParser(title)}</h1>
            <p className="articlePageP">{ReactHtmlParser(excerpt)}</p>

            <div className="articlePageWrittenBy">
              <WrittenBy author={author} legacy={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="featuredArticlePage">
        <div className="featuredContentDiv">
          <WPContent>
            {ReactHtmlParser(prepareContent(content || ""), {
              transform: (n, i) => transformContent(n, i)
            })}
            {ReactHtmlParser(prepareContent(ingredients || ""), {
              transform: (n, i) => transformContent(n, i)
            })}
            {ReactHtmlParser(prepareContent(directions || ""), {
              transform: (n, i) => transformContent(n, i)
            })}
          </WPContent>
          <div className="shareArticleLinks">
            <ShareLinks
              url={`${paths.legacy[cat]}/${slug}/`}
              article={currentPost}
              media={featuredImage?.sourceUrl || ""}
            />
          </div>
        </div>
        <div className="articleAndNewsletter">
          <SidebarBrandsAndProducts />
          <div className="sidebarBrands">
            <LoadRandomBrands
              brandsToShow={2}
              headingFontSize={"14px"}
              borderTop={"none"}
            />
          </div>
        </div>
      </div>
      <div className="herbRandomRecommendedProducts">
        <p css={{ fontSize: "30px" }} className="recommendedProductsHeading">
          Herb <span>Recommended</span> Products:
        </p>
        <LoadRandomProducts />
      </div>

      <div className="forLatestArticles">
        <SectionHeading heading="READ MORE" />
        <div className="forLatestArticlesContainer">
          {pagelinks ? (
            pagelinks?.related_links?.map(item => {
              return <RelatedLinksCard link={item} />;
            })
          ) : (
            <LatestArticleLoadAlgolia />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!, $post: Boolean!, $strain: Boolean!, $recipe: Boolean!) {
    legacyPosts(id: { eq: $id }) @include(if: $post) {
      ...LegacyPost
    }

    nextLegacyPost: allLegacyPosts(filter: { id: { ne: $id } }, limit: 1)
      @include(if: $post) {
      nodes {
        ...LegacyPost
      }
    }

    nextRecipe: allRecipe(filter: { id: { ne: $id } }, limit: 1)
      @include(if: $recipe) {
      nodes {
        ...Recipe
      }
    }

    nextStrain: allStrain(filter: { id: { ne: $id } }, limit: 1)
      @include(if: $strain) {
      nodes {
        ...Strain
      }
    }

    strain(id: { eq: $id }) @include(if: $strain) {
      ...Strain
    }

    recipe(slug: { eq: $id }) @include(if: $recipe) {
      ...Recipe
    }

    latestArticles: allWordpressWpPost(
      filter: { id: { ne: $id } }
      limit: 4
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...Post
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
