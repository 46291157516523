import React from "react";
import "./index.css";
const PickUpAndDelivery = ({ item }) => {
  const { delivery, pickup } = item.content_dispensary.pickupAndDelivery;

  return (
    <div className="pickup-and-deliver-container">
      {delivery ? (
        <div className="delivery-container">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6.5105L5.4791 12.0001L16 1.48956L14.4896 0L5.4791 8.99999L1.48953 5.01045L0 6.5105Z"
              fill="#3041FF"
            />
          </svg>
          <p className="deliveryAndPickup">Delivery</p>
        </div>
      ) : (
        <></>
      )}
      {pickup ? (
        <div className="container-pickup">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6.5105L5.4791 12.0001L16 1.48956L14.4896 0L5.4791 8.99999L1.48953 5.01045L0 6.5105Z"
              fill="#3041FF"
            />
          </svg>
          <p className="deliveryAndPickup">Pickup</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PickUpAndDelivery;
