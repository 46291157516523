import React, { useEffect, useState } from "react";
import { MailingListForm } from "@herb/components/Forms";
import { Box, Flex, Heading, Text, Icon } from "@herb/ui";
import { supportsLocalStorage } from "@herb/utils";
import Image from "@herb/components/Image";
import { fallbackImages } from "../utils/constants";

export default () => {
  const [isOpen, setOpen] = useState(
    supportsLocalStorage() &&
      !localStorage.getItem("closedNewsletterOverlayJun2021")
  );
  const [isReady, setIsReady] = useState(true);

  const delay = () => {
    setTimeout(() => {
      setIsReady(true);
    }, 30000);
  };

  useEffect(() => {
    delay();
  }, []);

  const checkIfNotNewLetter = () => {
    if (window !== undefined) {
      if (window.location.pathname.includes("newsletter")) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    isReady &&
    isOpen &&
    checkIfNotNewLetter() && (
      <Box
        css={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 99999,
          background: "rgba(0,0,0,0.8)",
          overflowY: "scroll"
        }}
      >
        <Box
          p={3}
          css={{
            width: "1110px",
            maxWidth: "calc(100% - 30px)",
            margin: "0 auto",
            paddingTop: "100px"
          }}
        >
          <Flex
            flexWrap={["wrap", "no-wrap"]}
            bg="white"
            css={{
              position: "relative",
              alignItems: "center",
              "@media screen and (max-width:430px)": {
                alignItems: "unset"
              }
            }}
          >
            <Box
              width={["25px", "", "32px"]}
              p={1}
              css={{
                cursor: "pointer",
                position: "absolute",
                top: "-14px",
                right: "0px",
                left: "98%",
                borderRadius: 99,
                background: "white",
                color: "black",
                transform: "translate(calc(50% - 50px),calc(-50% + 50px))",
                "@media screen and (max-width:768px)": {
                  width: "32px"
                },
                "@media screen and (max-width:480px)": {
                  width: "35px"
                }
              }}
              onClick={() => {
                localStorage.setItem("closedNewsletterOverlayJun2021", true);
                setOpen(false);
              }}
            >
              <Icon icon="close" height={24} width={24} viewBox={"0 0 24 24"} />
            </Box>
            <Box
              width={[1, "50%"]}
              css={{
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "32px",
                paddingRight: "0px",
                "@media screen and (max-width:769px)": {
                  paddingBottom: "15px",
                  paddingRight: "25px"
                }
              }}
            >
              <Heading
                as="h2"
                mb={3}
                css={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  fontWeight: "700",
                  textAlign: "center",
                  padding: "0 20px",
                  "@media screen and (max-width:768px)": {
                    fontSize: "22px",
                    lineHeight: "24px",
                    padding: "0 20px"
                  },
                  "@media screen and (max-width:480px)": {
                    fontSize: "22px",
                    lineHeight: "24px",
                    padding: "0 10px"
                  }
                }}
              >
                Herbivores, this one’s for you!
              </Heading>
              <Text
                as="h3"
                mb={[3, "", 4]}
                css={{
                  fontFamily: "Source Code Pro",
                  fontStyle: "italic",
                  fontSize: "24px",
                  lineHeight: "32px",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  textAlign: "center",
                  padding: "0px 35px",
                  marginBottom: "20px",
                  "@media screen and (max-width:768px)": {
                    fontSize: "18px",
                    lineHeight: "22px",
                    padding: "0px 0px"
                  },
                  "@media screen and (max-width:480px)": {
                    fontSize: "13px"
                  }
                }}
              >
                Sign up for our newsletter and get ready to explore the largest
                cannabis community, where you'll unlock exclusive news, deals,
                giveaways, and more.
              </Text>
              <MailingListForm
                callback={() => {
                  if (window) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "newsletterOverlaySubmitted"
                    });
                  }
                }}
              />
            </Box>
            <Box width={[1, "50%"]} ml="auto">
              <Image
                className="lazyload"
                css={{
                  objectFit: "cover",
                  height: "100%",
                  marginBottom: "-4px"
                }}
                src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04111714/email-images.webp"
                fallback={fallbackImages.square}
                alt=""
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    )
  );
};
