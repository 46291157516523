import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import SEO from "@herb/components/seo";
import { Formik, Form, ErrorMessage } from "formik";
import { rules } from "@herb/components/Forms";
import Button from "@herb/components/Button";
import { deployUrl } from "@herb/utils";
import Image from "@herb/components/Image";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";

const newsLetters = [
  {
    id: "SVQR9F",
    title: "Herb Hype",
    description:
      "Get the latest in cannabis news, strains, products, and deals straight to your inbox.",
    image:
      "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19115610/newsletter-2.jpg",
    subheading: "2 updates per month"
  },
  {
    id: "Ws5mPf",
    title: "Herb Trend Report",
    description:
      "Don’t miss out on what’s coming! Stay up to date with Herb’s monthly trend report, including news, exclusive guides, and more.",
    image:
      "https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19115611/newsletter-3.jpg",
    subheading: "1 update per month"
  }
];

// Object for Richtext
const richtext = {
  title:
    "Your bi-weekly and monthly dose of cannabis updates! Stay informed with the latest news, top strains, hottest products, and unbeatable deals—all delivered straight to your inbox."
};

const NewslettersForm = ({
  selectedNewsletters,
  allowSubmit,
  onSubmitted,
  analytics,
  setSelectedNewsletter,
  ...rest
}) => (
  <Formik
    initialValues={{
      email: ""
    }}
    validate={values => {
      let errors = {};

      // Validate email
      if (!values.email) {
        errors.email = "Required";
      } else if (!rules.email.test(String(values.email).toLowerCase())) {
        errors.email = "Please enter a valid e-mail address";
      }

      return errors;
    }}
    onSubmit={async (values, actions) => {
      if (!allowSubmit) return;
      actions.setSubmitting(true);
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: values.email
        })
      };

      const newsLetterPromises = selectedNewsletters.map(newsletter => {
        let url = `https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=${newsletter}`;
        return fetch(url, options);
      });

      try {
        let response = await Promise.all(newsLetterPromises);
        setSelectedNewsletter([]);
        actions.setStatus({
          message: "Thanks for signing up!"
        });

        actions.setSubmitting(false);
        onSubmitted && onSubmitted();
      } catch (err) {
        console.log(err);
      }
    }}
    {...rest}
  >
    {({ status, isSubmitting, handleBlur, handleChange }) => (
      <Form name="join-newsletter" method="POST" style={{ width: "100%" }}>
        {(status || {}).message ? (
          <p>{status.message}</p>
        ) : (
          <div className="newsletterContainer">
            <div className="richText">
              <p className="richTextP">{richtext.title}</p>
            </div>
            <div className="newsletterInput">
              <div className="newsletterInputForm">
                <input
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="newsletterInputField"
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                />
                <div className="newsletterSubmitButton" type="submit">
                  <Button disabled={!allowSubmit}>submit</Button>
                </div>
              </div>
              <p style={{ color: "red", textAlign: "start", marginTop: "8px" }}>
                <ErrorMessage name="email" />
              </p>
            </div>
          </div>
        )}
      </Form>
    )}
  </Formik>
);

const NewsLetterCard = ({
  selectednewsletter,
  handleChangeNewsletter,
  ...props
}) =>
  newsLetters.map(newsletter => {
    return (
      <div className="NewsletterInboxContainer">
        <div className="newsletterPhoto">
          <Image alt="/" className="newsletterImage" src={newsletter.image} />
        </div>

        <div className="checkboxWithText">
          <div className="onlyCheckbox">
            <input
              className="checkboxForNewsletter"
              type="checkbox"
              id="selectNewsletter"
              onChange={handleChangeNewsletter(newsletter)}
              checked={selectednewsletter.includes(newsletter.id)}
              name="selectNewsletter"
              value={newsletter.id}
            />
          </div>
          <div className="newsletterCheckboxText">
            <h3 className="CheckboxTextH3">{newsletter.title}</h3>
            <p className="CheckboxTextP1">{newsletter.subheading}</p>
            <p className="CheckboxTextP2">{newsletter.description}</p>
          </div>
        </div>
      </div>
    );
  });

const MainNewsletter = () => {
  const [selectednewsletter, setSelectedNewsletter] = React.useState([
    "SVQR9F",
    "Ws5mPf"
  ]);

  const handleChangeNewsletter = newsletter => () => {
    setSelectedNewsletter(
      selectednewsletter.includes(newsletter.id)
        ? selectednewsletter.filter(id => id !== newsletter.id)
        : [...selectednewsletter, newsletter.id]
    );
  };
  useEffect(() => {
    scriptCreate();
  }, []);
  return (
    <Layout>
      <SEO
        title="Newsletters"
        description=""
        url="/newsletters"
        canonical={`${deployUrl}/newsletters`}
      />

      <div className="mainNewsletter">
        <div className="newsletterHeading">
          <h1 className="newsLetterTitle">HERB INSIDER</h1>
        </div>
        <NewslettersForm
          selectedNewsletters={selectednewsletter}
          setSelectedNewsletter={setSelectedNewsletter}
          allowSubmit={selectednewsletter.length > 0}
        />
        {/* <div className="ForInbox">
          <p className="newsletterForInbox">
            Select the newsletters you'd like dropped in your inbox.
          </p>
        </div> */}
      </div>
      <Container>
        <div className="selectNewsletter">
          <NewsLetterCard
            selectednewsletter={selectednewsletter}
            handleChangeNewsletter={handleChangeNewsletter}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default MainNewsletter;
