import React, { useState } from "react";
import { AdSlot } from "react-dfp";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { Flex, Box } from "@herb/ui";

const sizes = {
  mobile: css`
    @media screen and (min-width: 0px) and (max-width: 791px) {
      display: flex;
    }
  `,
  tablet: css`
    @media screen and (min-width: 792px) and (max-width: 1099px) {
      display: flex;
    }
  `,
  desktop: css`
    @media screen and (min-width: 1100px) {
      display: flex;
    }
  `
};

const AdContainer = styled(Flex)`
  overflow: hidden;
  display: none;

  ${props => (props.size && !props.empty ? sizes[props.size] : "")}
`;

export default ({
  adUnit,
  banners,
  slotId = "div-ad",
  renderOutOfThePage = false,
  visibleProps = {},
  hiddenProps = {},
  ...rest
}) => {
  const [empty, setEmpty] = useState(false);

  let containerProps = empty ? hiddenProps : visibleProps;

  return (
    <Box {...containerProps}>
      {(banners || []).map((banner, idx) => (
        <AdContainer
          justifyContent="center"
          key={`banner-${adUnit}-${banner.breakpoint}-${idx}`}
          size={banner.breakpoint}
          empty={empty}
        >
          <AdSlot
            renderOutOfThePage={renderOutOfThePage}
            adUnit={adUnit}
            sizes={banner.sizes}
            onSlotRender={eventData => {
              setEmpty(eventData.event.isEmpty);
            }}
          />
        </AdContainer>
      ))}
    </Box>
  );
};
