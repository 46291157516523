import React from "react";
import Recaptcha from "react-recaptcha";
import "./index.css";

const ReferralForm = ({
  retURL = "https://email-page--herbco-dev.netlify.app/marketing/download/"
}) => {
  const callback = () => {};
  const lableStyle = {
    fontFamily: "Source Code Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px"
  };
  const inputStyle = {
    outline: "none",
    "&:placeholder": {
      color: "#fff"
    },
    fontFamily: "Source Code Pro",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderBottom: "1px solid #000",
    fontSize: "14px",
    lineHeight: "16px",
    width: "100%",
    backgroundColor: "transparent",
    padding: "12px",
    boxSizing: "border-box",
    marginTop: "6px",
    marginBottom: "15px"
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
      >
        <input type="hidden" name="oid" value="00D5f000007G9M3" />
        <input type="hidden" name="retURL" value={retURL} />

        <label style={lableStyle} for="first_name">
          First Name
        </label>
        <input
          style={inputStyle}
          id="first_name"
          maxlength="40"
          name="first_name"
          size="20"
          type="text"
          required
        />
        <br />

        <label style={lableStyle} for="last_name">
          Last Name
        </label>
        <input
          style={inputStyle}
          id="last_name"
          maxlength="80"
          name="last_name"
          size="20"
          type="text"
          required
        />
        <br />

        <label style={lableStyle} for="email">
          Email
        </label>
        <input
          style={inputStyle}
          id="email"
          maxlength="80"
          name="email"
          size="20"
          type="text"
          required
        />
        <br />

        <label style={lableStyle} for="company">
          Company
        </label>
        <input
          style={inputStyle}
          id="company"
          maxlength="40"
          name="company"
          size="20"
          type="text"
          required
        />
        <br />
        <Recaptcha
          sitekey="6Lf9x1geAAAAAOM2OKsNCQDU4S-RHl-u2xavTggp"
          render="explicit"
          onloadCallback={callback}
        />
        <br />

        <input
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 24px",
            gap: "10px",
            width: "204px",
            height: "40px",
            color: "white",
            background: "#3041ff",
            cursor: "pointer"
          }}
          type="submit"
          name="submit"
        />
      </form>
    </div>
  );
};

export default ReferralForm;
