import React, { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { Box, Icon } from "@herb/ui";
import "./index.css";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <>
      <div className="counter1">
        <div className="time1">
          <p className="countDays1">{zeroPad(days)}</p>

          <p className="timeDetail1">Days</p>
        </div>
        <div className="time1">
          <p className="countHours1">{zeroPad(hours)}</p>

          <p className="timeDetail1">Hours</p>
        </div>
        <div className="time1">
          <p className="countMinutes1">{zeroPad(minutes)}</p>

          <p className="timeDetail1">Minutes</p>
        </div>
        <div className="time1">
          <p className="countSecond1">{zeroPad(seconds)}</p>
          <p className="timeDetail1">Seconds</p>
        </div>
      </div>
    </>
  );
};

const SalesCounter = ({ counterDate }) => {
  let finalDate;

  const utcDate = counterDate;

  let date = new Date(utcDate);

  date = date.toLocaleString("en-US", { hour12: false });

  date = date.split(",");
  let firstHalf = date[0];
  let secondHalf = date[1];
  firstHalf = firstHalf.split("/");
  firstHalf = firstHalf[2] + "-" + "0" + firstHalf[0] + "-" + firstHalf[1];
  secondHalf = secondHalf.split(" ");
  secondHalf = secondHalf[1];

  finalDate = firstHalf + "T" + secondHalf;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModalOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const customStyles = {
    content: {
      background: "rgb(255, 255, 255)",
      borderRadius: "4px",
      margin: "83px auto",
      maxWidth: "712px"
    },
    overlay: {
      overflowY: "scroll",
      background: "#00000033"
    }
  };

  return (
    <>
      <div className="salesMainCounter">
        <div className="salesCounterText">
          <h2 className="text">Offer Ends In....</h2>
        </div>
        <div className="salesMainCountdown">
          <Countdown date={finalDate} renderer={renderer} />
        </div>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} className="testing">
        <div className="webinarFormContainer">
          <div className="webinarCross">
            <Box
              width={["22px", "", "25px"]}
              p={1}
              css={{
                cursor: "pointer",
                position: "absolute",
                top: "0px",
                right: "0px",
                borderRadius: 99,
                background: "black",
                color: "white",
                transform: "translate(calc(50% - 50px),calc(-50% + 50px))"
              }}
              onClick={e => {
                e.preventDefault();
                toggleModalOpen();
                if (typeof window !== undefined) window.location.reload(false);
              }}
            >
              <Icon icon="close" width={24} height={24} viewBox="0 0 24 24" />
            </Box>
          </div>

          <div className="webinarFormHeading">
            Join Matt, founder of Herb, for this no-BS training, where he’ll
            share exactly how you land your dream customer at scale.
          </div>
          <div class="wj-embed-wrapper" data-webinar-hash="7064rtm">
            <Helmet>
              <script src="https://event.webinarjam.com/register/7064rtm/embed-form?formButtonText=Register&formAccentColor=%23080808&formAccentOpacity=1&formBgColor=%23ffffff&formBgOpacity=1"></script>
            </Helmet>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SalesCounter;
