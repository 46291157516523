import React from "react";
import InstantSearch from "@herb/components/Search/InstantSearch";
import { Index, connectInfiniteHits, Configure } from "react-instantsearch-dom";
import Button from "@herb/components/Button";
import TrendingArticleCard from "../../Cards/TrendingArticleCard";
const isBrowser = typeof window !== "undefined";
const documentCheck = typeof document !== "undefined";

const CustomHits = connectInfiniteHits(({ hits, hasMore, refine }) => {
  return (
    <React.Fragment>
      {hits.map(article => (
        <TrendingArticleCard article={article} key={article.id} />
      ))}
      {hasMore && (
        <div className="moreButton">
          <Button
            type={"buttonMore"}
            to="/read/"
            link
            onClick={e => {
              e.preventDefault();

              refine();
            }}
          >
            more
          </Button>
        </div>
      )}
    </React.Fragment>
  );
});

const LatestArticleLoadAlgolia = ({ posts }) => {
  const handleMoreClick = () => {
    const algoliaElem = document.getElementById("algolia");
    const wordpressElem = document.getElementById("wordpress");

    if (isBrowser && documentCheck) {
      algoliaElem.style.display = "unset";
      wordpressElem.style.display = "none";
    }
  };
  return (
    <>
      <div id="algolia">
        <InstantSearch>
          <Index indexName="Dev_Articles">
            <Configure
              aroundLatLngViaIP={false}
              hitsPerPage={4}
              query="*"
              filters={"NOT categories:strains AND NOT categories:recipes"}
            />
            <CustomHits />
          </Index>
        </InstantSearch>
      </div>

      {/* <div id="wordpress" style={{ display: "unset" }}>
        {posts?.slice(0, 4)?.map(article => (
          <TrendingArticleCard article={article} key={article.id} />
        ))}

        <div className="moreButton">
          <Button
            type={"buttonMore"}
            to="/read/"
            link
            onClick={e => {
              e.preventDefault();
              handleMoreClick();
            }}
          >
            more
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default LatestArticleLoadAlgolia;
