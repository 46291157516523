import React, { useEffect } from "react";
import { Box } from "@herb/ui";
import Image from "@herb/components/Image";
import $ from "jquery";

const DispensaryImagesScroll = ({ list, direction }) => {
  useEffect(() => {
    if (typeof window !== undefined) {
      $(window).ready(() => {
        if ($(".location-row-left").infiniteslide) {
          $(".location-row-left").infiniteslide({
            speed: 50
          });
          $(".location-row-right").infiniteslide({
            direction: "right",
            speed: 40,
            clone: 4
          });
        }
      });
    }
  }, []);

  return (
    <Box
      className={`location-row-${direction}`}
      css={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "@media screen and (max-width:768px)": {
          flexWrap: "wrap",
          justifyContent: "space-around"
        }
      }}
    >
      {list.map(logo => (
        <Box
          key={logo.id}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "45px",

            "@media screen and (max-width:768px)": {},
            "@media screen and (max-width:425px)": {}
          }}
        >
          <Image
            className="lazyload"
            src={logo.src}
            width={logo.width}
            height={logo.height}
          />
        </Box>
      ))}
    </Box>
  );
};

export default DispensaryImagesScroll;
