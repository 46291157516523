import React from "react";
import { Link } from "gatsby";
import { getDate } from "@herb/utils/date";
import RedirectLink from "../../RedirectLink";
import slugify from "slugify";
import "./index.css";

const HomePageMegaAricle = props => {
  const { article } = props;
  const category = article.categories ? article.categories.nodes[0] : {};
  const author = article.author ? article.author.node : {};

  return (
    <div className="articleSample">
      <div className="dateAndCategory">
        <Link className="asCategory" to={`/${(category || {}).slug}`}>
          {category.name}
        </Link>
        <p className="asDate">{getDate(article.date)}</p>
      </div>
      <RedirectLink to={`/${(category || {}).slug}/${(article || {}).slug}`}>
        <div className="asContent">
          <h1 className="asTitle">{article.title}</h1>
          <h4 className="asSubHeading">
            {((article || {}).content_post || {}).postExcerpt}
          </h4>
          {/* <p className="asSubText">T</p> */}
        </div>
      </RedirectLink>

      <Link to={`/authors/${slugify(author.name || "", { lower: true })}`}>
        <p className="asWrittenby">Written By: {author.name}</p>
      </Link>
    </div>
  );
};

export default HomePageMegaAricle;
