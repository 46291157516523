import React, { useEffect, useState } from "react";
import { DFPSlotsProvider } from "react-dfp";
import InstantSearch from "@herb/components/Search/InstantSearch";
import GlobalSchema from "@herb/components/Schema/GlobalSchema";
import IFrame from "@herb/components/IFrame";
import { deployUrl } from "@herb/utils";
import theme from "@herbkit/theme";
import { CookiesProvider } from "react-cookie";
import SEO from "@herb/components/seo";
import { ThemeProvider } from "emotion-theming";
import ReferralNavBar from "../../components/ReferralPage/ReferralNavBar";
import Button from "../../components/Button";
import { Icon, Box } from "@herb/ui";
import Modal from "react-modal";
import ReferralForm from "@herb/components/ReferralPage/ReferralForm";
import "../../layouts/layout.css";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";
const isBrowser = typeof window !== "undefined";

const MarketingGuide = ({ hideWebPageSchema = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUPOpen, setIsPopUpOpen] = useState(true);
  const [retURL, setRetUrl] = useState(
    "https://email-page--herbco-dev.netlify.app/marketing/download/"
  );

  const toggleModal = () => {
    if (isBrowser) {
      localStorage.setItem("marketingGuidePopUp", false);
    }
    setIsPopUpOpen(false);
    setIsOpen(!isOpen);
  };

  const togglePopUp = () => {
    if (isBrowser) {
      localStorage.setItem("marketingGuidePopUp", false);
    }
    setIsPopUpOpen(!isPopUPOpen);
  };
  const customStyles = {
    content: {
      position: "relative",
      background: "rgb(255, 255, 255)",
      maxWidth: "550px",
      overflow: "hidden",
      padding: "0",
      border: "none",
      borderRadius: "0",
      height: "100%",
      width: "100%",
      inset: "0",
      maxHeight: "530px",
      margin: "10px"
    },
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  };

  const customStylesPopUp = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    content: {
      position: "relative",
      background: "rgb(255, 255, 255)",
      maxWidth: "720px",
      overflow: "hidden",
      padding: "0",
      border: "none",
      borderRadius: "0",
      height: "100%",
      width: "100%",
      inset: "0",
      maxHeight: "340px",
      margin: "10px"
    }
  };
  const handleClick = () => {
    // window.open(
    //   "https://herb-platform-images.s3.amazonaws.com/Herb+Marketing+Guide2022.pdf",
    //   "_blank"
    // );
    setIsPopUpOpen(false);
    setRetUrl(
      "https://herb-platform-images.s3.amazonaws.com/Herb+Marketing+Guide2022.pdf"
    );
    toggleModal();
  };
  useEffect(() => {
    scriptCreate();
  }, []);
  return (
    <DFPSlotsProvider
      lazyload={true}
      dfpNetworkId={process.env.GATSBY_DFP_NETWORK_ID}
    >
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <GlobalSchema hideWebPageSchema={hideWebPageSchema} />
      <IFrame />
      <CookiesProvider>
        <InstantSearch>
          <ReferralNavBar />
          <ThemeProvider theme={theme}>
            <SEO
              title={`Marketing Guide`}
              url={"/marketing-guide"}
              image={
                "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
              }
              canonical={`${deployUrl}/marketing-guide`}
            />
            <div className="marketingGuidePage">
              <div className="marketingGuideTopSection">
                <div className="downloadCannabis">
                  <div className="downloadCannabisText">
                    Download Herb’s Guide to Marketing Cannabis in 2024
                  </div>
                  <div className="downloadCannabisButton">
                    <Button onClick={handleClick}>Get it here</Button>
                  </div>
                </div>
              </div>
              <div className="backgroundForLowerSections">
                <div className="marketingGuideMiddleSection">
                  <div className="heading">
                    Here at Herb our #1 mission is to break down the stigma
                    around weed.
                  </div>
                  <div className="subHeading">
                    One of the ways we do this is by arming{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        fontFamily: "Source Code Pro",
                        textDecorationThickness: "2px"
                      }}
                    >
                      trailblazers like you
                    </span>{" "}
                    with the tools and resources you need to grow your
                    cannabis-based business.
                  </div>
                  <div className="text">
                    <p>
                      We’ve put together a special guide on how to market and
                      grow your brand in today’s ever-changing landscape.
                    </p>
                    <p>
                      In it, we share exactly what we’re focusing on as we take
                      Herb to the next level, and we show you how you can do the
                      same - and explode the growth of your business.
                    </p>
                    <div className="vectorSvgDiv">
                      <svg
                        className="vectorSvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="111"
                        height="112"
                        viewBox="0 0 111 112"
                        fill="none"
                      >
                        <path
                          d="M46 48C62.1539 50.416 74.9366 70.114 76.5 95C78.0679 70.114 90.8506 50.416 107 48C90.8506 45.584 78.0679 25.886 76.5 1C74.9321 25.8928 62.1494 45.5908 46 48Z"
                          stroke="#141313"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M4 80.5C14.3279 82.0679 22.5004 94.8506 23.5 111C24.5024 94.8506 32.675 82.0679 43 80.5C32.675 78.9321 24.5024 66.1494 23.5 50C22.4976 66.1539 14.325 78.9366 4 80.5Z"
                          stroke="#141313"
                          stroke-miterlimit="10"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="downloadguideButton">
                    <Button onClick={handleClick}>
                      <span className="forbigScreens">
                        You can download the guide here
                      </span>
                      <span className="forSmallScreens">download it here</span>
                    </Button>
                  </div>
                </div>
                <div className="marketingGuideLastSection">
                  <div className="cardDetail">
                    <div className="card">
                      <div className="cardImage">
                        <img
                          src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/10/03092002/pic.png"
                          alt="mask"
                        />
                      </div>
                      <div className="cardContent">
                        <p>
                          Our founder, <strong>Matt Gray</strong>, also sends
                          out weekly tips for growing your cannabis-based
                          business.
                        </p>
                        <p className="hideCardContentOnSmallScreens">
                          When you download the guide, you’ll be signed up to
                          receive these highly-actionable insights.
                        </p>
                      </div>
                    </div>
                    <p className="hideCardContentOnLargeScreens">
                      When you download the guide, you’ll be signed up to
                      receive these highly-actionable insights.
                    </p>
                    <p>
                      And, if at any point you’re interested in a proven method
                      of growing your monthly revenue - we can help with that.
                    </p>
                  </div>
                  <div className="discoverMoreUpperDiv">
                    <div className="discoverMore">
                      <div className="text">
                        To discover more about how you can grow your revenue on
                        auto-pilot
                      </div>
                      <div className="discoverMoreButton">
                        <Button to={"https://herb.co/herb-plus"} link>
                          Click Here
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal isOpen={isOpen} style={customStyles}>
                <div
                  className="marketingGuideModel1"
                  style={{
                    height: "100%",
                    background:
                      "linear-gradient( 154.02deg, rgba(255, 243, 225, 0.81) 22.37%, rgba(247, 255, 227, 0.81) 68.48%, rgba(249, 204, 204, 0.81) 111.92% )"
                  }}
                >
                  <div className="referralCloseIcon">
                    <Box
                      width={["24px", "", "24px"]}
                      css={{
                        background: "white",
                        borderRadius: "50%"
                      }}
                      onClick={toggleModal}
                    >
                      <Icon
                        icon="close"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      />
                    </Box>
                  </div>
                  <ReferralForm retURL={retURL} />
                </div>
              </Modal>

              {/* {isBrowser &&
                localStorage.getItem("marketingGuidePopUp") !== "false" && (
                  <Modal isOpen={isPopUPOpen} style={customStylesPopUp}>
                    <div className="marketingGuidePopUp">
                      <div className="referralCloseIcon">
                        <Box
                          width={["24px", "", "24px"]}
                          css={{
                            background: "white",
                            borderRadius: "50%"
                          }}
                          onClick={togglePopUp}
                        >
                          <Icon icon="close" width={24} height={24} viewBox="0 0 24 24" />
                        </Box>
                      </div>
                      <div className="marketingGuidePopUpHeading">
                        Do you own a cannabis-based business?
                      </div>
                      <div className="marketingGuidePopUpSubHeading">
                        We have some special resources to help your business
                        grow.
                      </div>
                      <div className="marketingGuidePopUpButton">
                        <Button onClick={handleClick}>
                          Check them out here
                        </Button>
                      </div>
                      <div className="marketingGuidePopUpName">
                        Matt Gray, Founder of Herb
                      </div>
                    </div>
                  </Modal>
                )} */}
            </div>
          </ThemeProvider>
        </InstantSearch>
      </CookiesProvider>
    </DFPSlotsProvider>
  );
};

export default MarketingGuide;
