import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import { Box, Flex, Heading } from "@herb/ui";
import Container from "@herb/components/Container";
import Fade from "react-reveal/Fade";
import MarketingPlan from "@herb/components/MarketingPlan";
import HerbPlusBanner from "@herb/components/Cards/HerbPlusBanner";
import SeoQuote from "@herb/components/SeoQuote";
import SeoSquareCard from "@herb/components/Cards/SeoSquareCard";
import animationObserver from "@herb/utils/animationObserver";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const VerifiedPartnersMarketing = ({ data, pageContext }) => {
  useEffect(() => {
    animationObserver.observe(document.querySelector(".animate-div"));
    scriptCreate();
  }, []);

  return (
    <Layout>
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <SEO
        title={`Verified Partner`}
        url={"/verified-partners"}
        canonical={`${deployUrl}/verified-partners`}
        image={
          "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
        }
      />
      <HerbPlusBanner
        heading="Put your traffic growth on autopilot."
        subheading="Herb Verified Partner Network gives you access to valuable consumers where they are."
        backgroundImage="Rectangle 108 VP.png"
      />

      <Container>
        <Box>
          <Box>
            <Fade bottom>
              <Heading
                css={{
                  color: "#3041FF",
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "48px",
                  lineHeight: "56px",
                  paddingTop: "120px",
                  paddingBottom: "30px",
                  maxWidth: "600px",
                  margin: "0 auto",
                  "@media screen and (max-width:768px)": {
                    fontSize: "32px",
                    lineHeight: "40px",
                    paddingTop: "15px"
                  }
                }}
              >
                How our partners can help
              </Heading>
            </Fade>

            <Flex
              className="animate-div"
              css={{
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-1"
                heading="Drive targeted traffic to your website"
                text="Increase the growth of your loyal community and convert people to sales and email subscribers."
                image="square7.png"
              />
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-2"
                heading="Get real sales from real people"
                text="Real people visiting your website to learn about your brand and purchase your products."
                image="square8.png"
              />
              <SeoSquareCard
                className="hideCareerAnimation animate_down customS-3"
                heading="Turn traffic into loyal customers."
                text="Our emails give people the content they know and love. We help endear them to your brand and help you grow predictable traffic and revenue."
                image="square9.png"
              />
            </Flex>
          </Box>
        </Box>
      </Container>
      <Box
        css={{
          paddingTop: "25px",
          background:
            "linear-gradient(190.02deg,rgb(255 255 255 / 81%) 15.01%,rgba(235,250,255,0.81) 63.27%,rgba(235,250,255,0) 93.05%)"
        }}
      >
        <div
          css={{
            padding: "0px 64px",
            "@media screen and (max-width:768px)": {
              padding: "0px 16px"
            }
          }}
        >
          <SeoQuote
            starting="“Herb partners have allowed us to double our traffic and triple our sales."
            colored="Highly recommend collaborating with Herb."
            ending="The power of this network cannot be overstated - very impactful and drives real-life action.”"
            author="Garyn Angel, Magical"
          />
        </div>

        <MarketingPlan />
      </Box>
    </Layout>
  );
};
export default VerifiedPartnersMarketing;
