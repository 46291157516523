import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import "./index.css";

const DispensaryTimings = ({ timings }) => {
  const [dispensaryOpen, setDispensaryOpen] = useState("");

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  const keysNames = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];
  const { opensAt, closesAt } = timings;
  useEffect(() => {
    let currentTime = getClockTime();
    currentTime = Number(convertTime12to24(currentTime));
    var todayOpen = getDay();
    if (opensAt[keysNames[todayOpen - 1]]) {
      todayOpen = opensAt[keysNames[todayOpen - 1]];
      todayOpen = Number(convertTime12to24(todayOpen));
    }

    var todayClose = getDay();
    if (closesAt[keysNames[todayClose - 1]]) {
      todayClose = closesAt[keysNames[todayClose - 1]];
      todayClose = Number(convertTime12to24(todayClose));
    }

    if (currentTime > todayOpen && currentTime < todayClose) {
      setDispensaryOpen("Open now");
    } else {
      setDispensaryOpen("Close now");
    }
  });
  const convertTime12to24 = time12h => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}${minutes}`;
  };
  function getClockTime() {
    var now = new Date();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var ap = "am";
    if (hour > 11) {
      ap = "pm";
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    var timeString = hour + ":" + minute + " " + ap;
    return timeString;
  }
  function getDay() {
    var now = new Date();

    var today = now.getDay();
    return today;
  }

  return (
    <div className="dispensaryCollapsible">
      <Collapsible trigger={`${dispensaryOpen}`}>
        <div className="dispensaryTimingsMainDiv">
          <div className="dispensaryTimingsDays">
            {daysOfWeek.map(item => (
              <div>{item}</div>
            ))}
          </div>
          <div className="dispensaryOpenAndCloseTime">
            <div className="dispensaryTimingsOpenTime">
              {keysNames.map(item => {
                return opensAt[item] && closesAt[item] ? (
                  <div>{`${opensAt[item]} - ${closesAt[item]}`}</div>
                ) : (
                  <div style={{ color: "#f66f6f" }}>Closed</div>
                );
              })}
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );
};

export default DispensaryTimings;
