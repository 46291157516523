import React from "react";
import CustomProductsHits from "./CustomProductHits";
import DealProductHit from "./Search/DealProductHit";
import { connectStateResults, Index, Configure } from "react-instantsearch-dom";
import Hits from "@herb/components/Search/Hits";
import "@herb/pages/deals/index.css";

const CategorySearchResults = ({ searchValue, productSearchCategory }) => {
  const IndexResults = connectStateResults(({ searchResults, children }) => {
    return searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : searchValue ? (
      <p className="noDealFound">No results have been found.</p>
    ) : null;
  });
  return searchValue !== "" ? (
    <>
      {searchValue !== "" ? (
        <Index indexId={"CategorySearchResults"} indexName="Deals">
          <IndexResults>
            <Hits hitComponent={DealProductHit} slider={true} />
          </IndexResults>
        </Index>
      ) : (
        ""
      )}
    </>
  ) : productSearchCategory !== "" ? (
    <>
      <Index indexId={"CategorySearchResults"} indexName="Deals">
        <Configure
          aroundLatLngViaIP={false}
          hitsPerPage={6}
          query="*"
          filters={`contentDeals.content.product.category:'${productSearchCategory}'`}
        />
        <CustomProductsHits />
      </Index>
    </>
  ) : (
    <>
      <Index indexId={"CategorySearchResults"} indexName="Deals">
        <Configure
          aroundLatLngViaIP={false}
          hitsPerPage={6}
          query="*"
          filters={
            "contentDeals.content.product.category:Edibles OR contentDeals.content.product.category:'Accessories' OR contentDeals.content.product.category:'Vape Pens'"
          }
        />
        <CustomProductsHits />
      </Index>
    </>
  );
};

export default CategorySearchResults;
