import { connectHits } from "react-instantsearch-dom";
import Slider from "react-slick";
import DealProductHit from "../../components/Search/DealProductHit";
import "../../pages/deals/index.css";
import { useEffect, useState } from "react";
const isBrowser = typeof window !== "undefined";

const CustomHits = ({ hits, hitComponent, slider }) => {
  const [windowWidth, setWindowWidth] = useState(
    isBrowser ? window.innerWidth : 0
  );
  useEffect(() => {
    isBrowser &&
      window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="dealsSliderArrowRight"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path d="M1 1L11 11L1 21" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="dealsSliderArrowLeft"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path d="M1 1L11 11L1 21" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
    );
  }

  const cannabisDealsSlidersettings = {
    dots: false,
    infinite: true,
    centerMode: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  if (slider) {
    return (
      <div
        className={
          hits.length > 2 && windowWidth > 600
            ? "dealProductHits"
            : hits.length > 1 && windowWidth < 600
            ? "dealProductHits"
            : "dealProductHitsWithOutSlider"
        }
      >
        {hits.length > 0 ? (
          hits.length > 2 && windowWidth > 600 ? (
            <div className="cannabisDealsSlider">
              <Slider {...cannabisDealsSlidersettings}>
                {hits.map(hit => {
                  return <DealProductHit hit={hit} name="search" />;
                })}
              </Slider>
            </div>
          ) : hits.length > 1 && windowWidth < 600 ? (
            <div className="cannabisDealsSlider">
              <Slider {...cannabisDealsSlidersettings}>
                {hits.map(hit => {
                  return <DealProductHit hit={hit} name="search" />;
                })}
              </Slider>
            </div>
          ) : (
            hits.map(hit => {
              return <DealProductHit hit={hit} name="search" />;
            })
          )
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return hits.map(hit => hitComponent(hit));
  }
};

const Hits = connectHits(CustomHits);

export default Hits;
