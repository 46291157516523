import styled from "@emotion/styled";
import { themeGet } from "styled-system";

export default styled.input`
  background-color: transparent;
  color: ${themeGet("colors.text")};
  height: 44px;
  padding: 0 ${themeGet("space.2")}px;
  border: none;
  width: 100%;
  outline: none;
  appearance: none;
  z-index: 1;
`;
