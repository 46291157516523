module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://herb-admin-prod.fyawmqztqy.us-east-1.elasticbeanstalk.com/graphql","verbose":true,"develop":{"hardCacheData":true,"nodeUpdateInterval":5000,"hardCacheMediaFiles":false},"excludeFieldNames":["featured_in"],"schema":{"timeout":100000,"perPage":10,"requestConcurrency":5,"previewRequestConcurrency":1,"typePrefix":"WordpressWp","queryDepth":15,"circularQueryLimit":5},"type":{"MediaItem":{"createFileNodes":false,"lazyNodes":false},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"Comment":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-analytics-without-segment/gatsby-browser.js'),
      options: {"plugins":[],"services":{"Amplitude":{"apiKey":"ca9c65c082632a1e7395f2de3a13fe9d"},"Google Tag Manager":{"containerId":"GTM-KFGF53R"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"herb","short_name":"herb","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fae7062f264aa941a99dd17d306b226c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
