import styled from "@emotion/styled";
import { themeGet } from "styled-system";
import { transparentize } from "polished";

import Box from "../../Box";

export default styled(Box)`
  background-color: ${p =>
    p.semiTransparent === true
      ? transparentize(0.6, p.theme.colors.foreground)
      : p.background};
  border-radius: ${themeGet("radii.base")};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  border: 1px solid
    ${p => (p.border ? p.border : themeGet("colors.formBorder"))};
`;
