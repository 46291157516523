import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import Button from "@herb/components/Button";
import { paths, deployUrl } from "@herb/utils";
import {
  Index,
  connectInfiniteHits,
  connectStateResults,
  Configure
} from "react-instantsearch-dom";
import InstantSearch from "@herb/components/Search/InstantSearch";
import Container from "@herb/components/Container";
import SectionHeading from "@herb/components/SectionHeading";
import ProductNav from "@herb/components/Navbar/ProductNav";
import ProductCard from "@herb/components/Cards/ProductCard";
import ClipLoader from "react-spinners/ClipLoader";
// import DisplayAd from "@herb/components/DisplayAd";
import { Flex, Box } from "@herb/ui";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const CustomHits = connectInfiniteHits(({ hits, hasMore, refine }) => (
  <React.Fragment>
    {hits.map((product, index) => (
      <>
        <ProductCard key={(product || {}).slug} productdetails={product} />
        {/* {(index + 1) % 10 === 0 && window.location.pathname !== "/" && (
          <DisplayAd
            visibleProps={{
              margin: "40px 0px",
              width: `100%`
            }}
            adUnit="21810472093/Homepage/"
            banners={[
              {
                breakpoint: "mobile",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "tablet",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "desktop",
                sizes: [[728, 90]]
              }
            ]}
          />
        )} */}
      </>
    ))}
    {hasMore && (
      <div style={{ textAlign: "center", width: "100%" }}>
        <Button type="boutiqueButton" role="button" onClick={refine}>
          Show more
        </Button>
      </div>
    )}
  </React.Fragment>
));

const Loading = connectStateResults(
  ({ searching }) =>
    searching && (
      <Flex width={1} flex="1" alignItems="center" justifyContent="center">
        <ClipLoader size={24} color="#f76f6f" loading={true} />
      </Flex>
    )
);

const NewProductCategory = ({ data, pageContext }) => {
  const {
    wordpressWpProductCategory: category,
    allWordpressWpProductCategory: categories
  } = data;

  const { noindex, catPath } = pageContext;

  const breadcrumbs = [
    {
      name: "Products",
      url: `${paths.products}/`
    },
    {
      name: category.name,
      url: `${paths.products}/${(category || {}).slug}/`
    }
  ];

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <div>
      <Layout path={catPath}>
        <>
          {noindex ? (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          ) : (
            <></>
          )}
        </>
        <SEO
          title={
            category.acf && category.acf.seo_title
              ? category.acf.seo_title
              : category.name
          }
          description={
            category.acf && category.acf.seo_description
              ? category.acf.seo_description
              : `${category.name} Archive from Herb`
          }
          url={`${paths.products}/${(category || {}).slug}`}
          canonical={`${deployUrl}${paths.products}/${(category || {}).slug}`}
          breadcrumbs={breadcrumbs}
        />
        <Container>
          <SectionHeading heading={category.name} topHeading />
          <ProductNav
            categories={categories}
            current={category.name}
            boutique={true}
          />
          <InstantSearch>
            <Index indexName="Products">
              <Configure
                aroundLatLngViaIP={false}
                hitsPerPage={20}
                query="*"
                filters={`product_category.slug:${
                  (category || {}).slug
                } AND acf.brand.is_brand_active:true`}
              />
              <div className="mProducts">
                <CustomHits />
                <Box width={1} mt={3}>
                  <Loading />
                </Box>
              </div>
            </Index>
          </InstantSearch>
        </Container>
      </Layout>
    </div>
  );
};

export default NewProductCategory;

export const pageQuery = graphql`
  query($slug: String) {
    wordpressWpProductCategory(slug: { eq: $slug }) {
      ...ProductCategory
    }
    ...allWordpressWpProductCategory
  }
`;
