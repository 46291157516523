export default {
  strainType: {
    sativa: "Sativa",
    indica: "Indica",
    hybrid: "Hybrid"
  },
  onset: {
    immediate: "Immediate",
    "1hr": "1 hour",
    "2hr": "2 hours",
    "3hr": "3 hours",
    "5hr": "5+ hours"
  },
  feeling: {
    full: "Full Body",
    body: "Body",
    head: "Head"
  },
  duration: {
    "1hr": "1 hour",
    "2hr": "2 hours",
    "3hr": "3 hours",
    "5hr": "5+ hours"
  },
  units: {
    percentage: "%",
    mg: "mg",
    mgml: "mg/ml"
  },
  productReviewTags: [
    { label: "Anxious", value: "anxious" },
    { label: "Aroused", value: "aroused" },
    { label: "Chatty", value: "chatty" },
    { label: "Chilled", value: "chilled" },
    { label: "Creative", value: "creative" },
    { label: "Dizzy", value: "dizzy" },
    { label: "Dry Eyes", value: "dry-eyes" },
    { label: "Dry Mouth", value: "dry-mouth" },
    { label: "Energized", value: "energized" },
    { label: "Euphoric", value: "euphoric" },
    { label: "Focused", value: "focused" },
    { label: "Giggly", value: "giggly" },
    { label: "Happy", value: "happy" },
    { label: "Headache", value: "headache" },
    { label: "Hungry", value: "hungry" },
    { label: "Paranoid", value: "paranoid" },
    { label: "Sleepy", value: "sleepy" },
    { label: "Uplifted", value: "uplifted" }
  ]
};
