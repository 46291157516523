import { useEffect, useState } from "react";

import client from "../clients/algolia";

export default (index, ids) => {
  const [objects, setObjects] = useState(null);
  const [loading, setLoading] = useState(true);

  const algoliaIndex = client.initIndex(index);

  useEffect(() => {
    if (!objects && ids.length >= 1) {
      algoliaIndex.getObjects(ids).then(({ results }) => {
        setObjects(results);
        setLoading(false);
      });
    }
  }, [algoliaIndex, ids, objects]);

  return [objects, loading];
};
