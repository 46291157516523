import React from "react";
import Image from "@herb/components/Image";
import { Link } from "gatsby";

import "./index.css";

const GiveAwayProductCard = ({ title, image, links }) => {
  return (
    <div className="giveAwayCard">
      <div className="giveAwayProductCard">
        <h3 className="productTitle">{title}</h3>
        <div className="bundleImage">
          <Image className="lazyload image" src={image} alt="" />
        </div>
        <ul className="aboutProduct">
          {links.map((link, index) => {
            return (
              <>
                <Link to={link.url} target="_blank">
                  <li className="listOfDetail" key={index}>
                    {link.title}
                  </li>
                </Link>
              </>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default GiveAwayProductCard;
