import React from "react";
import JsonLd from "@herb/components/Schema/JsonLd";
import { deployUrl } from "@herb/utils";

export default ({ breadcrumbs = [] }) => {
  const breadcrumbsSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: deployUrl
      },
      ...breadcrumbs.map((item, i) => ({
        "@type": "ListItem",
        position: i + 2,
        name: item.name,
        item: `${deployUrl}${item.url}`
          .toLocaleLowerCase()
          .replace(/(.*[^/])$/, "$1/")
      }))
    ]
  };

  return <JsonLd data={breadcrumbsSchema} />;
};
