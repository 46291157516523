import React from "react";
import Image from "@herb/components/Image";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./index.css";

const GiveAwayForm = () => {
  const bundleOptions = [
    "The Meditator Bundle",
    "The High Yogi Bundle",
    "The Mindfulness Beginner",
    "The Mindful Materialist",
    "The Selfcare Addict"
  ];
  return (
    <div className="giveAwayForm">
      <div className="formContent">
        <h2 className="heading">Hey Herbivore!</h2>
        <p className="postHeading">
          Celebrate 4/20 the right way with some of our favorite brands and
          products, helping you achieve true connoisseur status. From laser
          bongs and gravity infusers to cannagar mold kits and leading
          vaporizers, you definitely don't want to miss the state-of-the-art
          products we're giving away.
        </p>

        <Formik
          initialValues={{
            email: "",
            bundle: "",
            followOnInsta: false,
            receiveMarketing: false
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email")
              .required("* Email is Required!"),
            bundle: Yup.string().required("* Bundle is Required!")
          })}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            const formValues = {
              email: values.email,
              bundle: values.bundle,
              followHerbInsta: values.followOnInsta ? "Yes" : "No",
              receiveMarketing: values.receiveMarketing ? "Yes" : "No"
            };

            const options = {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                contacts: [formValues]
              })
            };
            let url = `https://us-central1-herb-platform-v3.cloudfunctions.net/sendLane?listId=88`;

            try {
              const res = await fetch(url, options);
              actions.setStatus({
                message:
                  "Thank you for entering! 5 winners will be selected and announced on April 30."
              });

              actions.setSubmitting(false);
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {({ status, values, handleChange, errors }) => (
            <Form method="POST" noValidate>
              {(status || {}).message ? (
                <>
                  <p className="giveawayThankyouMessage">{status.message}</p>
                </>
              ) : (
                <>
                  <p className="smallHeading">
                    Follow the steps below to enter; five winners will be
                    randomly selected on April 30.
                  </p>{" "}
                  <div className="aboveForm">
                    <input
                      className="dropEmail"
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Drop your email address"
                      onChange={handleChange}
                      value={values.email ? values.email : ""}
                    />

                    <p className="validationError">{errors.email}</p>
                    <br />
                    <select
                      className="selectBundle"
                      name="bundle"
                      title="Budget"
                      value={values.bundle}
                      onChange={handleChange}
                    >
                      <option name="bundle" value="">
                        Which is your favourite bundle?
                      </option>
                      {bundleOptions.map((bundle, index) => {
                        return (
                          <option name="bundle" value={bundle} key={index}>
                            {bundle}
                          </option>
                        );
                      })}
                    </select>
                    <p className="validationError">{errors.bundle}</p>
                  </div>
                  <div className="followHerbInsta">
                    <div className="buttonAndCheckbox">
                      <div className="followHerbBtn">
                        <a
                          className="followHerbButtonLink"
                          onClick={e => {
                            if (typeof window !== "undefined") {
                              window.open(
                                "https://www.instagram.com/herb/",
                                "mywindow",
                                "menubar=1,resizable=1,width=500,height=500,top=300,left=500"
                              );
                            }
                          }}
                        >
                          Follow Herb
                        </a>
                      </div>
                      <div className="lowerForm">
                        <div className="checkboxAndLabelForm">
                          <input
                            type="checkbox"
                            id="followOnInsta"
                            name="followOnInsta"
                            onChange={handleChange}
                            value={
                              values.followOnInsta ? values.followOnInsta : ""
                            }
                          />
                          <label
                            for="follow_herb"
                            className="labelForLowerForm"
                          >
                            I follow Herb on Instagram
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="lowerForm2">
                      <div className="checkboxAndLabelForm2">
                        <input
                          type="checkbox"
                          id="receiveMarketing"
                          name="receiveMarketing"
                          onChange={handleChange}
                          value={
                            values.receiveMarketing
                              ? values.receiveMarketing
                              : ""
                          }
                        />
                        <label for="follow_herb" className="labelForLowerForm2">
                          I agree to receive marketing updates from Herb
                        </label>
                      </div>
                    </div>
                    <div className="formEndContent">
                      <button className="giveAwaySubmitButton" type="submit">
                        enter to win
                      </button>
                      <p>
                        Winners will be announced on our Instagram page on April
                        30th at 10:00am PST. <br />
                        *You must be 21+ to enter{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <div className="giveAwayFormImage">
        <Image
          className="lazyload image"
          src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19114525/Frame-93.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default GiveAwayForm;
