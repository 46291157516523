import React from "react";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import algoliaClient from "@herb/clients/algolia";

const searchClient = {
  async search(requests) {
    if (requests.every(({ params: { query } }) => Boolean(query) === false)) {
      return {
        results: requests.map(() => {
          return {
            processingTimeMS: 0,
            nbHits: 0,
            hits: [],
            facets: {}
          };
        })
      };
    }

    return algoliaClient.search(requests);
  },
  searchForFacetValues: algoliaClient.searchForFacetValues
};

export default ({ children, ...rest }) => (
  <InstantSearch searchClient={searchClient} indexName="Products" {...rest}>
    <Configure aroundLatLngViaIP={false} hitsPerPage={10} />
    {children}
  </InstantSearch>
);
