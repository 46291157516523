import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { themeGet } from "styled-system";
import { useLocation } from "@reach/router";
import fetch from "isomorphic-unfetch";
import L from "@herb/components/Link";
import {
  AlertDialogLabel,
  AlertDialogOverlay,
  AlertDialogContent
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";
import { Flex, Box, Text } from "@herb/ui";
import AgeGateForm from "@herb/components/AgeGate/AgeGateForm";
import { supportsLocalStorage } from "@herb/utils";
import theme from "@herbkit/theme";

const AgeGateLabel = styled(AlertDialogLabel)`
  color: ${themeGet("colors.white")};
  font-weight: 300;
  font-size: ${themeGet("fontSizes.6")};
  margin-bottom: ${themeGet("space.4")}px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  font-family: "Source Code Pro";
  font-size: 24px;
`;

const Link = styled(L)`
  text-decoration: underline;
`;

const confirmRef = React.createRef();

const StyledCard = styled(Box)`
  width: 100%;
  max-width: 650px;
  position: relative;
`;

const StyledAlertDialogOverlay = styled(AlertDialogOverlay)`
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const AgeGate = props => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const [ipData, setIpData] = useState(false);

  const openPopUp = () => {
    if (document) {
      setTimeout(() => {
        document.dispatchEvent(new Event("ageGate:verified"));
      }, 10000);
    }
  };

  useEffect(() => {
    setOpen(
      !localStorage.getItem("ageOfMajority") &&
        ["/privacy/", "/tos/"].indexOf(location.pathname) === -1 &&
        ipData.country_code === "CA"
    );

    if (!ipData) {
      fetch(process.env.GATSBY_GEOLOCATION)
        .then(response => response.json())
        .then(data => {
          if (data.country_code !== "CA") {
            localStorage.setItem("ageOfMajority", true);
          }
          openPopUp();
          setIpData(data);
        })
        .catch(error => {
          setIpData({
            country_code: ""
          });
        });
    }
  }, []);

  return (
    supportsLocalStorage() && (
      <React.Fragment>
        {open && ipData && (
          <StyledAlertDialogOverlay leastDestructiveRef={confirmRef}>
            <Flex flexDirection="column" w={1}>
              <AlertDialogContent
                style={{
                  width: "100%",
                  padding: 0,
                  background: "transparent"
                }}
              >
                <Flex flexDirection="column" alignItems="center" p={3}>
                  <StyledCard
                    style={{
                      padding: "100px 64px",
                      backgroundColor: "#fff"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "5%",
                        right: "5%"
                      }}
                    >
                      <Link to="https://google.ca">
                        <svg
                          class="CursorP"
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="24"
                          viewBox="0 0 26 24"
                          fill="none"
                        >
                          <ellipse
                            cx="12.5714"
                            cy="12"
                            rx="12.5714"
                            ry="12"
                            fill="#0A0909"
                          ></ellipse>
                          <line
                            x1="7.21"
                            y1="6.50387"
                            x2="18.6386"
                            y2="17.9324"
                            stroke="white"
                          ></line>
                          <line
                            x1="7.21"
                            y1="6.50387"
                            x2="18.6386"
                            y2="17.9324"
                            stroke="white"
                          ></line>
                          <line
                            x1="7.21"
                            y1="6.50387"
                            x2="18.6386"
                            y2="17.9324"
                            stroke="white"
                          ></line>
                          <line
                            x1="18.6387"
                            y1="6.06791"
                            x2="7.21014"
                            y2="17.4965"
                            stroke="white"
                          ></line>
                          <line
                            x1="18.6387"
                            y1="6.06791"
                            x2="7.21014"
                            y2="17.4965"
                            stroke="white"
                          ></line>
                          <line
                            x1="18.6387"
                            y1="6.06791"
                            x2="7.21014"
                            y2="17.4965"
                            stroke="white"
                          ></line>
                        </svg>
                      </Link>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        paddingBottom: "25px"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="164"
                        height="56"
                        viewBox="0 0 164 56"
                        fill="none"
                      >
                        <path
                          d="M0.103638 0H25.2487L25.2774 0.143454C25.1111 0.241002 24.9419 0.335681 24.7728 0.430361C21.1974 2.39854 19.2907 5.45123 19.2191 9.49376C19.1244 14.7298 19.1933 19.9687 19.1961 25.2077C19.1961 25.3196 19.2162 25.4286 19.2305 25.5921C20.9934 25.6828 22.76 25.5156 24.4746 25.0958C26.1949 24.674 27.8578 24.126 29.5122 23.5436C32.7077 22.4227 36.0614 21.8193 39.4469 21.7562C39.5846 21.7562 39.7193 21.7332 39.9258 21.7131V20.9442C39.9258 17.2345 39.9487 13.5277 39.9258 9.81796C39.8828 5.45124 37.9159 2.2264 33.9362 0.286907C33.8371 0.224249 33.7413 0.156248 33.6495 0.0832031H58.9781C58.786 0.209442 58.6598 0.306991 58.5222 0.37011C54.8121 2.37846 52.8567 5.47419 52.8481 9.70033C52.8252 21.6816 52.8252 33.6638 52.8481 45.6469C52.8481 49.9505 54.7691 53.1237 58.5824 55.1522C58.6971 55.2153 58.8061 55.2927 59.0641 55.4391H33.6036C34.1169 55.1522 34.4638 54.9857 34.8165 54.7792C38.1022 52.7708 39.8655 49.8472 39.9028 46.0084C39.963 39.7998 39.92 33.5882 39.92 27.3738V26.5906C38.366 26.7455 36.855 26.7713 35.4042 27.0726C33.6386 27.4358 31.9021 27.9287 30.2089 28.5473C26.8835 29.7813 23.378 30.4598 19.8326 30.5556C19.6491 30.5556 19.4685 30.5843 19.1847 30.6044V31.4307C19.1847 36.1101 19.1847 40.7925 19.1847 45.4748C19.1847 49.8702 21.0741 53.161 25.0107 55.2296C25.1025 55.2755 25.1828 55.3386 25.4265 55.5165C25.0939 55.5366 24.9477 55.5538 24.8014 55.5538H0.312941C0.241262 55.5538 0.17245 55.5538 0.103638 55.5538V55.3731C0.203989 55.3157 0.30434 55.2583 0.407558 55.2067C4.20942 53.2815 6.23651 50.2001 6.25085 45.9367C6.28526 33.812 6.25085 21.6902 6.25085 9.56548C6.27223 8.25208 6.03314 6.94747 5.54739 5.72713C5.06165 4.50678 4.3389 3.39494 3.42095 2.45592C2.49772 1.48331 1.43974 0.651279 0.103638 0.286907V0Z"
                          fill="#141313"
                        ></path>
                        <path
                          d="M125.024 55.4621C125.385 55.244 125.565 55.1293 125.752 55.026C127.816 53.8784 128.963 52.1282 129.158 49.7928C129.299 48.1316 129.428 46.4675 129.43 44.8035C129.453 33.1015 129.453 21.3986 129.43 9.69465C129.38 7.96323 129.207 6.23752 128.914 4.53032C128.659 2.78019 127.527 1.60387 126.084 0.688638C125.815 0.516493 125.54 0.347218 125.267 0.172205C125.267 0.172205 125.253 0.134907 125.224 0.0402276C125.457 0.0402276 125.669 0.00292969 125.884 0.00292969C129.155 0.00292969 132.427 0.00292969 135.695 0.00292969C136.129 0.0148655 136.556 0.120566 136.945 0.312789C139.448 1.54649 140.595 3.67247 140.698 6.35218C140.822 9.24421 140.822 12.1391 140.873 15.034C140.873 15.2836 140.873 15.5332 140.873 15.8574C141.581 15.8574 142.224 15.8574 142.866 15.8574C145.926 15.7472 148.984 16.1229 151.926 16.9706C158.635 18.979 162.942 24.1433 163.702 31.1065C164.163 35.3614 163.854 39.5072 162.239 43.5095C159.946 49.1645 155.573 52.4582 149.974 54.3661C147.665 55.1444 145.242 55.5323 142.806 55.5137C137.123 55.5137 131.44 55.5137 125.758 55.5137C125.588 55.5109 125.413 55.4879 125.024 55.4621ZM140.928 19.3634V52.0048C142.092 52.0048 143.204 52.0508 144.311 52.0048C145.097 51.9814 145.876 51.858 146.631 51.6376C149.346 50.7769 151.04 48.9349 151.582 46.1032C152.528 41.1368 152.262 36.1991 151.098 31.3102C150.306 27.9936 148.964 24.9151 146.731 22.2899C145.234 20.5139 143.394 19.3806 140.928 19.3634Z"
                          fill="#141313"
                        ></path>
                        <path
                          d="M94.4634 33.046H70.5569C71.0988 36.8418 71.9503 40.4282 74.7458 43.1681C78.1377 46.4905 82.146 47.2393 86.5901 45.6872C89.0817 44.8264 91.1604 43.2685 93.0584 41.4668C93.219 41.3147 93.3882 41.1799 93.5545 41.0221L93.715 41.1081C93.5659 41.57 93.4283 42.0406 93.2649 42.4939C91.7195 46.6282 89.3569 50.2117 85.9106 53.0033C83.0635 55.2986 79.7433 56.1392 76.1049 55.9814C69.086 55.6773 61.8608 50.0625 59.8767 41.8799C58.4632 36.0557 58.3399 30.1856 60.582 24.545C63.2341 17.8687 68.1771 13.5392 75.2763 12.208C81.7847 10.9743 87.2008 13.3068 91.0973 19.5499C92.8033 22.2813 93.6605 25.3225 94.0648 28.4928C94.2541 29.9647 94.3286 31.4623 94.4634 33.046ZM83.2527 28.3608C83.0004 26.9062 82.814 25.5319 82.5187 24.1806C82.0026 21.805 81.2457 19.5098 79.7376 17.5301C79.1068 16.7095 78.347 16.0411 77.2804 15.846C75.6891 15.5591 74.5595 16.3452 73.6219 17.4842C71.8414 19.6446 71.0415 22.2096 70.6917 24.9438C70.5397 26.0742 70.4651 27.2161 70.3533 28.3867L83.2527 28.3608Z"
                          fill="#141313"
                        ></path>
                        <path
                          d="M117.171 29.7174C117.205 27.1725 117.093 24.774 116.491 22.4271C116.133 21.0298 115.631 19.6842 114.633 18.5997C114.257 18.1781 113.788 17.8487 113.264 17.6373C112.74 17.4258 112.175 17.3379 111.611 17.3804V18.0489C111.611 27.4566 111.611 36.8642 111.637 46.2748C111.679 47.6152 111.817 48.9508 112.05 50.2714C112.337 52.2281 113.446 53.6541 115.112 54.6754C115.33 54.8074 115.545 54.9451 115.757 55.0857C115.789 55.1087 115.803 55.1603 115.869 55.2693H95.7643C95.9182 55.139 96.08 55.0183 96.2488 54.9078C98.7117 53.6053 99.741 51.4334 99.9217 48.7795C100.028 47.2331 100.091 45.6809 100.091 44.1316C100.091 36.7151 100.091 29.2985 100.051 21.8848C100.004 20.683 99.8517 19.4877 99.5948 18.3128C99.245 16.4652 98.0695 15.1913 96.5183 14.2244L95.7413 13.7481C95.9298 13.7003 96.1214 13.6658 96.3148 13.6449C102.623 13.6449 108.93 13.6305 115.238 13.6621C117.047 13.675 118.839 14.0172 120.525 14.672C123.782 15.9172 125.531 18.4018 126.348 21.6754C126.552 22.4787 126.973 23.4399 126.704 24.0854C126.434 24.7309 125.442 25.0408 124.737 25.4482C122.443 26.7622 120.15 28.0533 117.856 29.353C117.658 29.4649 117.46 29.5653 117.171 29.7174Z"
                          fill="#141313"
                        ></path>
                      </svg>
                    </div>
                    <div
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <h2
                        css={{
                          color: "#3041FF",
                          fontSize: "32px",
                          paddingBottom: "50px",
                          "@media screen and (max-width:769px)": {
                            paddingBottom: "25px"
                          }
                        }}
                      >
                        Cannabis simplified.
                      </h2>
                    </div>
                    <AgeGateLabel>
                      Please Verify
                      <br />
                      Your Age
                    </AgeGateLabel>
                    <Box>
                      <AgeGateForm
                        theme={theme}
                        callback={() => {
                          localStorage.setItem("ageOfMajority", true);
                          setOpen(false);
                          openPopUp();
                          // if (document) {
                          //   document.dispatchEvent(
                          //     new Event("ageGate:verified")
                          //   );
                          // }
                        }}
                        ipData={ipData}
                        innerRef={confirmRef}
                      />
                    </Box>
                  </StyledCard>
                  <Text
                    fontSize={2}
                    mt="auto"
                    mb={2}
                    color="white"
                    textAlign="center"
                    fontFamily="Source Code Pro"
                    textTransform="uppercase"
                    fontStyle="italic"
                  >
                    By entering this site, you agree to our
                    <br />
                    <Link to="/tos/">Terms and Conditions</Link> &amp;{" "}
                    <Link to="/privacy/">Privacy Policy</Link>.
                  </Text>
                </Flex>
              </AlertDialogContent>
            </Flex>
          </StyledAlertDialogOverlay>
        )}
      </React.Fragment>
    )
  );
};

export default AgeGate;
