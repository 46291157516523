import React from "react";
import { Box, Text } from "@herb/ui";
import Fade from "react-reveal/Fade";
import styled from "@emotion/styled";

const ColoredText = styled.span`
  color: #3041ff;
  font-weight: 400;
`;

const SeoQuote = ({ starting, ending, colored, author, SeoQuoteStyles }) => {
  return (
    <Fade bottom>
      <Box
        css={{
          maxWidth: "980px",
          margin: "0 auto",
          textAlign: "center",
          paddingTop: "80px",

          "@media screen and (max-width:768px)": {
            paddingBottom: "20px",
            paddingTop: "80px"
          }
        }}
      >
        <Text
          css={{
            fontSize: "32px",
            lineHeight: "40px",
            fontWeight: 300,
            textTransform: "uppercase",
            fontFamily: "Source Code Pro",
            paddingTop: "15px",
            fontStyle: "italic",
            "@media screen and (max-width:768px)": {
              fontSize: "24px",
              lineHeight: "32px",
              padding: "0px 15px"
            }
          }}
        >
          {starting}
          <ColoredText
            css={{
              "@media screen and (max-width:425px)": {
                fontSize: "30px"
              }
            }}
          >
            {colored}
          </ColoredText>
          {ending}
        </Text>
        <Text
          css={{
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 500,
            fontFamily: "Source Code Pro",
            paddingTop: "25px"
          }}
        >
          {author}
        </Text>
      </Box>
    </Fade>
  );
};

export default SeoQuote;
