const parentCategory = categoriesPram => {
  const categories =
    typeof categoriesPram === Array ? categoriesPram : categoriesPram.nodes;
  if (categories.length === 1) {
    // If only 1 category, defaults to parent
    return categories[0];
  }

  // If more than one category, return category with parent
  const parent = categories.find(cat => cat.wpParent === null);

  return parent;
};

module.exports = parentCategory;
