import React from "react";
import CategoryAndDate from "../../CategoryAndDate";
import getCategoryPath from "@herb/utils/categoryPath";
import RedirectLink from "@herb/components/RedirectLink";
import Image from "@herb/components/Image";
import { orderBy } from "lodash";
import "./index.css";

import ReactHtmlParser from "react-html-parser";
import { fallbackImages } from "../../../utils/constants";

const TrendingArticleCard = ({ article, strain }) => {
  let { categories: { nodes: categoryArray = [] } = {} } = article;
  let legacyCategory;
  let [category] = orderBy(categoryArray, ["databaseId"], ["desc"]);
  const isCategoriesExist = article.categories
    ? article.categories[0]
    : article._xtypename;

  if (!category) {
    if (typeof isCategoriesExist == "string")
      legacyCategory = isCategoriesExist.toLowerCase() + "s";
    else category = article.categories;
  }

  category = category ||
    legacyCategory || {
      slug: strain ? "strains" : "recipes",
      name: strain ? "strains" : "recipes"
    };

  const categoryPath = !legacyCategory
    ? getCategoryPath(article.categories)
    : legacyCategory;

  const sourceImage =
    (article.featuredImage || {}).node ||
    article.featuredImage || {
      sourceUrl: (article.featured_media || {}).source_url
    } ||
    {};

  // const category = (article || {}).categories && article.categories.nodes;
  // const image = (article || {}).featuredImage && article.featuredImage.node;

  return (
    <div className="newCardTrending">
      <div className="cntPic">
        <RedirectLink to={`/${categoryPath}/${(article || {}).slug}`}>
          <Image
            className="lazyload nctImg"
            src={(sourceImage || {}).sourceUrl || "../assets/Rectangle 15.jpg"}
            alt={(sourceImage || {}).altText}
            fallback={fallbackImages.square}
            blur={50}
            width={297}
            height={208}
            css={{
              width: "100%"
            }}
          />
        </RedirectLink>
      </div>

      <div className="nctContent">
        <CategoryAndDate
          category={category}
          path={`/${categoryPath}`}
          date={article.modified ? article.modified : article.date}
        />
        <RedirectLink to={`/${categoryPath}/${(article || {}).slug}`}>
          <h3 className="nctHeading">
            {ReactHtmlParser((article || {}).title)}
          </h3>
          <p className="nctText">
            {((article || {}).content_post || {}).postExcerpt}
          </p>
        </RedirectLink>
      </div>
    </div>
  );
};

export default TrendingArticleCard;
