import React from "react";
import { slugs as createSlug } from "@herb/utils";
import "./index.css";
import RedirectLink from "@herb/components/RedirectLink";

const SmallVideoArticles = ({ video, path }) => {
  return (
    <div className="svContent">
      <div className="svImage">
        <div
          className="svImg"
          style={{
            backgroundImage: `url(${video.image})`
          }}
        >
          <RedirectLink to={`/videos/${path}/${createSlug(video.title)}`}>
            <div className="playSvg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="63"
                height="71"
                viewBox="0 0 63 71"
                fill="none"
              >
                <path
                  d="M63 35.5L0.749997 70.574L0.75 0.425969L63 35.5Z"
                  fill="#fff"
                  fillOpacity="0.5"
                />
              </svg>
            </div>
          </RedirectLink>
        </div>
      </div>

      <div className="svText">
        {/* <Link className="nctCategoryDate lvCategory">
          Category | 01.01.2023
        </Link> */}
        <RedirectLink to={`/videos/${path}/${createSlug(video.title)}`}>
          <h3 className="svTitle">{video.title}</h3>
        </RedirectLink>
      </div>
    </div>
  );
};

export default SmallVideoArticles;
