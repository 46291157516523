import React from "react";

import Box from "./Box";
import Card from "./Card";
import Flex from "./Flex";

export default ({ children, left, icon, ...props }) => (
  <Flex justifyContent="space-between" alignItems="center" {...props}>
    <Card width="68px" css={{ height: "68px", position: "relative" }}>
      {left}
    </Card>
    <Box flex="1 0" px={3}>
      {children}
    </Box>
    <Box width="20px" css={{ height: "20px" }}>
      {icon}
    </Box>
  </Flex>
);
