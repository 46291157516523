import React, { useEffect } from "react";
import { DFPSlotsProvider } from "react-dfp";
import InstantSearch from "@herb/components/Search/InstantSearch";
import GlobalSchema from "@herb/components/Schema/GlobalSchema";
import IFrame from "@herb/components/IFrame";
import Footer from "@herb/components/Footer";
import theme from "@herbkit/theme";
import Navbar from "../../components/WebinarForm/Navbar";
import WebinarFrom from "@herb/components/WebinarForm";
import Container from "@herb/components/Container";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import "../../layouts/layout.css";
import SEO from "@herb/components/seo";
import { ThemeProvider } from "emotion-theming";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const Webinar = ({ location, hideWebPageSchema = false }) => {
  useEffect(() => {
    scriptCreate();
  });
  return (
    <>
      <DFPSlotsProvider
        lazyload={true}
        dfpNetworkId={process.env.GATSBY_DFP_NETWORK_ID}
      >
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <div className="webinarBg">
          <div className="layout mainSec">
            <GlobalSchema hideWebPageSchema={hideWebPageSchema} />
            <IFrame />
            <CookiesProvider>
              <InstantSearch>
                <Navbar />
                <ThemeProvider theme={theme}>
                  <div className="contentForLayout">
                    <SEO
                      title={`Workshop`}
                      url={"/workshop"}
                      image={
                        "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
                      }
                    />

                    <Container>
                      <div class="cus-position-relative">
                        <h1 className="webinarMainHeading">
                          Free Live Training:
                          <br />
                          The 4-Step Blueprint for Landing Your Dream Customer
                          at Scale
                        </h1>
                        <div className="webinarHeadingExcerpt">
                          A proven method of growing your cannabis-related
                          business to 8-figures and beyond.
                        </div>
                        <div className="webinarSubText">
                          August 11<sup>th</sup> at 4pm EST
                        </div>

                        <WebinarFrom />
                        <div className="cusPt130px">
                          <div className="customFlex cusAlignItemCenter cusFlexWrapMobile">
                            <div className="cusWidthHalf cusWidthFull">
                              <img
                                className="imgWidthHeight"
                                src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/13075843/Webinar.webp"
                                alt="/"
                              />
                            </div>
                            <div className="cusWidthHalf cusWidthFull">
                              <p className="sideHeading">
                                Join Herb Founder,{" "}
                                <span className="textBold">Matt Gray</span>, as
                                he reveals the steps he took to create a
                                community of over 14 million people, and drive
                                $26 million in revenue for cannabis-related
                                brands.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cusPt130px">
                          <h2 className="customSmallHeading cusTextAlignCenter">
                            In this live training, Matt will reveal:
                          </h2>
                          <div className="customFlex cusFlexWrap isJustifySpaceBTW cusPT20px">
                            <div className="svgAndText">
                              <div className="customFlex cusAlignItemStart isAlignCenterMobile isFlexDirectionColumnMobile">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="65"
                                    height="64"
                                    viewBox="0 0 65 64"
                                    fill="none"
                                  >
                                    <path
                                      d="M29.834 39.7786V47.9998H24.5007C23.0279 47.9998 21.834 49.1937 21.834 50.6665C21.834 52.1392 23.0279 53.3331 24.5007 53.3331H40.5007C41.9734 53.3331 43.1673 52.1392 43.1673 50.6665C43.1673 49.1937 41.9734 47.9998 40.5007 47.9998H35.1673V39.7786C34.3001 39.9241 33.4092 39.9998 32.5007 39.9998C31.5921 39.9998 30.7012 39.9241 29.834 39.7786Z"
                                      fill="#3041FF"
                                    />
                                    <path
                                      d="M17.8851 13.3333C17.0165 14.4716 16.5007 15.8934 16.5007 17.4358V18.6666H14.2149C12.5318 18.6666 11.1673 20.031 11.1673 21.7142C11.1673 24.4493 13.3846 26.6666 16.1197 26.6666H16.7219C17.0431 28.581 17.7042 30.3802 18.6412 31.9999H16.1197C10.4391 31.9999 5.83398 27.3948 5.83398 21.7142C5.83398 17.0855 9.58626 13.3333 14.2149 13.3333H17.8851Z"
                                      fill="#3041FF"
                                    />
                                    <path
                                      d="M48.5006 18.6666V17.4358C48.5006 15.8934 47.9848 14.4716 47.1162 13.3333H50.7864C55.415 13.3333 59.1673 17.0855 59.1673 21.7142C59.1673 27.3948 54.5622 31.9999 48.8816 31.9999H46.3601C47.2971 30.3802 47.9582 28.581 48.2794 26.6666H48.8816C51.6167 26.6666 53.834 24.4493 53.834 21.7142C53.834 20.031 52.4695 18.6666 50.7864 18.6666H48.5006Z"
                                      fill="#3041FF"
                                    />
                                    <path
                                      d="M16.5 17.4359C16.5 13.6974 19.5307 10.6667 23.2692 10.6667H41.7308C45.4693 10.6667 48.5 13.6974 48.5 17.4359V24C48.5 32.8366 41.3366 40 32.5 40C23.6634 40 16.5 32.8366 16.5 24V17.4359Z"
                                      fill="#12131A"
                                      fill-opacity="0.25"
                                    />
                                    <path
                                      d="M16.5 17.4359C16.5 13.6974 19.5307 10.6667 23.2692 10.6667H41.7308C45.4693 10.6667 48.5 13.6974 48.5 17.4359V24C48.5 32.8366 41.3366 40 32.5 40C23.6634 40 16.5 32.8366 16.5 24V17.4359Z"
                                      fill="url(#paint0_linear_2925_25472)"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_2925_25472"
                                        x1="16.5178"
                                        y1="10.6497"
                                        x2="48.1008"
                                        y2="47.2573"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stop-color="#F7FFE3" />
                                        <stop offset="1" stop-color="#F9CCCC" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="cusPL5px textAlignCenterMobile">
                                  <h2 className="textForSvg">
                                    The ACER framework
                                  </h2>
                                  <p className="smallTextForSvg">
                                    This is the exact same framework he used to
                                    grow Herb into the internet’s #1 community
                                    for weed enthusiasts, and how he’s helped
                                    businesses scale to 8 figures.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="svgAndText">
                              <div className="customFlex cusAlignItemStart isAlignCenterMobile isFlexDirectionColumnMobile">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="65"
                                    height="64"
                                    viewBox="0 0 65 64"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.5 20.8C8.5 16.3196 8.5 14.0794 9.37195 12.3681C10.1389 10.8628 11.3628 9.63893 12.8681 8.87195C14.5794 8 16.8196 8 21.3 8H43.7C48.1804 8 50.4206 8 52.1319 8.87195C53.6372 9.63893 54.8611 10.8628 55.6281 12.3681C56.5 14.0794 56.5 16.3196 56.5 20.8V43.2C56.5 47.6804 56.5 49.9206 55.6281 51.6319C54.8611 53.1372 53.6372 54.3611 52.1319 55.1281C50.4206 56 48.1804 56 43.7 56H21.3C16.8196 56 14.5794 56 12.8681 55.1281C11.3628 54.3611 10.1389 53.1372 9.37195 51.6319C8.5 49.9206 8.5 47.6804 8.5 43.2V20.8Z"
                                      fill="#12131A"
                                      fill-opacity="0.25"
                                    />
                                    <path
                                      d="M8.5 20.8C8.5 16.3196 8.5 14.0794 9.37195 12.3681C10.1389 10.8628 11.3628 9.63893 12.8681 8.87195C14.5794 8 16.8196 8 21.3 8H43.7C48.1804 8 50.4206 8 52.1319 8.87195C53.6372 9.63893 54.8611 10.8628 55.6281 12.3681C56.5 14.0794 56.5 16.3196 56.5 20.8V43.2C56.5 47.6804 56.5 49.9206 55.6281 51.6319C54.8611 53.1372 53.6372 54.3611 52.1319 55.1281C50.4206 56 48.1804 56 43.7 56H21.3C16.8196 56 14.5794 56 12.8681 55.1281C11.3628 54.3611 10.1389 53.1372 9.37195 51.6319C8.5 49.9206 8.5 47.6804 8.5 43.2V20.8Z"
                                      fill="url(#paint0_linear_2922_25388)"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M49.3434 13.4702C50.7405 13.9359 51.4956 15.4461 51.0299 16.8433L45.7588 32.6566C43.8782 38.2985 36.7177 39.9889 32.5125 35.7837L28.7165 31.9876C27.3147 30.5859 24.9279 31.1494 24.301 33.03L19.0299 48.8433C18.5642 50.2405 17.054 50.9956 15.6568 50.5299C14.2596 50.0641 13.5045 48.5539 13.9703 47.1568L19.2414 31.3434C21.122 25.7016 28.2825 24.0112 32.4877 28.2164L36.2837 32.0124C37.6854 33.4142 40.0723 32.8507 40.6992 30.9701L45.9703 15.1568C46.436 13.7596 47.9462 13.0045 49.3434 13.4702Z"
                                      fill="#3041FF"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_2922_25388"
                                        x1="8.52676"
                                        y1="7.97222"
                                        x2="60.6764"
                                        y2="63.3812"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stop-color="#F7FFE3" />
                                        <stop offset="1" stop-color="#F9CCCC" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="cusPL5px textAlignCenterMobile">
                                  <h2 className="textForSvg">
                                    An often-overlooked method to accelerate
                                    your customer journey
                                  </h2>
                                  <p className="smallTextForSvg">
                                    Taking new leads from visitor to loyal
                                    customer in a fraction of the time.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="svgAndText">
                              <div className="customFlex cusAlignItemStart isAlignCenterMobile isFlexDirectionColumnMobile">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="65"
                                    height="64"
                                    viewBox="0 0 65 64"
                                    fill="none"
                                  >
                                    <path
                                      d="M27.1667 49.0023V37.3333H16.5L18.5635 49.7143C18.9116 51.8027 20.7184 53.3333 22.8356 53.3333C25.2276 53.3333 27.1667 51.3942 27.1667 49.0023Z"
                                      fill="#3041FF"
                                    />
                                    <path
                                      d="M8.5 29.3321C8.5 24.9138 12.0817 21.3321 16.5 21.3321H24.5L29.8333 21.3331L46.4668 11.6015C50.9111 9.00135 56.5 12.2067 56.5 17.3558V41.3257C56.5 46.477 50.9069 49.682 46.4626 47.0775L29.8333 37.3321H24.5H16.5C12.0817 37.3321 8.5 33.7503 8.5 29.3321Z"
                                      fill="#12131A"
                                      fill-opacity="0.25"
                                    />
                                    <path
                                      d="M8.5 29.3321C8.5 24.9138 12.0817 21.3321 16.5 21.3321H24.5L29.8333 21.3331L46.4668 11.6015C50.9111 9.00135 56.5 12.2067 56.5 17.3558V41.3257C56.5 46.477 50.9069 49.682 46.4626 47.0775L29.8333 37.3321H24.5H16.5C12.0817 37.3321 8.5 33.7503 8.5 29.3321Z"
                                      fill="url(#paint0_linear_2925_25481)"
                                    />
                                    <path
                                      d="M48.5 28.7407C48.5 26.1225 50.6225 24 53.2407 24H53.8333C56.7789 24 59.1667 26.3878 59.1667 29.3333C59.1667 32.2789 56.7789 34.6667 53.8333 34.6667H53.2407C50.6225 34.6667 48.5 32.5442 48.5 29.9259V28.7407Z"
                                      fill="#F8F1DD"
                                    />
                                    <path
                                      d="M29.8333 21.3332L46.4668 11.6017C50.9111 9.00148 56.5 12.2068 56.5 17.3559V41.3258C56.5 46.4771 50.9069 49.6821 46.4626 47.0776L29.8333 37.3322V21.3332Z"
                                      fill="#3041FF"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_2925_25481"
                                        x1="8.52676"
                                        y1="10.6574"
                                        x2="47.2489"
                                        y2="63.5686"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stop-color="#F7FFE3" />
                                        <stop offset="1" stop-color="#F9CCCC" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="cusPL5px textAlignCenterMobile">
                                  <h2 className="textForSvg">
                                    A step-by-step method of attracting your
                                    ideal customer, and how you can do it at
                                    scale
                                  </h2>
                                  <p className="smallTextForSvg">
                                    This “ban-proof” method works regardless of
                                    your product or service.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="svgAndText">
                              <div className="customFlex cusAlignItemStart isAlignCenterMobile isFlexDirectionColumnMobile">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="65"
                                    height="64"
                                    viewBox="0 0 65 64"
                                    fill="none"
                                  >
                                    <circle
                                      cx="32.2559"
                                      cy="32.1992"
                                      r="26.5"
                                      fill="#C4C4C4"
                                    />
                                    <circle
                                      cx="32.2559"
                                      cy="32.1992"
                                      r="26.5"
                                      fill="url(#paint0_linear_2925_25486)"
                                    />
                                    <path
                                      d="M30.5298 17.2016C31.3379 15.1984 34.174 15.1984 34.9822 17.2016L38.0825 24.8867C38.1958 25.1674 38.4578 25.3602 38.7595 25.3848L46.5471 26.0203C48.6587 26.1926 49.5281 28.8162 47.9373 30.2154L42.028 35.4128C41.8093 35.6051 41.7123 35.9011 41.7749 36.1855L43.6646 44.7747C44.1366 46.9203 41.7174 48.5202 39.9278 47.246L33.2201 42.4701C32.9423 42.2723 32.5697 42.2723 32.2919 42.4701L25.5842 47.246C23.7946 48.5202 21.3753 46.9203 21.8474 44.7747L23.7371 36.1855C23.7996 35.9011 23.7027 35.6051 23.484 35.4128L17.5745 30.2154C15.9836 28.8163 16.853 26.1926 18.9646 26.0203L26.7524 25.3848C27.0542 25.3602 27.3162 25.1674 27.4294 24.8867L30.5298 17.2016Z"
                                      fill="#3041FF"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_2925_25486"
                                        x1="5.78541"
                                        y1="5.66855"
                                        x2="63.3673"
                                        y2="66.8493"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stop-color="#F7FFE3" />
                                        <stop offset="1" stop-color="#F9CCCC" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="cusPL5px textAlignCenterMobile">
                                  <h2 className="textForSvg">
                                    The easiest way to grow your revenue month
                                    over month, guaranteed
                                  </h2>
                                  <p className="smallTextForSvg">
                                    Our untapped audience is packed full of your
                                    ideal customers, they're just waiting to
                                    hear from you.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="svgAndText">
                              <div className="customFlex cusAlignItemStart isAlignCenterMobile isFlexDirectionColumnMobile">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="65"
                                    height="64"
                                    viewBox="0 0 65 64"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.5 20.8C8.5 16.3196 8.5 14.0794 9.37195 12.3681C10.1389 10.8628 11.3628 9.63893 12.8681 8.87195C14.5794 8 16.8196 8 21.3 8H43.7C48.1804 8 50.4206 8 52.1319 8.87195C53.6372 9.63893 54.8611 10.8628 55.6281 12.3681C56.5 14.0794 56.5 16.3196 56.5 20.8V43.2C56.5 47.6804 56.5 49.9206 55.6281 51.6319C54.8611 53.1372 53.6372 54.3611 52.1319 55.1281C50.4206 56 48.1804 56 43.7 56H21.3C16.8196 56 14.5794 56 12.8681 55.1281C11.3628 54.3611 10.1389 53.1372 9.37195 51.6319C8.5 49.9206 8.5 47.6804 8.5 43.2V20.8Z"
                                      fill="#12131A"
                                      fill-opacity="0.25"
                                    />
                                    <path
                                      d="M8.5 20.8C8.5 16.3196 8.5 14.0794 9.37195 12.3681C10.1389 10.8628 11.3628 9.63893 12.8681 8.87195C14.5794 8 16.8196 8 21.3 8H43.7C48.1804 8 50.4206 8 52.1319 8.87195C53.6372 9.63893 54.8611 10.8628 55.6281 12.3681C56.5 14.0794 56.5 16.3196 56.5 20.8V43.2C56.5 47.6804 56.5 49.9206 55.6281 51.6319C54.8611 53.1372 53.6372 54.3611 52.1319 55.1281C50.4206 56 48.1804 56 43.7 56H21.3C16.8196 56 14.5794 56 12.8681 55.1281C11.3628 54.3611 10.1389 53.1372 9.37195 51.6319C8.5 49.9206 8.5 47.6804 8.5 43.2V20.8Z"
                                      fill="url(#paint0_linear_2925_25490)"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M45.0523 14.1144C44.0109 13.073 42.3224 13.073 41.281 14.1144L35.9477 19.4477C34.9063 20.4891 34.9063 22.1776 35.9477 23.219C36.9891 24.2604 38.6775 24.2604 39.7189 23.219L40.5 22.4379V29.3333C40.5 38.1699 33.3365 45.3334 24.5 45.3334H16.5C15.0272 45.3334 13.8333 46.5273 13.8333 48C13.8333 49.4728 15.0272 50.6667 16.5 50.6667H24.5C36.2821 50.6667 45.8333 41.1154 45.8333 29.3333V22.4379L46.6144 23.219C47.6558 24.2604 49.3442 24.2604 50.3856 23.219C51.427 22.1776 51.427 20.4891 50.3856 19.4477L45.0523 14.1144Z"
                                      fill="#3041FF"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_2925_25490"
                                        x1="8.52676"
                                        y1="7.97222"
                                        x2="60.6764"
                                        y2="63.3812"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stop-color="#F7FFE3" />
                                        <stop offset="1" stop-color="#F9CCCC" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="cusPL5px textAlignCenterMobile">
                                  <h2 className="textForSvg">
                                    Common pitfalls you MUST avoid if you want
                                    grow your business successfully
                                  </h2>
                                  <p className="smallTextForSvg">
                                    Many business owners are their own worst
                                    enemy, and are keeping their businesses from
                                    growing.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="forDreamCustomers cusPt130px">
                            <p className="paragraph customMaxWidth850px">
                              Are you ready to discover exactly how you can
                              create a steady stream of your dream customer?
                            </p>
                            <p className="paragraph customMaxWidth800px">
                              One that will buy from you again and again, while
                              also singing your praises to their friends and
                              family? (aka, send you lots of referrals)
                            </p>
                            <p className="paragraph customMaxWidth800px cusFontWeight700">
                              Join Matt for this no-BS training, where he’ll
                              share exactly how you land your dream client at
                              scale.
                            </p>
                          </div>
                          <div className="cusPt130px">
                            <div className="webinarSubText1">
                              August 11<sup>th</sup> at 4pm EST
                            </div>

                            <WebinarFrom />
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <Footer />
                </ThemeProvider>
              </InstantSearch>
            </CookiesProvider>
          </div>
        </div>
      </DFPSlotsProvider>
    </>
  );
};

export default Webinar;
