import React from "react";
import { MailingListForm } from "@herb/components/Forms";
import "./index.css";

const HerbProductNewsletter = () => {
  return (
    <div className="backgroundImage">
      <div className="productNewslettersForm">
        <div className="productNewslettersFormHeading">
          Herbivores, this one’s for you!
        </div>
        <div className="productNewslettersFormSubHeading">
          Sign up for our newsletter and get ready to explore the largest
          cannabis community, where you'll unlock exclusive news, deals,
          giveaways, and more.
        </div>
        <div className="productNewslettersFormEmailInput">
          <MailingListForm />
        </div>
      </div>
    </div>
  );
};

export default HerbProductNewsletter;
