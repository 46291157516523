import React from "react";
import styled from "@emotion/styled";
import { borderRadius } from "styled-system";

import Box from "./Box";

const Card = styled(Box)(borderRadius);

export default props => (
  <Card
    bg="foreground"
    borderRadius="base"
    css={{ overflow: "hidden" }}
    {...props}
  />
);
