import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import EpoisodeBanner from "@herb/components/EpisodeBanner";
import SectionHeading from "@herb/components/SectionHeading";
import SEO from "@herb/components/seo";
import JsonLd from "@herb/components/Schema/JsonLd";
import SmallVideoArticles from "@herb/components/SmallVideoArticles";
import format from "date-fns/format";
import { paths, time, slugs as createSlug } from "@herb/utils";
// import DisplayAd from "@herb/components/DisplayAd";
import "./index.css";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const VideoEpisode = ({ data, pageContext }) => {
  const allSeries = data.allSeries.edges;
  const series = data.series;
  const mediaid = pageContext.mediaid;
  const { noindex } = pageContext;
  const getSeries = (allSeries, videoID) =>
    allSeries.find(series =>
      series.node.videos.find(v => v.mediaid === videoID)
    ) || {};

  series.videos = series.videos.map(vid => {
    vid.series = getSeries(allSeries, vid.mediaid);
    return vid;
  });

  const video = series.videos.find(vid => vid.mediaid === mediaid);

  const videoSources = video.sources.filter(
    source => source.type.indexOf("video") >= 0
  );

  const largestFile =
    videoSources.length > 0 ? videoSources[videoSources.length - 1] : {};

  const schema = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    name: video.title,
    description: video.description,
    thumbnailUrl: video.image,
    duration: time.durationISO8601(video.duration),
    contentUrl: largestFile.file,
    uploadDate: format(new Date(video.pubdate), "yyyy-MM-dd'T'HH:mm:ssX")
  };

  const breadcrumbs = [
    {
      name: "Watch",
      url: `${paths.watch}/`
    },
    {
      name: "Series",
      url: `${paths.series}/`
    },
    {
      name: series.title,
      url: `${paths.watch}/${series.slug}/`
    },
    {
      name: video.title,
      url: `${paths.watch}/${series.slug}/${createSlug(video.title)}/`
    }
  ];

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <div className="videoTemplate">
      <Layout>
        <>
          {noindex ? (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          ) : (
            <></>
          )}
        </>
        <SEO
          title={video.title}
          description={video.description}
          image={video.image}
          url={`${paths.watch}/${series.slug}/${createSlug(video.title)}/`}
          type="video.other"
          meta={[
            {
              name: "og:video",
              content: largestFile.file
            },
            {
              name: "og:video:url",
              content: largestFile.file
            },
            {
              name: "og:video:secure_url",
              content: largestFile.file
            },
            {
              name: "og:video:type",
              content: largestFile.type
            },
            {
              name: "og:video:width",
              content: largestFile.width
            },
            {
              name: "og:video:height",
              content: largestFile.height
            }
          ]}
          breadcrumbs={breadcrumbs}
        />
        <JsonLd data={schema} />
        <Container>
          <EpoisodeBanner video={video} />
          <SectionHeading heading="more episode" />
          <div className="ForLatestShows">
            {(series.videos || []).map((obj, index) => (
              <div className="forWrapDiv">
                <SmallVideoArticles
                  path={series.slug}
                  key={index}
                  video={obj}
                />
              </div>
            ))}
          </div>
          {/* <DisplayAd
            visibleProps={{
              margin: "80px 0px"
            }}
            adUnit="21810472093/Homepage/"
            banners={[
              {
                breakpoint: "mobile",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "tablet",
                sizes: [[320, 50]]
              },
              {
                breakpoint: "desktop",
                sizes: [[970, 250]]
              }
            ]}
          /> */}
          {/* <SectionHeading heading="more like this" />
          <div className="suggestionsForShows">
            {(series || []).map(({ node }) => (
              <MiniShows serie={node} />
            ))}
          </div> */}
        </Container>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query($series: String!) {
    series(id: { eq: $series }) {
      id
      slug
      title
      coverImage
      bannerImage
      shortDescription
      longDescription
      videos {
        mediaid
        title
        description
        image
        pubdate
        sources {
          type
          file
          width
          height
          label
        }
        tracks {
          kind
          file
        }
        duration
      }
    }
    allSeries(filter: { slug: { nin: ["latest", "trending"] } }) {
      edges {
        node {
          id
          slug
          title
          coverImage
          shortDescription
          videos {
            mediaid
            title
            description
            image
          }
        }
      }
    }
  }
`;

export default VideoEpisode;
