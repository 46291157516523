import React, { useEffect } from "react";
import { graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import WPContent from "@herb/components/WPContent";
import { Container, Flex, PageHeading } from "@herb/ui";
import { prepareContent, transformContent } from "@herb/utils/transformContent";
import theme from "@herbkit/theme";
import { deployUrl } from "@herb/utils";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const PageTemplate = ({ data, pageContext }) => {
  const { wordpressWpPage: page } = data;
  const { noindex } = pageContext;

  const breadcrumbs = [
    {
      name: page.title,
      url: `/${(page || {}).slug}`
    }
  ];

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <Layout>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          <></>
        )}
      </>
      <SEO
        title={page.title}
        description={page.title}
        url={`/${(page || {}).slug}`}
        canonical={`${deployUrl}/${(page || {}).slug}`}
        breadcrumbs={breadcrumbs}
      />
      <Container flexDirection="column" alignItems="center">
        <PageHeading>{page.title}</PageHeading>

        <Flex width={1} mb={[3, 5]} justifyContent="center">
          <WPContent measure="wide" theme={theme}>
            {ReactHtmlParser(prepareContent(page.content || ""), {
              transform: (n, i) => transformContent(n, i)
            })}
          </WPContent>
        </Flex>
      </Container>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpPage(id: { eq: $id }) {
      title
      content
      slug
    }
  }
`;
