import React from "react";
import { ILGMPopUp } from "@herb/components/Forms";
import "./index.css";
const ILGMPopupForm = () => {
  return (
    <div className="trichomePopUP">
      <div className="trichomePopUpForm">
        <div className="trichomePopUpHeading">
          Want $10 off when you buy 10 seeds, and get 10 FREE?
        </div>

        <div className="trichomePopUpEmailInput">
          <ILGMPopUp />
        </div>
      </div>
    </div>
  );
};

export default ILGMPopupForm;
