const colors = {
  neutral: {
    900: "#000000",
    800: "#111111",
    600: "#252525",
    500: "#919191",
    0: "#FFFFFF"
  },
  brand: {
    bold: "#EF4B3C",
    rise: "#FFBD17",
    calm: "#1973C0",
    flow: "#1DA849",
    roam: "#F9B6B1",
    blue: "#2273BA",
    purple: "#7428EF",
    charcoal: "#353439",
    lightPurple: "#F4EEFF",
    lightGrey: "#ADAEB6"
  }
};

colors.background = colors.neutral[900];
colors.backgroundAlt = "#1B1A1A";
colors.foreground = colors.neutral[800];
colors.border = colors.neutral[600];
colors.formBorder = "#373737";
colors.text = colors.neutral[0];
colors.textSubdued = "#767676";
colors.error = "#FF4060";
colors.errorBackground = "#FFD8DF";
colors.tabBorder = "#ADAEB6";
colors.highlight = colors.brand.purple;

export default colors;
