import React from "react";

import { Flex } from "./";

export default props => (
  <Flex
    px={[3, 4, 5]}
    mt={0}
    mb={0}
    mx="auto"
    css={{ maxWidth: "1300px" }}
    {...props}
  />
);
