import React from "react";
import { Index, Configure } from "react-instantsearch-dom";
import CustomDealsHits from "./CustomDealsHits";
import "@herb/pages/deals/index.css";

const DealsSearchResults = ({ dealSearchCategory }) => {
  return dealSearchCategory !== "" ? (
    <Index indexId={"DealsSearchResults"} indexName="Deals">
      <Configure
        aroundLatLngViaIP={false}
        hitsPerPage={6}
        query="*"
        filters={`dealsCategories.nodes.name:"${dealSearchCategory}"`}
      />
      <CustomDealsHits />
    </Index>
  ) : (
    <Index indexId={"DealsSearchResults"} indexName="Deals">
      <Configure
        aroundLatLngViaIP={false}
        hitsPerPage={6}
        query="*"
        filters={
          "dealsCategories.nodes.name:New OR dealsCategories.nodes.name:'Free Shipping' OR dealsCategories.nodes.name:'Discount Codes'"
        }
      />
      <CustomDealsHits />
    </Index>
  );
};

export default DealsSearchResults;
