import React from "react";
import Image from "@herb/components/Image";
import { Link } from "gatsby";
import { fallbackImages } from "../../../utils/constants";
const RelatedLinksCard = ({ link }) => {
  const converTime = time => {
    if (time) {
      if (time.includes("T")) {
        let convertedTime = time.split("T");
        convertedTime = convertedTime[0].split("-");
        convertedTime = `${convertedTime[2]}.${convertedTime[1]}.${convertedTime[0]}`;
        return convertedTime;
      } else {
        return "01.01.2023";
      }
    } else {
      return "01.01.2023";
    }
  };
  return (
    <div
      css={{
        display: "flex",
        margin: "0 0 56px 56px",
        maxWidth: "715px",
        "@media screen and (max-width:500px)": {
          margin: "0 0 16px 0px"
        }
      }}
    >
      <div
        css={{
          marginRight: "24px",
          width: "40%",
          "@media screen and (max-width:500px)": {
            marginRight: "8px",
            minWidth: "164px"
          }
        }}
      >
        <Image
          css={{
            maxHeight: "250px",
            objectFit: "cover",
            objectPosition: "top"
          }}
          src={link.image_url}
          alt={link.title}
          width={300}
          height={"auto"}
        />
      </div>
      <div
        css={{
          maxWidth: "392px",
          width: "60%"
        }}
      >
        <div
          css={{
            display: "flex",
            marginBottom: "16px",
            "@media screen and (max-width:500px)": {
              marginBottom: "8px"
            }
          }}
        >
          <Link
            to={`/${link.category[0]}`}
            css={{
              fontFamily: "Source Code Pro",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              fontStyle: "normal",
              color: "#3041FF",
              textTransform: "capitalize"
            }}
          >
            {link.category[0]} {" | "}
          </Link>{" "}
          <div
            css={{
              fontFamily: "Source Code Pro",
              fontSize: "14px",
              lineHeight: "16px",
              fontWeight: 500,
              fontStyle: "normal",
              marginLeft: "5px"
            }}
          >
            {" "}
            {converTime(link.published_time)}
          </div>
        </div>
        <Link
          to={`${link.url_path}`}
          css={{
            display: "-webkit-box",
            textOverflow: "ellipsis",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            fontFamily: "Graphik",
            fontStyle: "normal",
            fontSize: "32px",
            lineHeight: "40px",
            fontWeight: "700",
            color: "#141313",
            overflow: "hidden",

            "@media screen and (max-width:500px)": {
              fontSize: "24px",
              lineHeight: "32px",
              WebkitLineClamp: 3
            }
          }}
        >
          {link.title}
        </Link>
      </div>
    </div>
  );
};

export default RelatedLinksCard;
