import React from "react";
import styled from "@emotion/styled";

import InstagramEmbed from "react-instagram-embed";

import { Box } from "@herb/ui";

const Embed = styled(Box)`
  iframe {
    margin: 0 auto !important;
  }
`;

export default ({ instagram_id }) => (
  <Embed pb={[3, 4]}>
    <InstagramEmbed
      url={`https://www.instagram.com/p/${instagram_id}`}
      maxWidth={520}
    />
  </Embed>
);
