import React from "react";
import { Button } from "@herb/ui";

export default ({ children, css = {}, ...rest }) => (
  <Button
    css={t => ({
      display: "inline-flex",
      backgroundColor: t.colors ? t.colors.brand.purple : "#7428EF",
      textTransform: "capitalize",

      borderRadius: "8px",
      "&:hover": {
        background: "#591fb6"
      },
      ...css
    })}
    {...rest}
  >
    {children}
  </Button>
);
