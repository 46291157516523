import React from "react";
import Imgix from "react-imgix";
import replaceImageCdn from "@herb/utils/replaceImageCdn";
import "lazysizes";
// import "lazysizes/plugins/attrchange/ls.attrchange";
const Image = ({
  width,
  height,
  src,
  fallback,
  blur = 100,
  alt = "Loading...",
  attributes = {},
  ...restProps
}) => {
  const sourceUrl = src
    ? replaceImageCdn(src)
    : "https://herb-platform-images.imgix.net/wp-content/uploads/2023/01/12084032/dispensary-image.png";

  return (
    <Imgix
      className="lazyload"
      alt={alt}
      css={{ width: "100%" }}
      src={sourceUrl}
      sizes="100vw"
      attributeConfig={{
        src: "data-src",
        srcSet: "data-srcset",
        sizes: "data-sizes"
      }}
      htmlAttributes={{
        src: `${fallback ? fallback : sourceUrl}?w=${width}&h=${height}`,
        alt,
        ...attributes
      }}
      width={width}
      height={height}
      {...restProps}
    />
  );
};

export default Image;
