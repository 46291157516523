import React from "react";
import styled from "@emotion/styled";

import { Tweet } from "react-twitter-widgets";

import { Box } from "@herb/ui";

const Embed = styled(Box)`
  .twitter-tweet {
    margin: 0 auto !important;
  }
`;

export default ({ tweet }) => (
  <Embed pb={[3, 4]}>
    <Tweet
      tweetId={tweet}
      options={{
        width: "520"
      }}
    />
  </Embed>
);
