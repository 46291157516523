import React from "react";

import Link from "@herb/components/Link";
import ExternalLink from "@herb/components/ExternalLink";

export default ({ to, children, ...props }) => {
  const internal = !!to.match(/^https?:\/\/(www\.)?herb\.co/g);

  return internal ? (
    <Link to={to.replace(/^https?:\/\/(www\.)?herb\.co/g, "")} {...props}>
      {children}
    </Link>
  ) : (
    <ExternalLink href={to} {...props}>
      {children}
    </ExternalLink>
  );
};
