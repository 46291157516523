import styled from "@emotion/styled";
import { Box as B } from "@rebass/emotion";
import { style } from "styled-system";

const display = style({
  prop: "display",
  cssProperty: "display",
  key: "display"
});

const Box = styled(B)(display);

export default Box;
